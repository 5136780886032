export class RolePermissionFlag {
  
  constructor(_isViewEnabled: boolean, _isEditEnabled: boolean, _isNewEnabled: boolean, _isDeleteEnabled: boolean) {
    this.isViewEnabled = _isViewEnabled;
    this.isEditEnabled = _isEditEnabled;
    this.isNewEnabled = _isNewEnabled;
    this.isDeleteEnabled = _isDeleteEnabled;
  }

  public isViewEnabled: boolean;
  public isEditEnabled: boolean;
  public isNewEnabled: boolean;
  public isDeleteEnabled: boolean;
}
