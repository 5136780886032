<div class="app-password-box" [class.big]="bigger" [class.error]="hasError" [class.hideheader]="hideheader">
  <div [id]="erroranchor"></div>
  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory" *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>
  <div class="input-password d-table w-100" *ngIf="!disabled">
    <input kendoTextBox [type]="type" (keyup)="onKeyUp($event)" class="d-table-cell w-100" [class.input-showpassword]="!showPasswordHolder" [(ngModel)]="text" [autocomplete]="autocomplete" [maxlength]="maxLength" #input_ref />
    <span class="d-table-cell k-icon k-i-eye" *ngIf="isShowPasswordButtonVisible" (click)="showPasswordHolder = !showPasswordHolder"></span>
  </div>
  <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span class="mandatory" *ngIf="mandatory"  [innerHtml]="mandatoryText"></span> </span>
  <div class="read-only-input-span" [hidden]="!disabled">{{readOnlyValue}}</div>
  <span class="error-span" [innerHtml]="error"></span>
</div>
