


import { CommonFilterDescriptor } from './CommonFilterDescriptor';
import { BaseRequest } from './BaseRequest';
export class BaseFilterRequest extends BaseRequest  {

  public filters: CommonFilterDescriptor[] =  [];
}





