<div class="message-container" [class.opened]="messageService.messages.length" [class.closed]="messageService.messages.length == 0">
  <div class="message-inner-container">
    <div class="message-title">
      <h3>{{localizeByCommon['WARNING']}}</h3>
      <span class="k-icon k-i-close close" (click)="close()"></span>
    </div>

    <div class="text">
      <div *ngFor="let message of messageService.messages;let i=index">
        <h4>An error has occurred ({{i+1}})</h4>
        <pre>{{message}}</pre>
      </div>
    </div>

  </div>
</div>
