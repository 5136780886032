import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonLostPasswordRequest } from '@mydto/CommonLostPasswordRequest';
import { HttpCommonService } from 'app/services/http/_common/http.common.service';
import { ControlsLib_Components_Base_BaseViewComponent, ControlsLib_Components_TextBox_TextBoxComponent } from 'controls';
import { CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_LocalizationService } from 'core';
import { BaseTokenResponse, CommonLoginRequest } from 'dto';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
})
export class LostPasswordComponent extends ControlsLib_Components_Base_BaseViewComponent implements OnInit, OnDestroy {

  @ViewChild('name_ref', { static: true }) nameField: ControlsLib_Components_TextBox_TextBoxComponent;

  @Input() public name: string = '';

  public errorText: string;

  constructor(injector: Injector,
    private localizationService: CoreLib_Services_Common_LocalizationService,
    private deskApiCommonService: HttpCommonService) {
    super(injector);
    this.formReference = 'LostPassword';
  }

  override async ngOnInit() {
    await super.ngOnInit();
  }

  async onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    if (event.keyCode == 13) {
      await this.recover();
    }
  }

  private setFocusOnName() {
    if (this.nameField != null) {
      setTimeout(() => this.nameField.focus(), 500);
    }
  }

  // Effettuo la chiamata alla Web Api per il recupero password
  async recover() {

    this.errorText = '';

    // 1. validazione del campo email
    if (this.name == null || this.name.length == 0) {
      this.errorText = this.localizeByCommon['NAMEREQUIRED'];
      this.setFocusOnName();
      return;
    }

    // 2. invoco l'api per effettuare il recupero password

    // costruisco l'oggetto request
    const lostPasswordRequest = new CommonLostPasswordRequest();
    lostPasswordRequest.name = this.name;
    lostPasswordRequest.cultureName = this.localizationService.getLocale();

    // invoco la Web Api
    const result = await this.deskApiCommonService.lostPassword(lostPasswordRequest);

    // 3. valuto il risultato del recupero password
    await this.processLostPasswordResult(result);
  }

  // Processo la risposta della chiamata alla Web Api per il recupero password
  async processLostPasswordResult(data: BaseTokenResponse): Promise<void> {
    if (data != null && data.success) {
      await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Info, this.localizeByCommon['LOST_PASSWORD_OK'], CoreLib_Enums_PopupButtonsTypes.Ok)
      this.router.navigate(['']);
    } else {
      //await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Info, this.localizeByCommon['LOST_PASSWORD_KO'], CoreLib_Enums_PopupButtonsTypes.Ok)
    }
  }

  public goToHome() {
    this.router.navigate(['login']);
  }

}
