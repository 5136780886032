<div class="app-crud-grid-actions">
  <ng-content select=".primary-extra-buttons"></ng-content>

  <span #anchor id="popupButton{{dataItem.uid}}">
    <app-button [hidden]="!showExpander" (buttonClick)="onToggle($event)" [icon]="'more-vertical'" [buttonClass]="'k-button-white k-button-more-vertical'">
    </app-button>
  </span>

  <div #popup [hidden]="!show" class="desk-grid-col-actions-popup-content" [style.left]="popupLeft" id="popup{{dataItem.uid}}">
    <ng-content select=".secondary-extra-buttons"></ng-content>
  </div>
</div>
