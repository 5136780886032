


export class BaseServiceResponse<T>    {
  
  public statusCode: string = '';
  
  public errorDesc: string = '';
  
  public payload: T = null;
  
}






