import { Component, Injector, Input, OnDestroy, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { CommonAccountDeskGridStateSetColumnOrderSettingsRequest, CommonGridColumnDataDefinition } from 'dto';
import { GridSortSetting } from '../../classes/GridSortSetting';
import { CoreLib_Classes_ObjectHelper, CoreLib_Classes_StringHelper, CoreLib_Services_Http_DeskCommonService } from 'core';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { IBaseGridViewContract } from '../../interfaces/IBaseGridViewContract';
import { HostService } from '../../services/common/host.service';
import { BaseComponent } from '../_base/base.component';
import { ColumnBase, ColumnReorderConfig, FilterService } from '@progress/kendo-angular-grid';
import { ExternalFilterHost } from '../../classes/ExternalFilterHost';
import { GridService } from '../../services/common/grid.service';
import { Subscription } from 'rxjs';
import { DragEndEvent } from '@progress/kendo-angular-sortable';


@Component({
  selector: 'app-grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
  styleUrls: ['./grid-toolbar.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridToolbarComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Input...


  sortableContainer: ElementRef;
  @ViewChild('sortableContainer')
  set _sortableContainer(val: ElementRef) {
    this.sortableContainer = val;
  }

  @Input()
  public override formReference: string;

  @Input()
  public showGridActions: boolean = true;

  @Input()
  public showRightPopupAnchor: boolean = true;

  @Input()
  public rightPopupAnchorIcon: string = 'gear';

  @Input()
  public gridView: IBaseGridViewContract;

  //#endregion


  //#region Properties...

  public isSortSettingsOpen: boolean = false;

  public selectionColumns: GridSortSetting[] = [];

  public selectedColumns: GridSortSetting[] = [];

  public filterColumns: ExternalFilterHost[] = [];

  public currentFilter: CompositeFilterDescriptor;

  public oldFilter: CompositeFilterDescriptor;

  public isFilterSettingsOpen: boolean = false;

  public isColumnOrderSettingsOpen: boolean = false;

  private filterService_changes_subscription: Subscription;

  public orderedColumns: GridSortSetting[] = [];

  //#endregion

  //#region Constructor...

  constructor(injector: Injector, private hostService: HostService, private filterService: FilterService, private gridService: GridService) {
    super(injector);


  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.filterService_changes_subscription?.unsubscribe();
  }
  //#endregion

  //#region Filter Methods...

  public async openFilterSettings() {

    //Mi metto in ascolto sul cambio filtro per memorizzare l'oggetto filtro che andrò eventualmente a salvare
    this.filterService_changes_subscription?.unsubscribe();
    this.filterService_changes_subscription = this.filterService.changes.subscribe((c: CompositeFilterDescriptor) => {
      this.currentFilter = c;
    })

    //Copio in un nuovo oggetto il  filtro attuale
    this.oldFilter = CoreLib_Classes_ObjectHelper.deepCopy(this.gridView.state.filter);

    this.filterColumns = [];

    this.repairColumnsDefinitionsOrderIndex();

    //Costruisco gli elementi per l'interfaccia
    this.gridView.grid.columnList.forEach((column: ColumnBase) => {
      if ((column as any).filterable) { //Se la colonna è filtrabile, costruisco l'oggetto per l'interfaccia.
        let host = new ExternalFilterHost();
        host.column = column;
        host.columnDefinition = this.gridView.gridSetting.columnsDefinitions.find(c => c.valueField?.replace('persData.', '') == (column as any).field?.replace('persData.', ''));
        host.filter = this.gridView.grid.filter;

        if (host.columnDefinition) {
          host.description = this.gridView.getFieldTitle(host.columnDefinition);
          this.filterColumns.push(host);
        }
      }
    });

    //Riordino gli elementi sulla base dell'ordinamento reale delle colonne sulla griglia
    this.filterColumns = this.filterColumns.sort((a: ExternalFilterHost, b: ExternalFilterHost) => { return a.column.orderIndex - b.column.orderIndex });

    this.isFilterSettingsOpen = true;
  }

  public closeFilterSettings() {
    this.gridView.state.filter = this.oldFilter;
    this.gridService.gridFilterRefresh.next(true)
    this.isFilterSettingsOpen = false;
  }

  public async clearFilters() {
    await this.gridView.clearFilters();
    this.isFilterSettingsOpen = false;
  }

  async clearAndSaveFilters() {
    this.gridView.saveFilterState = true;
    await this.clearFilters();
    this.gridView.saveFilterState = false;
  }

  public async applyFilterSettings() {

    this.gridView.state.filter = this.currentFilter;
    (this.gridView.grid as any).filterService.filter(this.gridView.state.filter);
    this.gridService.gridFilterRefresh.next(true)
    this.isFilterSettingsOpen = false;
  }

  public async applyAndSaveFilterSettings() {
    this.gridView.saveFilterState = true;
    await this.applyFilterSettings();
    this.gridView.saveFilterState = false;
  }

  //#endregion

  //#region Sort Methods...

  public async openSortSettings() {

    this.selectionColumns = [];
    this.selectedColumns = [];

    this.repairColumnsDefinitionsOrderIndex();

    //Ripristino in un oggeto l'eventuale stato di ordinamento salvato e gli do un ordine
    let savedState: any = null;
    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.gridView?.gridSetting?.sortSetting)) {
      savedState = JSON.parse(this.gridView.gridSetting.sortSetting);
      let orderIndex: number = 0;
      if (savedState)
        for (const col of savedState) {
          col.selectedOrderIndex = ++orderIndex;
        }
    }


    //Costruisco l'array con tutte le colonne
    let selectionOrderIndex = 0;
    for (const col of this.gridView.gridSetting.columnsDefinitions.sort((a: CommonGridColumnDataDefinition, b: CommonGridColumnDataDefinition) => { return a.orderIndex - b.orderIndex })) {

      let colInSavedState: any;

      //cerco se c'è uno stato di ordinamento salvato per la colonna
      if (savedState) {
        colInSavedState = savedState.find((c: any) => c.Member == col.valueField?.replace('persData.', ''));
      }

      //Costruisco l'elemento per l'interfaccia...
      const colSetting = new GridSortSetting();
      colSetting.field = col.valueField?.replace('persData.', '');
      colSetting.description = this.gridView.getFieldTitle(col);
      colSetting.selectionOrderIndex = ++selectionOrderIndex;

      if (colInSavedState != null) {//Se ho uno stato salvato lo imposto sull'elemento
        if (colInSavedState.SortDirection == null || colInSavedState.SortDirection == 0) {
          colSetting.dir = 'asc';
        } else {
          colSetting.dir = 'desc';
        }
        colSetting.selectedOrderIndex = colInSavedState.selectedOrderIndex;
        this.selectedColumns.push(colSetting);//aggiungo l'elemento tra le colonne selezionate (parte dx)
      } else {
        this.selectionColumns.push(colSetting);//diversamenteo non ho uno stato salvato quindi la colonna non fa parte del sorting. Lo aggiungo tra le colonne da selezionare (parte sx)
      }
    }

    this.isSortSettingsOpen = true;

  }

  public addSort(col: GridSortSetting) {
    col.selectedOrderIndex = this.selectedColumns.length + 1;
    col.dir = 'asc';
    this.selectedColumns.push(col);
    this.selectionColumns.splice(this.selectionColumns.findIndex((c: any) => c == col), 1);
  }

  public changeSortDir(col: GridSortSetting) {
    if (col.dir == 'asc') {
      col.dir = 'desc';
    } else {
      col.dir = 'asc';
    }
  }

  public removeSort(col: GridSortSetting) {
    this.selectionColumns.push(col);
    this.selectedColumns.splice(this.selectedColumns.findIndex((c: any) => c == col), 1);

    for (let item of this.selectedColumns) {
      if (item.selectedOrderIndex >= col.selectedOrderIndex) {
        item.selectedOrderIndex--;
      }
    }
  }

  public moveUpSort(col: GridSortSetting) {
    if (col.selectedOrderIndex > 0) {
      var prev = this.selectedColumns.find((c: any) => c.selectedOrderIndex == col.selectedOrderIndex - 1);
      if (prev != null) {
        col.selectedOrderIndex = col.selectedOrderIndex - 1;
        prev.selectedOrderIndex = prev.selectedOrderIndex + 1;
      }

    }
  }

  public moveDownSort(col: GridSortSetting) {
    if (col.selectedOrderIndex < this.selectedColumns.length) {
      var next = this.selectedColumns.find((c: any) => c.selectedOrderIndex == col.selectedOrderIndex + 1);
      if (next != null) {
        col.selectedOrderIndex = col.selectedOrderIndex + 1;
        next.selectedOrderIndex = next.selectedOrderIndex - 1;
      }

    }
  }

  public closeSortSettings() {
    this.isSortSettingsOpen = false;
  }

  public async applySortSettings() {
    let sortDescriptor: SortDescriptor[] = [];

    for (const sel of this.selectedColumns.sort((a: GridSortSetting, b: GridSortSetting) => { return (a.selectedOrderIndex - b.selectedOrderIndex); })) {

      sortDescriptor.push(<SortDescriptor>{ field: sel.field?.replace('persData.', ''), dir: sel.dir });
    }

    this.gridView.state.sort = sortDescriptor;
    await this.gridView.loadItems();
    this.isSortSettingsOpen = false;
  }

  public async applyAndSaveSortSettings() {
    this.gridView.saveSortState = true;
    await this.applySortSettings();
    this.gridView.saveSortState = false;
  }

  public async clearSortSettings() {
    this.gridView.state.sort = [];
    this.gridView.saveSortState = true;
    await this.gridView.loadItems();
    this.gridView.saveSortState = false;
    this.isSortSettingsOpen = false;
  }

  //#endregion


  //#region Column order Methods...
  public openColumnOrderSettings() {
    this.orderedColumns = [];

    let selectionOrderIndex = 0;

    this.repairColumnsDefinitionsOrderIndex();

    //Costruisco gli elementi per l'interfaccia (uso la classe GridSortSetting che fa già al caso)
    for (const col of this.gridView.gridSetting.columnsDefinitions.sort((a: CommonGridColumnDataDefinition, b: CommonGridColumnDataDefinition) => { return a.orderIndex - b.orderIndex; })) {
      const colSetting = new GridSortSetting();
      colSetting.field = col.valueField?.replace('persData.', '');
      colSetting.description = this.gridView.getFieldTitle(col);
      colSetting.selectionOrderIndex = ++selectionOrderIndex;
      this.orderedColumns.push(colSetting);
    }

    this.isColumnOrderSettingsOpen = true;
  }




  public closeColumnOrderSettings() {
    this.isColumnOrderSettingsOpen = false;
  }

  public async clearColumnOrderSettings() {

    const api: CoreLib_Services_Http_DeskCommonService = this.gridView.injector.get(CoreLib_Services_Http_DeskCommonService);
    const request = new CommonAccountDeskGridStateSetColumnOrderSettingsRequest();
    request.searchSchemaName = this.gridView.gridSetting.searchSchemaName;

    await api.accountDeskGridStateSetColumnOrderSettings(request);

    this.isColumnOrderSettingsOpen = false;
    await this.gridView.loadItems();
    const config = <ColumnReorderConfig>{ before: true };

    this.gridView.grid.columnList.forEach((column: ColumnBase) => {

      let colDef = this.gridView.gridSetting.columnsDefinitions.find(c => c.valueField?.replace('persData.', '') == (column as any).field?.replace('persData.', ''));

      if (colDef != null)
        this.gridView.grid.reorderColumn(column, colDef.orderIndex + 1, config);


    });


  }


  // public moveUpOrder(col: GridSortSetting) {
  //   if (col.selectionOrderIndex > 0) {
  //     var prev = this.orderedColumns.find((c: any) => c.selectionOrderIndex == col.selectionOrderIndex - 1);
  //     if (prev != null) {
  //       col.selectionOrderIndex = col.selectionOrderIndex - 1;
  //       prev.selectionOrderIndex = prev.selectionOrderIndex + 1;
  //     }

  //   }
  // }

  // public moveDownOrder(col: GridSortSetting) {
  //   if (col.selectionOrderIndex < this.orderedColumns.length) {
  //     var next = this.orderedColumns.find((c: any) => c.selectionOrderIndex == col.selectionOrderIndex + 1);
  //     if (next != null) {
  //       col.selectionOrderIndex = col.selectionOrderIndex + 1;
  //       next.selectionOrderIndex = next.selectionOrderIndex - 1;
  //     }

  //   }
  // }

  public applyAndSaveColumnOrderSettings() {

    const config = <ColumnReorderConfig>{ before: true };

    this.gridView.grid.columnList.forEach((column: ColumnBase) => {

      var orderedCol = this.orderedColumns.findIndex(c => c.field?.replace('persData.', '') == (column as any).field?.replace('persData.', ''));

      if (orderedCol > -1) {

        this.gridView.grid.reorderColumn(column, orderedCol + 1, config);

        let colDef = this.gridView.gridSetting.columnsDefinitions.find(c => c.valueField?.replace('persData.', '') == (column as any).field?.replace('persData.', ''));
        if (colDef != null) {
          colDef.orderIndex = orderedCol + 1;
        }
      }


    });



    this.isColumnOrderSettingsOpen = false;
  }
  //#endregion


  private repairColumnsDefinitionsOrderIndex() {
    //Ripristino il valore corretto di orderIndex sulle columns definitions, sulla base dell'ordinamento reale rilevato sulla griglia
    this.gridView.grid.columnList.forEach((column: ColumnBase) => {
      if ((column as any).filterable) {
        let columnDefinition = this.gridView.gridSetting.columnsDefinitions.find(c => c.valueField?.replace('persData.', '') == (column as any).field?.replace('persData.', ''));
        if (columnDefinition != null)
          columnDefinition.orderIndex = column.orderIndex;
      }
    });
  }

  public onDragEnd(e: DragEndEvent): void {
    //Disabilito temporaneamente la possibilità di fare subito drag&drop perchè a causa di un bug dell'oggetto kendo-sortable se l'utente agisce troppo velocemente viene generato un errore che interrompe il funzionamento dell'oggetto
    this.sortableContainer.nativeElement.style.pointerEvents = 'none';
    setTimeout(() => { this.sortableContainer.nativeElement.style.pointerEvents = 'auto'; }, 200);
  }
}
