<div class="centered-box">
  <div class="form">
    <div class="header-container">
      <div class="header">

      </div>
    </div>

    <h1>{{localizeByCommon['LOGIN_TITLE']}}</h1>

    <div class="form-box">

      <p *ngIf="isReloginMode">{{localizeByCommon['RELOGINREQUIRED']}}</p>

      <app-text-box [hidden]="isReloginMode" [header]="localizeByCommon['USERNAME']" [whiteSpaces]="false" [mandatory]="true"
        [(text)]="name" [maxLength]="25" #name_ref (inputKeyUp)="onKeyUp($event)" [textTransformation]="'lowercase'">
      </app-text-box>

      <label class="text-info" *ngIf="isReloginMode">{{localizeByCommon['RELOGIN_INSERTPASSWORD']}}&nbsp;<strong
          style="font-size:20px">{{name}}</strong></label>

      <app-password-box [header]="localizeByCommon['PASSWORD']" [(text)]="password" [maxLength]="20"  [mandatory]="true"
        #password_ref (inputKeyUp)="onKeyUp($event)"></app-password-box>

      <span class="error-span" *ngIf="loginFailed">{{errorText}}</span>

      <app-button [text]="localizeByCommon['BTN_LOGIN']" (buttonClick)="login()">
      </app-button>

    </div>
  </div>
</div>
