<div class="app-crud-grid-actions">

  <app-button [icon]="'check'" [inverted]="true" (buttonClick)="crudView.selectItem(dataItem)"
    *ngIf="crudView.selectionMode == gridSelectionModes.Single">
  </app-button>

  <app-button [icon]="'preview'" [inverted]="true" (buttonClick)="crudView.viewItem(dataItem)"
    *ngIf="crudView.rolePermission.isViewEnabled && crudView.canView && (!crudView.canViewItemProperty || dataItem[crudView.canViewItemProperty])">
  </app-button>

  <ng-container
    *ngIf="crudView.selectionMode == gridSelectionModes.MultipleAllSelected || crudView.selectionMode == gridSelectionModes.MultipleAllUnselected">

    <app-button [icon]="'checkbox-checked'" [inverted]="true" (buttonClick)="crudView.itemUidSelectionChange(false, dataItem.uid)"
      *ngIf="(crudView.selectionMode == gridSelectionModes.MultipleAllSelected && !crudView.hasUidSelected(dataItem.uid)) || (crudView.selectionMode == gridSelectionModes.MultipleAllUnselected && crudView.hasUidSelected(dataItem.uid))">
    </app-button>

    <app-button [icon]="'checkbox'" [inverted]="true" (buttonClick)="crudView.itemUidSelectionChange(true, dataItem.uid)"
      *ngIf="!((crudView.selectionMode == gridSelectionModes.MultipleAllSelected && !crudView.hasUidSelected(dataItem.uid)) || (crudView.selectionMode == gridSelectionModes.MultipleAllUnselected && crudView.hasUidSelected(dataItem.uid)))">
    </app-button>

  </ng-container>

  <ng-content select=".primary-extra-buttons">

  </ng-content>

  <span #anchor id="popupButton{{dataItem.uid}}">
    <app-button [hidden]="!showExpander" (buttonClick)="onToggle($event)" [icon]="'more-vertical'" [buttonClass]="'k-button-white k-button-more-vertical'">
    </app-button>
  </span>

  <div #popup [hidden]="!show" class="desk-grid-col-actions-popup-content" [style.left]="popupLeft"
    id="popup{{dataItem.uid}}" (click)="popupMouseUp()">

    <app-button [text]="localizeByCommon['VIEW']" [icon]="'preview'" (buttonClick)="crudView.viewItem(dataItem)"
      *ngIf="crudView.rolePermission.isViewEnabled && crudView.canView && (!crudView.canViewItemProperty || dataItem[crudView.canViewItemProperty]) && crudView.selectionMode == gridSelectionModes.Single">
    </app-button>


    <app-button [text]="localizeByCommon['BTN_EDIT']" [icon]="'edit'" (buttonClick)="crudView.editItem(dataItem)"
      *ngIf="crudView.rolePermission.isEditEnabled && crudView.canEdit && (!crudView.canEditItemProperty || dataItem[crudView.canEditItemProperty])">
    </app-button>

    <app-button [text]="localizeByCommon['BTN_CLONE']" [icon]="'copy'" (buttonClick)="crudView.cloneItem(dataItem)"
      *ngIf="crudView.rolePermission.isEditEnabled && crudView.canClone && crudView.canNew">
    </app-button>


    <app-button [text]="localizeByCommon['BTN_DELETE']" [icon]="'delete'" (buttonClick)="crudView.deleteItem(dataItem)"
      *ngIf="crudView.rolePermission.isDeleteEnabled && crudView.canDelete && (!crudView.canDeleteItemProperty || dataItem[crudView.canDeleteItemProperty])">
    </app-button>

    <ng-content select=".secondary-extra-buttons"></ng-content>
  </div>

</div>
