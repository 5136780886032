<div *ngIf="isNavigationMenuEnabled" class="app-navigator fix-to-parent-height-wrapper" [class.off]="!isOpenedOneTime" [class.opened]="isOpen"
  [class.closed]="!isOpen && isOpenedOneTime" [class.small-device]="isSmallDevice">
  <div class="app-navigator-inner" fixToParentHeight="'mainmenu'">
    <div class="navigator-item" *ngFor="let group of groups" [class.isactive]="group.isActive">
      <div class="navigator-item-inner" (click)="toggleGroup(group)">
        <div class="img" (click)="open($event, group)">
          <img src="data:image/png;base64,{{group.icon}}" />
        </div>
        <div class="text">{{group.groupDescription}}</div>
        <span class="k-icon k-i-arrow-60-up expander" *ngIf="group.items && !group.isOpen"></span>
        <span class="k-icon k-i-arrow-60-down expander" *ngIf="group.items && group.isOpen"></span>
      </div>
      <div class="inner-level" *ngIf="group.items" [class.opened]="group.isOpen">
        <div *ngFor="let item of group.items" kendoTooltip [showAfter]="2000"
          [title]="'CTRL + click to open in a new tab'" class="text" [class.isactive]="item.isActive"
          (click)="navigate(group, item)">{{item.title}}<span *ngIf="item.badgeText" class="badge-text"> ({{item.badgeText}})</span></div>
      </div>
    </div>
  </div>
</div>