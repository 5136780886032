export class JsonHelper {

    private static reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    private static reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

    public static dateParser(key: any, value: any) {
        if (typeof value === 'string') {
            var a = JsonHelper.reISO.exec(value);
            if (a)
                return new Date(value);
            a = JsonHelper.reMsAjax.exec(value);
            if (a) {
                var b = a[1].split(/[-+,.]/);
                return new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
        }
        return value;
    };
}