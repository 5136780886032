



import { BaseRequest } from './BaseRequest';
export class CommonNotifyLogoutRequest extends BaseRequest {        
  
  public loginUid: string = '00000000-0000-0000-0000-000000000000';
  
  public accountName: string = '';
  
  public isFromTokenExpired: boolean = false;
  
}




