import { Directive, HostListener, Injector, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import * as signalR from '@aspnet/signalr';
import { HubConnection } from '@aspnet/signalr';
import { NgProgress } from '@ngx-progressbar/core';
import { CoreLib_Classes_StringHelper, CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_LicenseService, CoreLib_Services_Common_ToastService, CoreLib_Services_Common_WebViewHostService, CoreLib_Services_Http_DeskCommonService } from 'core';
import { BaseRequest, CommonMenuGroupDataResponse, CommonMenuItemDataResponse, CommonNotifyLogoutRequest, CommonNotifyMenuBadgeChangeRequest, CommonNotifyReloginRequest, CoreConstants } from 'dto';
import { Subscription } from 'rxjs';
import { HostService } from '../../services/common/host.service';
import { BaseViewComponent } from './base-view.component';

@Directive()
export class BaseAppComponent extends BaseViewComponent implements OnInit, OnDestroy {

  //#region Properties...

  public isOpenNavigator: boolean = false;
  public showRelogin: boolean = false;
  public isLoggedIn: boolean = false;
  public name: string = '';

  public topheight: any;
  public groups: CommonMenuGroupDataResponse[];

  public isOpenNotificationsMenu: boolean = false;
  public unreadedNotificationsCount: number = 0;

  public isOpenUserMenu: boolean = false;
  public isEmbedded: boolean = false;
  public hasMenuItems: boolean = false;
  public hasMenuBadges: boolean = false;

  private hostService: HostService;

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    this.hostService.documentClick.next(event);
  }

  //#endregion

  //#region Subscription holders...

  private authenticationService_loginRequested_subscribe: Subscription;
  private authenticationService_reloginRequested_subscribe: Subscription;
  private authenticationService_logoutRequested_subscribe: Subscription;
  private toastService_notificationsCountChanged_subscribe: Subscription;
  private navigationService_badgeChanged_subscribe: Subscription;
  private router_events_subscribe: Subscription;
  private loginHubConnection: HubConnection;
  private pushHubConnection: HubConnection;


  //#endregion

  //#region Constructors...

  ngProgress: NgProgress;

  constructor(
    injector: Injector,
    protected authenticationService: CoreLib_Services_Common_AuthenticationService,
    protected licenseService: CoreLib_Services_Common_LicenseService,
    protected deskApiCommonService: CoreLib_Services_Http_DeskCommonService,
    protected toastService: CoreLib_Services_Common_ToastService,
    protected webViewHostService: CoreLib_Services_Common_WebViewHostService
  ) {
    super(injector);
    this.formReference = 'App';
    this.isDynamicModulesEnabled = false;
    this.startLoginRequestedSubscription();
    this.startLogoutRequestedSubscription();
    this.startNotificationsCountSubscription();
    this.startUserLoggedSignalSubscription();
    this.startReloginRequestedSubscription();

    // this.titleService.setTitle('Smart.1');

    // this.authenticationService.isWindowsAuth = false;

    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.hostService = injector.get(HostService);
    this.ngProgress = injector.get(NgProgress);

    // definisco l'oggetto che verrà utilizzato per comunicare con desk da un attore esterno
    window.desk = window.desk || {};

  }
  //#endregion

  //#region Class implementations...

  override async ngOnInit() {
    await super.ngOnInit();

    const embed = this.querystring('embed')[0];
    this.isEmbedded = embed == '1';

    this.applicationStateService.isEmbedded = this.isEmbedded;



    const debug = this.querystring('debug')[0];

    this.applicationStateService.isDebug = debug == '1';

    window.desk.userLogged = this.userLoggedFromExternalContainer.bind(this);

    if (this.isLoggedIn || this.authenticationService.isWindowsAuth) {
      await this.login(true);
    } else {
      if (this.router.url == '/login-other') {
        this.router.navigate(['/login']);
      }
    }
  }

  userLoggedFromExternalContainer() {

    console.log("userLoggedFromExternalContainer");

    this.zone.run(() => {
      this.authenticationService.loginRequested.next(true);
      this.webViewHostService.sendResult(CoreConstants.DeskResults.RELOGGED);
    });

  }
  querystring(obj: any) {
    const result = [];
    let match;
    const re = new RegExp('(?:\\?|&)' + obj + '=(.*?)(?=&|$)', 'gi');
    while ((match = re.exec(document.location.search)) !== null) {
      result.push(match[1]);
    }
    return result;
  }

  override ngOnDestroy(): void {

    this.authenticationService_reloginRequested_subscribe?.unsubscribe();

    this.authenticationService_loginRequested_subscribe?.unsubscribe();

    this.router_events_subscribe?.unsubscribe();

    this.authenticationService_logoutRequested_subscribe?.unsubscribe();

    this.toastService_notificationsCountChanged_subscribe?.unsubscribe();

    this.navigationService_badgeChanged_subscribe?.unsubscribe();
  }

  //#endregion

  //#region Events...

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event: any) {
    if (!this.applicationStateService.isEmbedded) {
      if (this.deactivationService.isInEditMode() && !this.deactivationService.forceExit) {
        event.returnValue = this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'];
      }
    }
  }

  @HostListener('window:dragover', ['$event'])
  dragover(e: any) {
    // impedisco la drop dei file nel browser
    e = e || event;
    e.preventDefault();
  }

  @HostListener('window:drop', ['$event'])
  drop(e: any) {
    // impedisco la drop dei file nel browser
    e = e || event;
    e.preventDefault();
  }

  //#endregion

  //#region Private Methods...

  public async goHome(): Promise<void> {

    // this.authenticationService.setToken("212131231");

    await this.navigationService.navigate(this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home'));
  }

  private startLoginRequestedSubscription() {
    // mi metto in ascolto della variabile loginRequested e quando cambia agisco di conseguenza
    this.authenticationService_loginRequested_subscribe = this.authenticationService.loginRequested.subscribe(async (value: boolean) => {
      if (value) {
        this.name = this.authenticationService.getAccountName();
        await this.login(value);
      }
    });
  }

  private startLogoutRequestedSubscription() {
    this.authenticationService_logoutRequested_subscribe = this.authenticationService.logoutRequested.subscribe(async (request: CommonNotifyLogoutRequest) => {

      if (this.pushHubConnection != null) {
        this.pushHubConnection.off('pushReceived');
        await this.pushHubConnection.stop();
      }

      this.afterLogout();

      this.navigationService.setTheme('');
      this.navigationService.setIsDefaultFullScreenLogin(false);

      if (request != null && request.loginUid != '')
        this.notifyUserLoggedOut(request);

      this.router_events_subscribe?.unsubscribe();

      this.isLoggedIn = false;

      this.showRelogin = false;

      this.groups = [];
      this.applicationStateService.menuItems = [];

    });
  }

  public async afterLogout() {

  }

  private async login(isloggedIn: boolean) {

    this.isLoggedIn = isloggedIn;

    this.showRelogin = !this.isLoggedIn;

    this.setThemeFromCookie();

    if (isloggedIn || this.authenticationService.isWindowsAuth) {
      await this.loadAccountSettings();

      await this.notifyUserLoggedIn();

      if (!this.isEmbedded) {
        await this.loadNavigator();
      }

      await this.rolePermissionService.init();

      if (!this.isEmbedded) {
        await this.loadNotificationsCount();
      }

      if (!this.isEmbedded) {
        this.startPushSignalSubscription();
        this.startBadgeChangedSubscription();
      }

      await this.afterLogin();
    }
  }

  public async afterLogin() {
  }

  private startBadgeChangedSubscription() {
    this.navigationService_badgeChanged_subscribe?.unsubscribe();

    this.navigationService_badgeChanged_subscribe = this.navigationService.badgeChanged.subscribe((badge: CommonNotifyMenuBadgeChangeRequest) => {
      this.setBadgeText(badge);
    });
  }

  protected async loadAccountSettings() {

    const response = await this.deskApiCommonService.accountSettingGetAll(new BaseRequest());

    if (response != null) {
      for (let i = 0; i < response.settings.length; i++) {
        this.applicationStateService.setting[response.settings[i].settingKey] = response.settings[i].settingValue;
      }
    }
  }

  public startNotificationsCountSubscription(): any {
    this.toastService_notificationsCountChanged_subscribe = this.toastService.notificationsCountChanged.subscribe((count) => {
      this.unreadedNotificationsCount = count;
    });
  }

  private setThemeFromCookie() {
    // Imposto il tema memorizzato nel cookie, solo se non siamo in modalità mobile
    if (this.navigationService.getTheme() != '' && this.isSmallDevice == false) {
      this.navigationService.changeTheme(this.navigationService.getTheme());
    }
  }

  private async notifyUserLoggedIn() {
    // notifico al server il relogin in modo tale che eventuali sessioni in giro per lo stesso utente vengano sloggate
    const request = new CommonNotifyReloginRequest();
    request.accountName = this.authenticationService.getAccountName();
    request.loginUid = this.authenticationService.getLoginUid();
    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(request.loginUid)) {
      await this.deskApiCommonService.notifyRelogin(request);
    }
  }

  private async notifyUserLoggedOut(request: CommonNotifyLogoutRequest) {
    // notifico al server il logout in modo tale che eventuali sessioni in giro per lo stesso utente vengano sloggate
    if (request.loginUid != '') {
      await this.deskApiCommonService.notifyLogout(request);
    }
  }

  private async loadNavigator(): Promise<void> {

    this.hasMenuItems = false;

    const response = await this.deskApiCommonService.menu(new BaseRequest());

    this.groups = [];

    if (response != null) {
      for (const g of response.groups) {

        let addGroup = false;
        const groupToAdd = new CommonMenuGroupDataResponse();
        groupToAdd.items = [];

        for (const i of g.items) {

          if (i.moduleKeys == null) {
            i.moduleKeys = '';
          }

          const modules: string[] = i.moduleKeys.split(';');

          let addItem = true;

          for (const m of modules) {
            if (m) {
              if (!this.licenseService.isActiveModule(m)) {
                addItem = false;
                break;
              }
            }
          }

          if (addItem) {
            addGroup = true;
            groupToAdd.items.push(i);
            this.applicationStateService.menuItems.push(i);
            this.hasMenuItems = true;
          }
        }

        if (addGroup) {
          groupToAdd.groupDescription = g.groupDescription;
          groupToAdd.icon = g.icon;


          this.groups.push(groupToAdd);
        }

      }
    }
    this.setActiveNavigatorItem(this.router.url);

    this.router_events_subscribe?.unsubscribe();

    this.router_events_subscribe = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setActiveNavigatorItem(event.url);
        this.checkNavigationPermissions();
      }
    });

    this.checkNavigationPermissions();
  }

  private checkNavigationPermissions() {
    const homeRoute = this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home');

    // Aggiungere qui eventuali altre pagine che non devono essere sottoposte al controllo...
    // Nota: questo check non viene svolto nel guard CanActivate perchè in quel momento il menù applicativo non è stato caricato...
    // Nota2: questo controllo viene disabilitato se è embedded perchè chi embedda gestisce questa cosa in autonomia e inoltre il menu di navigazione nella modalità embedded non viene caricato.
    if (!this.applicationStateService.isEmbedded && this.router.url !== '/' && this.router.url !== '/login' && this.router.url !== '/login-other' && this.router.url !== '/license-error' && this.router.url !== '/change-password') {
      if (this.router.url !== homeRoute) {
        if (this.applicationStateService.menuItems.filter((c) => this.router.url.indexOf(c.itemRoute) > -1).length === 0 && this.authenticationService.unsecuredUrls.filter((c) => this.router.url.indexOf(c) > -1).length === 0) {
          this.router.navigate([homeRoute]);
        }
      }
    }
  }

  public toggleNavigator(): void {

    this.closeUserMenu();
    this.closeNotificationsMenu();

    if (this.isOpenNavigator) {
      this.isOpenNavigator = false;
      this.collapseAllNavigatorGroups();
    } else {
      this.openNavigatorAndExpandActive();
    }
  }

  public closeNavigator(force: boolean): void {

    if (force) {
      this.collapseAllNavigatorGroups();
      this.isOpenNavigator = false;
    } else {
      let close = true;

      for (const group of this.groups) {
        if (group.isOpen) {
          close = false;
          break;
        }
      }

      this.isOpenNavigator = !close;
    }

  }

  public openNavigator(): void {
    this.isOpenNavigator = true;

    for (const group of this.groups) {
      group.isOpen = false;
      /*  for (const item of group.items) {
         if (item.isActive) {
           group.isOpen = true;
           break;
         }
       } */
    }
  }
  public openNavigatorAndExpandActive(): void {
    this.isOpenNavigator = true;

    for (const group of this.groups) {
      group.isOpen = false;
      for (const item of group.items) {
        if (item.isActive) {
          group.isOpen = true;
          break;
        }
      }
    }
  }
  private setActiveNavigatorItem(url: string): void {
    for (const group of this.groups) {
      group.isActive = false;
      for (const item of group.items) {
        item.isActive = false;
      }
    }
    for (const group of this.groups) {
      for (const item of group.items) {
        var urlTest = url.toLowerCase().trim();
        if (!urlTest.endsWith('/')) {
          urlTest += '/';
        }

        if (urlTest.indexOf(item.itemRoute.toLowerCase().trim() + '/') == 0) {
          this.setTitle(group, item);
          item.isActive = true;
          group.isActive = true;

          break;
        }
      }
    }
  }

  private collapseAllNavigatorGroups(): void {
    if (this.groups) {
      for (const item of this.groups) {
        item.isOpen = false;
      }
    }
  }

  private async loadNotificationsCount(): Promise<void> {
    const response = await this.deskApiCommonService.pushNotificationGetUnreadedCount(new BaseRequest());

    if (response != null) {
      this.unreadedNotificationsCount = response.unreadedCount;
    }
  }
  private startUserLoggedSignalSubscription() {

    let baseUrl = '';
    if (isDevMode()) {
      baseUrl = this.getSignalRBaseUrl();
    }
    this.loginHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(baseUrl + '/loginHub')
      .configureLogging(signalR.LogLevel.Trace)
      .build();

    this.startLoginSignalR();

    this.loginHubConnection.on('userLoggedIn', async (chatMessage: any) => {
      if (this.authenticationService.isInLogin == false) {
        if (this.authenticationService.getLoginUid().length > 0) {
          if (chatMessage.accountName == this.authenticationService.getAccountName()) {
            if (chatMessage.loginUid != this.authenticationService.getLoginUid()) {
              this.isLoggedIn = false;
              this.groups = [];
              this.authenticationService.logout();

              this.deactivationService.forceExit = true;
              this.router.navigate(['/login-other']);

            } else {
              if (this.isLoggedIn == false) {
                await this.login(false);
                if (this.router.url == '/login') {
                  history.back();
                }
              }
            }

          }
        }
      } else {
        if (chatMessage.accountName == this.authenticationService.getAccountName()) {
          if (chatMessage.loginUid == this.authenticationService.getLoginUid()) {
            if (this.isLoggedIn == false) {
              await this.login(false);
              if (this.router.url == '/login') {
                history.back();
              }
            }
          }
        }
      }

    });

    this.loginHubConnection.on('userLoggedOut', (chatMessage: any) => {
      if (this.authenticationService.isInLogin == false) {
        if (this.authenticationService.getLoginUid().length > 0) {
          if (chatMessage.loginUid == this.authenticationService.getLoginUid()) {
            this.isLoggedIn = false;
            this.authenticationService.logout();

            if (chatMessage.isFromTokenExpired) {
              this.authenticationService.reloginRequested.next(true);
            } else {
              this.deactivationService.forceExit = true;
              if (this.router.url != '/login-other') {
                this.router.navigate(['/login']);
              }
            }
          }
        }
      }
    });
  }

  private startLoginSignalR() {
    this.applicationStateService.isInLoadingEnabled = false;
    this.loginHubConnection.start()
      .catch((err) => {
        this.applicationStateService.isInLoadingEnabled = true;
        if (err != null) {
          console.error(err.toString());
        }
        setTimeout(() => {
          this.startLoginSignalR();
        }, 4000);
      }).then(() => {
        this.applicationStateService.isInLoadingEnabled = true;
      });
  }

  private startPushSignalSubscription() {
    let baseUrl = '';
    if (isDevMode()) {
      baseUrl = this.getSignalRBaseUrl();
    }
    this.pushHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(baseUrl + '/pushHub')
      .configureLogging(signalR.LogLevel.Trace)
      .build();

    this.startPushSignalR();

    this.pushHubConnection.on('pushReceived', async (chatMessage: any) => {


      let originDebounce = this.ngProgress.config.debounceTime;
      this.ngProgress.config.debounceTime = 5000;


      if (this.authenticationService.isInLogin == false && this.authenticationService.isLoggedIn()) {
        if (this.authenticationService.getLoginUid().length > 0) {
          if (chatMessage.loginUid == this.authenticationService.getLoginUid()) {

            const toastContent = await this.toastService.getToastContent(chatMessage.uid, chatMessage.pushText, true);
            this.ngProgress.config.debounceTime = 5000;
            if (toastContent != null) {
              this.toastService.set(toastContent);
              this.closeNotificationsMenu();
            }
          }
        }
      }

      this.ngProgress.config.debounceTime = originDebounce;
    });
  }

  public setBadgeText(message: CommonNotifyMenuBadgeChangeRequest) {
    let _hasMenuBadges: boolean = false;
    for (let group of this.groups) {
      for (let item of group.items) {
        if (item.itemRoute == message.itemRoute) {
          item.badgeText = message.badgeText;
        }
        if (item.badgeText != null && item.badgeText != "") _hasMenuBadges = true;
      }
    }
    this.hasMenuBadges = _hasMenuBadges;
  }

  private startPushSignalR() {
    this.applicationStateService.isInLoadingEnabled = false;
    this.pushHubConnection.start().catch((err) => {
      this.applicationStateService.isInLoadingEnabled = true;
      if (err != null) {
        console.error(err.toString());
      }
      setTimeout(() => {
        if (this.isLoggedIn)
          this.startPushSignalR();
      }, 4000);
    }).then(() => {
      this.applicationStateService.isInLoadingEnabled = true;
    });
  }

  private startReloginRequestedSubscription() {
    this.authenticationService_reloginRequested_subscribe = this.authenticationService.reloginRequested.subscribe(async (value: boolean) => {
      
        if (this.pushHubConnection != null) {
          this.pushHubConnection.off('pushReceived');
          await this.pushHubConnection.stop();
        }

        this.popupService.closeRequested.next();
        this.name = this.authenticationService.getAccountName();

        if (value && this.router.url != '/login') {
          this.showRelogin = value;
        }

        this.isLoggedIn = !value;      
    });
  }

  public toggleUserMenu(): void {

    this.closeNavigator(true);
    this.closeNotificationsMenu();

    this.isOpenUserMenu = !this.isOpenUserMenu;
  }

  public closeUserMenu(): void {
    this.isOpenUserMenu = false;
  }

  public toggleNotificationsMenu(): void {

    this.closeNavigator(true);
    this.closeUserMenu();

    this.isOpenNotificationsMenu = !this.isOpenNotificationsMenu;
  }

  public closeNotificationsMenu(): void {
    this.isOpenNotificationsMenu = false;
  }

  public closeAllMenu(): void {
    if (this.isOpenNavigator) {
      this.closeNavigator(true);
    }

    if (this.isOpenUserMenu) {
      this.closeUserMenu();
    }

    if (this.isOpenNotificationsMenu) {
      this.closeNotificationsMenu();
    }
  }

  public getSignalRBaseUrl(): string {
    return "http://localhost:55512";
  }
  public setTitle(group: CommonMenuGroupDataResponse, item: CommonMenuItemDataResponse): void {
    this.titleService.setTitle(group.groupDescription + '/' + item.title);
  }

  //#endregion

}

