import { KeyValue, Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { FilterDescriptor, CompositeFilterDescriptor, toDataSourceRequest } from '@progress/kendo-data-query';
import { CoreLib_Classes_Guid, CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Classes_StringHelper, CoreLib_Classes_ObjectHelper } from 'core';
import { BaseCrudEntityWrapper, BaseEditRequest, BasePersEditDataRequestWrapper, CommonCrudEntityCloneRequest, CommonCrudEntityDeleteResponse } from 'dto';
import { Md5 } from 'ts-md5';

import { CrudPopupResult } from '../../classes/CrudPopupResult';
//import { CrudToolbarManager } from '../../classes/CrudToolbarManager';
import { PagerErrorConfig } from '../../classes/PagerErrorConfig';
import { PanelItemKey } from '../../classes/PanelItemKey';
import { PersCrudPanel } from '../../classes/PersCrudPanel';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { CrudViewModes } from '../../enums/CrudViewModes';
import { IBaseCrudContract } from '../../interfaces/IBaseCrudContract';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { ICrudComponentPanel } from '../../interfaces/ICrudComponentPanel';
import { ICrudEntity } from '../../interfaces/ICrudEntity';
import { ICrudLoaded } from '../../interfaces/ICrudLoaded';
import { ICrudView } from '../../interfaces/ICrudView';
import { HostService } from '../../services/common/host.service';
import { PagerErrorService } from '../../services/common/pager-error.service';
import { ToolbarLeftService } from '../../services/common/toolbar-left.service';
import { ValidationService } from '../../services/common/validation.service';
import { BaseCrudService } from '../../services/http/BaseCrudService';
import { BaseGridViewComponent } from './base-grid-view.component';
import { CrudActivatedModes } from '../../enums/CrudActivatedModes';
import { GridHelperMethods } from '../../classes/GridHelperMethods';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Directive()
export class BaseCrudViewComponent<TGridDto extends ICrudEntity, TAggregationDto> extends BaseGridViewComponent<TGridDto, TAggregationDto> implements IBaseCrudViewContract, OnInit, OnDestroy, AfterViewInit, ICrudView {
  //#region Declarations...


  private hostService: HostService

  private beforeNewViewMode: CrudViewModes;

  private beforeNewCurrentItem: TGridDto;

  private beforeEditViewMode: CrudViewModes;

  public crudView: IBaseCrudViewContract;
  //public crudToolbarManager = new CrudToolbarManager();

  public readonly panelItem_MainData: string = 'mainData';

  public onSelectTab: EventEmitter<string> = new EventEmitter<string>();

  protected validationService: ValidationService;

  private location: Location;

  private pagerErrorService: PagerErrorService;

  private urlUid: string = '';
  private clearInitialFiltersOnBack: boolean = false;
  public baseUrl: string = '';

  public isInCloneMode: boolean = false;

  public titleVisible: boolean = true;

  //#endregion

  //#region Properties...

  public reloadSensor: boolean;

  public currentItem: TGridDto;

  public viewModes: any = CrudViewModes;

  private _viewMode: CrudViewModes = CrudViewModes.List;
  isPreviousAvailableChange = new EventEmitter();
  isNextAvailableChange = new EventEmitter();

  public panelItems: { [index: string]: ICrudComponentPanel } = {};
  public persPanelItems: PersCrudPanel[];

  private isFirstLoad: boolean = true;

  public startInNewMode: boolean = false;
  public startInViewMode: boolean = false;
  public startInEditMode: boolean = false;
  public selectedItemUid: string = '';
  public selectedPanelItemName: string = '';

  public firstViewOrEditMode: boolean = false;

  public canViewItemProperty: string;
  public canEditItemProperty: string;
  public canDeleteItemProperty: string;

  public componentParameters: any;

  public get viewMode(): CrudViewModes {
    return this._viewMode;
  }
  public set viewMode(value: CrudViewModes) {
    this._viewMode = value;
    if (value != CrudViewModes.List) {
      this.firstViewOrEditMode = true;
    }
    this.reloadSensor = !this.reloadSensor;
  }

  public get isPreviousAvailable() {
    return this.currentItem != null && this.items != null && (this.state.skip) + this.items.data.indexOf(this.currentItem) > 0;
  }

  public get isNextAvailable() {
    return this.currentItem != null && this.items != null && (this.state.skip) + this.items.data.indexOf(this.currentItem) < this.items.total - 1;
  }


  public get canViewCurrentItem(): boolean {
    return this.currentItem != null && (this.canViewItemProperty == null || this.currentItem.canView == null || this.currentItem.canView);
  }

  public get canEditCurrentItem(): boolean {
    return this.currentItem != null && (this.canEditItemProperty == null || this.currentItem.canEdit == null || this.currentItem.canEdit);
  }

  public get canDeleteCurrentItem(): boolean {
    return this.currentItem != null && (this.canDeleteItemProperty == null || this.currentItem.canDelete == null || this.currentItem.canDelete);
  }

  private newAdded: string[] = [];
  //#endregion

  //#region Constructor
  constructor(injector: Injector) {
    super(injector);

    this.validationService = injector.get(ValidationService);
    this.location = injector.get(Location);
    this.hostService = injector.get(HostService);
    this.pagerErrorService = injector.get(PagerErrorService);

    this.crudView = this;
  }

  //#endregion

  //#region Methods...

  override async ngOnInit() {

    if (this.applicationStateService.isEmbedded == false && this.isInPopup == false && (window.location.href.indexOf('/edit/') > -1 || window.location.href.indexOf('/view/') > -1)) {

      this.urlUid = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

      this.initialFilters = ({
        filters: [{ field: 'uid', operator: 'eq', value: this.urlUid } as FilterDescriptor],
        logic: 'and',
      } as CompositeFilterDescriptor);
      this.clearInitialFiltersOnBack = true;
    }


    await super.ngOnInit();

    this.validationService.init();

    this.setPanelItems();

    this.persPanelItems = [];
    for (const module of this.pluginLoader.allDataModules) {
      // Se la destinazione è questo componente...
      if (module.destinationComponentName.length > 0 && module.destinationComponentName === this.formReference + 'Component') {
        if (module.destinationHostName === 'crud-panel') {
          this.persPanelItems.push(new PersCrudPanel(module, ''));
        }
      }
    }

  }


  public async onInitCompleted() {
    if (!this.startInNewMode && !this.startInViewMode && !this.startInEditMode) {
      await this.loadItems();
    } else {
      await this.itemsLoaded();
    }
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
  }

  public override async itemsLoaded() {
    this.newAdded = [];
    await super.itemsLoaded();
    if (this.isFirstLoad) {
      this.isFirstLoad = false;

      let isInternalProcessed: boolean = false;

      if (this.startInNewMode) {
        this.newItem();
        isInternalProcessed = true;
      } else {
        if (this.startInViewMode) {
          this.titleVisible = false;
          let data = this.setEmptyInstance();
          data.uid = this.selectedItemUid;
          this.items = <GridDataResult>{ data: [data] as any };
          this.viewItem(this.items.data[0]);
          isInternalProcessed = true;
        }

        if (this.startInEditMode) {
          this.titleVisible = false;
          let data = this.setEmptyInstance();
          data.uid = this.selectedItemUid;
          this.items = <GridDataResult>{ data: [data] as any };
          this.editItem(this.items.data[0]);
          isInternalProcessed = true;
        }

        if (this.selectedPanelItemName != null && this.selectedPanelItemName != '') {
          this.selectTab(this.selectedPanelItemName);
          isInternalProcessed = true;
        }
      }

      if (!isInternalProcessed && this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
        if (window.location.href.indexOf('/new') > -1) {
          this.newItem();
        } else if (window.location.href.indexOf('/edit/') > -1 && this.items.data.length > 0) {
          this.editItem(this.items.data[0]);
        } else if (window.location.href.indexOf('/view/') > -1 && this.items.data.length > 0) {
          this.viewItem(this.items.data[0]);
        }
      }

    }

  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    const ref = this.injector.get(ChangeDetectorRef);
    ref.detach();
  }

  public viewItem(item: TGridDto): void {

    this.selectTab(Object.keys(this.panelItems)[0]);

    this.loadDetail(item);
    this.viewMode = CrudViewModes.View;

    if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
      this.location.replaceState(this.baseUrl + '/view/' + (item as any).uid);
    }

  }

  public newItem(): void {

    this.selectTab(Object.keys(this.panelItems)[0]);

    this.beforeNewViewMode = this.viewMode;
    this.beforeNewCurrentItem = this.currentItem;
    const empty = this.setEmptyInstance();
    empty.uid = CoreLib_Classes_Guid.Empty;
    this.loadDetail(empty);
    this.viewMode = CrudViewModes.New;

    if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
      this.location.replaceState(this.baseUrl + '/new');
    }

  }


  public editItem(item: TGridDto): void {

    this.selectTab(Object.keys(this.panelItems)[0]);
    this.isInCloneMode = false;
    this.beforeEditViewMode = this.viewMode;
    this.loadDetail(item);
    this.viewMode = CrudViewModes.Edit;

    if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
      this.location.replaceState(this.baseUrl + '/edit/' + (item as any).uid);
    }

  }

  public editCurrentItem(): void {
    this.loadDetail(this.currentItem);
    this.beforeEditViewMode = this.viewMode;
    this.viewMode = CrudViewModes.Edit;

    if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
      this.location.replaceState(this.baseUrl + '/edit/' + (this.currentItem as any).uid);
    }

  }

  public async cloneItem(item: TGridDto): Promise<void> {
    const crudService = this.getCrudService() as BaseCrudService<any, any, any, any, any>;
    let cloneRequest = new CommonCrudEntityCloneRequest();
    cloneRequest.uid = item.uid;
    let cloneResponse = await crudService.clone(cloneRequest);
    if (cloneResponse != null) {
      let gridDtoResponse = await crudService.gridByUid(cloneResponse.cloneUid);
      if (gridDtoResponse != null && gridDtoResponse.item != null) {
        this.editItem(gridDtoResponse.item);
        this.isInCloneMode = true;
      }
    }

  }

  public async cloneCurrentItem(): Promise<void> {
    await this.cloneItem(this.currentItem);
  }


  public cancelEdit(): void {
    this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo).then(async (result) => {
      if (result == CoreLib_Enums_PopupResultTypes.Yes) {

        this.clearErrors();

        this.afterCancelEdit(this.currentItem);

        if (this.viewMode == CrudViewModes.New) {
          if (this.beforeNewViewMode == CrudViewModes.View) {
            this.viewItem(this.beforeNewCurrentItem);
          } else {// altrimenti era sicuramente list
            await this.returnToList();
          }
        } else {
          if (this.beforeEditViewMode == CrudViewModes.List) {
            await this.returnToList();
          } else {// altrimenti era sicuramente view
            this.loadDetail(this.currentItem);

            this.viewMode = CrudViewModes.View;
            if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
              this.location.replaceState(this.baseUrl + '/view/' + (this.currentItem as any).uid);
            }
          }
        }


      }
    });
  }



  private clearErrors() {
    for (const item of Object.keys(this.panelItems).map((prop) => new PanelItemKey(prop, this.panelItems[prop]))) {
      item.value.error = false;
    }
    for (const pers of this.persPanelItems) {
      pers.error = false;
    }
  }

  public getPersDataRequests(): BasePersEditDataRequestWrapper[] {
    const persDataRequests = new Array<BasePersEditDataRequestWrapper>();
    for (const persComp of this.persComponents) {
      if (persComp.destinationHostName == 'crud-panel') {
        const wrapper = persComp.getEditRequestWrapper();
        if (wrapper != null) {
          persDataRequests.push(wrapper);
        }
      }
    }
    return persDataRequests;
  }

  public crudPersLoaded(instance: any) {


    const previousPanelIndex = this.persComponents.findIndex((c) => c.destinatioHostName == instance.destinatioHostName && c.destinationHostIdentifier == instance.destinationHostIdentifier);
    if (previousPanelIndex > -1) {
      this.persComponents = this.persComponents.splice(previousPanelIndex, 1);
    }

    this.persComponents.push(instance);
  }


  public initEditRequest<TEditRequest extends BaseEditRequest>(request: TEditRequest): TEditRequest {

    request.uid = this.getEntityUid();

    if (request.uid == CoreLib_Classes_Guid.Empty) {
      request.uid = CoreLib_Classes_Guid.newId();
    }

    return request;
  }

  public async getEditRequest(): Promise<BaseEditRequest> {
    return null;
  }

  public async save(selectAfterSave: boolean, returnToOrigin: boolean) {

    // if (this.currentItem != null && !this.isWrapperLoaded(this.currentItem as any)) {
    //   await this.endSaveUid("", selectAfterSave, false, returnToOrigin);
    //   return true;
    // }

    if (this.currentItem != null && (CoreLib_Classes_Guid.IsValid(this.currentItem.uid) || this.currentItem.uid == CoreLib_Classes_Guid.Empty)) {
      if (await this.validate()) {
        await this.sendSave(await this.getEditRequest(), selectAfterSave, returnToOrigin);
        return true;
      } else {
        this.validationService.showAlert();
        return false;
      }
    }
  }
  

  public async sendSave(request: BaseEditRequest, selectAfterSave: boolean, returnToOrigin: boolean) {


    request.persDataRequests = this.getPersDataRequests();

    let someFieldFilled: boolean = false;
    for (const item in request) {
      if (item != 'uid' && item != 'rollbackAfterSave' && item != 'cultureName' && item != 'constructor' && (request as any)[item] != null) {

        if (Array.isArray((request as any)[item])) {
          someFieldFilled = (request as any)[item].length > 0;
        } else {
          someFieldFilled = true;
        }

        if (someFieldFilled)
          break;

      }
    }

    const continueWithSave: boolean = request.persDataRequests.length > 0 || someFieldFilled;

    if (continueWithSave) {
      const response = await this.getCrudService().edit(request);
      if (response != null) {
        await this.afterSaveExecuted(response.uid);
        await this.endSaveUid(response.uid, selectAfterSave, continueWithSave, returnToOrigin);
      }
    } else {
      await this.endSaveUid(this.currentItem.uid, selectAfterSave, continueWithSave, returnToOrigin);
    }
  }

  public async endSaveUid(savedOrSelectedItem: string, selectAfterSave: boolean, saveExecuted: boolean, returnToOrigin: boolean): Promise<void> {
    // Questo metodo viene invocato dall'implementazione del metodo save, a fine salvataggio...
    if (saveExecuted) {

      //Invalido la cache del crud service collegato
      this.getCrudService().clearCache();

      const response = await this.getItems(this.state, savedOrSelectedItem);

      const savedItem: TGridDto = response?.dataSource?.data[0];

      if (savedItem != null && this.gridSetting?.columnsDefinitions != null) {
        for (const col of this.gridSetting.columnsDefinitions.filter(c => c.isVisible)) {
          GridHelperMethods.processConverters(this, col, [savedItem]);
        }
      }

      this.clearErrors();

      Object.keys(this.panelItems).map((prop) => this.panelItems[prop]).forEach((item) => {
        item.uid = this.getEntityUid();
      });

      // Se il salvataggio è stato eseguito...
      if (savedItem != null) {


        // Aggiorno la griglia con il dato nuovo...
        let foundExisting: boolean = false;
        if (this.items != null) {
          for (const item in this.items.data) {
            if (this.items.data[item].uid == savedItem.uid) {

              await this.itemChanged(this.items.data[item], savedItem);

              this.items.data[item] = savedItem;
              foundExisting = true;
              this.refreshItems();
              break;
            }
          }
        }

        // se non ho trovato l'elemento nella griglia lo aggiungo io in coda...
        if (this.items != null) {
          if (!foundExisting) {
            this.items.total++;
            this.newAdded.push(savedItem.uid);
            this.items.data.push(savedItem);
            await this.itemChanged(null, savedItem);
            this.refreshItems();
          }
        }

        if (selectAfterSave) {
          // Se siamo in modalità popup per una selezione...
          this.selectItem(savedItem);
        } else {
          if (returnToOrigin) {
            // Ricarico l'elemento oppure torno alla lista
            if (this.viewMode == CrudViewModes.New) {
              // Se ero in insert, lo mostro in modalità view
              this.viewItem(savedItem);
            } else {
              // se ero in edit, se l'edit era stato avviato dalla griglia, ritorno alla griglia,
              if (this.beforeEditViewMode == CrudViewModes.List) {
                await this.returnToList();
              } else {// altrimenti se l'edit era stato avviato dalla vista view, ritorno alla vista view
                this.loadDetail(savedItem);
                this.viewMode = CrudViewModes.View;
                if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
                  this.location.replaceState(this.baseUrl + '/view/' + (this.currentItem as any).uid);
                }
              }
            }
          }
        }
      }
    } else {
      // Se dopo una azione di edit, il salvataggio non è stato effettuato, perchè l'utente non ha modificato nessun dato
      if (selectAfterSave) {
        // Se siamo in modalità popup per una selezione...
        this.selectItem(this.currentItem);
      } else if (returnToOrigin) {
        if (this.beforeEditViewMode == CrudViewModes.List) {
          // Se prima della azione di edit si era in modalità list, torno alla lista
          await this.returnToList();
        } else {
          // Diversamente torno alla modalità view
          this.viewMode = CrudViewModes.View;
        }
      }
    }
  }

  public async itemChanged(oldItem: TGridDto, newItem: TGridDto): Promise<void> {

  }

  public async deleteItem(item: TGridDto): Promise<void> {
    const deletedItem = await this.delete(item);
    this.endDel(deletedItem);
  }

  public async deleteCurrentItem(): Promise<void> {
    const deletedItem = await this.delete(this.currentItem);
    this.endDel(deletedItem);

    if (deletedItem != null) {
      await this.returnToList();
    }
  }

  public endDel(deletedItem: TGridDto) {
    if (deletedItem != null) {
      this.itemChanged(deletedItem, null);
      this.items.data = this.items.data.filter((item) => item.uid !== deletedItem.uid);
      this.newAdded = this.newAdded.filter((item) => item !== deletedItem.uid);
      this.items.total--;
      this.refreshItems();
    }
  }

  public async handleDelResult(response: CommonCrudEntityDeleteResponse, item: TGridDto): Promise<TGridDto> {
    if (response != null) {
      return item;
    } else {
      return null;
    }
  }

  public async returnToList(): Promise<boolean> {

    this.selectedOrUnselectedUids = {};

    if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
      if (this.clearInitialFiltersOnBack) {
        this.clearInitialFiltersOnBack = false;
        this.state.filter = null;
        await this.loadItems();
      }

      this.location.replaceState(this.baseUrl + '/list');
    }

    for (const comp of this.getComponents()) {
      if (comp != null) {
        comp.unload();
      }
    }

    if (!this.startInEditMode && !this.startInViewMode && !this.startInNewMode) {
      const empty = this.setEmptyInstance();
      empty.uid = 'cancel';
      this.loadDetail(empty);
      this.viewMode = CrudViewModes.List;

      if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
        this.location.replaceState(this.baseUrl + '/list');
      }

      return true;
    } else {

      this.popupCloseRequested.emit(new CrudPopupResult(GridSelectionModes.None));

      return false;
    }
  }

  public async previousRecord(): Promise<void> {
    let newIndex: number = 0;
    for (let index = 0; index < this.items.data.length; index++) {
      if (this.items.data[index].uid == this.currentItem.uid) {
        newIndex = index - 1;
        break;
      }
    }

    if (newIndex >= 0 || this.state.skip > 0) {
      if (this.items.data[newIndex] == null) {
        this.state.skip = this.state.skip - this.state.take;
        await this.loadItems();
        newIndex = this.state.take - 1;
      }

      if (this.items.data[newIndex] != null) {
        this.loadDetail(this.items.data[newIndex]);

        if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
          this.location.replaceState(this.baseUrl + '/view/' + (this.currentItem as any).uid);
        }
      }
    }
  }

  public async nextRecord(): Promise<void> {
    let newIndex: number = 0;
    for (let index = 0; index < this.items.data.length; index++) {
      if (this.items.data[index].uid == this.currentItem.uid) {
        newIndex = index + 1;
        break;
      }
    }

    if (this.items.data[newIndex] == null && this.items.data.length == this.state.take + this.newAdded.length) {
      this.state.skip = this.state.skip + this.state.take;
      await this.loadItems();
      newIndex = 0;
    }

    if (this.items.data[newIndex] != null) {
      this.loadDetail(this.items.data[newIndex]);

      if (this.applicationStateService.isEmbedded == false && this.isInPopup == false) {
        this.location.replaceState(this.baseUrl + '/view/' + (this.currentItem as any).uid);
      }
    }
  }

  public async saveAndNextRecord(): Promise<void> {
    if (await this.save(false, false)) {
      await this.nextRecord();
    }
  }

  public async saveAndPreviousRecord(): Promise<void> {
    if (await this.save(false, false)) {
      await this.previousRecord();
    }
  }

  public loadDetail(detail: TGridDto): void {

    this.clearErrors();

    for (const comp of this.getComponents()) {
      if (comp != null) {
        comp.unload();
      }
    }

    this.currentItem = detail;

    if (this.currentItem != null) {
      if (this.canViewItemProperty != null) {
        this.currentItem.canView = (this.currentItem as any)[this.canViewItemProperty];
      }
      if (this.canEditItemProperty != null) {
        this.currentItem.canEdit = (this.currentItem as any)[this.canEditItemProperty];
      }
      if (this.canDeleteItemProperty != null) {
        this.currentItem.canDelete = (this.currentItem as any)[this.canDeleteItemProperty];
      }
    }

    this.isNextAvailableChange.emit(this.isNextAvailable);
    this.isPreviousAvailableChange.emit(this.isPreviousAvailable);

    Object.keys(this.panelItems).map((prop) => this.panelItems[prop]).forEach((item) => {

      if (this.currentItem != null) {
        item.preloadUid = this.currentItem.uid;
      } else {
        item.preloadUid = '';
      }
    });

    for (const item of this.persPanelItems) {
      if (this.currentItem != null) {
        item.preloadUid = this.currentItem.uid;
      } else {
        item.preloadUid = '';
      }
    }
  }

  public isWrapperLoaded(item: ICrudLoaded): boolean {
    return item != null && item.isLoaded;
  }

  public isEdited() {

    for (const item of this.getComponents()) {
      if (item.isEdited()) {
        return true;
      }
    }

    for (const persComp of this.persComponents) {
      if (persComp.destinationHostName == 'crud-panel') {
        const wrapper = (persComp as IBaseCrudContract);
        if (wrapper.isEdited()) {
          return true;
        }
      }
    }
  }

  // public checkEdited<TDto>(item: BaseCrudEntityWrapper<TDto>): boolean {
  //   if (item.isDeleted == true && item.uid == CoreLib_Classes_Guid.Empty) {
  //     return false;
  //   } else if (item.isDeleted == true && item.uid != CoreLib_Classes_Guid.Empty) {
  //     return true;
  //   } else {
  //     const check = Md5.hashStr(JSON.stringify(item.dataItem)).toString();
  //     return this.isInCloneMode || this.viewMode == CrudViewModes.New || check != item.originalHash;
  //   }
  // }
  public getEntityUid(): string {
    let returnValue: string = CoreLib_Classes_Guid.Empty;
    for (const p in this.panelItems) {
      if (this.isWrapperLoaded((this.panelItems[p] as any).dataWrapper) && this.panelItems[p].uid != null && this.panelItems[p].uid != '' && this.panelItems[p].uid != CoreLib_Classes_Guid.Empty) {
        returnValue = this.panelItems[p].uid;
        break;
      }
    }

    if (returnValue == CoreLib_Classes_Guid.Empty) {
      // Cerco tra le pers...
      for (const p of this.persPanelItems) {
        if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(p.uid) && p.uid != CoreLib_Classes_Guid.Empty) {
          returnValue = p.uid;
          break;
        }
      }
    }

    return returnValue;
  }

  public override async selectItem(selectedItem: TGridDto) {
    await super.selectItem(selectedItem);
    await this.returnToList();
  }

  selectTimeTimer: any;

  public selectTab(panelName: string) {

    clearTimeout(this.selectTimeTimer);


    if (!this.isSmallDevice) {
      for (const item of this.persPanelItems) {
        if (item.module.destinationHostIdentifier == panelName && item.expanded) {
          return;
        }
      }

      for (const item of Object.keys(this.panelItems).map((prop) => new PanelItemKey(prop, this.panelItems[prop]))) {
        if (item.key == panelName && item.value.expanded) {
          return;
        }
      }
    }

    for (const item of Object.keys(this.panelItems).map((prop) => new PanelItemKey(prop, this.panelItems[prop]))) {
      if (item.key != panelName) {
        item.value.expanded = false;
      }
    }

    if (this.panelItems[panelName] != null && this.panelItems[panelName].expanded == false) {
      this.selectTimeTimer = setTimeout(() => { this.panelItems[panelName].expanded = true; this.onSelectTab.emit(panelName); this.injector.get(ToolbarLeftService).sizeChanged.next(true); this.injector.get(ChangeDetectorRef).detectChanges(); }, 100);

    }

    let persPanel: PersCrudPanel = null;
    for (const pers of this.persPanelItems) {
      if (pers.module.destinationHostIdentifier != panelName) {
        pers.expanded = false;
      } else {
        persPanel = pers;
      }
    }

    if (persPanel != null && persPanel.expanded == false) {
      this.selectTimeTimer = setTimeout(() => { persPanel.expanded = true; this.onSelectTab.emit(panelName); this.injector.get(ToolbarLeftService).sizeChanged.next(true); this.injector.get(ChangeDetectorRef).detectChanges(); }, 100);

    }

  }


  public override isInEditMode(): boolean {
    return (this.viewMode == CrudViewModes.Edit || this.viewMode == CrudViewModes.New);
  }

  public override canDeactivateCalled(currentRoute: ActivatedRouteSnapshot): boolean {

    const location = this.injector.get(Location) as Location;
    const router = this.injector.get(Router) as Router;

    const currentUrlTree = router.createUrlTree([], currentRoute);

    if (this.deactivationService.isInEditMode() || this.popupService.isModalOpen) {

      if (!this.popupService.isModalOpen) {
        this.cancelEdit();
      }

      location.go(currentUrlTree.toString());
      return false;
    } else {
      return true;
    }
  }

  public isLoadedComponentDataWrapper(panelItemName: string): boolean {
    const castedPanelItem = (this.panelItems[panelItemName]) as any;
    return castedPanelItem != null && castedPanelItem.dataWrapper != null && castedPanelItem.dataWrapper.isLoaded; // CoreLib_Classes_Guid.IsValid(this.panelItems[panelItemName].uid);
  }

  public getComponentDataWrapperIfLoaded<T>(panelItemName: string): BaseCrudEntityWrapper<T> {
    if (this.isLoadedComponentDataWrapper(panelItemName)) {
      if (this.panelItems[panelItemName] != null) {
        const castedPanelItem = (this.panelItems[panelItemName]) as any;
        return castedPanelItem.dataWrapper as BaseCrudEntityWrapper<T>;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public getComponentIfLoaded<T extends IBaseCrudContract>(panelItemName: string): T {
    if (this.isLoadedComponentDataWrapper(panelItemName)) {
      for (const compo of this.getComponents()) {
        if (compo.id == panelItemName) {
          return compo as T;
        }
      }
    } else {
      return null;
    }
  }

  public async loadIfNeverLoaded(panelItemName: string): Promise<void> {
    for (const compo of this.getComponents()) {
      if (compo.id == panelItemName && compo.isLoaded() == false) {
        this.panelItems[panelItemName].dataWrapper = await compo.loadIfNeverLoaded(this.currentItem.uid);
        return;
      }
    }
  }

  public getCrudService(): any {
    return null;
  }

  public async refresh() {
    if (this.viewMode == this.viewModes.List) {
      await this.loadItems();
    } else if (this.viewMode == this.viewModes.View || this.viewMode == this.viewModes.Edit) {
      await this.loadItems();

      if (this.currentItem != null) {
        const c = this.items.data.find((c) => c.uid == this.currentItem.uid);

        if (c != null) {
          this.currentItem = c;
        } else {
          // se non ho trovato l'elemento corrente tra quelli nella pagina della griglia è perchè c'è un filtro in griglia che lo esclude
          // lo rileggo puntualmente dal server dato il suo uid
          const crudService = this.getCrudService() as BaseCrudService<any, any, any, any, any>;

          const resp = await crudService.gridByUid(this.currentItem.uid);
          this.currentItem = resp.item;
        }

        if (this.currentItem != null) {
          if (this.canViewItemProperty != null) {
            this.currentItem.canView = (this.currentItem as any)[this.canViewItemProperty];
          }
          if (this.canEditItemProperty != null) {
            this.currentItem.canEdit = (this.currentItem as any)[this.canEditItemProperty];
          }
          if (this.canDeleteItemProperty != null) {
            this.currentItem.canDelete = (this.currentItem as any)[this.canDeleteItemProperty];
          }
        }
      }

      // this.loadDetail(c);

      for (const compo of this.getComponents()) {
        if (compo != null) {
          await compo.refresh();
        }
      }
    }
  }

  public async validate(): Promise<boolean> {

    this.validationService.resetErrors();
    this.clearErrors();

    let returnValue: boolean = true;

    for (const item of this.getComponents()) {
      if (item.isLoaded()) {
        await item.activated(CrudActivatedModes.Validate);
      }
    }

    for (const item of this.getComponents()) {
      if (!await item.validate()) {
        returnValue = false;
        this.validationService.beforeScrollToErrorAction(() => { this.selectTab(item.id); });

        for (const panel of Object.keys(this.panelItems).map((prop) => new PanelItemKey(prop, this.panelItems[prop]))) {
          if (panel.key == item.id) {
            panel.value.error = true;

            // se il componente ha un paginatore (eredita dalla classe BaseCrudListComponent), vado ad impostare l'errore anche sul paginatore
            if ((item as any).pageSize != null) {
              let itemIndex: number = 0;
              let pageSize: number = (item as any).pageSize;

              const pagerErrorConfig = new PagerErrorConfig();
              pagerErrorConfig.formReference = (item as any).formReference;
              pagerErrorConfig.pagesInError = [];
              //per ogni elemento, se ha errori, identifico in che pagina è e aggiungo la pagina al config che verrà passato alla direttiva pagerError tramite il service pagerError
              for (const pagedItem of (item as any).getItemsFiltered()) {
                if (pagedItem.errors != null && Object.keys(pagedItem.errors).length > 0) {
                  const pageIndex = Math.floor(itemIndex / pageSize);
                  if (pagerErrorConfig.pagesInError.indexOf(pageIndex) == -1) {
                    pagerErrorConfig.pagesInError.push(pageIndex);
                  }
                }
                itemIndex++;
              }

              // Tramite il service, invoco la direttiva
              this.pagerErrorService.checkPagedErrors.next(pagerErrorConfig);
            }

          }
        }

        for (const pers of this.persPanelItems) {
          if (pers.module.destinationHostIdentifier == item.id) {
            pers.error = true;
          }
        }


      }
    }

    if (returnValue) {
      for (const item of this.getComponents()) {
        if (!await item.validateAlerts()) {

          this.validationService.beforeScrollToErrorAction(() => { this.selectTab(item.id); });

          for (const panel of Object.keys(this.panelItems).map((prop) => new PanelItemKey(prop, this.panelItems[prop]))) {
            if (panel.key == item.id) {
              panel.value.error = true;
            }
          }
          for (const pers of this.persPanelItems) {
            if (pers.module.destinationHostIdentifier == item.id) {
              pers.error = true;
            }
          }

          return false;
        }
      }
    }

    return returnValue;
  }



  //#endregion

  //#region Mandatory Overridable Methods


  /**
   * Metodo che è necessario sovrascrivere nella classe derivata.
   * Viene richiamato dal motore crud per ottenere una istanza vuota del dto della griglia (il TGridDto).
   * E' necessario restituire semplicemente una nuova istanza dello stesso tipo TGridDto utilizzato nella classe derivata
   * @example
   * return new TGridDto();
   */
  public setEmptyInstance(): TGridDto {
    return null;
  }

  /**
   * Metodo che è necessario sovrascrivere nella classe derivata.
   * In questo metodo è necessario definire i pannelli nella collezione panelItems.
   * @example
   * this.panelItems[this.panelItem_MainData] = new ControlsLib_Classes_CrudPanel<BaseCrudEntityWrapper<CrudIssueGetDataResponse>>(this.localizeByCommon['PANELBARITEM_MAINDATA'], 0);
   */
  public setPanelItems() {
    //Object.keys(this.panelItems).map((prop) => new PanelItemKey(prop, this.panelItems[prop])).filter(c=> c.value.isVisible())
  }



  /**
   * Questo metodo deve essere implementato nella classe derivata.
   * In questo metodo viene implementata la logica di cancellazione di un record
   * A seguito della cancaellazione dell'elemento, deve essere restituito il valore ottenuto richiamando il metodo base handleDelResult(result, item) con  i seguenti parametri:
   * - result: l'oggetto CommonCrudEntityDeleteRequest ottenuto come risposta dal servizio http che ha gestito l'eliminazione.
   * - item: lo stesso item ricevuto in ingresso a questo metodo
   * @param item L'elemento per cui è richiesta l'eliminazione
   * @example
   *  if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByModule['CONFIRM_DELETE_ENTITY'].replace('REPLACEPLACEHOLDER', item.replaceproperty ? item.replaceproperty : ''), CoreLib_Enums_PopupButtonsTypes.YesNo) == CoreLib_Enums_PopupResultTypes.Yes) {
   *   const result = await this.crudIssueService.delete(<CommonCrudEntityDeleteRequest>{ uid: item.uid });
   *   return this.handleDelResult(result, item);
   * } else {
   *   return null;
   * }
   */
  public async delete(item: TGridDto): Promise<TGridDto> {
    return null;
  }

  public getComponents(): IBaseCrudContract[] {
    return [

    ];

  }
  //#endregion

  //#region Optional Overridable Methods

  /**
   * Metodo che è possibile sovrascrivere per impedire la possibilità di visualizzare i dettagli di un elemento.
   */
  public get canView(): boolean {
    return true;
  }

  /**
   * Metodo che è possibile sovrascrivere per impedire la possibilità di modificare un elemento.
   */
  public get canEdit(): boolean {
    return true;
  }

  /**
   * Metodo che è possibile sovrascrivere per impedire la possibilità di inserire un nuovo elemento.
   */
  public get canNew(): boolean {
    return true;
  }

  /**
   * Metodo che è possibile sovrascrivere per impedire la possibilità di eliminare un elemento.
   */
  public get canDelete(): boolean {
    return true;
  }

  /**
   * Metodo che è possibile sovrascrivere per attivare la possibilità di clonare un elemento.
   */
  public get canClone(): boolean {
    return false;
  }
  /**
   * Se è necessario, è possibile effettuare l'override di questo metodo per effettuare azioni di pulizia quando l'utente annulla una modifica o un inserimento
   * @param item L'elemento di tipo ICrudEntity dalla quale si sta uscendo dall'edit.
   */
  public afterCancelEdit(item: ICrudEntity): void {

  }

  public afterSaveExecuted(uid: CoreLib_Classes_Guid): void {

  }

  public async afterCrudComponentLoadNewMode(panelItemName: string) {

  }

  public async afterCrudComponentLoadEditOrViewMode(panelItemName: string) {

  }

  //#endregion

}


