import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FileInfo, FileRestrictions, RemoveEvent, SelectEvent, SuccessEvent, ErrorEvent, UploadComponent, UploadEvent } from '@progress/kendo-angular-upload';
import { CoreLib_Classes_StringHelper, CoreLib_Services_Common_AuthenticationService } from 'core';

import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-temporary-stream-upload',
  templateUrl: './temporary-stream-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemporaryStreamUploadComponent extends BaseInputControlComponent implements OnInit, OnDestroy {

  //#region Properties...

  private loginRequestedSubscription: Subscription;

  public uploadSaveUrl = './deskApi/common/temporaryStreamInsert';
  public uploadRemoveUrl = './deskApi/common/temporaryStreamDelete';

  @ViewChild('kendoUpload') kendoUpload: UploadComponent;

  @Input()
  public accept: string = '';

  public restrictions: FileRestrictions;

  public filesAdded: FileInfo[];

  private uidToRetry: string = null;

  // focus
  

  @Output()
  onSuccess: EventEmitter<SuccessEvent> = new EventEmitter();

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, protected ref: ChangeDetectorRef, private authenticationService: CoreLib_Services_Common_AuthenticationService) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  override async ngOnInit() {
    await super.ngOnInit();

    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.accept)) {
      const extensions = this.accept.split(',');

      this.restrictions = {
        allowedExtensions: [],
      };

      for (const item of extensions) {
        this.restrictions.allowedExtensions.push(item.trim());
      }
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.loginRequestedSubscription?.unsubscribe();
  }

  public uploadEventHandler(e: UploadEvent) {
    // Costruisco i dati da passare al server...
    if (e.files != null && e.files.length > 0) {
      e.data = {
        cultureName: this.translationService.cultureName,
        fileName: e.files[0].name,
        extension: e.files[0].extension,
        uid: e.files[0].uid,
      };
    }
  }

  onBlur() {   
    this.hasFocus = false;
    this.ref.detectChanges();
  }

  onFocus() {
    this.hasFocus = true;
    this.ref.detectChanges();
  }

  public selectEventHandler(e: SelectEvent) {
    // gestisco il fatto che ci possa essere un solo file presente...
    if (this.filesAdded != null && this.filesAdded.length > 0) {
      this.kendoUpload.removeFilesByUid(this.filesAdded[0].uid);
    }
  }

  public removeEventHandler(e: RemoveEvent) {
    if (e.files != null && e.files.length > 0) {
      // Costruisco i dati da passare al server...
      e.data = {
        cultureName: this.translationService.cultureName,
        uid: e.files[0].uid,
      };
    }
  }

  public successEventHandler(e: SuccessEvent) {
    this.onSuccess.emit(e);
  }

  public errorEventHandler(e: ErrorEvent) {
    console.log(e);
    try {

      if (e.operation == 'upload') {
        let filesToRemove: string[] = [];

        let files = e.files as any;
        if (files != null) {
          for (const file of files) {
            this.uidToRetry = file.uid;
          }
        }
      }

      if (e.response.status == 401) {
        this.loginRequestedSubscription?.unsubscribe();
        this.authenticationService.loginRequested.subscribe(c => {
          if(this.uidToRetry != null){
          this.kendoUpload.retryUploadByUid(this.uidToRetry);
          this.uidToRetry = null;
        }
        });
        this.authenticationService.relogin();
      }

    } catch { }
  }

  //#endregion

}
