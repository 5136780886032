



export class BaseTokenResponse   {

  public token: string =  '';
  public success: boolean =  false;
  public friendlyMessage: string =  '';
}





