



import { BaseRequest } from './BaseRequest';
export class CommonPushNotificationSendRequest extends BaseRequest {        
  
  public loginUid: string = '00000000-0000-0000-0000-000000000000';
  
  public pushText: string = '';
  
}




