








import { ReportBaseRequest } from './ReportBaseRequest';
export class ReportGetListByAreaAndEntityUidRequest extends ReportBaseRequest {
      
    public applicationAreaCode: string = '';    
    public applicationAreaEntityUid: string = null;    
}

