import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { BaseComponent } from '../_base/base.component';


@Component({
  selector: 'app-label-color',
  templateUrl: './label-color.component.html',
  styleUrls: ['./label-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelColorComponent extends BaseComponent {

  //#region Properties...

  // COLOR
  private _color: string;

  @Input()
  get color() {
    return this._color;
  }
  set color(val) {
    this._color = val;
  }
  //#endregion

  //#region Constructors...

  constructor(injector: Injector) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  //#endregion
}
