import { IFunc } from "../interfaces/IFunc";

export class ArrayHelper {

  public static move<T>(arr: T[], old_index: number, new_index: number): T[] {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  public static groupBy<T>(list: T[], keyGetter: IFunc<T, any>) {
    const map = new Map<T, any>();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
  }
  
}
