import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, OnDestroy, HostBinding, AfterViewInit, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { BaseFilterCellComponent, FilterOperatorBase, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { DropDownFilterSettings, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_TranslationService } from 'core';
import { GridService } from '../../services/common/grid.service';
import { Subscription } from 'rxjs';
import { ElementHelperMethods } from '../../classes/ElementHelperMethods';
import { debounceTime } from 'rxjs/operators';



@Component({
  selector: 'app-grid-filter-multi-select',
  templateUrl: './grid-filter-multi-select.component.html',
  styleUrls: ['./grid-filter-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridFilterMultiSelectComponent extends BaseFilterCellComponent implements OnInit, AfterViewInit, OnDestroy {

  //#region Declarations...

  @HostBinding('class.highlighted')
  get promoted() { return this.selectedValues != null && this.selectedValues.length > 0 }

  @ViewChild('multiselect')
  public multiselect: MultiSelectComponent;

  @ViewChildren(FilterOperatorBase)
  override operatorList: QueryList<FilterOperatorBase>;

  //#endregion

  //#region Input

  @Input()
  public operator: string;

  @Input()
  public override filter: CompositeFilterDescriptor;

  private _data: any[];
  @Input()
  public get data(): any[] {
    return this._data;
  }
  public set data(value: any[]) {
    this._data = value;

    this.setNoDataText();
  }

  @Input()
  public textField: string;

  @Input()
  public valueField: string;

  @Input()
  public filterField: string;

  @Input()
  public ondemand: boolean = false;

  @Input()
  public itemTemplate: TemplateRef<any>;

  @Input()
  public get selectedValues(): any[] {
    return this._selectedValues;
  }
  public set selectedValues(val: any[]) {
    this._selectedValues = val;
    this.selectedValuesChange.emit(val);
  }

  @Input()
  public dropDownFilterOperator: 'startsWith' | 'contains' = 'startsWith';

  @Output()
  public filterChange = new EventEmitter<string>();

  @Output()
  public selectedValuesChange = new EventEmitter();

  //#endregion

  //#region Properties...

  public hasFilters: boolean;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  private _selectedValues: any[];

  private gridFilterClearedSubscription: Subscription;

  public customOperators: { text: string; value: string; }[] = [];

  public initialOperator: string;

  public noDataText = '';

  //#endregion

  //#region Private variables...

  private searchEnterHandled: boolean = false;
  private searchStopAutoArrowDown: boolean = false;
  private timeoutSendArrowDownAndEnter: any = null;
  private lasttarget: any = null;


  private internalFilterChange = new EventEmitter();

  private internalFilterChangeSubscription: Subscription;

  private tempFilterValue: string = '';

  private minCharToStartSearch: number = 2;

  //#endregion

  //#region Constructor

  constructor(filterService: FilterService, private translationService: CoreLib_Services_Common_TranslationService, private gridService: GridService, private element: ElementRef, private authenticationService: CoreLib_Services_Common_AuthenticationService) {
    super(filterService);
    this.gridFilterClearedSubscription = gridService.gridFilterCleared.subscribe(c => {
      this.selectedValues = [];
      this.hasFilters = false;
      this.operator = this.initialOperator;
    });

    this.internalFilterChangeSubscription = this.internalFilterChange.pipe(debounceTime(300)).subscribe(c => {
      if (this.tempFilterValue.length >= this.minCharToStartSearch)
        this.filterChange.emit(this.tempFilterValue);
    });
  }

  //#endregion

  //#region Methods...

  ngOnInit() {

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterEqOperator'], value: 'eq' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterIsEmptyOperator'], value: 'isempty' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterIsNotEmptyOperator'], value: 'isnotempty' });


    this.setNoDataText();

    this.initialOperator = this.operator;

    this.filterSettings.operator = this.dropDownFilterOperator;

    this.selectedValues = new Array<any>();

    if (this.filter != null) {

      let thisFieldFilters: FilterDescriptor[] = this.processRecursive(this.filter.filters);

      if (thisFieldFilters.length > 0) {
        this.operator = thisFieldFilters[0].operator.toString();
        this.hasFilters = true;
      }
    }

  }


  processRecursive(filters: (CompositeFilterDescriptor | FilterDescriptor)[]): FilterDescriptor[] {

    let thisFieldFilters: FilterDescriptor[] = [];

    for (const f of filters) {

      if ((f as CompositeFilterDescriptor).filters) {

        var nested = this.processRecursive((f as CompositeFilterDescriptor).filters);
        for (const n of nested) {
          thisFieldFilters.push(n);
        }

      } else {
        if ((f as FilterDescriptor).field == this.filterField) {
          thisFieldFilters.push((f as FilterDescriptor))
          try {
            let itemToAdd: any = null;

            if (this.data == null)
              this.data = [];

            itemToAdd = this.data.find((c) => c[this.valueField].toString().toLowerCase() == (f as FilterDescriptor).value.toString().toLowerCase());

            if (itemToAdd == null && this.ondemand && (f as any)?.text) {
              itemToAdd = JSON.parse('{"' + this.valueField + '":"' + (f as FilterDescriptor).value + '","' + this.textField + '":"' + (f as any).text + '"}');
              this.data.push(itemToAdd);
            } else  if (itemToAdd == null && this.ondemand &&  (f as FilterDescriptor).value.toString().toLowerCase() == 'currentaccount') {
              itemToAdd = JSON.parse('{"' + this.valueField + '":"' + (f as FilterDescriptor).value + '","' + this.textField + '":"' + this.authenticationService.getAccountDescription() + '"}');
              this.data.push(itemToAdd);
            }

            if (itemToAdd != null) {
              this.selectedValues.push(itemToAdd);
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    }

    return thisFieldFilters;

  }


  ngAfterViewInit(): void {
    const input = this.element.nativeElement.querySelector('kendo-searchbar');
    if (input != null) {
      input.removeEventListener("keydown", this.handleFilterSearchKeyDown);;
      input.addEventListener("keydown", this.handleFilterSearchKeyDown.bind(this), true);
    }

  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.gridFilterClearedSubscription?.unsubscribe();
    this.internalFilterChangeSubscription?.unsubscribe();
  }

  public operatorChange(newOperator: string) {
    this.operator = newOperator;
    if (this.getHasFilters()) {
      this.setFilter(this.operator == 'isempty' || this.operator == 'isnotempty');
    }
  }

  public getHasFilters(): boolean {
    return (this.selectedValues != null && this.selectedValues.length > 0) || this.operator == 'isempty' || this.operator == 'isnotempty';
  }

  public clearFilter(resetValues: boolean) {

    this.filter = this.removeFilter(this.filterField);
    if (resetValues) {
      this.selectedValues = [];

      this.applyFilter(
        this.filter
      );
    }
    this.hasFilters = false;
  }

  public onChange(value: any): void {
    this.setFilter(false);
  }

  private setFilter(resetValues: boolean) {


    let f: CompositeFilterDescriptor;

    this.hasFilters = this.getHasFilters();

    this.clearFilter(!this.hasFilters);

    if (this.operator == 'isempty' || this.operator == 'isnotempty') {
      f = {
        filters: [
          {
            field: this.filterField,
            operator: this.operator
          }
        ],
        logic: 'or',
      };
    } else {
      f = {
        filters: this.selectedValues.map((value: any) => ({
          field: this.filterField,
          operator: 'eq',
          value: value[this.valueField],
          text: value[this.textField]
        })),
        logic: 'or',
      } as CompositeFilterDescriptor;

    }

    if (f?.filters != null && f.filters.length > 0) {
      if (this.filter != null) {
        this.filter.filters.push(f);
      } else {
        this.filter = ({ filters: [f], logic: 'and' } as CompositeFilterDescriptor);
      }


      this.applyFilter(
        this.filter
      ); // update the root filter


    } else {
      this.clearFilter(true);
    }


    if (this.ondemand && this.data != null && this.data.length > 0)
      this.data.splice(0, this.data.length);

  }


  private setNoDataText() {
    if (this.ondemand == false || this.tempFilterValue.length >= this.minCharToStartSearch) {
      this.noDataText = this.translationService.tryGetLocalizeByCommon("NO_ITEMS_FOUND");
    } else {
      this.noDataText = this.translationService.tryGetLocalizeByCommon("X_CHARS_TOCONTINUE").replace("@@X@@", this.minCharToStartSearch.toString());
    }
  }

  public handleFilter(value: any): void {
    this.tempFilterValue = value;
    this.setNoDataText();
    if (this.ondemand && value.length >= this.minCharToStartSearch) {
      this.internalFilterChange.emit();
    } else {
      if (this.ondemand && this.data != null && this.data.length > 0)
        this.data.splice(0, this.data.length);
    }
  }


  private async handleFilterSearchKeyDown(e: any) {


    if (e.keyCode == 40) {
      clearTimeout(this.timeoutSendArrowDownAndEnter);
    }

    if ((e.keyCode == 13 || e.which == 13) && this.searchEnterHandled == false && this.searchStopAutoArrowDown == false) { //&& elementList
      e.cancelBubble = true;
      e.preventDefault();
      e.stopPropagation();
      this.lasttarget = e.target;
      this.sendArrowDownAndEnter();
    } else if (e.keyCode == 40 || e.which == 40 || e.keyCode == 38 || e.which == 38) {
      this.searchStopAutoArrowDown = true;
    } else {
      this.searchStopAutoArrowDown = false;
    }
  }

  private sendArrowDownAndEnter() {

    clearTimeout(this.timeoutSendArrowDownAndEnter);
    this.timeoutSendArrowDownAndEnter = null;
    if (this.lasttarget != null) {
      this.timeoutSendArrowDownAndEnter = setTimeout(async () => {
        if (this.multiselect.isOpen) {
          await ElementHelperMethods.elementReady(document.documentElement, 'kendo-popup kendo-list ul li', 300);

          this.searchEnterHandled = true;
          var evt = new KeyboardEvent('keydown', <KeyboardEventInit>{ keyCode: 40 });
          this.lasttarget.dispatchEvent(evt);
          var evt = new KeyboardEvent('keydown', <KeyboardEventInit>{ keyCode: 13 });
          this.lasttarget.dispatchEvent(evt);

          setTimeout(async () => {
            this.searchEnterHandled = false;
            this.lasttarget = null;
          }, 500);

        }
      }, 300);
    }
  }


  //#endregion

}
