import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends BaseComponent implements OnInit {

  //#region Properties...

  @Output()
  public buttonClick = new EventEmitter<any>();

  // TYPE
  @Input()
  public type: string;

  // TEXT
  @Input()
  public text: string;

  // TITLE
  @Input()
  public title: string = '';

  // ICON
  @Input()
  public icon: string;

  @Input() width: number;

  // CLASS
  private _buttonClass: string = 'k-primary';

  @Input()
  public get buttonClass(): string {
    return this._buttonClass;
  }
  public set buttonClass(value: string) {
    this._buttonClass = value;
    this.ref.detectChanges();
  }


  @Input()
  public disabled: boolean = false;

  @Input()
  public inverted: boolean = false;

  public additionalClass: string = '';
  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    if (this.text == null) {
      this.additionalClass += " only-icon"
      this.ref.detectChanges();
    } else {
      if (this.icon == null) {
        this.additionalClass += " only-text"
        this.ref.detectChanges();
      }
    }

    if (this.inverted) {
      this.additionalClass += " inverted"
      this.ref.detectChanges();
    }
  }
  
  
  public handleClick(event: any) {
    if (!this.disabled) {
      this.buttonClick.emit(event);
    }
  }

  //#endregion
}
