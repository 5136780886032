<div class="centered-box">
  <div class="form">
    <div class="header-container">
      <div class="header">

      </div>
    </div>

    <h1>{{localizeByCommon['CHANGE_PASSWORD_TITLE']}}</h1>

    <div class="form-box">

      <app-text-box [header]="localizeByCommon['USERNAME']" [(text)]="name" [maxLength]="25" [disabled]="true"></app-text-box>

      <app-text-box [header]="localizeByCommon['PASSWORDOLD']" [(text)]="passwordOld" [maxLength]="20" [type]="'password'"
        #passwordold_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

      <app-text-box [header]="localizeByCommon['PASSWORDNEW']" [(text)]="passwordNew" [maxLength]="20" [type]="'password'"
        #passwordnew_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>
        
      <app-text-box [header]="localizeByCommon['PASSWORDNEWCONFIRM']" [(text)]="passwordNewConfirm" [maxLength]="20" [type]="'password'"
        #passwordnewconfirm_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

      <span class="error-span" *ngIf="changePasswordFailed" [innerText]="errorText"></span>

      <app-button [text]="localizeByCommon['BTN_CONFIRM']" (buttonClick)="change()">
      </app-button>
      
      <app-button *ngIf="!authenticationService.isLoggedIn()" [text]="localizeByCommon['BTN_RETURN_TO_LOGIN']" [buttonClass]="'k-button-white'" (buttonClick)="return()">
      </app-button>

    </div>
  </div>
</div>
