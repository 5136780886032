import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { DatePickerComponent as KendoDatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { DatePipe, IntlService } from '@progress/kendo-angular-intl';
import { isBefore, isAfter } from 'date-fns';


import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';


@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent extends BaseInputControlComponent implements OnInit, AfterViewInit {


    //#region Properties...

    @ViewChild(KendoDatePickerComponent, { static: true })
    public dateobject: KendoDatePickerComponent;

    private focused: boolean = false;

    // focus


    // VALUE
    private _value: Date = null;
    @Input()
    get value() {
        if (this._value != null) {
            return new Date(this._value);
        } else {
            return null;
        }
    }
    set value(val) {
        this._value = val;
        this.valueChange.emit(val);
        this.setHeaderHidden();
        this.setReadOnlyValue();
    }
    @Output() valueChange = new EventEmitter();


    @Input()
    public format: string = 'd';

    private _min: Date = new Date(1900, 0, 1);
    @Input()
    public get min(): Date {
        return this._min;
    }
    public set min(value: Date) {
        this._min = value;
    }

    private _max: Date = new Date(2077, 0, 1);
    @Input()
    public get max(): Date {
        return this._max;
    }
    public set max(value: Date) {
        this._max = value;
    }

    // AUTOCOMPLETE
    @Input()
    public autocomplete: string;

    //#endregion

    //#region Constructor...

    constructor(injector: Injector, validationService: ValidationService, private ref: ChangeDetectorRef) {
        super(injector, validationService);
    }

    //#endregion

    //#region Methods...

    override async ngOnInit() {
        await super.ngOnInit();

        if (this.value != null && this.min != null && this.value < this.min) {
            this.value = this.min;
        } else if (this.value != null && this.max != null && this.value > this.max) {
            this.value = this.max;
        }

    }

    onBlur() {
        if (this.value != null && isBefore(this.value, this.min)) {
            this.value = this.min;
        }

        if (this.value != null && isAfter(this.value, this.max)) {
            this.value = this.max;
        }

        if (this.value == null) {
            setTimeout(() => (this.dateobject.input.dateInput as any).nativeElement.value = '');
        }


        this.hasFocus = false;
        this.ref.detectChanges();
    }

    onFocus() {
        this.hasFocus = true;
        this.ref.detectChanges();
    }

    override async ngAfterViewInit(): Promise<void> {
        await super.ngAfterViewInit();

        this.dateobject.onFocus.subscribe(() => { this.focused = true; this.setHeaderHidden(); });
        this.dateobject.onBlur.subscribe(() => { this.focused = false; this.setHeaderHidden(); });

        try {
            const clearButton: HTMLElement = document.createElement('span');
            clearButton.setAttribute('role', 'button');
            clearButton.className = 'k-icon k-clear-value k-i-close';
            clearButton.addEventListener('click', this.clearHandler);

            this.dateobject.wrapper.nativeElement.insertBefore(clearButton, this.dateobject.wrapper.nativeElement.childNodes[1]);

        } catch {

        }

        try {
            const input = this.dateobject.wrapper.nativeElement.querySelector('span.k-dateinput-wrap input');
            if (input != null) {
                input.setAttribute("autocomplete", this.autocomplete);
            }
        } catch {

        }
    }

    private clearHandler = () => {
        this.value = null;
    }

    public override setReadOnlyValue() {
        if (this.disabled && this.value == null) {
            this.readOnlyValue = '-';
        } else {
            if (this.value != null) {
                const pipe = new DatePipe(this.injector.get(IntlService));
                this.readOnlyValue = pipe.transform(this.value, this.format);
            }
        }

        this.ref.detectChanges();
    }

    public override setHeaderHidden(): void {
        if (this.focused) {
            this.headerhidden = false;
        } else {
            this.headerhidden = this.disabled == false && (this.value == null);
        }

        this.ref.detectChanges();
    }

    //#endregion
}
