<div class="app-root">
  <div class="content" [class.logged]="isLoggedIn">
    <div class="inner-content" [class.scrollable-y]="isEmbedded" (click)="closeAllMenu()">
      <div class="container-fluid">


        <div class="router-wrapper">

          <div class="row front-end" [class.mobile]="isSmallDevice">

            <!-- Header -->
            <div class="page-header">

              <!-- Cookie Banner -->
              <div class="cookie-banner" *ngIf="!cookiePolicyViewed">
                <div class="container">
                  <div class="row cookie-banner-content">
                    <div class="col-10" [innerHtml]="localizeByCommon['COOKIE_POLICY']">
                    </div>
                    <div class="col-2 text-right">
                      <app-button (click)="cookiePolicyViewed = true" text="{{localizeByCommon['BTN_OK']}}"
                        [buttonClass]="'k-primary'"></app-button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Menu -->
              <div class="menu" *ngIf="authenticationService.isLoggedIn()">
                <div class="container">
                  <div class="row">
                    <div class="col-6 user">
                      <i class="k-icon k-i-user"></i>
                      {{translateService.localizeByCommon['WELCOME'].toUpperCase()}}
                      {{authenticationService.getAccountName().toUpperCase()}}
                    </div>
                    <div class="col-6 menu-buttons">
                      <app-button (click)="goToRegistration()"
                        text="{{translateService.localizeByCommon['LINK_REGISTRATION']}}"
                        [buttonClass]="'k-primary'"></app-button>
                      <app-button (click)="logout()" text="{{translateService.localizeByCommon['EXIT']}}"
                        [buttonClass]="'k-primary'"></app-button>
                    </div>
                  </div>
                </div>

              </div>

              <!-- Logo -->
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <img class="logo" *ngIf="isSmallDevice"
                      src="assets/images/header_{{translateService.cultureName}}_mobile.png" (click)="goToHome()">
                    <img class="logo" *ngIf="!isSmallDevice"
                      src="assets/images/header_{{translateService.cultureName}}.png" (click)="goToHome()">
                  </div>
                </div>
              </div>

              <div class="plus-bar"></div>

            </div>

            <!-- Content -->
            <div class="page-content">
              <div class="container container-content">
                <div [hidden]="showRelogin">
                  <router-outlet></router-outlet>
                </div>
                <app-login-pers *ngIf="showRelogin" [name]="name" [isReloginMode]="true"></app-login-pers>
              </div>
            </div>

            <!-- Footer -->
            <div class="page-footer">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">

                    <h4>
                      <br />
                      {{translateService.localizeByCommon['SUPPORT_TEXT']}}
                      <a href="mailTo:{{emailSupport}}">{{emailSupport}}</a>
                      <br /><br />
                      {{translateService.localizeByCommon['CREDENTIALS_TEXT']}}
                      <br />
                      {{translateService.localizeByCommon['POWERED_BY']}}
                      <a href="https://www.ribo.it" title="Ribo Srl" target="_blank" rel="noopener">Ribo Srl</a>
                    </h4>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <app-loader [hidden]="showRelogin" [imageSrc]="'/assets/images/logo-bird.png'"></app-loader>

      </div>
    </div>
  </div>

  <div kendoDialogContainer></div>

  <app-messages></app-messages>

  <app-toast-holder [toastTitle]="title"></app-toast-holder>
</div>