<div>

  <div class="body">

    <img class="fullMobileWidth" src="assets/images/slide.jpg">

    <h1 [innerHtml]="localizeByModule['TITLE']">
    </h1>
  
    <h2>
      {{localizeByModule['SUBTITLE']}}
    </h2>
  
    <!-- <h4>
      {{localizeByModule['PAGE_CONTENT']}}
    </h4> -->
  
    <div class="row" *ngIf="!authenticationService.isLoggedIn()">
      <div class="col-md-6 text-center">
        <app-button (click)="goToRegistration()" text="{{localizeByCommon['BTN_REGISTRATION']}}"
          [buttonClass]="'k-primary register'"></app-button>
      </div>
      <div class="col-md-6 text-center">
        <app-button (click)="goToLogin()" text="{{localizeByCommon['BTN_LOGIN']}}"
          [buttonClass]="'k-primary login'"></app-button>
      </div>
    </div>
    <div class="row" *ngIf="authenticationService.isLoggedIn()">
      <div class="col-md-12 text-center">
        <app-button (click)="goToEventRequest()" text="{{localizeByCommon['EVENT_REQUEST']}}"
          [buttonClass]="'k-primary event-request'"></app-button>
      </div>
    </div>

  </div>

</div>