import { Injectable } from '@angular/core';
import { CommonMenuItemDataResponse} from 'dto';


@Injectable({ providedIn: 'root' })
export class ApplicationStateService {

  //#region Declarations...

  //#endregion

  //#region Properties...
  public isEmbedded: boolean = false;

  public isDebug: boolean = false;

  /**
 * @ignore
 */
  public isInLoadingCrud: number = 0;

  public isInLoadingPopupHost: boolean = false;

  public isInLoadingEnabled: boolean = true;

  public setting: { [index: string]: any } = {};

  public menuItems = new Array<CommonMenuItemDataResponse>();
  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  public restartLoading() {
    setTimeout(() => {
      this.isInLoadingEnabled = true;
    }, 500);
  }

  public getStringSetting(key: string, defaultValue: string) {
    if (this.setting[key] != null) {
      return this.setting[key];
    } else {
      return defaultValue;
    }
  }

  public getNumberSetting(key: string, defaultValue: number) {
    if (this.setting[key] != null) {
      return parseFloat(this.setting[key]);
    } else {
      return defaultValue;
    }
  }

  public getTypedSetting<T>(key: string, defaultValue: T): T {
    if (this.setting[key] != null) {
      return this.setting[key];
    } else {
      return defaultValue;
    }
  }


  public getBooleanSetting(key: string, defaultValue: boolean) {
    if (this.setting[key] != null) {
      if(this.setting[key].toString() == "1"){
        return true;
      } else {
        return false;
      }
    } else {
      return defaultValue;
    }
  }

  //#endregion

}
