import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable, Injector, NgModuleFactory, ViewContainerRef } from '@angular/core';
import { ControlsLib_Classes_ModuleHolder, ControlsLib_Interfaces_IPluginData, ControlsLib_Services_PluginLoader_PluginLoaderService } from 'controls';
import { PLUGIN_EXTERNALS_MAP } from './plugin-externals';


const SystemJs = window.System;

@Injectable()
export class ClientPluginLoaderService extends ControlsLib_Services_PluginLoader_PluginLoaderService {
  constructor(private injector: Injector, private http: HttpClient, private resolver: ComponentFactoryResolver) {
    super();
  }

  provideExternals() {
    Object.keys(PLUGIN_EXTERNALS_MAP).forEach((externalKey) =>
      window.define(externalKey, [], () => (PLUGIN_EXTERNALS_MAP as any)[externalKey]),
    );
  }

  async loadModules() {
    this.allDataModules = [];
    try {
      this.allDataModules = await this.http.get<ControlsLib_Interfaces_IPluginData[]>('./assets/plugins/plugins-config.json').toPromise();
    } catch {
      console.log("no plugins-config.json found");
    }
  }

  async load(moduleInfo: ControlsLib_Interfaces_IPluginData): Promise<ControlsLib_Classes_ModuleHolder> {

    const alreadyLoadedModule = this.allModules.find((e) => e.moduleName == moduleInfo.moduleName);

    if (alreadyLoadedModule == null) {

      const module = await SystemJs.import(moduleInfo.modulePath);

      const holder = new ControlsLib_Classes_ModuleHolder();
      holder.moduleName = moduleInfo.moduleName;
      holder.module = module.default.default;
      holder.exports = module.default.WidgetModule;

      this.allModules.push(holder);

      return holder;
    } else {
      return alreadyLoadedModule;
    }
  }

  async getModuleInstance(module: ControlsLib_Interfaces_IPluginData, viewContainerRef: ViewContainerRef): Promise<any> {
    let returnValue: any = null;

    // Carico il modulo se non è già stato caricato...
    const moduleFactory = await this.load(module);

    if (moduleFactory != null && moduleFactory.module) {
      // Costruisco l'istanza del componente richiesto e la aggancio all'host

      const entryComponentType = moduleFactory.module.entry.find((c: any) => c.name == module.sourceComponentName).type;
      const componentFactory = this.resolver.resolveComponentFactory(
        entryComponentType,
      );

      if (componentFactory) {
        const componentRef = viewContainerRef.createComponent(componentFactory);
        returnValue = componentRef.instance;
        returnValue.destinationHostName = module.destinationHostName;
        returnValue.destinationHostIdentifier = module.destinationHostIdentifier;
      } else {
        console.error('Error loading Desk Pers! componentFactory is null!');
      }
    } else {
      console.error('Error loading Desk Pers! moduleInstance is null OR moduleInstance.module is null!');
    }

    return returnValue;

  }


}
