import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable, of } from 'rxjs';
import { delay, map, retryWhen, takeWhile } from 'rxjs/operators';

import { Type } from '@angular/compiler/src/core';
import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';
import { CommonGridResponse, BaseSearchRequest } from 'dto';
import { BaseRequest } from 'dto';
import { SearchRequest } from '../../../classes/SearchRequest';
import { TypeFactory } from '../../../classes/TypeFactory';
import { AuthenticationService } from '../../common/authentication.service';
import { CommonGridResponseWithGroupBy, CommonBulkChangeResponse } from 'dto';




@Injectable({
  providedIn: 'root',
})
export class BaseService {

  baseUrl: string = './deskApi/';
  // baseUrl: string = "http://localhost:55512/deskApi/";
  loader: number = 0;

  constructor(public http: HttpClient, public authenticationService: AuthenticationService) {

  }

  delayRetryMilliseconds: number = 2000;
  takeRetry: number = 2000;


  handleRetryWhen(error: Observable<any>): Observable<any> {
    
    return error.pipe(map((e) => {
      if (e.status === 401) {
        this.authenticationService.attemptRelogin = true;        
        this.authenticationService.relogin();
        return of(e.status);
      }
      throw (error);
    }), delay(this.delayRetryMilliseconds), takeWhile(() => this.authenticationService.attemptRelogin));
  }

  get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {

    return this.http.get<T>(url, options).pipe(
      retryWhen((error) => this.handleRetryWhen(error)),
    );
  }

  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {

    return this.http.post<T>(url, body, options).pipe(
      retryWhen((error) => this.handleRetryWhen(error)),
    );

  }


  public async baseGridPost<TAggregateDataResponse>(state: DataSourceRequestState, areaName: string, searchRequest: SearchRequest, type: Type, isFirstRequest: boolean, saveSortState: boolean, saveFilterState: boolean, uid: string): Promise<CommonGridResponse<DataResult, TAggregateDataResponse>> {
    const queryStr = `${toDataSourceRequestString(state)}`; // Serialize the state
    const hasGroups = state.group && state.group.length;

    // inizializzo le variabili per la gestione delle date, ho bisogno di un istanza del tipo che dovrò generare per capire quali sono i campi di tipo data
    const dateCols = new Array<string>();
    const factory = new TypeFactory<typeof type>(type);

    let methodName = areaName + 'grid';
    let request: BaseSearchRequest;

    if (searchRequest != null) {
      methodName = methodName + searchRequest.mode;
      request = searchRequest.request;
    }

    if (request == null) {
      request = new BaseSearchRequest();
    }
    
    request.isFirstRequest = isFirstRequest;
    request.saveSortState = saveSortState;
    request.saveFilterState = saveFilterState;

    if(state.filter?.filters != null){
      request.filterStateForSave = JSON.stringify(state.filter.filters);
    }
    request.uid = uid;

    const response: CommonGridResponse<DataResult, TAggregateDataResponse> = await this.post<CommonGridResponse<DataResult, TAggregateDataResponse>>(`${this.baseUrl + methodName}?${queryStr}`, request).toPromise();

    if (response != null && response.dataSource != null) {

      const result = response.dataSource as GridDataResult;

      result.data = hasGroups ? translateDataSourceResultGroups(result.data) : result.data;
      if (result.total) {
        result.total = result.total;
      }
      this.parseDates(type, factory, result, dateCols, response);

      return response;
    } else {
      return null;
    }
  }


  public async baseGridPostWithGroupBy<TGroupByValue, TAggregateDataResponse>(state: DataSourceRequestState, areaName: string, searchRequest: SearchRequest, type: Type, isFirstRequest: boolean, saveSortState: boolean, saveFilterState: boolean): Promise<CommonGridResponseWithGroupBy<DataResult,TGroupByValue, TAggregateDataResponse>> {
    const queryStr = `${toDataSourceRequestString(state)}`; // Serialize the state
    const hasGroups = state.group && state.group.length;

    // inizializzo le variabili per la gestione delle date, ho bisogno di un istanza del tipo che dovrò generare per capire quali sono i campi di tipo data
    const dateCols = new Array<string>();
    const factory = new TypeFactory<typeof type>(type);

    let methodName = areaName + 'grid';
    let request: BaseSearchRequest;

    if (searchRequest != null) {
      methodName = methodName + searchRequest.mode;
      request = searchRequest.request;
    }

    if (request == null) {
      request = new BaseSearchRequest();
    }  

    request.isFirstRequest = isFirstRequest;
    request.saveSortState = saveSortState;
    request.saveFilterState = saveFilterState;

    if(state.filter?.filters != null){
      request.filterStateForSave = JSON.stringify(state.filter.filters);
    }
    
    const response: CommonGridResponseWithGroupBy<DataResult,TGroupByValue, TAggregateDataResponse> = await this.post<CommonGridResponseWithGroupBy<DataResult,TGroupByValue, TAggregateDataResponse>>(`${this.baseUrl + methodName}?${queryStr}`, request).toPromise();

    if (response != null && response.dataSource != null) {

      const result = response.dataSource as GridDataResult;

      result.data = hasGroups ? translateDataSourceResultGroups(result.data) : result.data;
      if (result.total) {
        result.total = result.total;
      }
      this.parseDates(type, factory, result, dateCols, response);

      return response;
    } else {
      return null;
    }
  }



  public async baseCommitBulk(state: DataSourceRequestState, areaName: string, searchRequest: SearchRequest): Promise<CommonBulkChangeResponse> {
    const queryStr = `${toDataSourceRequestString(state)}`; // Serialize the state

    // inizializzo le variabili per la gestione delle date, ho bisogno di un istanza del tipo che dovrò generare per capire quali sono i campi di tipo data

    let methodName = areaName + 'commit';
    let request: BaseRequest;

    if (searchRequest != null) {
      methodName = methodName + searchRequest.mode;
      request = searchRequest.request;
    }

    if (request == null) {
      request = new BaseRequest();
    }

    const response: CommonBulkChangeResponse = await this.post<CommonBulkChangeResponse>(`${this.baseUrl + methodName}?${queryStr}`, request).toPromise();

    if (response != null) {

      return response;
    } else {
      return null;
    }
  }




  private parseDates<TAggregateDataResponse>(type: Type, factory: TypeFactory<Type>, result: GridDataResult, dateCols: string[], response: CommonGridResponse<DataResult, TAggregateDataResponse>) {
    if (type != null) {
      // estraggo i nomi dei campi di tipo Data dall'istanza del tipo che dovrò generare
      const fakeInstance = factory.getNew();
      if (result.data.length > 0 && result.data[0] != null) {
        for (const col of Object.keys(result.data[0])) {
          const propType = Object.getOwnPropertyDescriptor(fakeInstance, col);
          if (propType != null) {
            const propValue = propType.value;
            if (propValue instanceof Date) {
              dateCols.push(col);
            } else {
              const colDef = response.columnsDefinitions.find(c => c.valueField == col);
              if (colDef != null && (colDef.filterType == "date" || colDef.filterType == "time")) {
                dateCols.push(col);
              }
            }
          }
        }
      }

      // ciclo gli elementi restituiti dalla mia get e vado a convertire al tipo Data Javascript i campi di tipo Data che il JSON mi ritorna come stringa
      for (const item of result.data) {
        for (const dateCol of dateCols) {
          if (item[dateCol] != null)
            item[dateCol] = new Date(item[dateCol]);
        }
      }
    }
  }
}
