
<ng-container *ngIf="columnDefinition.template == null">
  <div *ngIf="columnDefinition.filterType == 'text' || columnDefinition.filterType == 'code' || columnDefinition.filterType == 'multiselect' || columnDefinition.filterType == 'numeric'">
    {{getFieldCellValue(dataItem, columnDefinition)}}
  </div>
  <app-label-bool *ngIf="columnDefinition.filterType == 'boolean'" [checked]="getFieldCellValue(dataItem, columnDefinition)"></app-label-bool>
  <app-label-date *ngIf="columnDefinition.filterType == 'date'" [value]="getFieldCellValue(dataItem, columnDefinition)" [format]="columnDefinition.format"></app-label-date>  
  <app-label-date *ngIf="columnDefinition.filterType == 'time'" [value]="getFieldCellValue(dataItem, columnDefinition)" [format]="columnDefinition.format"></app-label-date>
</ng-container>
<ng-container *ngIf="columnDefinition.template != null">
  <ng-container [ngTemplateOutlet]="columnDefinition.template" [ngTemplateOutletContext]="{dataItem:dataItem}"></ng-container>
</ng-container>
