import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ToolbarLeftService  {

  //#region Declarations...

  public sizeChanged = new Subject<boolean>();
  public docheckChanged = new Subject<boolean>();

  
  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...
  
  //#endregion

}
