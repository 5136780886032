<div class="app-label-box" [class.big]="bigger">
  <label class="header" title="{{header}}"><span class="text" [innerHtml]="header"></span></label>
  
  <div class="read-only-input-span" [style.color]="color" *ngIf="template == null">{{text}}</div>

  <div class="read-only-input-span" *ngIf="template && context">
    <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{$implicit: context}">
    </ng-container>
  </div>

</div>
