import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnDestroy } from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { CommonGridColumnDataDefinition } from 'dto';
import { Subscription, throwError } from 'rxjs';
import { GridHelperMethods } from '../../classes/GridHelperMethods';
import { GridService } from '../../services/common/grid.service';
import { BaseComponent } from '../_base/base.component';
import { CoreLib_Classes_SearchRequest, CoreLib_Classes_StringHelper } from 'core';


@Component({
  selector: 'app-grid-dynamic-filter-cell',
  templateUrl: './grid-dynamic-filter-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridDynamicFilterCellComponent extends BaseComponent implements OnDestroy {

  //#region Input...

  @Input()
  public column: any;

  @Input()
  public filter: any;

  @Input()
  public columnDefinition: CommonGridColumnDataDefinition;

  @Input()
  public parentContext: any;

  @Input()
  public view: any;

  //#endregion

  //#region Properties...
  
  public minDate: Date;

  public maxDate: Date;

  public visible: boolean = true;

  //#endregion
  
  //#region Private variables...

  private gridService_gridFilterRefresh_Subscription: Subscription;

  private filterService_changes_Subscription: Subscription;
  
  //#endregion


  //#region Constructors...

  constructor(injector: Injector, gridService: GridService, private ref: ChangeDetectorRef, private filterService: FilterService) {
    super(injector);
    this.minDate = new Date(1900, 0, 1);
    this.maxDate = new Date(2077, 0, 1);

    this.filterService_changes_Subscription = this.filterService.changes.subscribe(c => {
      this.filter = c; 
      this.column = this.column;
      this.ref.detectChanges();
    });

    this.gridService_gridFilterRefresh_Subscription = gridService.gridFilterRefresh.subscribe(async c => {

      this.visible = false;
      this.ref.detectChanges();

      setTimeout(() => {
        this.visible = true;
        this.ref.detectChanges();
      }, 500);

    });

  }

  //#endregion

  //#region Methods...  

  override ngOnDestroy(): void {
      super.ngOnDestroy();
      this.gridService_gridFilterRefresh_Subscription?.unsubscribe();
      this.filterService_changes_Subscription?.unsubscribe();
  }

  public getValueFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getValueFieldName(columnDefinition);
  }

  public convertToOperator(value: string): 'startsWith' | 'contains' {
    return value as 'startsWith' | 'contains';
  }

  public getThisFieldValue(fieldName: string): any {
    if (fieldName.indexOf('.') > -1) {
      const splitted = fieldName.split('.');
      if (this.parentContext[splitted[0]] != null) {
        return this.parentContext[splitted[0]][splitted[1]];
      } else if (this.view[splitted[0]] != null) {
        return this.view[splitted[0]][splitted[1]];
      }
      else {
        console.error('Could not find ' + splitted[0] + ' in parent context in getThisFieldValue method in DynamicFilterCellComponent');
        return null;
      }
    } else {
      if (this.parentContext[fieldName] != null) {
        return this.parentContext[fieldName];
      } else if (this.view[fieldName] != null) {
        return this.view[fieldName];
      } else {
        console.error('Could not find ' + fieldName + ' in parent context in getThisFieldValue method in DynamicFilterCellComponent');
        return null;
      }
    }
  }
  public getThisFunction(functionName: string, parameter: any): any {

    
    let requestMode: CoreLib_Classes_SearchRequest;
    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.columnDefinition.filterChangeRequestMode)) {
      requestMode = new CoreLib_Classes_SearchRequest();
      requestMode.mode = this.columnDefinition.filterChangeRequestMode;
    }
    
    if (functionName.indexOf('.') > -1) {
      const splitted = functionName.split('.');
      if (this.parentContext[splitted[0]] != null) {
        return this.parentContext[splitted[0]][splitted[1]](parameter, this.columnDefinition.dropDownFilterOperator, requestMode);
      } else if (this.view[splitted[0]] != null) {
        return this.view[splitted[0]][splitted[1]](parameter, this.columnDefinition.dropDownFilterOperator, requestMode);
      } else {
        console.error('Could not find ' + splitted[0] + ' in parent context in getThisFunction method in DynamicFilterCellComponent');
        return null;
      }
    } else {
      if (this.parentContext[functionName] != null) {
        return this.parentContext[functionName](parameter, this.columnDefinition.dropDownFilterOperator, requestMode);
      } else if (this.view[functionName] != null) {
        return this.view[functionName](parameter, this.columnDefinition.dropDownFilterOperator, requestMode);
      } else {
        console.error('Could not find ' + functionName + ' in parent context in getThisFunction method in DynamicFilterCellComponent');
        return null;
      }
    }
  }
  //#endregion
}
