


import { IBaseCrudSharedDataResponse } from './IBaseCrudSharedDataResponse';
export class BaseGridDataResponse   implements IBaseCrudSharedDataResponse {

  public overallCount: number =  0;
  public uid: string =  '00000000-0000-0000-0000-000000000000';
  public description: string =  '';
  public computed: string =  '';
  public fullDescription: string =  '';
  public timeInsert: Date =  new Date(0);
  public userInsert: string =  '';
  public timeUpdate: Date =  new Date(0);
  public userUpdate: string =  '';
  public isNew: boolean =  false;
  public isEdited: boolean =  false;
  public persData: { [key: string]: any; } =  {};
  public originalHash: string =  '';
}





