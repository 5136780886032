



export class BaseResponse   {

}





