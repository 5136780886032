import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'capitalizeFirstLetter', pure: false })
export class CapitalizeFirstLetterPipe implements PipeTransform {
    constructor() {
    }

    transform(value: string): string {
        if (value != null) {
            if (value.indexOf(' ') > -1) {
                let newString = '';
                let splitted = value.trim().split(' ');
                
                for (const item of splitted) {
                    let first = item.substring(0, 1).toUpperCase();
                    newString += first + item.substring(1) + ' ';
                }

                return newString.trim();
            } else {
                let first = value.trim().substring(0, 1).toUpperCase();
                return first + value.trim().substring(1);
            }

        } else {
            return '';
        }
    }
}
