import { Component, Injector, OnInit } from '@angular/core';
import { CoreLib_Services_Common_MessageService } from 'core';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent extends BaseComponent {

  //#region Constructors...

  constructor(injector: Injector, public messageService: CoreLib_Services_Common_MessageService) {
    super(injector);
    this.messageService.errorVisualizerLoaded = true;
  }

  //#endregion

  //#region Methods...

  close(): void {
    this.messageService.clear();
  }

  //#endregion
}
