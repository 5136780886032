import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonGridColumnDataDefinition } from 'dto';



@Component({
  selector: 'app-grid-dynamic-cell',
  templateUrl: './grid-dynamic-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridDynamicCellComponent {

  //#region Properties...

  @Input()
  public dataItem: any;

  @Input()
  public columnDefinition: CommonGridColumnDataDefinition;

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  public getFieldCellValue(dataItem: any, columnDefinition: CommonGridColumnDataDefinition): any {
    if (columnDefinition.textField != null && columnDefinition.textField.length > 0) {
      if (columnDefinition.isPersField && dataItem['persData'] != null) {
        return dataItem['persData'][columnDefinition.textField];
      } else {
        if (columnDefinition.textField.indexOf("_") > -1) {
          let parts = columnDefinition.textField.split("_");
          if (parts.length > 1 && dataItem[parts[0]] != null)
            return dataItem[parts[0]][parts[1]];
          else
            return dataItem[columnDefinition.textField];
        } else {
          return dataItem[columnDefinition.textField];
        }
      }
    } else {
      if (columnDefinition.isPersField && dataItem['persData'] != null) {
        return dataItem['persData'][columnDefinition.valueField];
      } else {
        if (columnDefinition.valueField.indexOf("_") > -1) {
          let parts = columnDefinition.valueField.split("_");
          if (parts.length > 1 && dataItem[parts[0]] != null)
            return dataItem[parts[0]][parts[1]];
          else
            return dataItem[columnDefinition.valueField];
        } else {
          return dataItem[columnDefinition.valueField];
        }
      }
    }
  }
  //#endregion
}
