




export class CommonAccountDeskGridStateColumnOrderSettingDataRequest  {        
  
  public fieldName: string = '';
  
  public orderIndex: number = 0;
  
}




