









export class ReportBaseRequest  {
      
    public cultureName: string = '';    
}

