import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreLib_Services_Common_ApplicationStateService } from 'core';

@Component({
  selector: 'app-default-route',
  templateUrl: './default-route.component.html',
})
export class DefaultRouteComponent implements OnInit {

  constructor(private router: Router, private applicationStateService: CoreLib_Services_Common_ApplicationStateService) {

  }

  ngOnInit() {
    this.router.navigate([this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home')]);
  }
}
