
import { Injectable, Injector } from '@angular/core';
import { CoreLib_Services_Common_ApplicationInitService, CoreLib_Services_Http_DeskCommonService } from 'core';
import { CoreLib_Services_Common_ApplicationStateService } from 'core';
import { BaseRequest } from 'dto';
import { HttpCommonService } from '../http/_common/http.common.service';


@Injectable({ providedIn: 'root' })
export class ApplicationInitService extends CoreLib_Services_Common_ApplicationInitService {

  constructor(private deskApiCommonService: CoreLib_Services_Http_DeskCommonService, private applicationStateService: CoreLib_Services_Common_ApplicationStateService, private httpCommonService: HttpCommonService) {
    super();
  }

  public async init() {

    const response = await this.deskApiCommonService.settingGetAll(new BaseRequest());

    if (response != null) {
      for (let i = 0; i < response.settings.length; i++) {
        this.applicationStateService.setting[response.settings[i].settingKey] = response.settings[i].settingValue;
      }
    }
  }

}
