import { AfterViewChecked, AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone } from '@angular/core';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[kendoPanelBarItemHideIfCollapsed]',
})
export class KendoPanelBarItemHideIfCollapsedDirective implements AfterViewInit {

  constructor(private element: ElementRef, private ngZone: NgZone) {
  }
  @Input()
  get expanded() {
    return this._expanded;
  }
  set expanded(val: boolean) {
    this._expanded = val;
    this.processVisibility();
  }
  @Input()
  get isSmallDevice() {
    return this._isSmallDevice;
  }
  set isSmallDevice(val: boolean) {
    this._isSmallDevice = val;
    this.processVisibility();
  }

  private _expanded: boolean;

  private _isSmallDevice: boolean;

  ngAfterViewInit(): void {
    this.processVisibility();
  }

  // @HostListener('window:load', ['$event'])
  // onLoad(event) {
  //  setTimeout(() => this.resize(), 1000);
  // }

  processVisibility() {
    if (this.isSmallDevice) {
      this.element.nativeElement.style.display = '';
    } else {
      if (this.expanded) {
        this.element.nativeElement.style.display = '';
      } else {
        this.element.nativeElement.style.display = 'none';
      }
    }
  }

}
