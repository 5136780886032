import { Component, Injector, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { HostService } from '../../services/common/host.service';
import { BaseComponent } from '../_base/base.component';


@Component({
  selector: 'app-crud-toolbar',
  templateUrl: './crud-toolbar.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrudToolbarComponent extends BaseComponent implements OnDestroy   {


  //#region Properties...
  
  @Input()
  public override formReference: string;

  @Input()
  public showRightPopupAnchor: boolean = true;

  public gridSelectionModes: any = GridSelectionModes;

  @Input()
  public crudView: IBaseCrudViewContract;

  //#endregion

  constructor(injector: Injector, private hostService: HostService) {
    super(injector);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }


}
