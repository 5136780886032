import { Directive, Injector, Input } from '@angular/core';
import { FilterService } from '../../services/common/filter.service';
import { BaseComponent } from './base.component';


/**
 * E' la classe base di tutti i componenti Desk. Fornisce i metodi in comune a tutti i componenti
 */
@Directive()
export abstract class BaseFilterContainerComponent extends BaseComponent {

  @Input()
  public filterGroupKey: string;

  
  //#region Private properties...


  //#endregion

  //#region Protected Properties...

  //#endregion

  //#region Constructor...

  /**
 * Inizializza una nuova istanza del componente.
 *
 * Nel costruttore della classe derivata, valorizzare la proprietà formReference con il nome del componente, per caricare le etichette relative.
 * @param injector Il servizio che consente di ricavare le istanze di altri servizi tramite dependency injection
 */
  constructor(injector: Injector, public filterService: FilterService) {
    super(injector);
  }


  //#endregion

  //#region Lifecycke Methods...

 
  //#endregion

  //#region Private Methods...

 
  //#endregion

  //#region Protected Methods...
 
  //#endregion

  //#region Public Methods...
 
  public getExtraFilterProp(propName: string): any {
    return this.filterService.getExtraFilterProp(this.filterGroupKey, propName);    
  }

  public setExtraFilterProp(propName: string, value: any) {
    this.filterService.setExtraFilterProp(this.filterGroupKey, propName, value);    
  }


  //#endregion

}
