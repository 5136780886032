import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogResult, DialogService } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';
import { PopupButtonsTypes } from '../../enums/PopupButtonsTypes';
import { PopupResultTypes } from '../../enums/PopupResultTypes';
import { PopupTitlesTypes } from '../../enums/PopupTitlesTypes';
import { TranslationService } from './translation.service';


@Injectable({ providedIn: 'root' })
export class PopupService {

  public isModalOpen: boolean = false;

  constructor(private dialogService: DialogService, private translationService: TranslationService, private readonly router: Router, private readonly location: Location) {

  }

  public closeRequested = new Subject();

  public async showMessageWithActions(titleType: PopupTitlesTypes, message: string, actions: any[]) {
    this.isModalOpen = true;

    const title = this.setTitle(titleType);

    const dialog: DialogRef = this.dialogService.open({
      title,
      content: message,
      actions,
      minHeight: 250,
      minWidth: 300,
    });

    const subscr = this.closeRequested.subscribe(() => { dialog.close(); });

    const result = await dialog.result.toPromise();

    this.isModalOpen = false;

    subscr.unsubscribe();

    return result;

  }

  public async showMessage(titleType: PopupTitlesTypes, message: string, buttonType?: PopupButtonsTypes): Promise<PopupResultTypes> {

    this.isModalOpen = true;

    const title = this.setTitle(titleType);

    let actions: any[];
    if (buttonType != null) {
      switch (buttonType) {
        case PopupButtonsTypes.Ok:
          actions = [{ text: this.translationService.localizeByCommon['OK'], primary: true }];
          break;
        case PopupButtonsTypes.OkCancel:
          actions = [{ text: this.translationService.localizeByCommon['CANCEL'] }, { text: this.translationService.localizeByCommon['OK'], primary: true }];
          break;
        case PopupButtonsTypes.YesNo:
          actions = [{ text: this.translationService.localizeByCommon['NO'] }, { text: this.translationService.localizeByCommon['YES'], primary: true }];
          break;
        default:
      }
    } else {
      actions = [{ text: this.translationService.localizeByCommon['OK'], primary: true }];
    }

    const dialog: DialogRef = this.dialogService.open({
      title,
      content: message,
      actions,
      minHeight: 250,
      minWidth: 300,
    });

    const subscr = this.closeRequested.subscribe(() => { dialog.close(); });

    const result = await dialog.result.toPromise();

    this.isModalOpen = false;

    subscr.unsubscribe();

    if (!(result instanceof DialogCloseResult)) {

      if (buttonType == PopupButtonsTypes.YesNo) {
        return result.primary ? PopupResultTypes.Yes : PopupResultTypes.No;
      } else {
        return result.primary ? PopupResultTypes.Ok : PopupResultTypes.Cancel;
      }
    } else {
      if (buttonType == PopupButtonsTypes.YesNo) {
        return PopupResultTypes.No;
      } else {
        return PopupResultTypes.Cancel;
      }
    }



  }


  private setTitle(titleType: PopupTitlesTypes) {
    let title = '';
    switch (titleType) {
      case PopupTitlesTypes.Info:
        title = this.translationService.localizeByCommon['ALERT_TITLE_INFO'];
        break;
      case PopupTitlesTypes.Warning:
        title = this.translationService.localizeByCommon['ALERT_TITLE_WARNING'];
        break;
      case PopupTitlesTypes.Confirm:
        title = this.translationService.localizeByCommon['ALERT_TITLE_CONFIRM'];
        break;
      default:
    }
    return title;
  }
}
