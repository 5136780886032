




    
export class CommonMenuItemDataResponse   {
  
  public title: string = '';
  
  public badgeText: string = '';
  
  public description: string = '';
  
  public itemRoute: string = '';
  
  public moduleKeys: string = '';
  
  public isActive: boolean = false;
  
}



