




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonChangePasswordResponse extends BaseTokenResponse  {
  
  public isValid: boolean = false;
  
  public reasonIsNotValid: string = '';
  
  public passwordValidationErrors: string[] = [];
  
}



