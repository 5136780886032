import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, AfterViewInit, AfterContentInit, QueryList, ViewChildren, HostBinding } from '@angular/core';
import { BaseFilterCellComponent, FilterOperatorBase, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { CoreLib_Services_Common_TranslationService } from 'core';
import { GridService } from '../../services/common/grid.service';
import { Subscription } from 'rxjs';
import { DatePipe, IntlService } from '@progress/kendo-angular-intl';



@Component({
  selector: 'app-grid-filter-time',
  templateUrl: './grid-filter-time.component.html',
  styleUrls: ['./grid-filter-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridFilterTimeComponent extends BaseFilterCellComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

  //#region Declarations...  

  @HostBinding('class.highlighted')
  get promoted() { return this.hasFilters; }

  @ViewChildren(FilterOperatorBase)
  override operatorList: QueryList<FilterOperatorBase>;

  //#endregion

  //#region Input...

  @Input()
  public filterField: string;

  @Input()
  public operator: string;

  @Input()
  public override filter: CompositeFilterDescriptor;

  @Input()
  public column: any;

  @Input()
  public format: string = 'HH:mm';

  //#endregion

  //#region  Properties...

  public initialOperator: string;

  public customOperators: { text: string; value: string; }[] = [];

  public fromValue: Date;

  public toValue: Date;

  public hasFilters: boolean;

  public error: string = '';

  //#endregion

  //#region Private variables...

  private gridFilterClearedSubscription: Subscription;

  //#endregion

  //#region Constructor...

  constructor(filterService: FilterService, private translationService: CoreLib_Services_Common_TranslationService, private gridService: GridService, private intlService: IntlService) {
    super(filterService);

    this.gridFilterClearedSubscription = gridService.gridFilterCleared.subscribe(c => {
      this.error = '';
      this.fromValue = null;
      this.toValue = null;
      this.hasFilters = false;
      this.operator = this.initialOperator;
    });
  }

  //#endregion

  //#region Methods...

  ngOnInit() {

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterEqOperator'], value: 'eq' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterNotEqOperator'], value: 'neq' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterGteOperator'], value: 'gte' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterGtOperator'], value: 'gt' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterLteOperator'], value: 'lte' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterLtOperator'], value: 'lt' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterIsEmptyOperator'], value: 'isempty' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterIsNotEmptyOperator'], value: 'isnotempty' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['FILTER_OPERATOR_BETWEEN'], value: 'between' });


    this.initialOperator = this.operator;

    if (this.filter != null) {

      const thisFieldFilters: FilterDescriptor[] = [];

      for (const f of this.filter.filters) {

        if ((f as CompositeFilterDescriptor).filters) {
          for (const cf of (f as CompositeFilterDescriptor).filters) {
            const cfCasted = cf as FilterDescriptor;
            if (cfCasted.field == this.filterField) {
              thisFieldFilters.push(cfCasted)
            }
          }
        } else {
          const fCasted = f as FilterDescriptor;
          if (fCasted.field == this.filterField) {
            thisFieldFilters.push(fCasted)
          }
        }
      }

      if (thisFieldFilters.length == 2) {
        for (const item of thisFieldFilters) {
          if (item.operator == 'gte') {
            this.fromValue = new Date(item.value);
          }

          if (item.operator == 'lte') {
            this.toValue = new Date(item.value);
          }
        }

        if (this.fromValue != null && this.toValue != null) {
          this.operator = 'between'
          this.hasFilters = true;
        }

      } else if (thisFieldFilters.length == 1) {
        this.fromValue = new Date(thisFieldFilters[0].value);
        this.operator = thisFieldFilters[0].operator.toString();
        this.hasFilters = true;
      }
    }
  }

  ngAfterViewInit(): void {

    for (const item of this.operatorList) {
      const jsonItem = item.toJSON();
      this.customOperators.push({ value: jsonItem.value, text: jsonItem.text });
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.gridFilterClearedSubscription != null) {
      this.gridFilterClearedSubscription.unsubscribe();
    }
  }


  public onChange(value: any): void {
    this.setFilter(false);
  }

  public operatorChange(newOperator: string) {
    this.operator = newOperator;
    if (this.getHasFilters()) {
      this.setFilter(this.operator == 'isempty' || this.operator == 'isnotempty');
    }
  }


  private setFilter(resetValues: boolean) {

    const pipe = new DatePipe(this.intlService);


    this.clearFilter(resetValues);

    let f: CompositeFilterDescriptor;

    this.hasFilters = this.getHasFilters();

    if (this.operator == 'between') {



      if (this.fromValue != null && this.toValue != null) {
        if (this.fromValue > this.toValue) {
          this.error = this.translationService.localizeByCommon['DATE_TO_MUST_GTE_THAN_FROM'];
          return;
        }
      }


      if (this.fromValue != null && this.toValue != null) {
        f = {
          filters: [
            {
              field: this.filterField,
              operator: 'gte',
              value: this.fromValue,
            },
            {
              field: this.filterField,
              operator: 'lte',
              value: this.toValue,
            }
          ],
          logic: 'and',
        };
      }
    } else if (this.operator == 'isempty' || this.operator == 'isnotempty') {
      f = {
        filters: [
          {
            field: this.filterField,
            operator: this.operator
          }
        ],
        logic: 'or',
      };
    } else {



      if (this.fromValue != null) {
        f = {
          filters: [
            {
              field: this.filterField,
              operator: this.operator,
              value: this.fromValue,
            }
          ],
          logic: 'or',
        };
      }
    }

    if (f != null) {
      if (this.filter != null) {
        this.filter.filters.push(f);
      } else {
        this.filter = ({ filters: [f], logic: 'and' } as CompositeFilterDescriptor);
      }


      this.applyFilter(
        this.filter
      ); // update the root filter


    }
  }

  public getHasFilters(): boolean {
    return this.fromValue != null || this.toValue != null || this.operator == 'isempty' || this.operator == 'isnotempty';
  }

  public clearFilter(resetValues: boolean) {
    this.error = '';
    this.filter = this.removeFilter(this.filterField);
    if (resetValues) {
      this.fromValue = null;
      this.toValue = null;

      this.applyFilter(
        this.filter
      );
    }
    this.hasFilters = false;
  }

  //#endregion
}
