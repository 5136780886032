<div class="app-color-picker" [class.big]="bigger" [class.error]="hasError" [class.hideheader]="hideheader">
  <div [id]="erroranchor"></div>

  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
  title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory"
    *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>

  <div [style.background-color]="(readOnlyValue) ? (readOnlyValue.indexOf('#')) > -1 ? readOnlyValue : '#' + readOnlyValue : ''" class="read-only-input-span" [hidden]="!disabled">

  </div>

  <kendo-colorpicker
    [format]="'hex'" [gradientSettings]="{ opacity: false }"
    #picker [hidden]="disabled" [view]="'combo'"[(value)]="selectedColor">
  </kendo-colorpicker>

  <span class="error-span" [innerHtml]="error"></span>
</div>
