




import { CommonCrudComboDataResponse } from './CommonCrudComboDataResponse';
import { BaseTokenResponse } from './BaseTokenResponse';
import { IBaseDtoItems } from './IBaseDtoItems';    
export class CommonCrudComboResponse extends BaseTokenResponse  implements IBaseDtoItems<CommonCrudComboDataResponse> {
  
  public items: CommonCrudComboDataResponse[] = [];
  
}



