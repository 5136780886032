<kendo-dialog class="k-dialog-fix k-dialog-notscroll" [title]="localizeByCommon['SELECTREPORT_TITLE']"
  *ngIf="reportServiceConfig.showReportSelection" (close)="closeReportSelection()" [minWidth]="250" [width]="450">
  <p>{{localizeByCommon['SELECTREPORT_DESCRIPTION']}}</p>

  <div class="card-item clickable" *ngFor="let report of reportServiceConfig.reports"
    [class.selected]="report.isSelected" (click)="select(report)">
    <div class="left-side">
      <div class="img-box-60">
        <img src="../../../../../assets/images/white_75_printer.png" />
      </div>
    </div>
    <div class="right-side">
      <h2>{{report.report.description}}</h2>
    </div>
  </div>

  <kendo-dialog-actions>
    <app-button (buttonClick)="printPreview()" [icon]="'preview'" [buttonClass]="'k-button-white'"
      [text]="localizeByCommon['BTN_PRINTPREVIEW']"></app-button>

    <app-button (buttonClick)="printCloud()" [icon]="'cloud'" [text]="localizeByCommon['BTN_CLOUD_PRINT']"
      *ngIf="reportServiceConfig.isCloudPrintEnabled"></app-button>

  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog class="k-dialog-full k-dialog-notscroll" [title]="localizeByCommon['PREVIEW_TITLE']"
  *ngIf="reportServiceConfig.showPrintPreview" (close)="closePrintPreview()">
  <iframe id="pdf-hidden-container" frameborder="0" style="height:100%;width:100%"></iframe>
  <div class="fallback" [hidden]="!reportServiceConfig.isPrintEvaluated">
    <a target="_blank" href="#" id="pdf-download">
      <img src="../../../../../assets/images/coloured_75_attachmentpdf.png" />
      <span>{{localizeByCommon['LNK_DOWNLOAD']}}</span>
    </a>
  </div>

  <kendo-dialog-actions>

    <app-button (buttonClick)="printCloud()" [icon]="'cloud'" [text]="localizeByCommon['BTN_CLOUD_PRINT']"
      *ngIf="reportServiceConfig.isCloudPrintEnabled"></app-button>

    <app-button (buttonClick)="download()" [icon]="'download'" [text]="localizeByCommon['BTN_DOWNLOAD']"></app-button>

  </kendo-dialog-actions>
  <app-loader [imageSrc]="'/assets/images/logo-bird.png'"></app-loader>
</kendo-dialog>
