import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridSelectionModes } from '../enums/GridSelectionModes';
import { ICrudEntity } from '../interfaces/ICrudEntity';
export class CrudPopupResult {

  public selectedOrUnselectedUids: ICrudEntity[] = [];

  public filter: CompositeFilterDescriptor;

  public sort: SortDescriptor[];

  public externalContainerResult: any;

  constructor(public selectionMode: GridSelectionModes = GridSelectionModes.Single) {

  }
}
