<div class="app-switch" [class.big]="bigger" [class.disabled]="disabled" [class.error]="hasError" [class.hideheader]="hideheader" [class.value]="checked" [class.focus]="hasFocus">
  
  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" 
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory"
      *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>  

  <div class="read-only-input-span" [hidden]="!disabled">{{readOnlyValue}}</div>

  <div class="d-flex">
    <kendo-switch [(ngModel)]="checked" [hidden]="disabled" (valueChange)="onValueChange($event)" (blur)="onBlur()" (focus)="onFocus()"></kendo-switch>
    <div [hidden]="disabled" class="mt-2 ml-2 mr-2 small w-100" [innerHtml]="description">
      
    </div>
  </div>

  <span class="error-span pt-2" *ngIf="error" [innerHtml]="error"></span>
  
</div>
