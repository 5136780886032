export class StringHelper {

  public static isNullOrWhiteSpace(text: string): boolean {
    return text == null || text.toString().trim().length == 0;
  }

  public static transformText(text: string, textTransformation: string): string {

    if (text != null) {
      if (textTransformation == 'uppercase') {
        text = text.toUpperCase();
      }
      if (textTransformation == 'lowercase') {
        text = text.toLowerCase();
      }
      if (textTransformation == 'capitalizefirstletter') {
        if (text.length > 0) {
          text = text.substring(0, 1).toUpperCase() + text.substring(1);
        }
      }
      if (textTransformation == 'capitalize') {
        if (text.length > 0) {
          const parts = text.split(' ');
          text = '';
          const newparts: string[] = [];
          for (const p of parts) {
            newparts.push(p.substring(0, 1).toUpperCase() + p.substring(1));
          }
          text = newparts.join(' ');

        }
      }
    }
    return text;
  }

  public static replaceAll(stringToProcess: string, searchString: string, substituteString: string) {
    if (stringToProcess === null) {
      return '';
    } else {
      return stringToProcess.split(searchString).join(substituteString);
    }

  }


}
