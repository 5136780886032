import { Injectable } from '@angular/core';
import { CoreLib_Classes_Poco_EntityIntString } from 'core';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class FixToParentHeightService {

  //#region Declarations...

  public parentHeightChanged = new Subject<boolean>();

  public parentHeightValueChanged = new Subject<CoreLib_Classes_Poco_EntityIntString>();

  public topSensorChanged = new Subject<boolean>();

  public scrollTop: any = 0;

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  //#endregion

}
