









export class ReportDataSourceParameterDataRequest  {
      
    public name: string = '';    
    public value: any = null;    
}

