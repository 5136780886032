import { AfterViewInit, EventEmitter, Injector, Input, OnInit, Output, Directive } from '@angular/core';
import { BaseCrudNestedEntityWrapper } from 'dto';
import { ICrudEntity } from '../../interfaces/ICrudEntity';

import { BaseHashedCrudComponent } from './base-hashed-crud.component';


@Directive()
export class BaseCrudNestedComponent<TDto> extends BaseHashedCrudComponent implements OnInit, AfterViewInit {

  //#region Properties...


  // DATAWRAPPER
  private _inputDataWrapper: ICrudEntity;
  @Input()
  public get inputDataWrapper() {
    return this._inputDataWrapper;
  }
  public set inputDataWrapper(val) {
    if (val != null) {
      this._inputDataWrapper = val;
      this.inputDataWrapperChange.emit(this.inputDataWrapper);
    }
  }
  @Output() inputDataWrapperChange = new EventEmitter();


  public get dataWrapper(): BaseCrudNestedEntityWrapper<TDto> {
    return this.inputDataWrapper as BaseCrudNestedEntityWrapper<TDto>;
  }

  public set dataWrapper(val) {
    this.inputDataWrapper = val;
  }


  public instance: TDto;

  public isInitSucceded: boolean = false;

  //#endregion

  //#region Constructors...
  constructor(injector: Injector) {
    super(injector);

    const dtw = new BaseCrudNestedEntityWrapper<TDto>();
    this.setEmptyInstance();
    dtw.dataItem = this.instance;
    dtw.errors = {};

    this.dataWrapper = dtw;
  }

  //#endregion

  //#region Methods...


  override async ngOnInit() {
    await super.ngOnInit();
    this.isInitSucceded = true;
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
  }

  public clearSingleError(key: string) {
    this.dataWrapper.errors[key] = '';
  }

  //#endregion

  //#region Mandatory Overridable Methods

  public setEmptyInstance() {
    this.instance = null;
  }


  //#endregion
}
