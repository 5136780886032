








import { ReportDataResponse } from './ReportDataResponse';
export class ReportGetDefinitionByUidResponse  {
      
    public report: ReportDataResponse = null;    
}

