<div class="app-code-editor" [class.big]="bigger" [class.error]="hasError" [class.hideheader]="hideheader">
  <div [id]="erroranchor"></div>
  <label class="header" *ngIf="!hideheader" title="{{header}}">{{header}}</label>

  <div class="command-bar">

    <app-button (buttonClick)="edit()" [icon]="'edit'" [title]="localizeByCommon['BTN_EDIT']" *ngIf="!disabled">
    </app-button>

  </div>

  <iframe style="width:100%;border:none;" #iFramePreview></iframe>

  <app-async-load-panel *ngIf="popupOpened">
    <kendo-dialog [title]="header" (close)="popupClose()" class="k-dialog-full k-dialog-notscroll">
      <div class="container-fluid">
        <div>
          <ng-content select=".command-bar"></ng-content>
        </div>
        <div fixToParentHeight="'editor'">
          <div style="position:relative;height:100%;width:100%">
            <textarea #editor style="position:absolute; left:0;right:0;top:0;bottom:0; overflow: auto;"
              [(ngModel)]="text">
          </textarea>
          </div>
        </div>
      </div>
      <app-loader></app-loader>
    </kendo-dialog>
  </app-async-load-panel>
  <span class="error-span">{{error}}</span>
</div>