<ng-container *ngIf="applicationStateService.isInLoadingEnabled">
  <div
    *ngIf="{state: progressRef.state | async, others: applicationStateService.isInLoadingCrud > 0 || applicationStateService.isInLoadingPopupHost} as state">
    <div class="loader-background" *ngIf="state.others || state.state.active"></div>
    <div class="loader-container" *ngIf="state.others || state.state.active">
      <div class="loader-content">
        <circle-progress [radius]="80" [percent]="100" [outerStrokeWidth]="16" [innerStrokeWidth]="8"
          [outerStrokeColor]="colorService.mainColorBrush" [innerStrokeColor]="colorService.shadowColorBrush"
          [showUnits]="false" [showTitle]="false" [unitsColor]="'#ffffff'" [animation]="true"
          [subtitleColor]="'#ffffff'" [imageSrc]="imageSrc" [showImage]="true" [imageHeight]="80" [imageWidth]="80"
          [subtitleFontSize]="'16px'" [animationDuration]="500"></circle-progress>
      </div>

    </div>
  </div>
</ng-container>