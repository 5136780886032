import { Type } from '@angular/compiler/src/core';
import { DataResult, DataSourceRequestState } from '@progress/kendo-data-query';
import { CoreLib_Classes_SearchRequest, CoreLib_Services_Http_Base_BaseService } from 'core';
import { BaseCrudEntityWrapper, CommonGridResponseWithGroupBy, BaseEditResponse, BaseSearchRequest, CommonCrudEntityDeleteRequest, CommonCrudEntityDeleteResponse, CommonCrudEntityGetListResponse, CommonCrudEntityGetRequest, CommonCrudEntityGetResponse, CommonGridResponse, CommonBulkChangeResponse, BaseCloneResponse, CommonCrudEntityCloneRequest } from 'dto';
import { Md5 } from 'ts-md5';
import { BaseService } from './BaseService';


export class BaseCrudService<TGridDataResponse, TAggregateDataResponse, TGetDataResponse, TComboDataResponse, TGetEditRequest> extends BaseService {

  public readonly panelItem_MainData: string = 'mainData';

  constructor(baseService: CoreLib_Services_Http_Base_BaseService, areaName: string, protected gridType: Type) {
    super(baseService, areaName);
  }

  public async grid(state: DataSourceRequestState, searchRequest: CoreLib_Classes_SearchRequest, isFirstRequest: boolean, saveSortState: boolean, saveFilterState: boolean, uid: string): Promise<CommonGridResponse<DataResult, TAggregateDataResponse>> {
    return this.baseService.baseGridPost<TAggregateDataResponse>(state, this.areaName, searchRequest, this.gridType, isFirstRequest, saveSortState, saveFilterState, uid);
  }

  public async commitBulk(state: DataSourceRequestState, searchRequest: CoreLib_Classes_SearchRequest): Promise<CommonBulkChangeResponse> {
    return this.baseService.baseCommitBulk(state, this.areaName, searchRequest);
  }

  public async gridWithGroupBy<TGroupByValue>(state: DataSourceRequestState, searchRequest: CoreLib_Classes_SearchRequest, isFirstRequest: boolean, saveSortState: boolean, saveFilterState: boolean): Promise<CommonGridResponseWithGroupBy<DataResult, TGroupByValue, TAggregateDataResponse>> {
    return this.baseService.baseGridPostWithGroupBy<TGroupByValue, TAggregateDataResponse>(state, this.areaName, searchRequest, this.gridType, isFirstRequest, saveSortState, saveFilterState);
  }

  public async gridByUid(uid: string): Promise<CommonCrudEntityGetResponse<TGridDataResponse>> {
    let request = new CommonCrudEntityGetRequest();
    request.uid = uid;
    return this.baseService.post<CommonCrudEntityGetResponse<TGridDataResponse>>(this.baseService.baseUrl + this.areaName + 'gridByUid', request).toPromise();
  }

  public async get(request: CommonCrudEntityGetRequest): Promise<CommonCrudEntityGetResponse<BaseCrudEntityWrapper<TGetDataResponse>>> {
    return this.baseService.post<CommonCrudEntityGetResponse<BaseCrudEntityWrapper<TGetDataResponse>>>(this.baseService.baseUrl + this.areaName + 'get', request).toPromise();
  }

  public async edit(request: TGetEditRequest): Promise<BaseEditResponse> {
    return this.baseService.post<BaseEditResponse>(this.baseService.baseUrl + this.areaName + 'edit', request).toPromise();
  }

  public async delete(request: CommonCrudEntityDeleteRequest): Promise<CommonCrudEntityDeleteResponse> {
    return this.baseService.post<CommonCrudEntityDeleteResponse>(this.baseService.baseUrl + this.areaName + 'delete', request).toPromise();
  }

  public async clone(request: CommonCrudEntityCloneRequest): Promise<BaseCloneResponse> {
    return this.baseService.post<BaseCloneResponse>(this.baseService.baseUrl + this.areaName + 'clone', request).toPromise();
  }

  public async comboCustom<TCustomComboDataResponse>(searchRequest: CoreLib_Classes_SearchRequest = null): Promise<CommonCrudEntityGetListResponse<TCustomComboDataResponse>> {

    let request: BaseSearchRequest;

    if (searchRequest != null) {
      request = searchRequest.request;
    }

    if (request == null) {
      request = new BaseSearchRequest();
    }

    if (searchRequest != null) {
      return this.baseService.post<CommonCrudEntityGetListResponse<TCustomComboDataResponse>>(this.baseService.baseUrl + this.areaName + 'combo' + searchRequest.mode, request).toPromise();
    } else {
      return this.baseService.post<CommonCrudEntityGetListResponse<TCustomComboDataResponse>>(this.baseService.baseUrl + this.areaName + 'combo', request).toPromise();
    }
  }

  public readonly SEARCHREQUESTMODE_NULL: string = 'NULL'

  public comboCache: { [index: string]: CommonCrudEntityGetListResponse<TComboDataResponse> } = {};

  public comboCacheEnabledSearchRequests: string[] = [];

  public async combo(searchRequest: CoreLib_Classes_SearchRequest = null): Promise<CommonCrudEntityGetListResponse<TComboDataResponse>> {

    let comboCacheEnabled: boolean = false;
    if (searchRequest == null) {
      if (this.comboCacheEnabledSearchRequests.findIndex(c => c == this.SEARCHREQUESTMODE_NULL) > -1) {
        comboCacheEnabled = true;
      }
    } else {
      if (this.comboCacheEnabledSearchRequests.findIndex(c => c == searchRequest.mode) > -1) {
        comboCacheEnabled = true;
      }
    }


    if (comboCacheEnabled == true) {

      let cacheId = "1";
      if (searchRequest != null) {
        cacheId = Md5.hashStr(JSON.stringify(searchRequest));
      }

      cacheId = cacheId + this.baseService.authenticationService.getToken();


      if (this.comboCache[cacheId] == null) {
        this.comboCache[cacheId] = await this.comboCustom<TComboDataResponse>(searchRequest);
      }
      return this.comboCache[cacheId];
    } else {
      return await this.comboCustom<TComboDataResponse>(searchRequest);
    }
  }


  public clearCache() {
    this.comboCache = {};
  }

  public async comboByUid(uid: string): Promise<CommonCrudEntityGetResponse<TComboDataResponse>> {
    let request = new CommonCrudEntityGetRequest();
    request.uid = uid;
    return this.baseService.post<CommonCrudEntityGetResponse<TComboDataResponse>>(this.baseService.baseUrl + this.areaName + 'comboByUid', request).toPromise();
  }

  /**
   * @deprecated The method should not be used. Use instead handleOnDemandFilter
   */
  public async handleComboOnDemandFilter(filterValue: string, filterOperator: 'startsWith' | 'contains' = 'startsWith', searchRequest: CoreLib_Classes_SearchRequest = null, target: TComboDataResponse[] = null) {
    this.ondemandItems = [];

    let request: BaseSearchRequest;

    if (searchRequest != null) {
      request = searchRequest.request;
    }

    if (request == null) {
      request = new BaseSearchRequest();
    }

    if (filterOperator == null) {
      filterOperator = 'startsWith';
    }

    request.filterValue = filterValue;
    request.filterOperator = filterOperator;
    request.isComboOnDemand = true;

    if (target == null) {
      target = this.ondemandItems;
    }

    let response: CommonCrudEntityGetListResponse<TComboDataResponse>;

    if (searchRequest != null) {
      response = await this.baseService.post<CommonCrudEntityGetListResponse<TComboDataResponse>>(this.baseService.baseUrl + this.areaName + 'combo' + searchRequest.mode, request).toPromise();
    } else {
      response = await this.baseService.post<CommonCrudEntityGetListResponse<TComboDataResponse>>(this.baseService.baseUrl + this.areaName + 'combo', request).toPromise();
    }


    if (response != null) {
      //target = [];
      target.length = 0;
      //            target.splice(0, target.length);
      //target = [];
      for (const item of response.items) {
        target.push(item);
      }
    }

  }

  /**
   * @deprecated The method should not be used. Use instead handleOnDemandFilter
   */
  public async handleMultiselectOnDemandFilter(filterValue: string, filterOperator: 'startsWith' | 'contains' = 'startsWith', searchRequest: CoreLib_Classes_SearchRequest = null, targetHost: any = null, targetName: string = null) {
    this.ondemandItems = [];

    let request: BaseSearchRequest;

    if (searchRequest != null) {
      request = searchRequest.request;
    }

    if (request == null) {
      request = new BaseSearchRequest();
    }

    if (filterOperator == null) {
      filterOperator = 'startsWith';
    }

    request.filterValue = filterValue;
    request.filterOperator = filterOperator;
    request.isComboOnDemand = true;

    if (targetHost[targetName] == null) {
      targetHost[targetName] = this.ondemandItems;
    }

    let response: CommonCrudEntityGetListResponse<TComboDataResponse>;

    if (searchRequest != null) {
      response = await this.baseService.post<CommonCrudEntityGetListResponse<TComboDataResponse>>(this.baseService.baseUrl + this.areaName + 'combo' + searchRequest.mode, request).toPromise();
    } else {
      response = await this.baseService.post<CommonCrudEntityGetListResponse<TComboDataResponse>>(this.baseService.baseUrl + this.areaName + 'combo', request).toPromise();
    }


    if (response != null) {
      targetHost[targetName] = [];

      for (const item of response.items) {
        targetHost[targetName].push(item);
      }
    }

  }
}
