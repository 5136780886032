import { Injectable } from '@angular/core';
import { CoreConstants } from 'dto';
import { ApplicationStateService } from './application-state.service';
import { WebViewHostService } from './web-view-host.service';

@Injectable({ providedIn: 'root' })
export abstract class MessageService {

  public errorVisualizerLoaded: boolean = false;
  public messages = new Array<string>();
  public lastMessage: string;

  constructor(protected applicationStateService: ApplicationStateService, private webViewHostService: WebViewHostService) {

  }

  setError(message: string, statusCode: number) {
    this.set(message);
  }

  set(message: string) {
    if (this.errorVisualizerLoaded) {
      if (this.applicationStateService.isEmbedded) {
        this.lastMessage = message;
        this.webViewHostService.sendMessage(CoreConstants.DeskMessages.ERROR, this.lastMessage);
      } else {
        this.messages.push(message);
      }
    } else {
      document.getElementById('startup-error-content').innerHTML = message;
      document.getElementById('startup-error').style.display = 'block';
    }
  }

  clear() {
    this.messages = [];
  }
}
