import { AfterViewInit, ChangeDetectionStrategy, Component, DoCheck, ElementRef, EventEmitter, HostListener, Injector, Input, Output, ViewChild } from '@angular/core';

import { Align } from '@progress/kendo-angular-popup';
import { ToolbarLeftService } from '../../services/common/toolbar-left.service';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagerComponent extends BaseComponent {


  //#region Properties...
  @Output()
  public pageChanged = new EventEmitter<number>();

  @Input()
  public pageSize: number = 0;


  private _currentPage: number = 1;
  @Input()
  public get currentPage(): number {
    return this._currentPage;
  }
  public set currentPage(value: number) {
    this._currentPage = value;
    this.recalculateFromPage();
  }

  @Input()
  public totalItems: number = 0;

  public get totalPages() {
    return Math.ceil(this.totalItems / this.pageSize);
  }
  private fromPage: number = 1;
  private maxButtons: number = 10;

  public get pages() {
    const returnValues = new Array<number>();

    for (let i = this.fromPage; i < this.fromPage + this.maxButtons; i++) {
      if (i <= this.totalPages) {
        returnValues.push(i);
      }
    }

    if (returnValues.length == 0) {
      returnValues.push(this.currentPage);
    }

    return returnValues;
  }

  public get showMorePages() {
    return this.pages[this.pages.length - 1] < this.totalPages;
  }

  public get showLessPages() {
    return this.pages[0] > 1;
  }

  public get fromIndex() {
    return (this.currentPage * this.pageSize) - this.pageSize + 1;
  }

  public get toIndex() {
    if ((this.currentPage * this.pageSize) < this.totalItems) {
      return (this.currentPage * this.pageSize);
    } else {
      return this.totalItems;
    }

  }
  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private toolbarLeftService: ToolbarLeftService) {
    super(injector);

  }

  //#endregion

  public goFirst($event: any) {
    $event.preventDefault();
    this.currentPage = 1;
    this.recalculateFromPage();
    this.pageChanged.emit(this.currentPage);
  }

  public goLast($event: any) {
    $event.preventDefault();
    this.currentPage = this.totalPages;
    this.recalculateFromPage();
    this.pageChanged.emit(this.currentPage);
  }

  public goPrevious($event: any) {
    $event.preventDefault();
    if (this.currentPage > 1) {
      this.currentPage--;
      this.recalculateFromPage();
      this.pageChanged.emit(this.currentPage);
    }
  }

  public goNext($event: any) {
    $event.preventDefault();
    if (this.currentPage + 1 <= this.totalPages) {
      this.currentPage++;
      this.recalculateFromPage();
      this.pageChanged.emit(this.currentPage);
    }
  }

  public goToPage($event: any, page: number) {
    $event.preventDefault();
    if (this.currentPage != page) {
      this.currentPage = page;
      this.pageChanged.emit(this.currentPage);
    }
  }

  public morePages($event: any) {
    $event.preventDefault();
    this.currentPage = this.pages[this.pages.length - 1] + 1;
    this.fromPage += this.maxButtons;
    this.pageChanged.emit(this.currentPage);
  }

  public lessPages($event: any) {
    $event.preventDefault();
    this.currentPage = this.pages[0] - 1;
    this.fromPage -= this.maxButtons;
    this.pageChanged.emit(this.currentPage);
  }


  private recalculateFromPage() {
    for (let i = 1; i <= this.totalPages; i += this.maxButtons) {
      if (i <= this.currentPage) {
        this.fromPage = i;

      }
    }
  }
}
