import { Type } from '@angular/core';

export class TypeFactory<T> {
  constructor(private testType: Type<T>) {
    
  }

  getNew() {
    return new this.testType();
  }
}
