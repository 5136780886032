import { Injectable, Injector } from '@angular/core';
import { CanActivate } from '@angular/router';

export abstract class LicenseService implements CanActivate {

  public isValidLicense: boolean = true;
  public modules: string[];
  public licenseResponseIsNull: boolean = false;

  canActivate() {
    return this.canActivateHandler();
  }

  abstract canActivateHandler(): boolean;

  abstract init(): Promise<void>;

  abstract isActiveModule(module: string): boolean;

}
