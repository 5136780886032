








import { ReportBaseRequest } from './ReportBaseRequest';
export class ReportGetDefinitionByUidRequest extends ReportBaseRequest {
      
    public reportUid: string = '00000000-0000-0000-0000-000000000000';    
}

