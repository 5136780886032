import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { CoreLib_Classes_StringHelper } from 'core';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';



@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ColorPickerComponent extends BaseInputControlComponent {

  //#region Properties...

  @Input()
  public includeHashTag: boolean = true;

  private _selectedColor: string = '';
  @Input()
  get selectedColor() {
    if (this.includeHashTag) {
      return this._selectedColor;
    }
    else {
      if (this._selectedColor != null){
        return this._selectedColor.replace("#", "");
      } else {
        return this._selectedColor;
      }
    }
  }
  @Output() selectedColorChange = new EventEmitter();
  set selectedColor(val) {
    this._selectedColor = val;

    this.setHeaderHidden();
    this.selectedColorChange.emit(this.selectedColor);
    this.setReadOnlyValue();
  }

  // @Output() inputKeyUp = new EventEmitter<KeyboardEvent>();

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, protected ref: ChangeDetectorRef) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  public override setHeaderHidden() {
    this.headerhidden = false;
    this.ref.detectChanges();
  }

  public override setReadOnlyValue() {
    if (this.disabled && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.selectedColor)) {
      this.readOnlyValue = '-';
    } else {
      this.readOnlyValue = this.selectedColor;
    }

    this.ref.detectChanges();
  }
  //#endregion

}
