import { AfterViewInit, Directive, Injector, Input, OnInit } from '@angular/core';
import { CoreLib_Classes_Guid } from 'core';
import { BaseCrudNestedEntityWrapper } from 'dto';
import { Md5 } from 'ts-md5';
import { CrudViewModes } from '../../enums/CrudViewModes';
import { BaseComponent } from './base.component';

/**
 * E' la classe base di tutti componenti di tipo Crud.
 * Definisce le proprietà e i metodi in comune ai BaseCrudComponents
 */
@Directive()
export class BaseHashedCrudComponent extends BaseComponent implements OnInit, AfterViewInit {

  //#region  Public Input Properties...

  /**
   * Imposta il valore della proprietà viewMode
   */
  @Input()
  public viewMode: CrudViewModes = CrudViewModes.List;

  //#endregion

  //#region Public Properties...

  /**
   * Espone l'enumeratore CrudViewModes al template
   */
  public viewModes: any = CrudViewModes;

  //#endregion

  //#region Constructors...
  /**
 * Inizializza una nuova istanza del componente.
 *
 * @param injector Il servizio che consente di ricavare le istanze di altri servizi tramite dependency injection
 */
  constructor(injector: Injector) {
    super(injector);

  }

  //#endregion

  //#region Lifecycle Methods...

  override async ngOnInit() {
    await super.ngOnInit();
  }


  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
  }

  //#endregion

  //#region Private Methods...
  private replacer(key: string, value: string) {
    if (key == 'errors') {
      return undefined;
    } else {
      return value;
    }
  }

  //#endregion

  //#region Public Methods...

  /**
   * Restutisce un valore boolean che indica se i valori delle proprietà del dataWrapper ricevuto sono stati variati dall'utente
   * @param item Il dataWrapper da verificare
   * @returns True se il dataWrapper è stato modificato, false se non è stato modificato
   */
  public checkEdited<TDto>(item: BaseCrudNestedEntityWrapper<TDto>): boolean {
    if (item.isDeleted == true && item.uid == CoreLib_Classes_Guid.Empty) {
      return false;
    } else if (item.isDeleted == true && item.uid != CoreLib_Classes_Guid.Empty) {
      return true;
    } else {
      if (this.forceIsEdited) {
        return true;
      } else {
        const check = Md5.hashStr(JSON.stringify(item.dataItem, this.replacer)).toString();
        return this.viewMode == CrudViewModes.New || check != item.originalHash;
      }
    }
  }

  /**
   * Consente alla classe derivata di forzare che i dati risultino modificati in modo che la request venga costruita e passata all'api di edit
   * Un caso tipico di utilizzo è quando si deve far si che un nuovo elemento nato da una duplicazione possa essere passato all'api di edit per far si che venga spento il flag IsDeleted
   */
  public get forceIsEdited(): boolean {
    return false;
  }

  /**
   * Storicizza sul dataWrapper l'hash code dell'intero oggetto dataItem al suo interno. Viene richiamato dopo che sono stati caricati i dati in interfaccia e usato in fase di salvataggio per stabilire se l'utente ha modificato i dati o meno
   * @param item Il dataWrapper alla quale impostare l'hash
   */
  public SetOriginalHashToItem<TDto>(item: BaseCrudNestedEntityWrapper<TDto>) {
    item.originalHash = Md5.hashStr(JSON.stringify(item.dataItem, this.replacer)).toString();
  }

  /**
  * Storicizza sul dataWrapper di ogni elemento l'hash code dell'intero oggetto dataItem al suo interno. Viene richiamato dopo che sono stati caricati i dati in interfaccia e usato in fase di salvataggio per stabilire se l'utente ha modificato i dati o meno
  * @param items L'array di dataWrapper ai quali impostare l'hash code
  */
  public SetOriginalHashToList<TDto>(items: Array<BaseCrudNestedEntityWrapper<TDto>>) {
    for (const item of items) {
      item.originalHash = Md5.hashStr(JSON.stringify(item.dataItem, this.replacer)).toString();
    }
  }


  //#endregion
}
