import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Injector, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { NgProgress, NgProgressState } from '@ngx-progressbar/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CoreLib_Classes_StringHelper, CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_PopupService } from 'core';
import { Subscription } from 'rxjs';

import { CrudPopupResult } from '../../classes/CrudPopupResult';
import { PopupHostDirective } from '../../directives/popup-host.directive';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { IBaseGridViewContract } from '../../interfaces/IBaseGridViewContract';
import { DeactivationService } from '../../services/common/deactivation.service';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';
import { BaseViewComponent } from '../_base/base-view.component';
import { ComboBoxComponent as KendoComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ElementHelperMethods } from '../../classes/ElementHelperMethods';
import { debounceTime } from 'rxjs/operators';






@Component({
  selector: 'app-combo-box',
  templateUrl: './combo-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboBoxComponent extends BaseInputControlComponent implements OnDestroy, AfterViewInit {

  //#region Events...

  @Output() filterChange = new EventEmitter<string>();

  @Output() loadItemsNeeded = new EventEmitter();

  @Output() selectedCrudItemChanged = new EventEmitter<any>();

  // focus
  

  //#endregion

  //#region Declarations...

  @ViewChild('combo')
  public combo: KendoComboBoxComponent;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };


  private popuphelperService_closeRequested_subscribe: Subscription;
  private crudComponent_closeRequested_subscribe: Subscription;
  private componentRef: ComponentRef<any>;
  private viewContainerRef: ViewContainerRef;

  private isItemsLoaded: boolean = false;

  private popupHost: PopupHostDirective;

  @ViewChild(PopupHostDirective) set content(content: PopupHostDirective) {
    this.popupHost = content;
  }
  private hostView: BaseViewComponent = null;
  //#endregion

  //#region Properties...

  @Input() itemTemplate: TemplateRef<any>;

  @Input() crudComponentFactory: ComponentFactory<any>;

  @Input() crudModuleName: string;
  @Input() crudComponentName: string;

  // ONDEMAND
  @Input()
  public ondemand: boolean = false;

  // ITEMSSOURCE
  private _itemsSource: any;

  @Input()
  get itemsSource() {
    return this._itemsSource;
  }
  set itemsSource(val) {
    this.isItemsLoaded = val != null && val.length > 0;
    this._itemsSource = val;
  }

  // SELECTEDITEM
  private _selectedItem: any = null;
  @Input()
  get selectedItem() {
    return this._selectedItem;
  }
  set selectedItem(val) {
    if (this._selectedItem != val) {

      if (!this.isItemsLoaded && val != null && !this.ondemand) {
        this._itemsSource = new Array<any>();
        this._itemsSource.push(val);
      }

      this._selectedItem = val;

      this.setHeaderHidden();
      this.selectedItemChange.emit(val);
      this.setReadOnlyValue();
    }
  }
  @Output() selectedItemChange = new EventEmitter();

  // TEXTFIELD
  private _textField: string = '-';
  @Input()
  get textField() {
    return this._textField;
  }

  set textField(val) {
    this._textField = val;
    this.setReadOnlyValue();
  }

  // VALUEFIELD
  private _valueField: string = '-';
  @Input()
  get valueField() {
    return this._valueField;
  }

  set valueField(val) {
    this._valueField = val;
  }

  // POPUPOPENED
  private _popupOpened: boolean = false;
  public get popupOpened(): boolean {
    return this._popupOpened;
  }
  public set popupOpened(value: boolean) {
    this._popupOpened = value;

    if (value) {
      this.hostView = this.deactivationService.currentView;
    } else {
      this.deactivationService.currentView = this.hostView;
    }
  }

  // SHOWEXTENDEDSEARCH
  @Input()
  public showExtendedSearch: boolean = false;

  // ALLOWCLEAR
  @Input()
  public allowClear: boolean = true;

  // AUTOCOMPLETE
  @Input()
  public autocomplete: string;

  // ENTITYOWNER
  @Input()
  public entityOwner: string = '';

  @Input()
  public extendedSearchInitialFilters: CompositeFilterDescriptor;

  @Input()
  public extendedSearchRequest: any;

  public noDataText = '';

  public get dialogTitle() {

    if (this.entityOwner != null && this.entityOwner.length > 0) {
      return this.localizeByCommon['COMBO_DIALOG_TITLE_FOR'].replace('@@HEADER@@', this.header).replace('@@ENTITYOWNER@@', this.entityOwner);
    } else {
      return this.localizeByCommon['COMBO_DIALOG_TITLE'].replace('@@HEADER@@', this.header);
    }
  }


  // ONDEMAND
  private _emptyText: string = '';
  @Input()
  public get emptyText(): string {
    return this._emptyText;
  }
  public set emptyText(value: string) {
    this._emptyText = value;
    this.setReadOnlyValue();
  }

  private internalFilterChange = new EventEmitter();

  private internalFilterChangeSubscription: Subscription;

  private ngProgressSubscription: Subscription;

  private tempFilterValue: string = '';

  private minCharToStartSearch: number = 2;

  public isInLoadingOnDemand: boolean = false;
  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, ngProgress: NgProgress, private popupService: CoreLib_Services_Common_PopupService, private componentFactoryResolver: ComponentFactoryResolver, private ref: ChangeDetectorRef, private deactivationService: DeactivationService, private element: ElementRef) {
    super(injector, validationService);

    this.internalFilterChangeSubscription = this.internalFilterChange.pipe(debounceTime(300)).subscribe(c => {
      if (this.tempFilterValue.length >= this.minCharToStartSearch)
        this.filterChange.emit(this.tempFilterValue);
    });

    this.ngProgressSubscription = ngProgress.ref().state.subscribe((e: NgProgressState) => {
      this.isInLoadingOnDemand = this.hasFocus && e.active;
    })
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.setNoDataText();
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    if (this.ondemand && this.element != null) {
      const search_bar = this.element.nativeElement.querySelector('kendo-searchbar');
      if (search_bar != null) {
        search_bar.removeEventListener("keydown", this.handleFilterSearchKeyDown);
        search_bar.addEventListener("keydown", this.handleFilterSearchKeyDown.bind(this), true);
      }
    }
    if (this.element != null) {
      const input = this.element.nativeElement.querySelector('kendo-searchbar input');
      if (input != null) {
        input.setAttribute("autocomplete", this.autocomplete);
      }
    }
  }

  private searchEnterHandled: boolean = false;
  private searchStopAutoArrowDown: boolean = false;
  private timeoutSendArrowDownAndEnter: any = null;
  private lasttarget: any = null;

  private async handleFilterSearchKeyDown(e: any) {

    if ((e.keyCode == 13 || e.which == 13) && this.searchEnterHandled == false && this.searchStopAutoArrowDown == false) { //&& elementList
      e.cancelBubble = true;
      e.preventDefault();
      e.stopPropagation();
      this.lasttarget = e.target;
      this.sendArrowDownAndEnter();
    } else if (e.keyCode == 40 || e.which == 40 || e.keyCode == 38 || e.which == 38) {
      this.searchStopAutoArrowDown = true;
    } else {
      this.searchStopAutoArrowDown = false;
    }
  }

  private sendArrowDownAndEnter() {

    clearTimeout(this.timeoutSendArrowDownAndEnter);
    this.timeoutSendArrowDownAndEnter = null;
    if (this.lasttarget != null) {
      this.timeoutSendArrowDownAndEnter = setTimeout(async () => {
        if (this.combo.isOpen) {
          await ElementHelperMethods.elementReady(document.documentElement, 'kendo-popup kendo-list ul li', 300);

          this.searchEnterHandled = true;
          this.lasttarget.dispatchEvent(new KeyboardEvent('keydown', <KeyboardEventInit>{ keyCode: 40 }));
          this.lasttarget.dispatchEvent(new KeyboardEvent('keydown', <KeyboardEventInit>{ keyCode: 13 }));

          setTimeout(() => {
            this.searchEnterHandled = false;
            this.lasttarget = null;
          }, 500);

        }
      }, 300);
    }
  }


  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe();
    this.ngProgressSubscription?.unsubscribe();
    this.internalFilterChangeSubscription?.unsubscribe();
  }

  private setNoDataText() {
    if (this.ondemand == false || this.tempFilterValue.length >= this.minCharToStartSearch) {
      this.noDataText = this.translationService.tryGetLocalizeByCommon("NO_ITEMS_FOUND");
    } else {
      this.noDataText = this.translationService.tryGetLocalizeByCommon("X_CHARS_TOCONTINUE").replace("@@X@@", this.minCharToStartSearch.toString());
    }
  }

  public handleFilter(value: any): void {
    this.tempFilterValue = value;
    this.setNoDataText();
    if (this.ondemand && value.length >= this.minCharToStartSearch) {
      this.internalFilterChange.emit();
    } else {
      if (this.ondemand && this.itemsSource != null && this.itemsSource.length > 0)
        this.itemsSource.splice(0, this.itemsSource.length);
    }

    this.setHeaderHidden();
  }

  onFocus() {
    this.applicationStateService.isInLoadingEnabled = false;

    this.hasFocus = true;
    this.setHeaderHidden();

    this.ref.detectChanges();
  }

  onBlur() {
    this.applicationStateService.isInLoadingEnabled = true;
    
    this.hasFocus = false;
    this.setHeaderHidden();

    this.ref.detectChanges();
  }

  public override setHeaderHidden(): void {
    this.headerhidden = (this.disabled == false && (this.selectedItem == null));    

    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.tempFilterValue))
      this.headerhidden = false;

    if (!this.ondemand && this.hasFocus)
      this.headerhidden = false;
    
    this.ref.detectChanges();
  }

  public async loadItemsIfEmpty(event: any) {
    event.stopPropagation();

    if (this.itemsSource == null && !this.ondemand) {
      this.loadItemsNeeded.emit();
    }
  }

  public override setReadOnlyValue(): void {
    if (this.disabled && this.selectedItem == null) {
      this.readOnlyValue = (this.emptyText != null && this.emptyText != '') ? this.emptyText : '-';
    } else {
      if (this.selectedItem != null) {
        this.readOnlyValue = this.selectedItem[this.textField];
      }
    }
    this.ref.detectChanges();
  }

  public popupClose(): void {
    if (this.componentRef != null && this.componentRef.instance != null) {
      if ((this.componentRef.instance as IBaseCrudViewContract).isInEditMode()) {
        this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo).then((result) => {
          if (result == CoreLib_Enums_PopupResultTypes.Yes) {
            this.popupOpened = false;
            this.ref.detectChanges();
          }
        });

      } else {
        this.popupOpened = false;
        this.ref.detectChanges();
      }
    } else {
      this.popupOpened = false;
      this.ref.detectChanges();
    }
  }

  public popupOpen(): void {
    //if (this.crudComponentFactory != null) {
    this.popupOpened = true;
    this.ref.detectChanges();
    //}
  }

  public async loadComponent() {
    this.unsubscribe();
    this.viewContainerRef = this.popupHost.viewContainerRef;
    this.viewContainerRef.clear();

    let factory: any = null;

    if (this.crudComponentFactory != null) {
      factory = this.crudComponentFactory;
    } else {
      factory = await this.lazyLoaderService.loadModule3(this.crudModuleName, this.crudComponentName);
    }

    this.componentRef = this.viewContainerRef.createComponent(factory);

    const componentInstance = (this.componentRef.instance as IBaseGridViewContract);
    componentInstance.isInPopup = true;
    componentInstance.selectionMode = GridSelectionModes.Single;
    componentInstance.initialFilters = this.extendedSearchInitialFilters;
    componentInstance.searchRequest = this.extendedSearchRequest;

    this.crudComponent_closeRequested_subscribe?.unsubscribe();

    this.crudComponent_closeRequested_subscribe = componentInstance.popupCloseRequested.subscribe((result: CrudPopupResult) => {

      this.popupOpened = false;
      this.setHeaderHidden();

      if (result.selectionMode == GridSelectionModes.Single) {
        this.selectedCrudItemChanged.emit(result.selectedOrUnselectedUids[0]);
      }
    });


    this.ref.detectChanges();
  }


  private unsubscribe(): void {

    if (this.viewContainerRef != null) {
      this.viewContainerRef.detach();
    }

    if (this.componentRef != null) {
      this.componentRef.destroy();
    }

    if (this.popuphelperService_closeRequested_subscribe != null) {
      this.popuphelperService_closeRequested_subscribe.unsubscribe();
    }

    if (this.crudComponent_closeRequested_subscribe != null) {
      this.crudComponent_closeRequested_subscribe.unsubscribe();
    }

  }

  //#endregion
}
