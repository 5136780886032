import { Injectable, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DeskCommonService } from '../http/desk.common.service';

@Injectable({ providedIn: 'root' })
export class TokenService {

  //#region Declarations...


  //#endregion

  //#region Constructors...


  constructor(private injector: Injector) {

  }


  //#endregion

  //#region Methods...

  async init() {
    const api: DeskCommonService = this.injector.get(DeskCommonService);

    const cookie: CookieService = this.injector.get(CookieService);

    let token: string = '';
    if (cookie.check('Token' + location.port)) {
      token = cookie.get('Token' + location.port);
    }


    if (token.length > 0) {
      const tokenValue = await api.tokenValid();
      if (tokenValue == null) {
        cookie.set('LoginUid' + location.port, '', null, '/', null, false, 'Lax');
        cookie.set('Token' + location.port, '', null, '/', null, false, 'Lax');
        cookie.set('AccountDescription' + location.port, '', null, '/', null, false, 'Lax');
      }
    }

  }

  //#endregion

}
