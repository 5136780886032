import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnChanges } from '@angular/core';

import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';



@Component({
  selector: 'app-label-error',
  templateUrl: './label-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class LabelErrorComponent extends BaseInputControlComponent {


  //#region Properties...

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  //#endregion

}
