import { Component, Input, OnInit, Injector, AfterViewInit } from '@angular/core';
import { CoreConstants } from 'dto';
import { CommonFilterDescriptor } from 'dto';
import { FilterData } from '../../classes/FilterData';
import { FilterService } from '../../services/common/filter.service';
import { BaseFilterComponent } from '../_base/base-filter.component';

@Component({
  selector: 'app-filter-lookup-static',
  templateUrl: './filter-lookup-static.component.html'
})
export class FilterLookupStaticComponent extends BaseFilterComponent implements OnInit, AfterViewInit {

  //#region Input Output


  @Input()
  public valueField: string = "";

  @Input()
  public textField: string = "";

  //#endregion

  //#region Fields
  @Input()
  public itemsSource: any[] = [];

  private _selectedElements: any[] = [];

  @Input()
  public get selectedElements(): any[] {
    return this._selectedElements;
  }
  public set selectedElements(value: any[]) {
    this._selectedElements = value;

    if (this.isInitSucceeded)
      this.notifyFilterChange();
  }

  //#endregion

  //#region Constructor...

  constructor(injector: Injector,
    filterService: FilterService) {
    super(injector, filterService);
    this.operator = CoreConstants.FilterOperators.IN;
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();  
  }

  public override loadExistingFilters(filterData: FilterData): void {
    super.loadExistingFilters(filterData);
    this.operator = filterData.filterOperator;
    this.selectedElements = filterData.filterValue;
  }
  
  public override clearFilters(): void {
    super.clearFilters();
    this.selectedElements = [];    
    this.operator = CoreConstants.FilterOperators.IN;
  }

  public override notifyFilterChange() {
    super.notifyFilterChange();

    let filter: CommonFilterDescriptor = null;
    this.hasFilter = false;

    switch (this.operator) {
      case CoreConstants.FilterOperators.IN:
      case CoreConstants.FilterOperators.NOTIN:
        if (this.selectedElements != null && this.selectedElements.length > 0) {
          filter = new CommonFilterDescriptor();
          filter.filterName = this.filterName;
          filter.operator = this.operator;
          filter.values = [];
          for (const el of this.selectedElements) {
            filter.values.push(el[this.valueField]);
            this.hasFilter = true;
          }
        }
        break;
    }


    this.addFilter(filter, this.selectedElements);
  }
  //#endregion
}

