import { AfterViewInit, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { BaseCrudEntityWrapper } from 'dto';
import { Subscription } from 'rxjs';

import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { ICrudEntity } from '../../interfaces/ICrudEntity';
import { BaseHashedCrudComponent } from './base-hashed-crud.component';


@Directive()
export class BaseCrudChildComponent<TDto> extends BaseHashedCrudComponent implements OnInit, AfterViewInit, OnDestroy {

  //#region Declarations...

  @Output() deleted = new EventEmitter();

  protected scrollToService: ScrollToService;
  private onSelectTabSubscription: Subscription;

  protected isInEditMode = false;

  //#endregion

  //#region Properties...



  // DATAWRAPPER
  private _inputDataWrapper: ICrudEntity;
  @Input()
  public get inputDataWrapper() {
    return this._inputDataWrapper;
  }
  public set inputDataWrapper(val) {
    if (val != null) {
      this._inputDataWrapper = val;
      this.inputDataWrapperChange.emit(this.inputDataWrapper);
    }
  }
  @Output() inputDataWrapperChange = new EventEmitter();


  public get dataWrapper(): BaseCrudEntityWrapper<TDto> {
    return this.inputDataWrapper as BaseCrudEntityWrapper<TDto>;
  }

  public set dataWrapper(val) {
    this.inputDataWrapper = val;
  }

  private _mainEntityUid: string = '';

  @Input()
  public get mainEntityUid() {
    return this._mainEntityUid;
  }
  public set mainEntityUid(val) {
    this._mainEntityUid = val;
  }

  public isInitSucceded: boolean = false;


  public instance: TDto;


  private _crudView: IBaseCrudViewContract;
  @Input()
  public get crudView() {
    return this._crudView;
  }
  public set crudView(val) {
    this._crudView = val;
  }
  //#endregion

  //#region Constructors...
  constructor(injector: Injector) {
    super(injector);

    this.scrollToService = this.injector.get(ScrollToService);

    const dtw = new BaseCrudEntityWrapper<TDto>();
    this.setEmptyInstance();
    dtw.dataItem = this.instance;
    dtw.errors = {};

    this.dataWrapper = dtw;
  }
  //#endregion

  //#region Methods...

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.onSelectTabSubscription != null) {
      this.onSelectTabSubscription.unsubscribe();
    }
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.isInitSucceded = true;
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
  }

  public scrollToTarget(target: string, timeout: number) {
    setTimeout(() => {
      const config: ScrollToConfigOptions = {
        duration: 50,
        target,
      };

      this.scrollToService.scrollTo(config);

      const targetObj = document.getElementById(target);
      if (targetObj != null) {
        targetObj.style.borderLeft = '4px solid #111111';

        setTimeout(() => {
          targetObj.style.borderLeft = '';
        }, 3000);
      }

    }, timeout);
  }

  public clearSingleError(key: string) {
    this.dataWrapper.errors[key] = '';
  }

  //#endregion

  //#region Mandatory Overridable Methods...

  public setEmptyInstance() {
    this.instance = null;
  }

  public async validate(): Promise<boolean> {
    this.dataWrapper.errors = {};
    return true;
  }

  public deleteItem() {
    this.dataWrapper.isDeleted = true;
    this.deleted.emit();
  }
  //#endregion
}
