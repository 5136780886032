import { ReportSelection } from './ReportSelection';


export class ReportServiceConfig {

  public showReportSelection: boolean = false;

  public reports: ReportSelection[] = [];

  public isCloudPrintEnabled: boolean = false;

  private _showPrintPreview: boolean = false;
    public get showPrintPreview(): boolean {
        return this._showPrintPreview;
    }
    public set showPrintPreview(value: boolean) {
        this._showPrintPreview = value;
    }

  public isDirectPrintEnabled: boolean = false;

  public isPrintEvaluated: boolean = false;
}
