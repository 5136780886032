<div class="k-pager-wrap k-grid-pager k-widget" style="margin-left:-10px;margin-right:-10px;margin-bottom:-10px;">
  <a href="#" (click)="goFirst($event)" tabindex="-1" class="k-link k-pager-nav k-pager-first" [class.k-state-disabled]="currentPage <= 1" [title]="localizeByCommon['kendo.grid.pagerFirstPage']"><span class="k-icon k-i-seek-w"></span></a>
  <a href="#" (click)="goPrevious($event)" tabindex="-1" class="k-link k-pager-nav k-state-disabled" [class.k-state-disabled]="currentPage <= 1" [title]="localizeByCommon['kendo.grid.pagerPreviousPage']"><span class="k-icon k-i-arrow-w"></span></a>

  <ul class="k-pager-numbers k-reset">
    <li><a class="k-link" href="#" tabindex="-1"  (click)="lessPages($event)" *ngIf="showLessPages">...</a></li>
    <li><a *ngFor="let page of pages" class="k-link" [class.k-state-selected]="currentPage == page" href="#" tabindex="-1"  (click)="goToPage($event, page)">{{page}}</a></li>
    <li><a class="k-link" href="#" tabindex="-1"  (click)="morePages($event)" *ngIf="showMorePages">...</a></li>
  </ul>
  <a href="#" (click)="goNext($event)" tabindex="-1" class="k-link k-pager-nav" [class.k-state-disabled]="currentPage >= totalPages" [title]="localizeByCommon['kendo.grid.pagerNextPage']"><span class="k-icon k-i-arrow-e"></span></a>
  <a href="#" (click)="goLast($event)" tabindex="-1" class="k-link k-pager-nav k-pager-last" [class.k-state-disabled]="currentPage >= totalPages" [title]="localizeByCommon['kendo.grid.pagerLastPage']"><span class="k-icon k-i-seek-e"></span></a>
  <span class="k-pager-info k-label">{{fromIndex}} - {{toIndex}} {{localizeByCommon['kendo.grid.pagerOf']}} {{totalItems}} {{localizeByCommon['kendo.grid.pagerItems']}}</span>
</div>
