




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonBulkChangeResponse extends BaseTokenResponse  {
  
  public recordsAffected: number = 0;
  
}



