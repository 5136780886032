import { Injectable } from '@angular/core';
import { CommonLostPasswordRequest } from '@mydto/CommonLostPasswordRequest';
import { CommonRecoverPasswordAccessRequest } from '@mydto/CommonRecoverPasswordAccessRequest';
import { CommonRecoverPasswordAccessResponse } from '@mydto/CommonRecoverPasswordAccessResponse';
import { CommonRecoverPasswordRequest } from '@mydto/CommonRecoverPasswordRequest';
import { CommonRecoverPasswordResponse } from '@mydto/CommonRecoverPasswordResponse';
import { CommonValidatePasswordRequest } from '@mydto/CommonValidatePasswordRequest';
import { CommonValidatePasswordResponse } from '@mydto/CommonValidatePasswordResponse';
import { ProcLoginResponse } from '@mydto/ProcLoginResponse';
import { CoreLib_Services_Http_Base_BaseService } from 'core';
import { BaseTokenResponse, CommonLoginRequest } from 'dto';
import 'rxjs/operator/catch';

@Injectable({
  providedIn: 'root',
})
export class HttpCommonService {
  constructor(private baseService: CoreLib_Services_Http_Base_BaseService) { }

  private areaName = 'common/';

  public async login(request: CommonLoginRequest): Promise<ProcLoginResponse> {
    return this.baseService.http.post<ProcLoginResponse>(this.baseService.baseUrl + this.areaName + 'login', request).toPromise();
  }

  public async lostPassword(request: CommonLostPasswordRequest): Promise<BaseTokenResponse> {
    return this.baseService.http.post<BaseTokenResponse>(this.baseService.baseUrl + this.areaName + 'lostPassword', request).toPromise();
  }

  public async recoverPasswordAccess(request: CommonRecoverPasswordAccessRequest): Promise<CommonRecoverPasswordAccessResponse> {
    return this.baseService.http.post<CommonRecoverPasswordAccessResponse>(this.baseService.baseUrl + this.areaName + 'recoverPasswordAccess', request).toPromise();
  }
  
  public async validatePassword(request: CommonValidatePasswordRequest): Promise<CommonValidatePasswordResponse> {
    return this.baseService.http.post<CommonValidatePasswordResponse>(this.baseService.baseUrl + this.areaName + 'validatePassword', request).toPromise();
  }

  public async recoverPassword(request: CommonRecoverPasswordRequest): Promise<CommonRecoverPasswordResponse> {
    return this.baseService.http.post<CommonRecoverPasswordResponse>(this.baseService.baseUrl + this.areaName + 'recoverPassword', request).toPromise();
  }

}
