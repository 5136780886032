



export class BasePersEditDataRequestWrapper   {

  public uid: string =  '00000000-0000-0000-0000-000000000000';
  public request: any =  null;
  public dbActionClassName: string =  '';
  public dbActionMethodName: string =  '';
}





