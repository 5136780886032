<div class="app-temporary-stream-upload" [class.error]="hasError" [class.value]="filesAdded" [class.focus]="hasFocus">
  <div [id]="erroranchor"></div>
  <label class="header" [style.color]="headercolor" title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory" *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>
  
  <kendo-upload #kendoUpload="kendoUpload" [(ngModel)]="filesAdded" [saveUrl]="uploadSaveUrl" (blur)="onBlur()" (focus)="onFocus()" 
    [removeUrl]="uploadRemoveUrl" [accept]="accept" [restrictions]="restrictions" [showFileList]="false" (select)="selectEventHandler($event)"
    (upload)="uploadEventHandler($event)" (remove)="removeEventHandler($event)" (success)="successEventHandler($event)" (error)="errorEventHandler($event)">
    <kendo-upload-messages select="{{localizeByCommon['BTN_BROWSE']}}"
      dropFilesHere="{{localizeByCommon['DROP_ZONE_TEXT']}}"
      headerStatusUploaded="{{localizeByCommon['UPLOAD_COMPLETED']}}"
      headerStatusUploading="{{localizeByCommon['UPLOAD_IN_PROGRESS']}}">
    </kendo-upload-messages>
  </kendo-upload>
  <span class="error-span" [innerHtml]="error"></span>
</div>
