import { Component, Injector, Input } from '@angular/core';
import { CoreLib_Classes_ToastContent, CoreLib_Services_Common_ToastService } from 'core';
import { BaseComponent } from '../_base/base.component';


@Component({
  selector: 'app-toast-holder',
  templateUrl: './toast-holder.component.html',
  styleUrls: ['./toast-holder.component.scss'],
})
export class ToastHolderComponent extends BaseComponent {

  //#region Properties...

  @Input()
  public toastTitle: string;
  //#endregion

  //#region Constructors...

  constructor(injector: Injector, public toastService: CoreLib_Services_Common_ToastService) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  close(toastContent: CoreLib_Classes_ToastContent): void {
    if (toastContent.uid == null) {
      this.toastService.clearAll();
    } else {
      this.toastService.clear(toastContent);
    }
  }

  async open(toastContent: CoreLib_Classes_ToastContent): Promise<void> {
    if (toastContent.uid != null) {
      if (await this.toastService.open(toastContent)) {
        this.toastService.clear(toastContent);
        await this.toastService.setReaded(toastContent);
      }
    }
  }

  async openNotificationMenu(toastContent: CoreLib_Classes_ToastContent): Promise<void> {
   this.toastService.openNotificationMenuRequested.next();
   this.close(toastContent);
  }

  //#endregion
}
