




import { CommonSettingDataResponse } from './CommonSettingDataResponse';
import { BaseResponse } from './BaseResponse';    
export class CommonSettingsResponse extends BaseResponse  {
  
  public settings: CommonSettingDataResponse[] = [];
  
}



