




import { CommonGridColumnDataDefinition } from './CommonGridColumnDataDefinition';
import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonGridResponse<TDto, TAggregationDto> extends BaseTokenResponse  {
  
  public searchSchemaName: string = '';
  
  public columnsDefinitions: CommonGridColumnDataDefinition[] = [];
  
  public dataSource: TDto = null;
  
  public aggregation: TAggregationDto = null;
  
  public sortSetting: string = '';
  
  public filterSetting: string = '';
  
}



