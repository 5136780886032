import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ValidationService } from '../../services/common/validation.service';

import { BaseInputControlComponent } from '../_base/base-input-control.component';


@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent extends BaseInputControlComponent {

  //#region Properties...

  // CHECKED
  private _checked: any;
  @Input()
  get checked() {
    return this._checked;
  }
  set checked(val) {
    this._checked = val;
    this.checkedChange.emit(val);
    this.setReadOnlyValue();
  }
  @Output() checkedChange = new EventEmitter();

  @Output()
  public valueChange = new EventEmitter<any>();

  @Input()
  public description: string = "";

  // focus
  

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, private ref: ChangeDetectorRef) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  public override setReadOnlyValue() {
    this.readOnlyValue = (this.checked) ? this.localizeByCommon['YES'] : this.localizeByCommon['NO'];

    this.ref.detectChanges();
  }

  public onValueChange(event: any) {
    this.valueChange.emit(event);
  }
  
  onBlur() {   
    this.hasFocus = false;
    this.ref.detectChanges();
  }

  onFocus() {
    this.hasFocus = true;
    this.ref.detectChanges();
  }

  //#endregion
}
