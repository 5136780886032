import { Pipe, PipeTransform } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';


@Pipe({
  name: 'minutesAsHours',
  pure: true,
})
export class MinutesAsHoursPipe implements PipeTransform {


  constructor(private intlService: IntlService) {

  }
  transform(minutes: number): string {
    if (minutes != null && minutes != 0) {
      return Math.trunc(minutes / 60).toString().padStart(2, '0') + ':' + (minutes % 60).toString().padStart(2, '0') + ' H';
    } else {
      return '00:00 H';
    }
  }
}