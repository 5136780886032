








import { ReportDataResponse } from './ReportDataResponse';
export class ReportGetListResponse  {
      
    public reports: ReportDataResponse[] = [];    
    public isCloudPrintEnabled: boolean = false;    
}

