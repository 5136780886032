




export class CommonFilterDescriptor  {        
  
  public filterName: string = '';
  
  public operator: number = 0;
  
  public values: any[] = [];
  
  public hasErrors: boolean = false;
  
}




