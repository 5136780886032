import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactory, ComponentRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, SimpleChange, TemplateRef, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CoreLib_Classes_ObjectHelper, CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_PopupService } from 'core';
import { Subscription } from 'rxjs';
import { CrudPopupResult } from '../../classes/CrudPopupResult';
import { PopupHostDirective } from '../../directives/popup-host.directive';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { IBaseGridViewContract } from '../../interfaces/IBaseGridViewContract';
import { ICrudEntity } from '../../interfaces/ICrudEntity';
import { DeactivationService } from '../../services/common/deactivation.service';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';
import { BaseViewComponent } from '../_base/base-view.component';




@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownListComponent extends BaseInputControlComponent implements OnDestroy, OnInit {

  //#region Events...

  @Output() selectedCrudItemChanged = new EventEmitter<any>();

  //#endregion

  //#region Declarations...

  private popuphelperService_closeRequested_subscribe: Subscription;
  private crudComponent_closeRequested_subscribe: Subscription;
  private componentRef: ComponentRef<any>;
  private viewContainerRef: ViewContainerRef;

  private isItemsLoaded: boolean = false;

  private popupHost: PopupHostDirective;

  @ViewChild(PopupHostDirective) set content(content: PopupHostDirective) {
    this.popupHost = content;
  }
  private hostView: BaseViewComponent = null;
  //#endregion

  //#region Properties...

  // focus
  
  
  @Input() itemTemplate: TemplateRef<any>;


  @Input() crudComponentFactory: ComponentFactory<any>;

  @Input() crudModuleName: string;
  @Input() crudComponentName: string;



  @Input() defaultItem: any;

  @Input() filterable: boolean = true;

  // ITEMSSOURCE
  public filteredSource: any;
  private _itemsSource: any;
  @Input()
  get itemsSource() {
    return this._itemsSource;
  }
  set itemsSource(val) {
    this.isItemsLoaded = val != null && val.length > 0;
    this._itemsSource = val;
    this.filteredSource = val;
  }

  // SELECTEDITEM
  private _selectedItem: any = null;
  @Input()
  get selectedItem() {
    return this._selectedItem;
  }
  set selectedItem(val) {
    if (this._selectedItem != val) {
      this._selectedItem = val;
      this.setHeaderHidden();
      this.selectedItemChange.emit(val);
      this.setReadOnlyValue();
    }
  }
  @Output() selectedItemChange = new EventEmitter();

  // TEXTFIELD
  private _textField: string = '-';
  @Input()
  get textField() {
    return this._textField;
  }
  set textField(val) {
    this._textField = val;
    this.setReadOnlyValue();
  }

  // VALUEFIELD
  private _valueField: string = '-';
  @Input()
  get valueField() {
    return this._valueField;
  }

  set valueField(val) {
    this._valueField = val;
  }

  // POPUPOPENED
  private _popupOpened: boolean = false;
  public get popupOpened(): boolean {
    return this._popupOpened;
  }
  public set popupOpened(value: boolean) {
    this._popupOpened = value;

    if (value) {
      this.hostView = this.deactivationService.currentView;
    } else {
      this.deactivationService.currentView = this.hostView;
    }
  }

  // SHOWEXTENDEDSEARCH
  @Input()
  public showExtendedSearch: boolean = false;

  // ENTITYOWNER
  @Input()
  public entityOwner: string = '';


  @Input()
  public extendedSearchInitialFilters: CompositeFilterDescriptor;

  @Input()
  public extendedSearchRequest: any;

  public get dialogTitle() {

    if (this.entityOwner != null && this.entityOwner.length > 0) {
      return this.localizeByCommon['COMBO_DIALOG_TITLE_FOR'].replace('@@HEADER@@', this.header).replace('@@ENTITYOWNER@@', this.entityOwner);
    } else {
      return this.localizeByCommon['COMBO_DIALOG_TITLE'].replace('@@HEADER@@', this.header);
    }
  }
  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, private ref: ChangeDetectorRef, private popupService: CoreLib_Services_Common_PopupService, private deactivationService: DeactivationService) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...
  override async ngOnInit() {
    await super.ngOnInit();
  }


  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe();
  }
  
  onBlur() {
    this.hasFocus = false;
    this.ref.detectChanges();
  }

  onFocus() {
    this.hasFocus = true;
    this.ref.detectChanges();
  }

  public override setHeaderHidden(): void {
    this.headerhidden = this.disabled == false && (this.selectedItem == null);
    this.ref.detectChanges();
  }

  public override setReadOnlyValue(): void {
    if (this.disabled && this.selectedItem == null) {
      this.readOnlyValue = '-';
    } else {
      if (this.selectedItem != null) {
        this.readOnlyValue = this.selectedItem[this.textField];
      }
    }

    this.ref.detectChanges();
  }

  handleFilter(value: any) {
    if (value != null && value.length > 0) {
      this.filteredSource = this.itemsSource.filter((s: any) => s[this.textField].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.filteredSource = this.itemsSource;
    }
  }

  public popupClose(): void {
    if (this.componentRef != null && this.componentRef.instance != null) {
      if ((this.componentRef.instance as IBaseCrudViewContract).isInEditMode()) {
        this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo).then((result) => {
          if (result == CoreLib_Enums_PopupResultTypes.Yes) {
            this.popupOpened = false;
            this.ref.detectChanges();
          }
        });

      } else {
        this.popupOpened = false;
        this.ref.detectChanges();
      }
    } else {
      this.popupOpened = false;
      this.ref.detectChanges();
    }
  }

  public popupOpen(): void {
    //if (this.crudComponentFactory != null) {
      this.popupOpened = true;
      this.ref.detectChanges();
    //}
  }

  public async loadComponent() {
    this.unsubscribe();
    this.viewContainerRef = this.popupHost.viewContainerRef;
    this.viewContainerRef.clear();

    let factory: any = null;

    if (this.crudComponentFactory != null) {
      factory = this.crudComponentFactory;
    } else {
      factory = await this.lazyLoaderService.loadModule3(this.crudModuleName, this.crudComponentName);
    }

    this.componentRef = this.viewContainerRef.createComponent(factory);

    const componentInstance = (this.componentRef.instance as IBaseGridViewContract);
    componentInstance.isInPopup = true;
    componentInstance.selectionMode = GridSelectionModes.Single;
    componentInstance.initialFilters = this.extendedSearchInitialFilters;
    componentInstance.searchRequest = this.extendedSearchRequest;

    this.crudComponent_closeRequested_subscribe?.unsubscribe();

    this.crudComponent_closeRequested_subscribe = componentInstance.popupCloseRequested.subscribe((result: CrudPopupResult) => {
      this.popupOpened = false;

      if (result.selectionMode == GridSelectionModes.Single) {
        this.selectedCrudItemChanged.emit(result.selectedOrUnselectedUids[0]);
      }
    });


    this.ref.detectChanges();
  }


  private unsubscribe(): void {

    if (this.viewContainerRef != null) {
      this.viewContainerRef.detach();
    }

    if (this.componentRef != null) {
      this.componentRef.destroy();
    }

    if (this.popuphelperService_closeRequested_subscribe != null) {
      this.popuphelperService_closeRequested_subscribe.unsubscribe();
    }

    if (this.crudComponent_closeRequested_subscribe != null) {
      this.crudComponent_closeRequested_subscribe.unsubscribe();
    }

  }

  //#endregion
}
