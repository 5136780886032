<div class="card-item">
  <div #target class="filter-operator-selector left-side">
    <div class="badge badge-primary" [class.dark-background-color]="!hasFilter" [class.main-background-color]="hasFilter">
      {{operatorString}}
    </div>
  </div>
  <div class="right-side">
    <app-multi-select *ngIf="operator == operators.IN || operator == operators.NOTIN" [header]="header"
      [itemsSource]="elements" [(selectedItems)]="selectedElements" [textField]="'fullDescription'" [valueField]="'uid'"
      [ondemand]="true"
      (filterChange)="crudService.handleOnDemandFilter($event, 'startsWith', searchRequest, thisComponent, 'elements')"
      [showExtendedSearch]="true" [extendedSearchRequest]="searchRequest" [crudModuleName]="crudRouteName"
      [crudComponentName]="crudViewName" (selectedCrudItemChanged)="crudItemChanged($event)">
    </app-multi-select>

    <app-text-box
      *ngIf="operator == operators.STARTSWITH || operator == operators.CONTAINS || operator == operators.ENDSWITH || operator == operators.NOTSTARTSWITH || operator == operators.NOTCONTAINS || operator == operators.NOTENDSWITH"
      [header]="header" [(text)]="text"></app-text-box>

    <app-label-box *ngIf="operator == operators.ISNULL" [header]="header" [text]="localizeByCommon['IS_EMPTY']">
    </app-label-box>

    <app-label-box *ngIf="operator == operators.ISNOTNULL" [header]="header" [text]="localizeByCommon['IS_NOTEMPTY']">
    </app-label-box>
    
  </div>
</div>
<kendo-contextmenu showOn="click" [target]="target" (select)="selectOperator($event)">
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_IN']" [data]="operators.IN"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_NOTIN']" [data]="operators.NOTIN"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_STARTSWITH']" [data]="operators.STARTSWITH">
  </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_CONTAINS']" [data]="operators.CONTAINS"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_ENDSWITH']" [data]="operators.ENDSWITH"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_NOTSTARTSWITH']" [data]="operators.NOTSTARTSWITH">
  </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_NOTCONTAINS']" [data]="operators.NOTCONTAINS">
  </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_NOTENDSWITH']" [data]="operators.NOTENDSWITH">
  </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_ISNULL']" [data]="operators.ISNULL"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_ISNOTNULL']" [data]="operators.ISNOTNULL">
  </kendo-menu-item>
</kendo-contextmenu>