import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { ColorService } from '../../services/common/color.service';
import { CoreLib_Services_Common_ApplicationStateService } from 'core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {

  //#region Declarations...

  @Input()
  public imageSrc: string = '../../../../assets/images/logo-bird.png';

  public progressRef: NgProgressRef;

  // public mainColorBrush: string = "#000";
  // public shadowColorBrush: string = "#000";

  //#endregion

  //#region Constructors...

  constructor(private ngProgress: NgProgress, public colorService: ColorService, public applicationStateService: CoreLib_Services_Common_ApplicationStateService) {
    this.progressRef = this.ngProgress.ref();

    // Procedura per ottenere il colore dalle variabili SCSS
    // const elem = getComputedStyle(document.documentElement);
    // this.mainColorBrush = elem.getPropertyValue('--main-color-brush').trim();
    // this.shadowColorBrush = elem.getPropertyValue('--main-shadow-color-brush').trim();
  }

  //#endregion

  //#region Methods...

  //#endregion
}
