import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, AfterViewInit, AfterContentInit, QueryList, ViewChildren, ElementRef, ViewChild, HostBinding } from '@angular/core';
import { BaseFilterCellComponent, FilterOperatorBase, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { CoreLib_Classes_StringHelper, CoreLib_Services_Common_TranslationService } from 'core';
import { GridService } from '../../services/common/grid.service';
import { Subscription } from 'rxjs';
import { DatePipe, IntlService } from '@progress/kendo-angular-intl';



@Component({
  selector: 'app-grid-filter-text',
  templateUrl: './grid-filter-text.component.html',
  styleUrls: ['./grid-filter-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridFilterTextComponent extends BaseFilterCellComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

  //#region  Declarations...

  @HostBinding('class.highlighted')
  get promoted() { return this.hasFilters; }

  @ViewChildren(FilterOperatorBase)
  override operatorList: QueryList<FilterOperatorBase>;

  //#endregion

  //#region  Input...

  @Input()
  public operator: string;

  @Input()
  public override filter: CompositeFilterDescriptor;

  @Input()
  public column: any;

  @Input()
  public filterField: string;

  //#endregion

  //#region Properties...

  public customOperators: { text: string; value: string; }[] = [];

  public initialOperator: string;

  public value: string;

  public hasFilters: boolean;

  //#endregion

  //#region Private variables...

  private gridFilterClearedSubscription: Subscription;

  private oldValue: string = '';

  //#endregion

  //#region Constructor...

  constructor(filterService: FilterService, private translationService: CoreLib_Services_Common_TranslationService, private gridService: GridService, private intlService: IntlService) {
    super(filterService);

    this.gridFilterClearedSubscription = gridService.gridFilterCleared.subscribe(c => {
      this.value = null;
      this.hasFilters = false;
      this.operator = this.initialOperator;
    });
  }

  //#endregion

  //#region Methods...

  ngOnInit() {

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterEqOperator'], value: 'eq' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterNotEqOperator'], value: 'neq' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterContainsOperator'], value: 'contains' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterNotContainsOperator'], value: 'doesnotcontain' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterStartsWithOperator'], value: 'startswith' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterEndsWithOperator'], value: 'endswith' });

    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterIsEmptyOperator'], value: 'isempty' });
    this.customOperators.push({ text: this.translationService.localizeByCommon['kendo.grid.filterIsNotEmptyOperator'], value: 'isnotempty' });


    this.initialOperator = this.operator;

    if (this.filter != null) {

      const thisFieldFilters: FilterDescriptor[] = [];

      for (const f of this.filter.filters) {

        if ((f as CompositeFilterDescriptor).filters) {
          for (const cf of (f as CompositeFilterDescriptor).filters) {
            const cfCasted = cf as FilterDescriptor;
            if (cfCasted.field == this.filterField) {
              thisFieldFilters.push(cfCasted)
            }
          }
        } else {
          const fCasted = f as FilterDescriptor;
          if (fCasted.field == this.filterField) {
            thisFieldFilters.push(fCasted)
          }
        }
      }

      if (thisFieldFilters.length > 0) {
        this.value = thisFieldFilters[0].value;
        this.operator = thisFieldFilters[0].operator.toString();
        this.hasFilters = true;
      }

    }

  }

  ngAfterViewInit(): void {

    for (const item of this.operatorList) {
      const jsonItem = item.toJSON();
      this.customOperators.push({ value: jsonItem.value, text: jsonItem.text });
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.gridFilterClearedSubscription != null) {
      this.gridFilterClearedSubscription.unsubscribe();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    console.log(event);
    if (event == null || (event.keyCode == 13)) {
      event.preventDefault();
      this.setFilter(false);
    }
  }

  onFocus(event: FocusEvent) {
    this.oldValue = this.value;
    (event.target as HTMLInputElement).select();
  }

  onBlur(event: FocusEvent) {
    if (this.value != this.oldValue)
      this.setFilter(false);
  }


  public operatorChange(newOperator: string) {
    this.operator = newOperator;
    if (this.getHasFilters()) {
      this.setFilter(this.operator == 'isempty' || this.operator == 'isnotempty');
    }
  }




  private setFilter(resetValues: boolean) {

    this.clearFilter(resetValues);

    let f: CompositeFilterDescriptor;

    this.hasFilters = this.getHasFilters();

    if (this.operator == 'isempty' || this.operator == 'isnotempty') {
      f = {
        filters: [
          {
            field: this.filterField,
            operator: this.operator
          }
        ],
        logic: 'or',
      };
    } else if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.value)) {
      f = {
        filters: [
          {
            field: this.filterField,
            operator: this.operator,
            value: this.value,
          }
        ],
        logic: 'or',
      };
    }

    if (f != null) {
      if (this.filter != null) {
        this.filter.filters.push(f);
      } else {
        this.filter = ({ filters: [f], logic: 'and' } as CompositeFilterDescriptor);
      }


      this.applyFilter(
        this.filter
      ); // update the root filter


    } else {
      this.clearFilter(true);
    }
  }

  public getHasFilters(): boolean {
    return !CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.value) || this.operator == 'isempty' || this.operator == 'isnotempty';
  }

  public clearFilter(resetValues: boolean) {

    this.filter = this.removeFilter(this.filterField);
    if (resetValues) {
      this.value = null;

      this.applyFilter(
        this.filter
      );
    }
    this.hasFilters = false;
  }

  //#endregion
}
