import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { CoreLib_Classes_StringHelper } from "core";
import { ValidationService } from "../../services/common/validation.service";
import { BaseInputControlComponent } from "../_base/base-input-control.component";
import { AutoCompleteComponent as KendoAutocompleteComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: "app-autocomplete",
  templateUrl: "./autocomplete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent extends BaseInputControlComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  //#region Events...

  @Output() filterChange = new EventEmitter<string>();

  @Output() valueChange = new EventEmitter<any>();

  @Output() loadItemsNeeded = new EventEmitter();

  //#endregion

  //#region Declarations...

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  private viewContainerRef: ViewContainerRef;

  private isItemsLoaded: boolean = false;

  //#endregion

  //#region Properties...

  @Input() itemTemplate: TemplateRef<any>;

  // ONDEMAND
  @Input()
  public ondemand: boolean = false;

  // ITEMSSOURCE
  private _itemsSource: any;

  @Input()
  get itemsSource() {
    return this._itemsSource;
  }
  set itemsSource(val) {
    this.isItemsLoaded = val != null && val.length > 0;
    this._itemsSource = val;
  }

  // SELECTEDITEM
  private _selectedItem: any = null;
  @Input()
  get selectedItem() {
    return this._selectedItem;
  }
  @Output() selectedItemChange = new EventEmitter();
  set selectedItem(val) {
    if (!this.isItemsLoaded && val != null && !this.ondemand) {
      this._itemsSource = new Array<any>();
      this._itemsSource.push(val);
    }

    this._selectedItem = val;
    this.setHeaderHidden();
    this.selectedItemChange.emit(val);
    this.setReadOnlyValue();
  }

  // VALUEFIELD
  private _valueField: string;
  @Input()
  get valueField() {
    return this._valueField;
  }

  set valueField(val) {
    this._valueField = val;
  }

  // AUTOCOMPLETE
  @Input()
  public autocomplete: string;
  
  
  //#endregion

  //#region Constructors...

  constructor(
    injector: Injector,
    validationService: ValidationService,
    private element: ElementRef
  ) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  override async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();
   
    if (this.element != null) {
      const input = this.element.nativeElement.querySelector('kendo-searchbar input');
      if (input != null) {
        input.setAttribute("autocomplete", this.autocomplete);
      }
    }
  }

  override ngOnDestroy(): void {
    this.unsubscribe();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    Promise.resolve(null).then(() => {
      for (const propName in changes) {
        if (!changes[propName].isFirstChange()) {
          if (propName == "selectedItem") {
            this.error = "";
          }
        }
      }
    });
  }

  //public handleFilter(value): void {
  //  this.filterChange.emit(value);
  //}
  public handleFilter(value : any): void {
    if (value.length > 1) {
      this.filterChange.emit(value);
    }
  }

  public handleValueChange(value : any): void {
    this.valueChange.emit(value);
  }

  public override setHeaderHidden(): void {
    this.headerhidden = this.disabled == false && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.selectedItem);
  }

  public async loadItemsIfEmpty(event : any) {
    event.stopPropagation();

    if (!this.isItemsLoaded && !this.ondemand) {
      this.loadItemsNeeded.emit();
    }
  }

  public override setReadOnlyValue(): void {
    if (this.disabled && this.selectedItem == null) {
      this.readOnlyValue = "-";
    } else {
      if (this.selectedItem != null) {
        this.readOnlyValue = this.selectedItem;
      }
    }
  }

  private unsubscribe(): void {
    if (this.viewContainerRef != null) {
      this.viewContainerRef.detach();
    }
  }

  //#endregion
}
