<div class="centered-box">
  <div class="form">
    <div class="header-container">
      <div class="header">

      </div>
    </div>

    <h1>{{localizeByCommon['LOGINOTHER_TITLE']}}</h1>

    <div class="form-box">

      <img src="../../../../../assets/images/warning300x300.png" />

      <p>{{localizeByCommon['LOGINOTHER_DESCRIPTION']}}</p>


      <app-button [text]="localizeByCommon['BTN_LOGIN']" (buttonClick)="retry()">
      </app-button>

    </div>
  </div>
</div>
