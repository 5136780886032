export class TimeSpan {

  constructor(public hours: number, public minutes: number) {

  }

  public get timeSpan() {
    return ((this.hours * 60 * 60) + (this.minutes * 60)) * 1000;
  }

  public get totalMinutes() {
    return ((this.hours * 60) + (this.minutes));
  }

  public static fromMinutes(minutes: number) {
    return new TimeSpan(Math.trunc(minutes / 60), Math.trunc(minutes % 60));
  }

}
