import { Component, Injector } from '@angular/core';
import { ControlsLib_Components_Base_BaseComponent } from 'controls';

@Component({
  selector: 'app-view-host-view',
  templateUrl: './view-host-view.component.html',
})
export class ViewHostViewComponent extends ControlsLib_Components_Base_BaseComponent {

  //#region Declarations...

  //#endregion

  //#region Properties...

  //#endregion

  //#region Constructors...
  constructor(injector: Injector) {
    super(injector);
  }
  //#endregion

  //#region Methods...

  //#endregion
}
