import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';



@NgModule({
  imports: [
    CommonModule,
    DialogsModule,
  ],
  declarations: [

  ],
  exports: [

    DialogsModule,
  ],
  providers: [

  ],
})
export class CoreModule { }
