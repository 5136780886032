import { ErrorHandler, Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable()
export class ErrorsHandlerService implements ErrorHandler {

  constructor(private messageService: MessageService) {

  }

  handleError(error: Error) {

    // Se l'errore è un ViewDestroyedError, succede quando si chiude un popup prima che questo sia stato caricato...
    // Non essendo riuscito a risolvere la questione, se succede questo errore lo ignoro
    if (error.stack != null && error.stack.indexOf('ViewDestroyedError') > -1) {
      return;
    }

    if (error.stack != null && error.stack.indexOf('Previous value: \'ng-untouched') > -1) {
      return;
    }

    if (error.stack != null && error.stack.indexOf('Previous value: \'aria-activedescendant') > -1) {
      return;
    }

    if (error.stack != null && error.stack.indexOf('Previous value: \'k-state-focused') > -1) {
      return;
    }

    this.messageService.setError(error.message, 500);
  }
}

