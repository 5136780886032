


import { CommonAccountDeskGridStateColumnOrderSettingDataRequest } from './CommonAccountDeskGridStateColumnOrderSettingDataRequest';
import { BaseRequest } from './BaseRequest';
export class BaseSearchRequest extends BaseRequest  {

  public uid: string =  null;
  public filterValue: string =  '';
  public filterOperator: string =  '';
  public saveSortState: boolean =  false;
  public saveFilterState: boolean =  false;
  public filterStateForSave: string =  '';
  public isFirstRequest: boolean =  false;
  public isComboOnDemand: boolean =  false;
  public gridColumnSettings: CommonAccountDeskGridStateColumnOrderSettingDataRequest[] =  [];
}





