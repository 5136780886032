import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnDestroy, Output, TemplateRef, ViewChild, NgZone, OnInit } from '@angular/core';
import { GridComponent as KendoGridComponent, GridDataResult, RowClassArgs, ColumnResizeArgs } from '@progress/kendo-angular-grid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { CoreLib_Classes_Poco_EntityIntString } from 'core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GridSetting } from '../../classes/GridSetting';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { FixToParentHeightService } from '../../services/common/fix-to-parent-height.service';
import { BaseComponent } from '../_base/base.component';
import { GridHelperMethods } from '../../classes/GridHelperMethods';
import { CommonGridColumnDataDefinition } from 'dto';
import { CellOptions } from '@progress/kendo-angular-excel-export';



@Component({
  selector: 'app-crud-grid',
  templateUrl: './crud-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrudGridComponent extends BaseComponent implements OnInit, OnDestroy {

  //#region Declarations...

  private grid_columnResizeSubscription: Subscription;

  private parentHeightValueChangedSubscription: Subscription;

  public grid: KendoGridComponent;

  @ViewChild(KendoGridComponent) set content(content: KendoGridComponent) {
    this.grid = content;
  }

  //#endregion

  //#region Properties...


  @Input()
  public state: DataSourceRequestState;

  @Input()
  public items: GridDataResult;

  @Input()
  public crudView: IBaseCrudViewContract;

  private _gridSetting: GridSetting;
  @Input()
  public get gridSetting(): GridSetting {
    return this._gridSetting;
  }
  public set gridSetting(value: GridSetting) {
    this._gridSetting = value;
  }

  public get gridColumns() {
    return this.gridSetting?.columnsDefinitions?.filter(c => c.isVisible);
  }

  public get excelColumns() {
    return this.gridSetting?.columnsDefinitions?.filter(c => c.isExcelVisible);
  }

  @Input()
  public showActionsColumn: boolean = true;

  @Input()
  public actionsColumnWidth: number = 90;

  @Input()
  public filterable: boolean = true;

  @Input()
  public groupable: boolean = false;

  @Input()
  public rowCallback: any = this.defaultRowCallback;

  @Input()
  public fixToParentHeightName: string;

  @Input()
  public toolbarPosition:  "top" | "bottom" | "both" = "top";

  public gridSelectionModes: any = GridSelectionModes;


  //#endregion

  //#region Constructors...

  constructor(injector: Injector) {
    super(injector);

    this.parentHeightValueChangedSubscription = this.injector.get(FixToParentHeightService).parentHeightValueChanged.pipe(debounceTime(100)).subscribe((e: CoreLib_Classes_Poco_EntityIntString) => this.updateGridHeight(e));
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

   }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    if (this.grid != null) {

      this.grid_columnResizeSubscription = this.grid.columnResize.pipe(debounceTime(50))
        .subscribe((e) => this.internalColumnResize(e));
    }
  }


  override ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.grid_columnResizeSubscription != null) {
      this.grid_columnResizeSubscription.unsubscribe();
    }

    if (this.parentHeightValueChangedSubscription != null) {
      this.parentHeightValueChangedSubscription.unsubscribe();
    }

  }

  public async internalColumnResize(args: Array<ColumnResizeArgs>) {
    GridHelperMethods.adjustLastColumn(this.crudView, this.grid);
  }


  public allData = (): Promise<any> => {
    return this.crudView.getAllItems();
  }
  private updateGridHeight(e: CoreLib_Classes_Poco_EntityIntString): void {

    if (e.description == this.fixToParentHeightName) {
      const ngZone = this.injector.get(NgZone) as NgZone;

      ngZone.runOutsideAngular(() => {
        if (this.grid != null) {
          this.grid.wrapper.nativeElement.style.height = (e.value) + 'px';
        }
      });
      if (this.grid != null) {
        this.grid.height = (e.value);
      }
    }
  }


  public defaultRowCallback(context: RowClassArgs) {
    //const isEven = context.index % 2 == 0;
    return {

    };
  }
  public getCellOptions(columnDefinition: CommonGridColumnDataDefinition) {
    if (columnDefinition.excelCellOptions != null && columnDefinition.excelCellOptions != '') {
      return JSON.parse(columnDefinition.excelCellOptions) as CellOptions;
    } else {
      return null;
    }
  }

  public onExcelExport(e: any): void {
    this.crudView.onExcelExport(e);
  }

  
  public getMobileExtraFiltersDescription(): string {
    return GridHelperMethods.getMobileExtraFiltersDescription(this.crudView);
  }

  //#endregion
}



