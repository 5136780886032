import { Component, OnInit, Injector } from '@angular/core';
import { CoreLib_Classes_StringHelper } from 'core';
import { CommonFilterDescriptor, CoreConstants } from 'dto';
import { FilterData } from '../../classes/FilterData';
import { FilterService } from '../../services/common/filter.service';
import { BaseFilterComponent } from '../_base/base-filter.component';

@Component({
  selector: 'app-filter-string',
  templateUrl: './filter-string.component.html'
})
export class FilterStringComponent extends BaseFilterComponent implements OnInit {

  //#region Input Output



  //#endregion

  //#region Fields

  private _textFrom: string = null;
  public get textFrom(): string {
    return this._textFrom;
  }
  public set textFrom(value: string) {
    this._textFrom = value;

    if (this.isInitSucceeded)
      this.notifyFilterChange();
  }

  private _textTo: string = null;
  public get textTo(): string {
    return this._textTo;
  }
  public set textTo(value: string) {
    this._textTo = value;

    if (this.isInitSucceeded)
      this.notifyFilterChange();
  }

  public fromError: string = "";

  public toError: string = "";

  public get computedHeaderFrom() {
    return (this.localizeByCommon['FROM_HEADER'] != null) ? this.localizeByCommon["FROM_HEADER"].replace("@@HEADER@@", this.header) : '';
  }

  public get computedHeaderTo() {
    return (this.localizeByCommon['TO_HEADER'] != null) ? this.localizeByCommon["TO_HEADER"].replace("@@HEADER@@", this.header) : '';
  }

  //#endregion

  //#region Constructor...

  constructor(injector: Injector,
    filterService: FilterService) {
    super(injector, filterService);
    this.formReference = 'CommonStringFilter';
    this.operator = CoreConstants.FilterOperators.EQ;
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  public override loadExistingFilters(filterData: FilterData): void {
    super.loadExistingFilters(filterData);
    this.operator = filterData.filterOperator;
    this.textFrom = filterData.filterValue.from;
    this.textTo = filterData.filterValue.to;
  }

  public override clearFilters(): void {
    super.clearFilters();
    this.textFrom = null;
    this.textTo = null;
    this.operator = CoreConstants.FilterOperators.EQ;
  }

  public override notifyFilterChange() {
    super.notifyFilterChange();

    this.fromError = "";
    this.toError = "";

    let filter: CommonFilterDescriptor = null;

    this.hasFilter = false;

    switch (this.operator) {
      case CoreConstants.FilterOperators.EQ:
      case CoreConstants.FilterOperators.NOTEQ:
      case CoreConstants.FilterOperators.STARTSWITH:
      case CoreConstants.FilterOperators.CONTAINS:
      case CoreConstants.FilterOperators.ENDSWITH:
      case CoreConstants.FilterOperators.NOTSTARTSWITH:
      case CoreConstants.FilterOperators.NOTCONTAINS:
      case CoreConstants.FilterOperators.NOTENDSWITH:
      case CoreConstants.FilterOperators.GREATHERTHAN:
      case CoreConstants.FilterOperators.GREATHERTHANOREQUALSTO:
      case CoreConstants.FilterOperators.LESSTHAN:
      case CoreConstants.FilterOperators.LESSTHANOREQUALSTO:
        if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.textFrom)) {
          filter = new CommonFilterDescriptor();
          filter.filterName = this.filterName;
          filter.operator = this.operator;
          filter.values = [];
          filter.values.push(this.textFrom);
          this.hasFilter = true;
        }
        break;

      case CoreConstants.FilterOperators.BETWEEN:
      case CoreConstants.FilterOperators.NOTBETWEEN:
        if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.textFrom) || !CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.textTo)) {

          if (CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.textFrom)) {
            this.fromError = this.localizeByCommon["MISSING_FROM"];
          }

          if (CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.textTo)) {
            this.fromError = this.localizeByCommon["MISSING_TO"];
          }

          filter = new CommonFilterDescriptor();
          filter.filterName = this.filterName;
          filter.operator = this.operator;
          filter.values = [];
          filter.values.push(this.textFrom);
          filter.values.push(this.textTo);

          filter.hasErrors = !CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.fromError);
          this.hasFilter = !filter.hasErrors;          
        }
        break;

      case CoreConstants.FilterOperators.ISNULL:
      case CoreConstants.FilterOperators.ISNOTNULL:
        filter = new CommonFilterDescriptor();
        filter.filterName = this.filterName;
        filter.operator = this.operator;
        this.hasFilter = true;
        break;
    }

    this.addFilter(filter, { textFrom: this.textFrom, textTo: this.textTo });
  }
  //#endregion
}
