




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonCrudEntityDeleteResponse extends BaseTokenResponse  {
  
}



