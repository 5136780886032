import { Injectable } from '@angular/core';
import { CommonFilterDescriptor } from 'dto';
import { Subject } from 'rxjs';
import { FilterData } from '../../classes/FilterData';


@Injectable({ providedIn: 'root' })
export class FilterService {

  private initialization: { [index: string]: boolean } = {};

  private needSave: { [index: string]: boolean } = {};

  private customFilterNames: { [index: string]: string } = {};

  private filters: { [index: string]: FilterData[] } = {};

  private extraFilters: { [index: string]: any } = {};

  public hasFilters: { [index: string]: boolean } = {};

  public filtersCleared = new Subject<string>();

  public filtersDefaultRestored = new Subject<string>();

  constructor() {

  }


  public getExtraFilterProp(filterGroupKey: string, propName: string): any {
    if (this.extraFilters[filterGroupKey])
      return this.extraFilters[filterGroupKey][propName];
    else
      return null;
  }

  public setExtraFilterProp(filterGroupKey: string, propName: string, value: any) {

    if (this.extraFilters[filterGroupKey] == null) {
      this.extraFilters[filterGroupKey] = {};
    }

    this.extraFilters[filterGroupKey][propName] = value;

    this.setHasFilters(filterGroupKey);
    this.storeFiltersInLocalStorage(filterGroupKey);

  }


  hasSavedFilter(filterGroupKey: string, filterName: string): boolean {

    return (this.filters[filterGroupKey] != null && this.filters[filterGroupKey].filter(c => c.filterDescriptor.filterName == filterName).length > 0) || (this.extraFilters[filterGroupKey] != null && this.extraFilters[filterGroupKey][filterName] !== undefined);
  }

  setHasFilters(filterGroupKey: string) {
    setTimeout(() => {
      this.hasFilters[filterGroupKey] = this.filters[filterGroupKey].map(c => c.filterDescriptor).length > 0;;

      if (this.hasFilters[filterGroupKey] == false) {
        for (var prop in this.extraFilters[filterGroupKey]) {
          if (this.extraFilters[filterGroupKey][prop] != null) {
            this.hasFilters[filterGroupKey] = true;
            break;
          }
        }
      }

    }, 50);
  }



  public hasErrors(filterGroupKey: string): boolean {
    return this.filters != null && this.filters[filterGroupKey] != null && this.filters[filterGroupKey].find(c => c.filterDescriptor.hasErrors) != null;
  }

  public clear(filterGroupKey: string) {
    this.filters[filterGroupKey] = [];
    this.extraFilters[filterGroupKey] = {};
    this.storeFiltersInLocalStorage(filterGroupKey);
    this.filtersCleared.next(filterGroupKey);
  }


  public restoreDefaults(filterGroupKey: string) {
    this.filters[filterGroupKey] = JSON.parse(localStorage.getItem('D_' + filterGroupKey + "_f")) as FilterData[];
    this.extraFilters[filterGroupKey] = JSON.parse(localStorage.getItem('D_' + filterGroupKey + "_ef")) as any;
    this.storeFiltersInLocalStorage(filterGroupKey);
    this.filtersDefaultRestored.next(filterGroupKey);
  }

  public storeDefaults(filterGroupKey: string) {

    if (localStorage.getItem('D_' + filterGroupKey + "_f") == null) {
      localStorage.setItem('D_' + filterGroupKey + "_f", JSON.stringify(this.filters[filterGroupKey]));

    }

    if (localStorage.getItem('D_' + filterGroupKey + "_ef") == null) {
      localStorage.setItem('D_' + filterGroupKey + "_ef", JSON.stringify(this.extraFilters[filterGroupKey]));
    }

  }

  public init(filterGroupKey: string, loadFromLocalStorage: boolean, autosaveFilters: boolean, customFilterName: string) {

    this.needSave[filterGroupKey] = autosaveFilters;

    this.customFilterNames[filterGroupKey] = customFilterName;

    this.initialization[filterGroupKey] = false;

    this.filters[filterGroupKey] = [];
    this.extraFilters[filterGroupKey] = {};

    if (loadFromLocalStorage && localStorage.getItem(filterGroupKey + "_f" + "_" + customFilterName) != null) {
      this.filters[filterGroupKey] = JSON.parse(localStorage.getItem(filterGroupKey + "_f" + "_" + customFilterName)) as FilterData[];
    }

    if (loadFromLocalStorage && localStorage.getItem(filterGroupKey + "_ef" + "_" + customFilterName) != null) {
      this.extraFilters[filterGroupKey] = JSON.parse(localStorage.getItem(filterGroupKey + "_ef" + "_" + customFilterName)) as any;
    }

    if (this.filters[filterGroupKey] == null)
      this.filters[filterGroupKey] = [];

    if (this.extraFilters[filterGroupKey] == null)
      this.extraFilters[filterGroupKey] = {};

    this.setHasFilters(filterGroupKey);


    this.initialization[filterGroupKey] = true;
  }

  public getAllFilterDescriptors(filterGroupKey: string): CommonFilterDescriptor[] {
    if (this.filters[filterGroupKey] != null) {
      return this.filters[filterGroupKey].map(c => c.filterDescriptor);
    } else {
      return [];
    }
  }

  public addFilter(filterGroupKey: string, filterDescriptor: CommonFilterDescriptor, filterOperator: number, filterValue: any) {
    const filterData = new FilterData();
    filterData.filterDescriptor = filterDescriptor;
    filterData.filterOperator = filterOperator;
    filterData.filterValue = filterValue;

    if (this.filters[filterGroupKey] == null) {
      this.filters[filterGroupKey] = [];
    }

    this.filters[filterGroupKey].push(filterData);

    this.storeFiltersInLocalStorage(filterGroupKey);

    this.setHasFilters(filterGroupKey);
  }

  public removeFilter(filterGroupKey: string, filterName: string) {
    if (this.filters[filterGroupKey] != null) {
      const index = this.filters[filterGroupKey].findIndex(o => o.filterDescriptor.filterName === filterName);

      if (index > -1)
        this.filters[filterGroupKey].splice(index, 1);

      this.storeFiltersInLocalStorage(filterGroupKey);

      this.setHasFilters(filterGroupKey);
    }
  }

  public getFilter(filterGroupKey: string, filterName: string) {
    if (this.filters[filterGroupKey] != null) {
      return this.filters[filterGroupKey].find(o => o.filterDescriptor.filterName === filterName);
    } else {
      return null;
    }
  }

  public storeFiltersInLocalStorage(filterGroupKey: string) {
    if (this.needSave[filterGroupKey]) {
      if (this.initialization[filterGroupKey]) {
        if (this.filters[filterGroupKey] !== undefined) {
          localStorage.setItem(filterGroupKey + "_f"+ "_" + this.customFilterNames[filterGroupKey], JSON.stringify(this.filters[filterGroupKey]));
        } else {
          localStorage.removeItem(filterGroupKey + "_f"+ "_" + this.customFilterNames[filterGroupKey]);
        }

        if (this.extraFilters[filterGroupKey] !== undefined) {
          localStorage.setItem(filterGroupKey + "_ef"+ "_" + this.customFilterNames[filterGroupKey], JSON.stringify(this.extraFilters[filterGroupKey]));
        } else {
          localStorage.removeItem(filterGroupKey + "_ef"+ "_" + this.customFilterNames[filterGroupKey]);
        }
      }
    }
  }


}
