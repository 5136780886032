


import { BaseTokenResponse } from './BaseTokenResponse';
export class BaseEditResponse extends BaseTokenResponse  {

  public uid: string =  '00000000-0000-0000-0000-000000000000';
}





