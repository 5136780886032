








import { ReportDataSourceDataRequest } from './ReportDataSourceDataRequest';
import { ReportBaseRequest } from './ReportBaseRequest';
export class ReportGetRequest extends ReportBaseRequest {
      
    public isForQueue: boolean = false;    
    public reportFileName: string = '';    
    public entityUid: string = '00000000-0000-0000-0000-000000000000';    
    public reportUid: string = '00000000-0000-0000-0000-000000000000';    
    public dataSources: ReportDataSourceDataRequest[] = [];    
}

