


import { BaseSearchRequest } from './BaseSearchRequest';
export class BaseBulkChangeSearchRequest extends BaseSearchRequest  {

  public selectionMode: number =  0;
  public selectedOrUnselectedUids: string[] =  [];
  public commitBulkChanges: boolean =  false;
}





