<div class="app-combo-box" [class.big]="bigger" [class.error]="hasError" [class.ondemand]="ondemand"
  [class.hideheader]="hideheader" [class.value]="selectedItem" [class.focus]="hasFocus" [class.loading]="isInLoadingOnDemand">
  <div [id]="erroranchor"></div>

  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory"
      *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>

  <div class="read-only-input-span" *ngIf="selectedItem && disabled && itemTemplate">
    <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit: selectedItem}">
    </ng-container>
  </div>

  <div class="read-only-input-span" [hidden]="!disabled" *ngIf="itemTemplate == null || selectedItem == null">
    {{readOnlyValue}}</div>

  <div *ngIf="!disabled" class="d-flex">
    <div class="align-self-start" style="width:100%" (click)="loadItemsIfEmpty($event)">
      <kendo-combobox #combo [data]="itemsSource" (blur)="onBlur()" (focus)="onFocus()"  [(ngModel)]="selectedItem"
        [filterable]="ondemand" [textField]="textField"
        title="{{(selectedItem != null) ? selectedItem[textField] : header}}" [valueField]="valueField" [suggest]="true"
        (filterChange)="handleFilter($event)" [placeholder]="''"
        [clearButton]="allowClear"
        kendoTooltip [tooltipTemplate]="tooltipTemplate" tooltipClass="white-tooltip" [showOn]="(selectedItem != null && itemTemplate != null) ? 'hover' : 'none'"
        [popupSettings]="{ popupClass: (bigger) ? 'app-combo-box-popup big' : 'app-combo-box-popup'}">
        <ng-template *ngIf="itemTemplate" kendoComboBoxItemTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
          </ng-container>
        </ng-template>
      </kendo-combobox>
      <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span
          class="mandatory" *ngIf="mandatory" [innerHtml]="mandatoryText"></span> </span>
    </div>
    <div class="d-inline-flex" *ngIf="showExtendedSearch">
      <ng-content select=".right-buttons-container-left"></ng-content>
      <app-button [icon]="'search'" [inverted]="true" (buttonClick)="popupOpen()">
      </app-button>
      <ng-content select=".right-buttons-container-right"></ng-content>
    </div>
  </div>
  <span class="error-span" [innerHtml]="error"></span>
</div>

<ng-template #tooltipTemplate>
  <ng-container *ngIf="selectedItem" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:selectedItem}">
  </ng-container>
</ng-template>

<app-async-load-panel *ngIf="popupOpened" (init)="loadComponent()">
  <kendo-dialog [title]="dialogTitle" (close)="popupClose()" class="k-dialog-full k-dialog-notscroll">
    <div class="container-fluid">
      <ng-template popup-host></ng-template>
    </div>
    <app-loader></app-loader>
  </kendo-dialog>
</app-async-load-panel>
