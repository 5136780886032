




    
export class CommonRolePermissionDataResponse   {
  
  public formReference: string = '';
  
  public isViewEnabled: boolean = false;
  
  public isEditEnabled: boolean = false;
  
  public isNewEnabled: boolean = false;
  
  public isDeleteEnabled: boolean = false;
  
}



