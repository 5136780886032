<app-grid-toolbar [formReference]="crudView.formReference  || formReference || 'default'" [gridView]="crudView" [showRightPopupAnchor]="true" [showGridActions]="crudView.viewMode == crudView.viewModes.List">
  <ng-container class="left-container">

    <app-button [text]="localizeByCommon['BTN_BACK']" [icon]="'undo'" (buttonClick)="crudView.returnToList()"
    [buttonClass]="'k-button-white'" *ngIf="crudView.viewMode == crudView.viewModes.View"></app-button>

    <app-button [text]="localizeByCommon['BTN_CANCEL']" [icon]="'undo'" (buttonClick)="crudView.cancelEdit()"
    [buttonClass]="'k-button-white'"
      *ngIf="crudView.viewMode == crudView.viewModes.Edit || crudView.viewMode == crudView.viewModes.New"></app-button>

    <!-- <app-button [text]="localizeByCommon['BTN_REFRESH']" [icon]="'refresh'" (buttonClick)="crudView.loadItems()"
    [buttonClass]="'k-button-white'" *ngIf="crudView.viewMode == crudView.viewModes.List"></app-button>

    <app-button [text]="localizeByCommon['BTN_CLEAR_FILTER']" [icon]="'filter-clear'" (buttonClick)="crudView.clearFilters()"
    [buttonClass]="'k-button-white'"
      *ngIf="crudView.viewMode == crudView.viewModes.List && crudView.state.filter != null && crudView.state.filter.filters != null && crudView.state.filter.filters.length > 0">
    </app-button> -->

    <app-button [text]="localizeByCommon['BTN_SELECT']" [icon]="'check'"
      (buttonClick)="crudView.selectItem(crudView.currentItem)"
      *ngIf="crudView.selectionMode == gridSelectionModes.Single && (crudView.viewMode == crudView.viewModes.View)">
    </app-button>

    <ng-container
      *ngIf="crudView.isInPopup && (crudView.selectionMode == gridSelectionModes.MultipleAllSelected || crudView.selectionMode == gridSelectionModes.MultipleAllUnselected) && (crudView.viewMode == crudView.viewModes.List)">
      <app-button [text]="localizeByCommon['BTN_CONFIRM']" [icon]="'check'" (buttonClick)="crudView.confirmSelection()"
      [buttonClass]="'k-button-green'"></app-button>
    </ng-container>

    <app-button [text]="localizeByCommon['BTN_NEW']" [icon]="'file-add'" (buttonClick)="crudView.newItem()"
      *ngIf="crudView.rolePermission.isNewEnabled && crudView.canNew && (crudView.viewMode == crudView.viewModes.List || crudView.viewMode == crudView.viewModes.View) && !crudView.startInViewMode && !crudView.startInEditMode">
    </app-button>

    <app-button [text]="localizeByCommon['BTN_EDIT']" [icon]="'edit'" (buttonClick)="crudView.editCurrentItem()"
      *ngIf="crudView.rolePermission.isEditEnabled && crudView.canEdit && crudView.viewMode == crudView.viewModes.View && crudView.canEditCurrentItem">
    </app-button>

    <app-button [text]="localizeByCommon['BTN_CLONE']" [icon]="'copy'" (buttonClick)="crudView.cloneCurrentItem()"
    *ngIf="crudView.rolePermission.isEditEnabled && crudView.canNew && crudView.canClone && crudView.viewMode == crudView.viewModes.View">
  </app-button>

    <app-button [text]="localizeByCommon['BTN_DELETE']" [icon]="'delete'" [buttonClass]="'k-button-red'"
      (buttonClick)="crudView.deleteCurrentItem()"
      *ngIf="crudView.rolePermission.isDeleteEnabled && crudView.canDelete && crudView.viewMode == crudView.viewModes.View && crudView.canDeleteCurrentItem && !crudView.startInViewMode && !crudView.startInEditMode">
    </app-button>

    <app-button [text]="localizeByCommon['BTN_SAVEANDSELECT']" [icon]="'check'" [buttonClass]="'k-button-green'"
      (buttonClick)="crudView.save(true, true)"
      *ngIf="crudView.selectionMode == gridSelectionModes.Single && (crudView.viewMode == crudView.viewModes.Edit || crudView.viewMode == crudView.viewModes.New)">
    </app-button>

    <app-button [text]="localizeByCommon['BTN_SAVE']" [icon]="'save'" [buttonClass]="'k-button-green'"
      (buttonClick)="crudView.save(false, true)"
      *ngIf="crudView.viewMode == crudView.viewModes.Edit || crudView.viewMode == crudView.viewModes.New">
    </app-button>


    <ng-content select=".left-container"></ng-content>

  </ng-container>
  <ng-container class="right-container">

    <ng-content select=".right-container"></ng-content>

    <app-button [text]="localizeByCommon['BTN_PREVIOUS']" [icon]="'arrow-60-left'" [buttonClass]="'k-button-white'"
      (buttonClick)="crudView.previousRecord()"
      *ngIf="crudView.viewMode == crudView.viewModes.View && !crudView.startInViewMode && !crudView.startInEditMode"
      [disabled]="!crudView.isPreviousAvailable">
    </app-button>


    <app-button [text]="localizeByCommon['BTN_NEXT']" [icon]="'arrow-60-right'" [buttonClass]="'k-button-white'"
      (buttonClick)="crudView.nextRecord()"
      *ngIf="crudView.viewMode == crudView.viewModes.View && !crudView.startInViewMode && !crudView.startInEditMode"
      [disabled]="!crudView.isNextAvailable">
    </app-button>



    <app-button [text]="localizeByCommon['BTN_SAVEANDPREVIOUS']" [icon]="'arrow-60-left'" [buttonClass]="'k-button-green'"
    (buttonClick)="crudView.saveAndPreviousRecord()"
    *ngIf="crudView.viewMode == crudView.viewModes.Edit && !crudView.startInViewMode && !crudView.startInEditMode && !crudView.isInCloneMode"
    [disabled]="!crudView.isPreviousAvailable">
  </app-button>


  <app-button [text]="localizeByCommon['BTN_SAVEANDNEXT']" [icon]="'arrow-60-right'" [buttonClass]="'k-button-green'"
    (buttonClick)="crudView.saveAndNextRecord()"
    *ngIf="crudView.viewMode == crudView.viewModes.Edit && !crudView.startInViewMode && !crudView.startInEditMode && !crudView.isInCloneMode"
    [disabled]="!crudView.isNextAvailable">
  </app-button>



    <ng-container
      *ngIf="(crudView.selectionMode == gridSelectionModes.MultipleAllSelected || crudView.selectionMode == gridSelectionModes.MultipleAllUnselected) && (crudView.viewMode == crudView.viewModes.List) && crudView.showSelectUnselectAll">

      <app-button [text]="localizeByCommon['BTN_SELECTALL']" [icon]="'checkbox-checked'" [buttonClass]="'k-button-white'"
        (buttonClick)="crudView.selectAll()">
      </app-button>
      <app-button [text]="localizeByCommon['BTN_UNSELECTALL']" [icon]="'checkbox'" [buttonClass]="'k-button-white'"
        (buttonClick)="crudView.unselectAll()">
      </app-button>
    </ng-container>


  </ng-container>
  <ng-container class="right-container-popup">
<!-- 
    <app-button [text]="localizeByCommon['BTN_EXPORT_EXCEL']" [icon]="'file-excel'" [buttonClass]="'k-button-white'"
      (buttonClick)="crudView.exportExcel()" *ngIf="crudView.viewMode == crudView.viewModes.List">
    </app-button> -->
    <ng-content select=".right-container-popup"></ng-content>

  </ng-container>
</app-grid-toolbar>
