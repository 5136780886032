import { BaseSearchRequest } from 'dto';
export class SearchRequest {

  constructor() {

  }

  public mode: string;
  public request: BaseSearchRequest;
}
