




import { IBaseCrudSharedDataResponse } from './IBaseCrudSharedDataResponse';    
export class CommonCrudComboDataResponse   implements IBaseCrudSharedDataResponse {
  
  public uid: string = '00000000-0000-0000-0000-000000000000';
  
  public fullDescription: string = '';
  
}



