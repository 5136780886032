import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ControlsLib_Components_Base_BaseComponent, ControlsLib_Components_ModuleHost_ModuleHostComponent, ControlsLib_Interfaces_IPluginData } from 'controls';

@Component({
  selector: 'app-empty-plugin-host',
  templateUrl: './empty-plugin-host.component.html',
})
export class EmptyPluginHostComponent extends ControlsLib_Components_Base_BaseComponent implements OnInit, AfterViewInit {

  //#region Declarations...


  private moduleHost: ControlsLib_Components_ModuleHost_ModuleHostComponent;

  @ViewChild(ControlsLib_Components_ModuleHost_ModuleHostComponent) set content(content: ControlsLib_Components_ModuleHost_ModuleHostComponent) {
    this.moduleHost = content;
  }

  //#endregion

  //#region Properties...

  //#endregion

  //#region Constructors...
  constructor(injector: Injector, private activatedRoute: ActivatedRoute) {
    super(injector);
  }
  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  override async ngAfterViewInit(): Promise<void> {
    this.activatedRoute.data.subscribe(async (data) => {
      await this.pluginLoader.getModuleInstance(data as ControlsLib_Interfaces_IPluginData, this.moduleHost.viewContainerRef);
    });


  }

  //#endregion
}
