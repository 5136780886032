import { Injectable } from '@angular/core';
import { ControlsLib_Services_Common_ColorService } from 'controls';


@Injectable({ providedIn: 'root' })
export class ColorService extends ControlsLib_Services_Common_ColorService {

  public override mainColorBrush: string = "#0067B2";

  public override shadowColorBrush: string = "#2b9be6";



}
