import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CoreLib_Services_Common_ApplicationStateService, CoreLib_Services_Common_AuthenticationService } from 'core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authenticationService: CoreLib_Services_Common_AuthenticationService
        , private router: Router
        , private cookieService: CookieService
        , private applicationStateService: CoreLib_Services_Common_ApplicationStateService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if ((this.authenticationService.isLoggedIn() || this.authenticationService.attemptRelogin || this.authenticationService.isWindowsAuth) && this.cookieService.get("haveMandatoryFields") == '') {
            return true;
        }

        if (state.url == '/' || state.url == '/login') {
            this.authenticationService.redirectUrl = this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home');
        } else {
            this.authenticationService.redirectUrl = state.url;
        }

        // go login page
        if ((this.authenticationService.isLoggedIn() || this.authenticationService.attemptRelogin || this.authenticationService.isWindowsAuth) && this.cookieService.get("haveMandatoryFields") != '')
            this.router.navigate(['/registration/update']);
        else
            this.router.navigate(['/login']);

        return false;
    }
}