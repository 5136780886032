<div class="app-date-picker" [class.big]="bigger" [class.error]="hasError" [class.hideheader]="hideheader">
  <div [id]="erroranchor"></div>
  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory" *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>
  <div>
    <kendo-timepicker #timeobject [(value)]="value"  [format]="format" [placeholder]="''" [hidden]="disabled"></kendo-timepicker>
  </div>
  <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span class="mandatory" *ngIf="mandatory"  [innerHtml]="mandatoryText"></span> </span>
  <div class="read-only-input-span" [hidden]="!disabled">{{readOnlyValue}}</div>
  <span class="error-span" [innerHtml]="error"></span>
</div>
