import { AfterViewChecked, AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone, OnDestroy } from '@angular/core';
import { CoreLib_Classes_Poco_EntityIntString } from 'core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { FixToParentHeightService } from '../services/common/fix-to-parent-height.service';
import { NavigationService } from '../services/common/navigation.service';
import { ToolbarLeftService } from '../services/common/toolbar-left.service';



@Directive({
  selector: '[fixToParentHeight]',
})
export class FixToParentHeightDirective implements AfterViewInit, OnDestroy {

  private themeChanged: Subscription;

  private toolbarLeftSizeChanged: Subscription;

  private resizeSubjectSubscription: Subscription;

  private topSensorChangedSubscription: Subscription;

  private resizeSubscription: Subscription;

  private resizeStableSubscription: Subscription;

  private scrollSubscription: Subscription;

  private resizeSubject = new Subject<boolean>();


  constructor(private element: ElementRef, private ngZone: NgZone, private navigationService: NavigationService, private fixToParentHeightService: FixToParentHeightService, private toolbarLeftService: ToolbarLeftService) {

    this.themeChanged = this.navigationService.themeChanged.pipe(debounceTime(10)).subscribe(() => this.resizeSubject.next(true));

    this.toolbarLeftSizeChanged = this.toolbarLeftService.sizeChanged.pipe(debounceTime(10)).subscribe(() => this.resizeSubject.next(true));

    this.resizeSubjectSubscription = this.resizeSubject.pipe(debounceTime(10)).subscribe(() => setTimeout(() => this.resize(), 100));

    this.topSensorChangedSubscription = this.fixToParentHeightService.topSensorChanged.pipe(debounceTime(10)).subscribe(() => setTimeout(() => this.resize(), 100));

    this.resizeSubscription = fromEvent(window, 'resize').pipe(
      debounceTime(500))
      .subscribe((event) => {
        this.resizeSubject.next(true);
      });
  }

  @Input() highlightColor: string;

  private _bottomMargin: number = 1;

  @Input()
  public set bottomMargin(val: number) {
    this._bottomMargin = val;
    this.resizeSubject.next(true);
  }

  public get bottomMargin() {
    return this._bottomMargin;
  }

  @Input() public fixToParentHeight: any;

  @Input() public fixToParentHeightEnabled: boolean = true;

  private _reloadSensor: boolean;
  @Input()
  public set reloadSensor(val: boolean) {
    this._reloadSensor = val;
    this.resizeSubject.next(true);
  }

  public get reloadSensor() {
    return this._reloadSensor;
  }

  ngOnDestroy(): void {
    this.themeChanged?.unsubscribe();
    this.toolbarLeftSizeChanged?.unsubscribe();
    this.resizeSubjectSubscription?.unsubscribe();
    this.topSensorChangedSubscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
    this.resizeStableSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.resizeSubject.next(true);
  }


  resize() {
    if (this.fixToParentHeight != null && this.fixToParentHeightEnabled) {
      this.resizeStableSubscription?.unsubscribe();
      this.resizeStableSubscription = this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {

        this.ngZone.runOutsideAngular(() => {
          const element = this.element;

          this.scrollSubscription = fromEvent(this.element.nativeElement, 'scroll').pipe(
            debounceTime(500))
            .subscribe((event: any) => {
              this.ngZone.run(() => {
                this.fixToParentHeightService.scrollTop = event.target.scrollTop;
              });
            });


          const oldElementHeight = element.nativeElement.style.height;

          let childPos = element.nativeElement.getBoundingClientRect();

          let parent = element.nativeElement.parentElement;

          let parentTops = 0;

          let foundCorrectWrapper: boolean = false;
          while (foundCorrectWrapper == false && parent != null) {

            const parentPos = parent.getBoundingClientRect();

            parentTops += childPos.top - parentPos.top;

            if (parent.className.indexOf('container-fluid') > -1 || parent.className.indexOf('fix-to-parent-height-wrapper') > -1) {
              foundCorrectWrapper = true;
            } else {
              parent = parent.parentElement;
            }

            childPos = parentPos;

          }

          if (parent == null || parent.clientHeight == null || parent.clientHeight < 10) {
            console.error('unable to get parent clientHeight [fix-to-parent-height.directive]');
            element.nativeElement.style.height = '400px';
          } else {
            element.nativeElement.style.height = parent.clientHeight - this.bottomMargin - parentTops + 'px';
          }

          if (oldElementHeight != element.nativeElement.style.height) {
            if (this.fixToParentHeight == 'true') {
              this.fixToParentHeightService.parentHeightChanged.next(true);
            } else {
              const parentHeightValueChanged = new CoreLib_Classes_Poco_EntityIntString();
              parentHeightValueChanged.value = parseInt(element.nativeElement.offsetHeight);
              parentHeightValueChanged.description = this.fixToParentHeight;
              this.fixToParentHeightService.parentHeightValueChanged.next(parentHeightValueChanged);
            }
          }
        });
      });
    }
  }

}
