<div class="grid-filter-multiselect">

  <kendo-multiselect #multiselect [data]="data" [popupSettings]="{ width: 'auto' }" [class.ondemand]="ondemand"
    [(ngModel)]="selectedValues" (valueChange)="onChange($event)" [filterable]="true" [textField]="textField"
    [valueField]="valueField" (filterChange)="handleFilter($event)" [kendoDropDownFilter]="filterSettings">
    <ng-template *ngIf="itemTemplate" kendoMultiSelectItemTemplate let-dataItem>
      <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
      </ng-container>
    </ng-template>
    <ng-template *ngIf="itemTemplate && !ondemand" kendoMultiSelectTagTemplate let-dataItem>
      <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
      </ng-container>
    </ng-template>
    <ng-template kendoMultiSelectNoDataTemplate>
      <span class="help-span ml-2 mr-2">
        {{noDataText}}        
      </span>
    </ng-template>
  </kendo-multiselect>


</div>
<kendo-grid-filter-cell-operators [value]="operator" [operators]="customOperators" [showButton]="hasFilters"
  (clear)="clearFilter(true)" (valueChange)="operatorChange($event)">
</kendo-grid-filter-cell-operators>