import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ControlsTranslationsService {

  public localizeByCultureName: { [index: string]: { [index: string]: string } } = {};

  constructor() {

    this.localizeByCultureName['en'] = {
      ['GRID_DIALOG_SORTSETTINGS_TITLE']: 'Sort setting',
      ['GRID_DIALOG_SORTSETTINGS_LEFTTITLE']: 'Select columns',
      ['GRID_DIALOG_SORTSETTINGS_RIGHTTITLE']: 'Order by',
      ['BTN_APPLY']: 'APPLY',
      ['BTN_APPLYANDSAVE']: 'APPLY AND SAVE',
      ['BTN_SORTSETTINGS']: 'SORT SETTING',
      ['BTN_RESTOREDEFAULT']: 'RESTORE DEFAULT',
      ['BTN_FILTERSETTINGS']: 'FILTER SETTING',
      ['GRID_DIALOG_FILTERSETTINGS_TITLE']: 'Filters settings',
      ['GRID_DIALOG_COLUMNORDERSETTINGS_TITLE']: 'Columns order',
      ['GRID_DIALOG_COLUMNORDERSETTINGS_HELP']: 'Drag columns in desired position',
      ['BTN_COLUMNORDERSETTINGS']: 'COLUMNS ORDER',
      ['BTN_CLEARANDSAVE_FILTER']: 'CLEAR AND SAVE',

      ['BTN_CLOUD_PRINT']: 'CLOUD PRINT',
      ['BTN_DOWNLOAD']: 'DOWNLOAD',
      ['BTN_PRINT']: 'PRINT',
      ['BTN_PRINTPREVIEW']: 'PRINT PREVIEW',
      ['LNK_DOWNLOAD']: 'Click here to show the document',
      ['PREVIEW_TITLE']: 'Print preview',
      ['SELECTREPORT_DESCRIPTION']: 'Select the report you want to print',
      ['SELECTREPORT_TITLE']: 'Report selection',

      ['MISSING_FROM']: 'Missing From value',
      ['MISSING_TO']: 'Missing To value',

      ['FROM_HEADER']: 'From @@HEADER@@',
      ['TO_HEADER']: 'To @@HEADER@@',


      ['MOBILEEXTRAFILTERS_SETTED']: 'Some more filters on theese fields: ',

      ['ACCOUNT_SETTING']: 'Account Setting',
      ['ALERT_TITLE_CONFIRM']: 'Please confirm',
      ['ALERT_TITLE_INFO']: 'Information',
      ['ALERT_TITLE_WARNING']: 'Warning',
      ['BTN_BACK']: 'Back',
      ['BTN_BROWSE']: 'BROWSE',
      ['BTN_CANCEL']: 'Cancel',
      ['BTN_CLEAR_FILTER']: 'CLEAR FILTERS',
      ['BTN_RESTORE_DEFAULT_FILTER']: 'RESTORE DEFAULT FILTERS',
      ['BTN_CLONE']: 'CLONE',
      ['BTN_CONFIRM']: 'Confirm',
      ['BTN_DELETE']: 'Delete',
      ['BTN_EDIT']: 'Edit',
      ['BTN_EXPORT_EXCEL']: 'EXCEL DOWNLOAD',
      ['BTN_LOGIN']: 'Login',
      ['BTN_NEW']: 'New',
      ['BTN_NEXT']: 'Next',
      ['BTN_SAVEANDNEXT']: 'Save and next',
      ['BTN_SAVEANDPREVIOUS']: 'Save and previous',
      ['BTN_PREVIOUS']: 'Previous',
      ['BTN_REFRESH']: 'Refresh',
      ['BTN_SAVE']: 'Save',
      ['BTN_SAVEANDSELECT']: 'Save and select',
      ['BTN_SELECT']: 'Select',
      ['BTN_SELECTALL']: 'Select all',
      ['BTN_UNSELECTALL']: 'Unselect all',
      ['BULKPROCESSABLEITEMSINFO']: 'There are @@PROCESSABLECOUNT@@ processable items and @@UNPROCESSABLECOUNT@@ unprocessable items.',
      ['BULK_CHANGE_PREVIEW']: 'Bulk changes preview',
      ['BULK_CHANGE_RESULT']: 'Bulk change result',
      ['CANCEL']: 'Cancel',
      ['COMBO_DIALOG_TITLE']: 'Select @@HEADER@@',
      ['COMBO_DIALOG_TITLE_FOR']: 'Select @@HEADER@@ for @@ENTITYOWNER@@',
      ['CRUD_VALIDATION_ERROR']: 'Please check the inserted data, one ore more field has not been compiled correctly.',
      ['DATE_BETWEEN_MUST_HAVE_FROM_AND_TO']: 'The from and to is both mandatory',
      ['DATE_FROM_MUST_BETWEEN_MIN_MAX']: 'The date from filter must be greather than @@MINDATE@@ and less than @@MAXDATE@@',
      ['DATE_TO_MUST_BETWEEN_MIN_MAX']: 'The date to filter must be greather than @@MINDATE@@ and less than @@MAXDATE@@',
      ['DATE_TO_MUST_GTE_THAN_FROM']: 'The date to filter must be greather than the filter date from',
      ['DROP_ZONE_TEXT']: 'Drag here to upload the file',
      ['FILTER_OPERATOR_BETWEEN']: 'From/to',
      ['FULL_SCREEN']: 'FULL SCREEN',
      ['GRIDHEADER_ACTIONS']: 'Actions',
      ['GRIDHEADER_RECORDINFO']: 'Info',
      ['IS_DEFAULT_FULL_SCREEN_LOGIN']: 'After login show the application into FullScreen Mode',
      ['LOGINOTHER_DESCRIPTION']: 'You have logged on another browser or device. Press login to continue.',
      ['LOGINOTHER_TITLE']: 'Session Ended',
      ['LOGIN_TITLE']: 'Login',
      ['LOGOUT']: 'Logout',
      ['MORETHANONEPUSRECEIVED']: 'There are @@COUNT@@ new notifications',
      ['NAMEREQUIRED']: 'Insert the user name to continue',
      ['NAVIGATION_CONFIRM_EXIT_EDIT']: 'Changes you made may not be saved. Are you sure to exit?',
      ['NO']: 'No',
      ['NONEWNOTIFICATIONS']: 'No new notifications',
      ['NOTIFICATIONSMENU_TITLE']: 'Notifications center',
      ['NO_FILTER']: 'No filter',
      ['NO_SELECTED_ITEMS']: 'No selected elements',
      ['NO_ITEMS_FOUND']: 'No items found',
      ['X_CHARS_TOCONTINUE']: 'Please type at least @@X@@ chars to start search',
      ['NUMERIC_OPERATOR_ISEMPTY']: 'Is zero or empty',
      ['NUMERIC_OPERATOR_ISNOTEMPTY']: 'Is not zero or empty',
      ['OK']: 'Ok',
      ['PASSWORD']: 'Password',
      ['PASSWORDREQUIRED']: 'Insert the password to continue',
      ['LOGINFAILED']: 'Incorrect username or password, please try again',
      ['PASSWORDEXPIRED']: 'Password expired. Change password to continue.',
      ['RECORDINFO_ATTIME']: 'at',
      ['RECORDINFO_BY']: 'from',
      ['RECORDINFO_CREATED']: 'Created on',
      ['RECORDINFO_UPDATED']: 'Updated on',
      ['RELOGINREQUIRED']: 'The session is expired! Please type your password to continue.',
      ['RELOGIN_INSERTPASSWORD']: 'Enter the password for the account',
      ['SELECTACTION']: 'Operation selection',
      ['UPLOAD_COMPLETED']: 'Upload completed',
      ['UPLOAD_IN_PROGRESS']: 'Upload in progress',
      ['USERNAME']: 'Account Name',
      ['VIEW']: 'View',
      ['WARNING']: 'Warning',
      ['YES']: 'Yes',
      ['CHANGE_PASSWORD']: 'Change password',
      ['CHANGE_PASSWORD_TITLE']: 'Change password',
      ['CHANGE_PASSWORD_INSERTPASSWORD']: 'Enter the new password for the account',
      ['CHANGE_PASSWORD_LOGINFAILED']: 'Incorrect old password, please try again',
      ['CHANGE_PASSWORD_OK']: 'Password changed successfully',
      ['PASSWORDNEW']: 'New password',
      ['PASSWORDOLD']: 'Old password',
      ['PASSWORDNEWCONFIRM']: 'New password confirmation',
      ['BTN_RETURN_TO_LOGIN']: 'Retry to login',
      ['PASSWORDNEWREQUIRED']: 'Insert the new password to continue',
      ['PASSWORDOLDREQUIRED']: 'Insert the old password to continue',
      ['PASSWORDNEWCONFIRMREQUIRED']: 'Insert the new password confirmation to continue',
      ['PASSWORDNEWMISMATCH']: 'New password and new password confirmation do not match',
      ['PASSWORDNEWALREADYUSED']: 'The new password must be different from the old password',
      ['FailedBadList']: 'be more complex',
      ['FailedDigit']: 'have at least one digit',
      ['FailedEntropy']: 'have entropy score of at least @@VALUE@@',
      ['FailedLength']: 'be at least @@VALUE@@ characters long',
      ['FailedLowercase']: 'have at least one lowercase letter in password',
      ['FailedPattern']: 'have a maximum of @@VALUE@@ characters that neighbor on the keyboard',
      ['FailedPunctuation']: 'have at least one punctuation mark',
      ['FailedRepeat']: 'have a maximum of @@VALUE@@ adjacent repeat characters',
      ['FailedUnique']: 'have at least @@VALUE@@ unique characters',
      ['FailedUppercase']: 'have at least one uppercase letter',
      ['kendo.autocomplete.combobox.dropdownlist.multisele']: 'No data found',
      ['kendo.autocomplete.combobox.dropdownlist.noDataTex']: 'No data found',
      ['kendo.autocomplete.combobox.noDataText']: 'No data found',
      ['kendo.autocomplete.noDataText']: 'No data found',
      ['kendo.calendar.today']: 'Today',
      ['kendo.combobox.noDataText']: 'No data found',
      ['kendo.dateinput.decrement']: 'Decrease value',
      ['kendo.dateinput.increment']: 'Increase value',
      ['kendo.datepicker.today']: 'Today',
      ['kendo.datepicker.toggle']: 'Toggle calendar',
      ['kendo.dialog.items']: 'items',
      ['kendo.dialog.of']: 'of',
      ['kendo.dropdownlist.noDataText']: 'No data',
      ['kendo.grid.columnMenu']: 'Column Menu',
      ['kendo.grid.columns']: 'Columns',
      ['kendo.grid.columnsApply']: 'Apply',
      ['kendo.grid.columnsReset']: 'Reset',
      ['kendo.grid.filter']: 'Filter',
      ['kendo.grid.filterAfterOperator']: 'Is after',
      ['kendo.grid.filterAfterOrEqualOperator']: 'Is after or equal to',
      ['kendo.grid.filterAndLogic']: 'And',
      ['kendo.grid.filterBeforeOperator']: 'Is before',
      ['kendo.grid.filterBeforeOrEqualOperator']: 'Is before or equal to',
      ['kendo.grid.filterBooleanAll']: '(All)',
      ['kendo.grid.filterClearButton']: 'Clear',
      ['kendo.grid.filterContainsOperator']: 'Contains',
      ['kendo.grid.filterEndsWithOperator']: 'Ends with',
      ['kendo.grid.filterEqOperator']: 'Is equal to',
      ['kendo.grid.filterFilterButton']: 'Filter',
      ['kendo.grid.filterGtOperator']: 'Is greater than',
      ['kendo.grid.filterGteOperator']: 'Is greater than or equal to',
      ['kendo.grid.filterIsEmptyOperator']: 'Is empty',
      ['kendo.grid.filterIsFalse']: 'No',
      ['kendo.grid.filterIsNotEmptyOperator']: 'Is not empty',
      ['kendo.grid.filterIsNotNullOperator']: 'Is not null',
      ['kendo.grid.filterIsNullOperator']: 'Is null',
      ['kendo.grid.filterIsTrue']: 'Yes',
      ['kendo.grid.filterLtOperator']: 'Is less than',
      ['kendo.grid.filterLteOperator']: 'Is less than or equal to',
      ['kendo.grid.filterNotContainsOperator']: 'Does not contain',
      ['kendo.grid.filterNotEqOperator']: 'Is not equal to',
      ['kendo.grid.filterOrLogic']: 'Or',
      ['kendo.grid.filterStartsWithOperator']: 'Starts with',
      ['kendo.grid.groupPanelEmpty']: 'Drag a column header and drop it here to group by that column',
      ['kendo.grid.loading']: 'Loading',
      ['kendo.grid.lock']: 'Lock',
      ['kendo.grid.noRecords']: 'No records available.',
      ['kendo.grid.pagerFirstPage']: 'Go to the first page',
      ['kendo.grid.pagerItems']: 'items',
      ['kendo.grid.pagerItemsPerPage']: 'items per page',
      ['kendo.grid.pagerLastPage']: 'Go to the last page',
      ['kendo.grid.pagerNextPage']: 'Go to the next page',
      ['kendo.grid.pagerOf']: 'of',
      ['kendo.grid.pagerPage']: 'Page',
      ['kendo.grid.pagerPreviousPage']: 'Go to the previous page',
      ['kendo.grid.sort']: 'Sort',
      ['kendo.grid.sortAscending']: 'Sort Ascending',
      ['kendo.grid.sortDescending']: 'Sort Descending',
      ['kendo.grid.unlock']: 'Unlock',
      ['kendo.multiselect.noDataText']: 'No data found',
      ['kendo.numerictextbox.decrement']: 'Decrease value',
      ['kendo.numerictextbox.increment']: 'Increase value',
      ['kendo.pager.items']: 'items',
      ['kendo.pager.of']: 'of',
      ['kendo.slider.decrement']: 'Decrease value',
      ['kendo.slider.dragHandle']: 'drag',
      ['kendo.slider.increment']: 'Increase value',
      ['kendo.switch.off']: 'Off',
      ['kendo.switch.on']: 'On',
      ['kendo.tabstrip.items']: 'items',
      ['kendo.tabstrip.of']: 'of',
      ['kendo.timepicker.accept']: 'Set',
      ['kendo.timepicker.acceptLabel']: 'Set',
      ['kendo.timepicker.cancel']: 'Cancel',
      ['kendo.timepicker.cancelLabel']: 'Cancel',
      ['kendo.timepicker.now']: 'Now',
      ['kendo.timepicker.nowLabel']: 'Now',
      ['kendo.timepicker.toggle']: 'Toggle time list',
      ['kendo.upload.cancel']: 'Cancel',
      ['kendo.upload.clearSelectedFiles']: 'Clear',
      ['kendo.upload.dropFilesHere']: 'Drop files here to upload',
      ['kendo.upload.headerStatusUploaded']: 'Done',
      ['kendo.upload.headerStatusUploading']: 'Uploading...',
      ['kendo.upload.invalidFileExtension']: 'File type not allowed.',
      ['kendo.upload.invalidFiles']: 'Invalid file(s). Please check file upload requirements.',
      ['kendo.upload.invalidMaxFileSize']: 'File size too large.',
      ['kendo.upload.invalidMinFileSize']: 'File size too small.',
      ['kendo.upload.remove']: 'Remove',
      ['kendo.upload.retry']: 'Retry',
      ['kendo.upload.select']: 'Select files...',
      ['kendo.upload.uploadSelectedFiles']: 'Upload files',
    };

    this.localizeByCultureName['it'] = {
      ['GRID_DIALOG_SORTSETTINGS_TITLE']: 'Impostazioni ordinamento',
      ['GRID_DIALOG_SORTSETTINGS_LEFTTITLE']: 'Seleziona colonne',
      ['GRID_DIALOG_SORTSETTINGS_RIGHTTITLE']: 'Ordina per',
      ['BTN_APPLY']: 'APPLICA',
      ['BTN_APPLYANDSAVE']: 'APPLICA E SALVA',
      ['BTN_SORTSETTINGS']: 'IMPOSTAZIONI ORDINAMENTO',
      ['BTN_RESTOREDEFAULT']: 'RIPRISTINA PREDEFINITO',
      ['BTN_FILTERSETTINGS']: 'IMPOSTAZIONI FILTRI',
      ['GRID_DIALOG_FILTERSETTINGS_TITLE']: 'Impostazioni filtri',
      ['GRID_DIALOG_COLUMNORDERSETTINGS_TITLE']: 'Ordinamento colonne',
      ['GRID_DIALOG_COLUMNORDERSETTINGS_HELP']: 'Trascina le colonne nella posizione desiderata',
      ['BTN_COLUMNORDERSETTINGS']: 'ORDINAMENTO COLONNE',
      ['BTN_CLEARANDSAVE_FILTER']: 'RIMUOVI E SALVA',

      ['BTN_CLOUD_PRINT']: 'STAMPA IN CLOUD',
      ['BTN_DOWNLOAD']: 'SCARICA',
      ['BTN_PRINT']: 'STAMPA',
      ['BTN_PRINTPREVIEW']: 'ANTEPRIMA DI STAMPA',
      ['LNK_DOWNLOAD']: 'Clicca qui per visualizzare il documento',
      ['PREVIEW_TITLE']: 'Anteprima di stampa',
      ['SELECTREPORT_DESCRIPTION']: 'Seleziona il report che desideri stampare',
      ['SELECTREPORT_TITLE']: 'Selezione report',
      ['MISSING_FROM']: 'Valore Da non specificato',
      ['MISSING_TO']: 'Valore A non specificato',

      ['FROM_HEADER']: '@@HEADER@@ Da',
      ['TO_HEADER']: '@@HEADER@@ A',
      ['MOBILEEXTRAFILTERS_SETTED']: 'Ulteriori filtri impostatî sui seguenti campi: ',
      ['ACCOUNT_SETTING']: 'Impostazione Account',
      ['ALERT_TITLE_CONFIRM']: 'Conferma richiesta',
      ['ALERT_TITLE_INFO']: 'Informazione',
      ['ALERT_TITLE_WARNING']: 'Attenzione',
      ['BTN_BACK']: 'Indietro',
      ['BTN_BROWSE']: 'SFOGLIA ',
      ['BTN_CANCEL']: 'Annulla',
      ['BTN_CLEAR_FILTER']: 'RIMUOVI FILTRI',
      ['BTN_RESTORE_DEFAULT_FILTER']: 'RIPRISTINA FILTRI',
      ['BTN_CLONE']: 'DUPLICA',
      ['BTN_CONFIRM']: 'Conferma',
      ['BTN_DELETE']: 'Elimina',
      ['BTN_EDIT']: 'Modifica',
      ['BTN_EXPORT_EXCEL']: 'SCARICA EXCEL',
      ['BTN_LOGIN']: 'Accedi',
      ['BTN_NEW']: 'Nuovo',
      ['BTN_SAVEANDNEXT']: 'Salva e successivo',
      ['BTN_SAVEANDPREVIOUS']: 'Salva e precedente',
      ['BTN_NEXT']: 'Successivo',
      ['BTN_PREVIOUS']: 'Precedente',
      ['BTN_REFRESH']: 'Aggiorna',
      ['BTN_SAVE']: 'Salva',
      ['BTN_SAVEANDSELECT']: 'Salva e seleziona',
      ['BTN_SELECT']: 'Seleziona',
      ['BTN_SELECTALL']: 'Seleziona tutto',
      ['BTN_UNSELECTALL']: 'Deseleziona tutto',
      ['BULKPROCESSABLEITEMSINFO']: 'Individuati @@PROCESSABLECOUNT@@ elementi processabili e @@UNPROCESSABLECOUNT@@ elementi non processabili.',
      ['BULK_CHANGE_PREVIEW']: 'Anteprima operazione massiva',
      ['BULK_CHANGE_RESULT']: 'Esito operazione massiva',
      ['CANCEL']: 'Annulla',
      ['COMBO_DIALOG_TITLE']: 'Seleziona @@HEADER@@',
      ['COMBO_DIALOG_TITLE_FOR']: 'Seleziona @@HEADER@@ per @@ENTITYOWNER@@',
      ['CRUD_VALIDATION_ERROR']: 'Controlla i dati inseriti, uno o più campi non sono stati valorizzati correttamente!',
      ['DATE_BETWEEN_MUST_HAVE_FROM_AND_TO']: 'Valorizza entrambe le date',
      ['DATE_FROM_MUST_BETWEEN_MIN_MAX']: 'Il filtro data da deve essere maggiore di @@MINDATE@@ e minore di @@MAXDATE@@',
      ['DATE_TO_MUST_BETWEEN_MIN_MAX']: 'Il filtro data a deve essere maggiore di @@MINDATE@@ e minore di @@MAXDATE@@',
      ['DATE_TO_MUST_GTE_THAN_FROM']: 'Il filtro data a deve essere maggiore del filtro data da',
      ['DROP_ZONE_TEXT']: 'Trascina qui per caricare il file',
      ['FILTER_OPERATOR_BETWEEN']: 'Da/a',
      ['FULL_SCREEN']: 'SCHERMO INTERO',
      ['GRIDHEADER_ACTIONS']: 'Azioni',
      ['GRIDHEADER_RECORDINFO']: 'Info',
      ['IS_DEFAULT_FULL_SCREEN_LOGIN']: 'All\'accesso mostra l\'applicazione a schermo intero',
      ['LOGINOTHER_DESCRIPTION']: 'Hai effettuato l\'accesso da un altro browser o dispositivo. Premi accedi per effettuare nuovamente l\'accesso',
      ['LOGINOTHER_TITLE']: 'Sessione Terminata',
      ['LOGIN_TITLE']: 'Accesso',
      ['LOGOUT']: 'Logout',
      ['MORETHANONEPUSRECEIVED']: 'Ci sono @@COUNT@@ nuove notifiche',
      ['NAMEREQUIRED']: 'Inserire il nome utente per continuare',
      ['NAVIGATION_CONFIRM_EXIT_EDIT']: 'Le modifiche apportate potrebbero non essere salvate, sei sicuro di abbandonare?',
      ['NO']: 'No',
      ['NONEWNOTIFICATIONS']: 'Nessuna nuova notifica',
      ['NOTIFICATIONSMENU_TITLE']: 'Centro notifiche',
      ['NO_FILTER']: 'Nessun filtro',
      ['NO_SELECTED_ITEMS']: 'Nessun elemento selezionato',
      ['NO_ITEMS_FOUND']: 'Nessun elemento trovato',
      ['X_CHARS_TOCONTINUE']: 'Digita almeno @@X@@ caratteri per avviare la ricerca',
      ['NUMERIC_OPERATOR_ISEMPTY']: 'E\' vuoto o uguale a zero',
      ['NUMERIC_OPERATOR_ISNOTEMPTY']: 'Non è zero o vuoto',
      ['OK']: 'Ok',
      ['PASSWORD']: 'Password',
      ['PASSWORDREQUIRED']: 'Inserire la password per continuare',
      ['LOGINFAILED']: 'Utente o password non valida, si prega di riprovare',
      ['PASSWORDEXPIRED']: 'Password scaduta. Cambia la password per continuare.',
      ['RECORDINFO_ATTIME']: 'alle',
      ['RECORDINFO_BY']: 'da',
      ['RECORDINFO_CREATED']: 'Creato il',
      ['RECORDINFO_UPDATED']: 'Modificato il',
      ['RELOGINREQUIRED']: 'Sessione scaduta! Reinserisci la password per continuare.',
      ['RELOGIN_INSERTPASSWORD']: 'Inserisci la password per l\'account',
      ['SELECTACTION']: 'Seleziona l\'operazione',
      ['UPLOAD_COMPLETED']: 'Caricamento completato',
      ['UPLOAD_IN_PROGRESS']: 'Caricamento in corso',
      ['USERNAME']: 'Nome Utente',
      ['VIEW']: 'Visualizza',
      ['WARNING']: 'Attenzione',
      ['YES']: 'Sì',
      ['CHANGE_PASSWORD']: 'Cambio password',
      ['CHANGE_PASSWORD_TITLE']: 'Cambio password',
      ['CHANGE_PASSWORD_INSERTPASSWORD']: 'Inserire la nuova password per l\'account',
      ['CHANGE_PASSWORD_LOGINFAILED']: 'Vecchia password non valida, si prega di riprovare',
      ['CHANGE_PASSWORD_OK']: 'Password cambiata con successo',
      ['PASSWORDNEW']: 'Nuova password',
      ['PASSWORDNEWCONFIRM']: 'Conferma nuova password',
      ['PASSWORDOLD']: 'Vecchia password',
      ['BTN_RETURN_TO_LOGIN']: 'Riprova l\'accesso',
      ['PASSWORDNEWREQUIRED']: 'Inserire la nuova password per continuare',
      ['PASSWORDOLDREQUIRED']: 'Inserire la vecchia password per continuare',
      ['PASSWORDNEWCONFIRMREQUIRED']: 'Inserire la conferma della nuova password per continuare',
      ['PASSWORDNEWMISMATCH']: 'Nuova password e la conferma della nuova password non coincidono',
      ['PASSWORDNEWALREADYUSED']: 'La nuova password deve essere diversa dalla vecchia password',
      ['FailedBadList']: 'essere più complessa',
      ['FailedDigit']: 'contenere almeno un numero',
      ['FailedEntropy']: 'avere un punteggio di entropia di almeno @@VALUE@@',
      ['FailedLength']: 'essere composta da almeno @@VALUE@@ caratteri',
      ['FailedLowercase']: 'contenere almeno una lettera minuscola',
      ['FailedPattern']: 'avere un massimo di @@VALUE@@ caratteri vicini sulla tastiera',
      ['FailedPunctuation']: 'contenere almeno un segno di punteggiatura',
      ['FailedRepeat']: 'avere un massimo di @@VALUE@@ caratteri ripetuti adiacenti',
      ['FailedUnique']: 'contenere almeno @@VALUE@@ caratteri univoci',
      ['FailedUppercase']: 'contenere almeno una lettera maiuscola',
      ['kendo.autocomplete.combobox.dropdownlist.multisele']: 'Nessun dato disponibile',
      ['kendo.autocomplete.combobox.dropdownlist.noDataTex']: 'Nessun dato disponibile',
      ['kendo.autocomplete.combobox.noDataText']: 'Nessun dato disponibile',
      ['kendo.autocomplete.noDataText']: 'Nessun dato disponibile',
      ['kendo.calendar.today']: 'Oggi',
      ['kendo.combobox.noDataText']: 'Nessun dato disponibile',
      ['kendo.dateinput.decrement']: 'Decrementa valore',
      ['kendo.dateinput.increment']: 'Incrementa valore',
      ['kendo.datepicker.today']: 'Oggi',
      ['kendo.datepicker.toggle']: 'Toggle calendar',
      ['kendo.dialog.items']: 'elementi',
      ['kendo.dialog.of']: 'di',
      ['kendo.dropdownlist.noDataText']: 'Nessun elemento',
      ['kendo.grid.columnMenu']: 'Column Menu',
      ['kendo.grid.columns']: 'Columns',
      ['kendo.grid.columnsApply']: 'Apply',
      ['kendo.grid.columnsReset']: 'Reset',
      ['kendo.grid.filter']: 'Filter',
      ['kendo.grid.filterAfterOperator']: 'È dopo',
      ['kendo.grid.filterAfterOrEqualOperator']: 'È dopo o uguale a',
      ['kendo.grid.filterAndLogic']: 'E',
      ['kendo.grid.filterBeforeOperator']: 'È prima',
      ['kendo.grid.filterBeforeOrEqualOperator']: 'È prima o uguale a',
      ['kendo.grid.filterBooleanAll']: '(Tutti)',
      ['kendo.grid.filterClearButton']: 'Rimuovi',
      ['kendo.grid.filterContainsOperator']: 'Contiene',
      ['kendo.grid.filterEndsWithOperator']: 'Finisce con',
      ['kendo.grid.filterEqOperator']: 'È uguale a',
      ['kendo.grid.filterFilterButton']: 'Filtro',
      ['kendo.grid.filterGtOperator']: 'È più grande di',
      ['kendo.grid.filterGteOperator']: 'È più grande o uguale a',
      ['kendo.grid.filterIsEmptyOperator']: 'È vuoto',
      ['kendo.grid.filterIsFalse']: 'No',
      ['kendo.grid.filterIsNotEmptyOperator']: 'Non è vuoto',
      ['kendo.grid.filterIsNotNullOperator']: 'Non è nullo',
      ['kendo.grid.filterIsNullOperator']: 'È nullo',
      ['kendo.grid.filterIsTrue']: 'Sì',
      ['kendo.grid.filterLtOperator']: 'È più piccolo di',
      ['kendo.grid.filterLteOperator']: 'È più piccolo o uguale a',
      ['kendo.grid.filterNotContainsOperator']: 'Non contiene',
      ['kendo.grid.filterNotEqOperator']: 'Non è uguale a',
      ['kendo.grid.filterOrLogic']: 'O',
      ['kendo.grid.filterStartsWithOperator']: 'Inizia con',
      ['kendo.grid.groupPanelEmpty']: 'Trascina l\'header di una colonna e rilascialo qui per raggruppare secondo tale colonna',
      ['kendo.grid.loading']: 'Loading',
      ['kendo.grid.lock']: 'Lock',
      ['kendo.grid.noRecords']: 'Nessun record disponibile.',
      ['kendo.grid.pagerFirstPage']: 'Vai alla prima pagina',
      ['kendo.grid.pagerItems']: 'elementi',
      ['kendo.grid.pagerItemsPerPage']: 'elementi per pagina',
      ['kendo.grid.pagerLastPage']: 'Vai all\'ultima pagina',
      ['kendo.grid.pagerNextPage']: 'Vai alla prossima pagina',
      ['kendo.grid.pagerOf']: 'di',
      ['kendo.grid.pagerPage']: 'Pagina',
      ['kendo.grid.pagerPreviousPage']: 'Vai alla pagina precedente',
      ['kendo.grid.sort']: 'Sort',
      ['kendo.grid.sortAscending']: 'Sort Ascending',
      ['kendo.grid.sortDescending']: 'Sort Descending',
      ['kendo.grid.unlock']: 'Unlock',
      ['kendo.multiselect.noDataText']: 'Nessun dato disponibile',
      ['kendo.numerictextbox.decrement']: 'Decrementa valore',
      ['kendo.numerictextbox.increment']: 'Incrementa valore',
      ['kendo.pager.items']: 'elementi',
      ['kendo.pager.of']: 'di',
      ['kendo.slider.decrement']: 'Decrementa valore',
      ['kendo.slider.dragHandle']: 'drag',
      ['kendo.slider.increment']: 'Incrementa valore',
      ['kendo.switch.off']: 'Off',
      ['kendo.switch.on']: 'On',
      ['kendo.tabstrip.items']: 'elementi',
      ['kendo.tabstrip.of']: 'di',
      ['kendo.timepicker.accept']: 'Imposta',
      ['kendo.timepicker.acceptLabel']: 'Imposta',
      ['kendo.timepicker.cancel']: 'Annulla',
      ['kendo.timepicker.cancelLabel']: 'Annulla',
      ['kendo.timepicker.now']: 'Adesso',
      ['kendo.timepicker.nowLabel']: 'Adesso',
      ['kendo.timepicker.toggle']: 'Selezionare il time list',
      ['kendo.upload.cancel']: 'Annulla',
      ['kendo.upload.clearSelectedFiles']: 'Clear',
      ['kendo.upload.dropFilesHere']: 'rilascia qui i file per l\'upload',
      ['kendo.upload.headerStatusUploaded']: 'Fatto',
      ['kendo.upload.headerStatusUploading']: 'Upload in corso...',
      ['kendo.upload.invalidFileExtension']: 'File type not allowed.',
      ['kendo.upload.invalidFiles']: 'Invalid file(s). Please check file upload requirements.',
      ['kendo.upload.invalidMaxFileSize']: 'File size too large.',
      ['kendo.upload.invalidMinFileSize']: 'File size too small.',
      ['kendo.upload.remove']: 'Rimuovi',
      ['kendo.upload.retry']: 'Riprova',
      ['kendo.upload.select']: 'Seleziona...',
      ['kendo.upload.uploadSelectedFiles']: 'Upload dei file selezionati',
    };

  }

}
