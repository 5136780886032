import { TemplateRef } from '@angular/core';
import { CommonGridColumnDataDefinition } from 'dto';
import { IBaseGridViewContract } from '../interfaces/IBaseGridViewContract';

export class GridSetting {

  public isLoaded: boolean;
  public gridHeight: number;
  public sortSetting: string;
  public searchSchemaName: string;  
  public columnsDefinitions: CommonGridColumnDataDefinition[];
  public templatePrimaryExtraButtons: TemplateRef<any>;
  public templateSecondaryExtraButtons: TemplateRef<any>;
  public templateComputed: TemplateRef<any>;  
  public templateToolbar: TemplateRef<any>;
}
