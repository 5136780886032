import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreLib_Services_Common_LicenseService } from 'core';
import { ControlsLib_Views_LoginOther_LoginOtherComponent } from 'controls';
import { HomeComponent } from './views/home/home.component';
import { LoginPersComponent } from './views/login/login.component';
import { ChangePasswordPersComponent } from './views/change-password/change-password.component';
import { AuthGuard } from './services/guard/auth-guard.service';
import { LostPasswordComponent } from './views/lost-password/lost-password.component';
import { RecoverPasswordComponent } from './views/recover-password/recover-password.component';


const activationRule = [CoreLib_Services_Common_LicenseService, AuthGuard];

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  { path: 'home', component: HomeComponent, canActivate: [CoreLib_Services_Common_LicenseService] },
  { path: 'registration', loadChildren: () => import('./modules/registration/lazy/registration/registration.module').then((m) => m.RegistrationModule), canActivate: [CoreLib_Services_Common_LicenseService] }, //tutti
  { path: 'event-request', loadChildren: () => import('./modules/event-request/lazy/event-request/event-request.module').then((m) => m.EventRequestModule), canActivate: activationRule }, //tutti

  //COMMON
  { path: 'login', component: LoginPersComponent, canActivate: [CoreLib_Services_Common_LicenseService] },
  { path: 'login-other', component: ControlsLib_Views_LoginOther_LoginOtherComponent, canActivate: [CoreLib_Services_Common_LicenseService] },
  { path: 'change-password', component: ChangePasswordPersComponent, canActivate: [CoreLib_Services_Common_LicenseService] },
  { path: 'lost-password', component: LostPasswordComponent, canActivate: [CoreLib_Services_Common_LicenseService] },
  { path: 'recover-password', component: RecoverPasswordComponent, canActivate: [CoreLib_Services_Common_LicenseService] }
];

export function lazyRoute() {
  const result = {};
  for (const w of routes) {
    (result as any)[w.path] = w;
  }
  return result;
}

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule { }
