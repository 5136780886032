




import { CommonPushNotificationGetUnreadedDataResponse } from './CommonPushNotificationGetUnreadedDataResponse';
import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonPushNotificationGetUnreadedResponse extends BaseTokenResponse  {
  
  public items: CommonPushNotificationGetUnreadedDataResponse[] = [];
  
}



