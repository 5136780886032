import { Injectable, Injector, isDevMode } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { TranslationByModuleCache } from '../../classes/TranslationByModuleCache';
import { DeskCommonService } from '../http/desk.common.service';
import { LocalizationService } from './localization.service';
import { PromiseHelper } from '../../classes/PromiseHelper';



@Injectable({ providedIn: 'root' })
export class TranslationService {

  //#region Declarations...

  private localizeByModuleCache: TranslationByModuleCache[];

  public cultureName: string;

  //#endregion

  //#region Properties...

  public localizeByShared: { [index: string]: { [index: string]: string } } = {};


  public localizeByCommon: { [index: string]: string } = {};

  private formReferencesInLoading: string[] = [];
  //#endregion

  //#region Constructors...

  constructor(public intlService: IntlService, private injector: Injector, private localizationService: LocalizationService) {

    this.cultureName = this.localizationService.getLocale();

    this.localizeByModuleCache = [];
  }

  //#endregion

  //#region Methods...

  public async initCommon(staticCommonTranslations: { [index: string]: { [index: string]: string } } = null) {
    const api: DeskCommonService = this.injector.get(DeskCommonService);

    if (staticCommonTranslations != null) { //Prima riempio con quelle statiche
      this.localizeByCommon = staticCommonTranslations[this.cultureName];
    }

    const response = await api.translationGetAllByFormRequest(this.cultureName, 'COMMON');

    if (response != null) {
      for (let i = 0; i < response.translations.length; i++) {
        this.localizeByCommon[response.translations[i].formObject] = response.translations[i].description;
      }
    }
  }

  public async initShared(formReference: string) {
    if (this.localizeByShared[formReference] == null) {
      const api: DeskCommonService = this.injector.get(DeskCommonService);

      this.localizeByShared[formReference] = {};

      const response = await api.translationGetAllByFormRequest(this.cultureName, formReference);

      if (response != null) {
        for (let i = 0; i < response.translations.length; i++) {
          this.localizeByShared[formReference][response.translations[i].formObject] = response.translations[i].description;
        }
      }
    }
  }

  async getShared(formReference: string, formObject: string) {
    await this.initShared(formReference);
    return this.localizeByShared[formReference][formObject];
  }
  

  public async initForm(formReference: string) {


    let indexExist = this.formReferencesInLoading.findIndex(c => c == formReference);

    while (indexExist > -1) {
      await PromiseHelper.delay(100);
      indexExist = this.formReferencesInLoading.findIndex(c => c == formReference);
    }

    let localizeByModule: { [index: string]: string } = {};
    let foundInCache = false;

    for (const item of this.localizeByModuleCache) {
      if (item.formReference == formReference) {
        localizeByModule = item.localizeByModule;
        foundInCache = true;
        break;
      }
    }

    if (!foundInCache) {

      this.formReferencesInLoading.push(formReference);

      const cache = new TranslationByModuleCache();
      cache.formReference = formReference;
      cache.localizeByModule = localizeByModule;
      this.localizeByModuleCache.push(cache);

      const api: DeskCommonService = this.injector.get(DeskCommonService);

      const response = await api.translationGetAllByFormRequest(this.cultureName, formReference);

      if (response != null) {
        for (let i = 0; i < response.translations.length; i++) {
          localizeByModule[response.translations[i].formObject] = response.translations[i].description;
        }

        const indexExist = this.formReferencesInLoading.findIndex(c => c == formReference);
        if (indexExist > -1) {
          this.formReferencesInLoading.splice(indexExist, 1);
        }
      } else {
        const indexExist = this.formReferencesInLoading.findIndex(c => c == formReference);
        if (indexExist > -1) {
          this.formReferencesInLoading.splice(indexExist, 1);
        }
      }
    }



    return localizeByModule;
  }

  public tryGetLocalizeByCommon(index: string): string {
    if (this.localizeByCommon[index] == null) {
      if (isDevMode) {
        return '[' + index + ']';
      } else {
        return null;
      }
    } else {
      return this.localizeByCommon[index];
    }
  }

  //#endregion

}
