import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { setTime } from '@progress/kendo-angular-dateinputs/dist/es2015/util';
import { CoreLib_Classes_StringHelper } from 'core';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';



@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class TextBoxComponent extends BaseInputControlComponent {

  @ViewChild('input_ref', { static: true }) inputField: ElementRef;

  //#region Properties...

  @Input()
  public name: string;

  @Input()
  public autocomplete: string;

  // MAXLENGTHa
  @Input()
  public maxLength: number;

  // TextTransformation
  @Input()
  public textTransformation: 'uppercase' | 'lowercase' | 'capitalizefirstletter' | 'capitalize';

  // whitespaces
  @Input()
  public whiteSpaces: boolean = true;

  // focus
  


  // TYPE
  @Input()
  public type: string = 'text';

  // TEXT
  private _text: string;
  @Input()
  get text() {
    return this._text;
  }
  set text(val) {
    this._text = val;

    this.setHeaderHidden();
    this.textChange.emit(val);
    this.setReadOnlyValue();
  }
  @Output() textChange = new EventEmitter();

  @Output() inputKeyUp = new EventEmitter<KeyboardEvent>();

  @Output() enterPressed = new EventEmitter();  

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, protected ref: ChangeDetectorRef) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    this.inputKeyUp.emit(event);

    if (event == null || (event.keyCode == 13)) {
     this.enterPressed.emit();
    }
  }

  onBlur() {
    this.text = CoreLib_Classes_StringHelper.transformText(this.text, this.textTransformation);

    if (this.whiteSpaces == false && this.text != null) {
      this.text = this.text.replace(' ', '');
    }    
    this.hasFocus = false;
    this.ref.detectChanges();
  }

  onFocus() {
    this.hasFocus = true;
    this.ref.detectChanges();
  }

  public focus() {
    this.inputField.nativeElement.focus();    
  }

  public select() {
    this.inputField.nativeElement.select();
  }

  public override setHeaderHidden() {
    this.headerhidden = this.disabled == false && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.text);    
    this.ref.detectChanges();
  }

  public insertAtCaret(text: string) {
    const value = this.inputField.nativeElement.value;

    // save selection start and end position
    const start = this.inputField.nativeElement.selectionStart;
    const end = this.inputField.nativeElement.selectionEnd;

    // update the value with our text inserted
    this.text = value.slice(0, start) + text + value.slice(end);

    // update cursor to be at the end of insertion
    this.inputField.nativeElement.selectionStart = this.inputField.nativeElement.selectionEnd = start + text.length;
  }

  public override setReadOnlyValue() {
    if (this.disabled && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.text)) {
      this.readOnlyValue = '-';
    } else {
      if (this.type == 'password') {
        this.readOnlyValue = '**********';
      } else {
        this.readOnlyValue = this.text;
      }
    }

    this.ref.detectChanges();
  }
  //#endregion

}
