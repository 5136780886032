
export class ElementHelperMethods {

  public static async elementReady(root: any, selector: string, waitAfter: number) {
    return new Promise((resolve, reject) => {
      let el = root.querySelector(selector);
      if (el) {
        if (waitAfter > 0) {
          setTimeout(() => {
            resolve(el);
          }, waitAfter);
        } else {
          resolve(el);
        }
      } else {
        new MutationObserver((mutationRecords, observer) => {
          let element = root.querySelector(selector)
          if (element) {
            if (waitAfter > 0) {
              setTimeout(() => {
                resolve(element);
                observer.disconnect();
              }, waitAfter);
            } else {
              resolve(element);
              observer.disconnect();
            }
          }
        })
          .observe(root, {
            childList: true,
            subtree: true
          });
      }
    });
  }
}
