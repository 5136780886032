import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { CrudViewModes } from '../../enums/CrudViewModes';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent  {

  //#region Properties...

  public viewModes: any = CrudViewModes;

  @Input()
  public itemDescription: string;

  @Input()
  public crudView: IBaseCrudViewContract;
  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...


  //#endregion
}
