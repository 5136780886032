




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonBooleanResponse extends BaseTokenResponse  {
  
  public value: boolean = false;
  
}



