









export class ReportGetResponse  {
      
    public reportData: string = '';    
    public reportDataOld: string = '';    
}

