




    
export class CommonGridColumnDataDefinition   {
  
  public isPersField: boolean = false;
  
  public valueField: string = '';
  
  public textField: string = '';
  
  public templateOutlet: string = '';
  
  public template: any = null;
  
  public filterType: string = '';
  
  public filterDataField: string = '';
  
  public filterTextField: string = '';
  
  public filterValueField: string = '';
  
  public filterOperator: string = '';
  
  public filterTemplateOutlet: string = '';
  
  public filterTemplate: any = null;
  
  public translationKey: string = '';
  
  public dropDownFilterOperator: string = '';
  
  public translationIsCommon: boolean = false;
  
  public ondemand: boolean = false;
  
  public filterChangeFunctionName: string = '';
  
  public filterChangeRequestMode: string = '';
  
  public sortable: boolean = false;
  
  public format: string = '';
  
  public description: string = '';
  
  public width: number = 0;
  
  public orderIndex: number = 0;
  
  public sortField: string = '';
  
  public isVisible: boolean = false;
  
  public isExcelVisible: boolean = false;
  
  public excelCellOptions: string = '';
  
  public codeFirstPartLength: number = 0;
  
  public codeSeparator: string = '';
  
  public codeSecondPartLength: number = 0;
  
}



