import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChange, ViewChild } from '@angular/core';
import { CoreLib_Classes_StringHelper } from 'core';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';

@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeEditorComponent extends BaseInputControlComponent {

  @ViewChild('iFramePreview')
  public iFramePreview: ElementRef;

  
  @ViewChild('editor') editor: ElementRef;


  //#region Properties...

  // TEXT
  private _text: string;
  @Input()
  get text() {
    return this._text;
  }
  @Output() textChange = new EventEmitter();
  set text(val) {
    this._text = val;

    this.setHeaderHidden();
    this.textChange.emit(val);
    this.setReadOnlyValue();
  }

  // POPUPOPENED
  public popupOpened: boolean = false;

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, protected ref: ChangeDetectorRef) {
    super(injector, validationService);
    this.formReference = 'CodeEditor';
  }

  //#endregion

  //#region Methods...


  public insertAtCaret(text: string) {
    const value = this.editor.nativeElement.value;

    // save selection start and end position
    const start = this.editor.nativeElement.selectionStart;
    const end = this.editor.nativeElement.selectionEnd;

    // update the value with our text inserted
    this.text = value.slice(0, start) + text + value.slice(end);

    // update cursor to be at the end of insertion
    this.editor.nativeElement.selectionStart = this.editor.nativeElement.selectionEnd = start + text.length;
  }

  public override setReadOnlyValue() {
    setTimeout(() => {
      let doc: any = null;
      if (this.iFramePreview != null && this.iFramePreview.nativeElement != null && this.iFramePreview.nativeElement.contentWindow != null) {
        doc = this.iFramePreview.nativeElement.contentWindow.document;
      }

      if (doc != null) {
        doc.open();
        doc.write('-');
        doc.close();
      }

      if (this.disabled && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.text)) {
        this.readOnlyValue = '-';
      } else {
        this.readOnlyValue = this.text;
        if (this.text != null && this.text.length > 0 && doc != null) {
          doc.open();
          doc.write(this.text);
          doc.close();
        }
      }
      if (this.iFramePreview != null && this.iFramePreview.nativeElement != null && this.iFramePreview.nativeElement.contentWindow != null && this.iFramePreview.nativeElement.contentWindow.document != null && this.iFramePreview.nativeElement.contentWindow.document.documentElement != null) {
        this.iFramePreview.nativeElement.style.height = this.iFramePreview.nativeElement.contentWindow.document.documentElement.scrollHeight + 'px';
      }
      this.ref.detectChanges();
    });
  }


  public edit() {
    this.popupOpened = true;
  }

  public popupClose() {
    this.popupOpened = false;
  }

  private getBlobURL = (code: any, type: any) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob);
  }

  //#endregion

}
