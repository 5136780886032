import { AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone, OnDestroy } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { NavigationService } from '../services/common/navigation.service';
import { ToolbarLeftService } from '../services/common/toolbar-left.service';
import { ButtonComponent } from '../components/button/button.component';



@Directive({
  selector: '[toolbar-left]',
})
export class ToolbarLeftDirective implements AfterViewInit, OnDestroy {


  constructor(private element: ElementRef, private ngZone: NgZone, private navigationService: NavigationService, private toolbarLeftService: ToolbarLeftService) {

    this.toolbarLeftService_docheckChanged = this.toolbarLeftService.docheckChanged.subscribe(() => this.resizeSubject.next(true));

    this.resizeSubjectSubscription = this.resizeSubject.pipe(debounceTime(100)).subscribe(() => this.resize());

    this.resizeSubscription = fromEvent(window, 'resize').pipe(
      debounceTime(500))
      .subscribe((event) => {
        this.resizeSubject.next(true);
      });
  }

  private toolbarLeftService_docheckChanged: Subscription;

  private resizeSubjectSubscription: Subscription;

  private resizeSubject = new Subject<boolean>();

  private resizeSubscription: Subscription;

  private resizeStableSubscription: Subscription;

  private popupButton: HTMLElement;

  private popup: HTMLElement;

  private elements: HTMLElement[] = [];

  @Input() formReference: string = '';

  ngAfterViewInit(): void {

    this.popup = document.querySelector('#leftPopupContent' + this.formReference) as HTMLElement;

    this.popupButton = document.querySelector('#leftAnchor' + this.formReference) as HTMLElement;

    this.resizeSubject.next(true);
  }

  ngOnDestroy(): void {
    this.resizeSubjectSubscription?.unsubscribe();
    this.toolbarLeftService_docheckChanged?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
    this.resizeStableSubscription?.unsubscribe();
  }

  resize() {
    this.resizeStableSubscription?.unsubscribe();
    this.resizeStableSubscription = this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      this.ngZone.runOutsideAngular(() => {
        this.popupButton.style.display = 'none';

        let endReached: boolean = false;

        this.elements = [];

        for (const item of this.element.nativeElement.children) {
          if (item.id != 'leftAnchor' + this.formReference) {
            this.elements.push(item);
          }
        }

        for (const item of this.element.nativeElement.children) {
          if (item.id != 'leftAnchor' + this.formReference) {
            item.style.display = '';
          }
        }

        for (let index = this.popup.children.length - 1; index >= 0; index--) {
          this.popup.removeChild(this.popup.children[index]);
        }

        let offsetLeft: number = 0;
        let showPopup: boolean = false;
        let multiplier: number = 1;

        if (this.navigationService.getTheme() == 'big') {
          multiplier = 1.5;
        }

        var leftAnchorOffset = this.popupButton.offsetWidth;

        for (const item of this.elements) {

          if (item == this.elements[this.elements.length - 1]) {
            leftAnchorOffset = 0;
          }

          if (endReached || (offsetLeft + item.offsetWidth > this.element.nativeElement.offsetWidth - leftAnchorOffset - (10 * multiplier))) {
            endReached = true;
            const clone = item.cloneNode(true) as HTMLElement;

            showPopup = true;
            clone.addEventListener('click', function () {
              let innerButton = item.querySelector("button");
              if (innerButton)
                innerButton.click();
              else
                item.click();
            });

            this.popup.appendChild(clone);
            item.style.display = 'none';
          } else {
            offsetLeft += item.offsetWidth + (10 * multiplier);
          }
        }

        if (showPopup) {
          this.popupButton.style.display = 'inline';
        }

        this.toolbarLeftService.sizeChanged.next(true);

      });

    });
  }
}
