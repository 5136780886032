

export class ColorService {

  //#region Declarations...


  //#endregion

  //#region Properties...

  public mainColorBrush: string = "#3598db";

  public shadowColorBrush: string = "#93c8ec";

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  //#endregion

}
