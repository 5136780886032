import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonRecoverPasswordAccessRequest } from '@mydto/CommonRecoverPasswordAccessRequest';
import { CommonRecoverPasswordRequest } from '@mydto/CommonRecoverPasswordRequest';
import { CommonRecoverPasswordResponse } from '@mydto/CommonRecoverPasswordResponse';
import { CommonValidatePasswordRequest } from '@mydto/CommonValidatePasswordRequest';
import { HttpCommonService } from 'app/services/http/_common/http.common.service';
import { ControlsLib_Components_Base_BaseViewComponent, ControlsLib_Components_TextBox_TextBoxComponent } from 'controls';
import { CoreLib_Classes_StringHelper, CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_LocalizationService, CoreLib_Services_Common_TranslationService } from 'core';
import { BaseTokenResponse } from 'dto';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/filter';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
})
export class RecoverPasswordComponent extends ControlsLib_Components_Base_BaseViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('passwordnew_ref', { static: true }) passwordNewField: ControlsLib_Components_TextBox_TextBoxComponent;
  @ViewChild('passwordnewconfirm_ref', { static: true }) passwordNewConfirmField: ControlsLib_Components_TextBox_TextBoxComponent;

  public code: string = '';

  public name: string = '';

  public passwordNew: string = '';
  public passwordNewConfirm: string = '';

  public errorText: string;
  public recoverPasswordFailed: boolean = false;

  public passwordRules: string = "";

  private pwdTextChangeSubscription: Subscription;

  public sharedAccount: string = "SharedAccount";

  constructor(injector: Injector,
    private route: ActivatedRoute,
    private localizationService: CoreLib_Services_Common_LocalizationService,
    public override translationService: CoreLib_Services_Common_TranslationService,
    private deskApiCommonService: HttpCommonService,
    public authenticationService: CoreLib_Services_Common_AuthenticationService) {
    super(injector);
    this.formReference = 'CommonRecoverPassword';
  }

  override async ngOnInit() {
    await this.translationService.initShared(this.sharedAccount);

    await super.ngOnInit();

    this.passwordRules = this.getPasswordRulesText(['FailedLength', 'FailedUppercase', 'FailedLowercase', 'FailedDigit', 'FailedPunctuation', 'FailedUnique', 'FailedRepeat', 'FailedPattern', 'FailedEntropy']);

    this.route.queryParams
      .subscribe(async params => {
        if (params.code == null) {
          if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Info, this.localizeByCommon["CODE_NOT_VALID"], CoreLib_Enums_PopupButtonsTypes.Ok) == CoreLib_Enums_PopupResultTypes.Ok) {
            this.router.navigate(['']);
          }
        } else {
          this.code = params.code;
          await this.init();
        }
      }
      );

    this.setChangePasswordTheme();
  }

  private async init() {
    // costruisco l'oggetto request
    const recoverPasswordAccessRequest = new CommonRecoverPasswordAccessRequest();
    recoverPasswordAccessRequest.code = this.code;
    recoverPasswordAccessRequest.cultureName = this.localizationService.getLocale();

    // invoco la Web Api
    const result = await this.deskApiCommonService.recoverPasswordAccess(recoverPasswordAccessRequest);

    if (!result.isValid) {
      if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Info, this.localizeByCommon[result.reasonIsNotValid], CoreLib_Enums_PopupButtonsTypes.Ok) == CoreLib_Enums_PopupResultTypes.Ok) {
        this.router.navigate(['']);
      }
    } else {
      this.name = result.name;
    }
  }

  private setChangePasswordTheme() {
    // La pagina change password ha sempre il tema standard
    this.navigationService.changeTheme('standard', false);
  }

  override async ngOnDestroy(): Promise<void> {
    this.pwdTextChangeSubscription?.unsubscribe();
  }

  override async ngAfterViewInit() {
    await super.ngAfterViewInit();

    this.pwdTextChangeSubscription = this.passwordNewField.textChange.pipe(
      debounceTime(500))
      .subscribe(async (event) => {
        this.applicationStateService.isInLoadingEnabled = false;
        await this.onPwdTextChange();
        this.applicationStateService.restartLoading();
        //this.applicationStateService.isInLoadingEnabled = true;
      });
  }

  async onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    if (event.keyCode == 13) {
      await this.save();
    }
  }

  private setFocusOnPasswordNew() {
    if (this.passwordNewField != null) {
      setTimeout(() => this.passwordNewField.focus(), 500);
    }
  }

  private setFocusOnPasswordNewConfirm() {
    if (this.passwordNewConfirmField != null) {
      setTimeout(() => this.passwordNewConfirmField.focus(), 500);
    }
  }

  // Effettuo la chiamata alla Web Api per il ChangePassword manuale
  async save() {

    this.recoverPasswordFailed = false;
    this.errorText = '';

    if (this.passwordNew == null || this.passwordNew.length == 0) {
      this.recoverPasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDNEWREQUIRED'];
      this.setFocusOnPasswordNew();
      return;
    } else {
      var res = await this.passwordValidate(this.name, this.passwordNew);
      if (!res) {
        this.setFocusOnPasswordNew();
        return;
      }
    }

    if (this.passwordNewConfirm == null || this.passwordNewConfirm.length == 0) {
      this.recoverPasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDNEWCONFIRMREQUIRED'];
      this.setFocusOnPasswordNewConfirm();
      return;
    }

    if (this.passwordNewConfirm != this.passwordNew) {
      this.recoverPasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDNEWMISMATCH'];
      this.passwordNewConfirm = '';
      this.setFocusOnPasswordNewConfirm();
      return;
    }

    // costruisco l'oggetto request
    const recoverPasswordRequest = new CommonRecoverPasswordRequest();
    recoverPasswordRequest.code = this.code;
    recoverPasswordRequest.newPassword = this.passwordNew;
    recoverPasswordRequest.cultureName = this.localizationService.getLocale();

    // invoco la Web Api
    const result = await this.deskApiCommonService.recoverPassword(recoverPasswordRequest);
    await this.processRecoverPasswordResult(result);
  }

  // Processo la risposta della chiamata alla Web Api per il recupero password
  async processRecoverPasswordResult(data: CommonRecoverPasswordResponse): Promise<void> {
    if (data != null && data.success) {
      // Il cambio password è avvenuto con successo: popup di conferma e torno alla login
      this.recoverPasswordFailed = false;

      if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Info, this.localizeByCommon['RECOVER_PASSWORD_OK'], CoreLib_Enums_PopupButtonsTypes.Ok) == CoreLib_Enums_PopupResultTypes.Ok) {
        this.router.navigate(['/login']);
      }

    } else {
      // se fallisce il cambio password mostro un avviso di errore
      this.recoverPasswordFailed = true;

      //se è un errore di conformità della password estraggo la traduzione degli errori multipli e sostituisco l'eventuale placeholder se prensente
      //altrimenti estraggo la traduzione del motivo
        if (data.reasonIsNotValid == "NEW_PASSWORD_NOT_VALID") {
            for (const item of data.passwordValidationErrors) {
                var indexOfUnderscore = item.indexOf('_');

                var itemToTranslate = item;
                var placeholderValue = "";
                if (indexOfUnderscore != -1) {
                    itemToTranslate = item.substring(0, indexOfUnderscore);
                    placeholderValue = item.substring(indexOfUnderscore + 1, item.length);
                }

                if (this.localizeByCommon[itemToTranslate] != null) {
                    this.errorText = this.errorText + this.localizeByCommon[itemToTranslate].replace("@@VALUE@@", placeholderValue) + "\n";
                } else {
                    this.errorText = this.errorText + itemToTranslate;
                }
            }

            this.passwordNew = '';
            this.passwordNewConfirm = '';
            this.setFocusOnPasswordNew();
        } else {
          if(this.localizeByCommon[data.reasonIsNotValid] != null) {
            this.errorText = this.errorText + this.localizeByCommon[data.reasonIsNotValid];
          } else {
            this.errorText = this.errorText + data.reasonIsNotValid;
          }
        }
    }
  }

  public async onPwdTextChange() {
    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.passwordNew)) {
      await this.passwordValidate(this.name, this.passwordNew);
    } else {
      this.passwordRules = this.getPasswordRulesText(['FailedLength', 'FailedUppercase', 'FailedLowercase', 'FailedDigit', 'FailedPunctuation', 'FailedUnique', 'FailedRepeat', 'FailedPattern', 'FailedEntropy']);
    }
  }
  public getPasswordRulesText(rules: string[]): string {
    var res: string = "<ul>";

    var passwordRequirementsString = this.applicationStateService.getStringSetting('PASSWORD_REQUIREMENTS',
      '{"MinLength":8,"MinUniqueCharacters":4,"MaxRepeatSameCharacter":3,"MaxNeighboringCharacter":4,"RequireDigit":true,"RequireLowercase":true,' +
      '"RequireUppercase":true,"RequirePunctuation":true,"MinScore":50,"MinEntropy":2.5,"UseEntropy":false,"ExitOnFailure":false}');
    var passwordRequirements = JSON.parse(passwordRequirementsString);

    let checkRes = "failed"

    //FailedLength
    if (passwordRequirements.MinLength > 0) {
      if (rules.findIndex((item: string) => item.substring(0, (item.indexOf('_') == -1 ? item.length : item.indexOf('_'))) == 'FailedLength') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedLength'].replace("@@VALUE@@", passwordRequirements.MinLength) + "</li>";
    }

    //FailedUppercase
    if (passwordRequirements.RequireUppercase) {
      if (rules.findIndex((item: string) => item == 'FailedUppercase') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedUppercase'] + "</li>";
    }

    //FailedLowercase
    if (passwordRequirements.RequireLowercase) {
      if (rules.findIndex((item: string) => item == 'FailedLowercase') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedLowercase'] + "</li>";
    }

    //FailedDigit
    if (passwordRequirements.RequireDigit) {
      if (rules.findIndex((item: string) => item == 'FailedDigit') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedDigit'] + "</li>";
    }

    //FailedPunctuation
    if (passwordRequirements.RequirePunctuation) {
      if (rules.findIndex((item: string) => item == 'FailedPunctuation') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedPunctuation'] + "</li>";
    }

    //FailedUnique
    if (passwordRequirements.MinUniqueCharacters > 0) {
      if (rules.findIndex((item: string) => item.substring(0, (item.indexOf('_') == -1 ? item.length : item.indexOf('_'))) == 'FailedUnique') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedUnique'].replace("@@VALUE@@", passwordRequirements.MinUniqueCharacters) + "</li>";
    }

    //FailedRepeat
    if (passwordRequirements.MaxRepeatSameCharacter > 0) {
      if (rules.findIndex((item: string) => item.substring(0, (item.indexOf('_') == -1 ? item.length : item.indexOf('_'))) == 'FailedRepeat') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedRepeat'].replace("@@VALUE@@", passwordRequirements.MaxRepeatSameCharacter) + "</li>";
    }

    //FailedPattern
    if (passwordRequirements.MaxNeighboringCharacter > 0) {
      if (rules.findIndex((item: string) => item.substring(0, (item.indexOf('_') == -1 ? item.length : item.indexOf('_'))) == 'FailedPattern') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedPattern'].replace("@@VALUE@@", passwordRequirements.MaxNeighboringCharacter) + "</li>";
    }

    //FailedEntropy
    if (passwordRequirements.UseEntropy) {
      if (rules.findIndex((item: string) => item.substring(0, (item.indexOf('_') == -1 ? item.length : item.indexOf('_'))) == 'FailedEntropy') != -1) checkRes = "failed"
      else checkRes = "success"

      res = res + "<li class=\"" + checkRes + "\">" + this.localizeByCommon['FailedEntropy'].replace("@@VALUE@@", passwordRequirements.MinEntropy) + "</li>";
    }

    res = res + "</ul>";

    return res;
  }
  public async passwordValidate(username: string, password: string): Promise<boolean> {
    
    let request: CommonValidatePasswordRequest = new CommonValidatePasswordRequest();
    request.username = username;
    request.password = password;

    var response = await this.deskApiCommonService.validatePassword(request);

    if (response.passwordValidationErrors == null) response.passwordValidationErrors = [];
    this.passwordRules = this.getPasswordRulesText(response.passwordValidationErrors);

    this.errorText = this.localizeByCommon['PASSWORDREQUIRED'];

    return response.isValid;
  }

}
