import {
  Compiler, ComponentFactoryResolver, Injectable, Injector, NgModuleFactory, Type,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class LazyLoaderService {
  constructor(private compiler: Compiler, private injector: Injector, private factoryResolver: ComponentFactoryResolver) { }

  async loadModule2(path: any, componentName: string) {
    return await (path() as Promise<NgModuleFactory<any> | Type<any>>)
      .then((elementModuleOrFactory) => {
        if (elementModuleOrFactory instanceof NgModuleFactory) {
          // if ViewEngine
          return elementModuleOrFactory;
        } else {
          try {
            // if Ivy
            return this.compiler.compileModuleAsync(elementModuleOrFactory);
          } catch (err) {
            throw err;
          }
        }
      })
      .then((moduleFactory) => {
        try {
          const elementModuleRef = moduleFactory.create(this.injector);
          const rootComponent = (moduleFactory.moduleType as any).entry.find((c: any) => c.name == componentName).type;
          const componentFactory = elementModuleRef.componentFactoryResolver.resolveComponentFactory(rootComponent);

          return componentFactory;
          // do something with the module...
        } catch (err) {
          throw err;
        }
      });
  }
  
  async loadModule(lazyWidgets: any, componentName: string) {
    return await (lazyWidgets.loadChildren() as Promise<NgModuleFactory<any> | Type<any>>)
      .then((elementModuleOrFactory) => {
        if (elementModuleOrFactory instanceof NgModuleFactory) {
          // if ViewEngine
          return elementModuleOrFactory;
        } else {
          try {
            // if Ivy
            return this.compiler.compileModuleAsync(elementModuleOrFactory);
          } catch (err) {
            throw err;
          }
        }
      })
      .then((moduleFactory) => {
        try {
          const elementModuleRef = moduleFactory.create(this.injector);
          const rootComponent = (moduleFactory.moduleType as any).entry.find((c: any) => c.name == componentName).type;
          const componentFactory = elementModuleRef.componentFactoryResolver.resolveComponentFactory(rootComponent);

          return componentFactory;
          // do something with the module...
        } catch (err) {
          throw err;
        }
      });
  }


  public getLazyRoute(): any {
    return null;
  }

  async loadModule3(moduleName: string, componentName: string) {
    return await ((this.getLazyRoute() as any)[moduleName].loadChildren() as Promise<NgModuleFactory<any> | Type<any>>)
      .then((elementModuleOrFactory) => {
        if (elementModuleOrFactory instanceof NgModuleFactory) {
          // if ViewEngine
          return elementModuleOrFactory;
        } else {
          try {
            // if Ivy
            return this.compiler.compileModuleAsync(elementModuleOrFactory);
          } catch (err) {
            throw err;
          }
        }
      })
      .then((moduleFactory) => {
        try {
          const elementModuleRef = moduleFactory.create(this.injector);
          const rootComponent = (moduleFactory.moduleType as any).entry.find((c: any) => c.name == componentName).type;
          const componentFactory = elementModuleRef.componentFactoryResolver.resolveComponentFactory(rootComponent);

          return componentFactory;
          // do something with the module...
        } catch (err) {
          throw err;
        }
      });
  }
}
