import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreLib_Classes_ToastContent, CoreLib_Services_Common_ToastService, CoreLib_Services_Http_DeskCommonService } from 'core';
import { BaseRequest } from 'dto';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../_base/base.component';


@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],

})
export class NotificationsMenuComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input()
  public toastTitle: string;

  //#region Events...

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  //#endregion

  //#region Properties...

  private _isOpen: boolean = false;
  @Input()
  get isOpen() {
    return this._isOpen;
  }

  set isOpen(val) {
    this._isOpen = val;
    if (val) {
      this.isOpenedOneTime = true;
      this.loadUnreadedNotification();
    }
  }

  isNotificationsMenuEnabled: boolean = false;

  isOpenedOneTime: boolean = false;

  openNotificationMenuRequested_Subscription: Subscription;

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private router: Router, private deskApiCommonService: CoreLib_Services_Http_DeskCommonService, public toastService: CoreLib_Services_Common_ToastService) {
    super(injector);
    this.formReference = 'NotificationsMenu';
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.isNotificationsMenuEnabled = !this.applicationStateService.isEmbedded;

    this.openNotificationMenuRequested_Subscription = this.toastService.openNotificationMenuRequested.subscribe(() => {
      this.isOpen = true;
    });
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.openNotificationMenuRequested_Subscription?.unsubscribe();
  }
  async loadUnreadedNotification(): Promise<void> {

    this.toastService.clearAll();

    const response = await this.deskApiCommonService.pushNotificationGetUnreaded(new BaseRequest());

    if (response != null) {
      for (const item of response.items) {

        const toastContent = await this.toastService.getToastContent(item.uid, item.pushText, false);
        if (toastContent != null) {
          this.toastService.toasts.push(toastContent);
        }
      }
    }
  }

  public async closeToast(toastContent: CoreLib_Classes_ToastContent): Promise<void> {

    toastContent.closeRequested = true;

    setTimeout(async () => {
      this.toastService.clear(toastContent);
      await this.toastService.setReaded(toastContent);
      this.toastService.toasts = this.toastService.toasts.filter((c: CoreLib_Classes_ToastContent) => c.uid != toastContent.uid);
    }, 400);
  }

  public async openToast(toastContent: CoreLib_Classes_ToastContent): Promise<void> {
    if (await this.toastService.open(toastContent)) {
      this.toastService.clear(toastContent);
      await this.toastService.setReaded(toastContent);
      this.toastService.toasts = this.toastService.toasts.filter((c: CoreLib_Classes_ToastContent) => c.uid != toastContent.uid);
    }
  }

  //#endregion
}
