export class ObjectHelper {

  public static deepCopyOnTarget(obj: any, target: any) {

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      target.setTime(obj.getTime());
      return target;
    }

    // Handle Array
    if (obj instanceof Array) {
      for (let i = 0, len = obj.length; i < len; i++) {
        target[i] = ObjectHelper.deepCopy(obj[i]);
      }
      return target;
    }

    // Handle Object
    if (obj instanceof Object) {
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          target[attr] = ObjectHelper.deepCopy(obj[attr]);
        }
      }
      return target;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }

  public static deepCopy(obj: any): any {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = ObjectHelper.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = ObjectHelper.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }
}
