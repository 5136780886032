import { Injectable } from '@angular/core';
import { TranslationService } from './translation.service';

import { CommonPushNotificationSetReadedRequest } from 'dto';
import { Subject } from 'rxjs';
import { PushParameter } from '../../classes/PushParameter';
import { ToastContent } from '../../classes/ToastContent';
import { DeskCommonService } from '../http/desk.common.service';



@Injectable({ providedIn: 'root' })
export class ToastService {

  public toasts: ToastContent[];
  public singleToast: ToastContent;
  public multipleToasts: ToastContent;
  public isClose: boolean;
  public notificationsCountChanged = new Subject<number>();

  public openNotificationMenuRequested = new Subject();

  constructor(protected translationService: TranslationService, protected deskCommonService: DeskCommonService) {
    this.toasts = [];
  }

  set(toastContent: ToastContent) {
    this.toasts.push(toastContent);
    this.isClose = false;
    this.refreshData();
  }

  clear(toastContent: ToastContent) {
    this.toasts = this.toasts.filter((c) => c != toastContent);

    if (this.toasts.length == 0) {
      setTimeout(() => this.isClose = true, 200);
    }

    this.singleToast = null;
    this.multipleToasts = null;

    //setTimeout(() => this.refreshData(), 1000);
  }

  clearAll() {
    this.toasts = [];

    if (this.toasts.length == 0) {
      setTimeout(() => this.isClose = true, 200);
    }

    this.singleToast = null;
    this.multipleToasts = null;

    // if (refresh)
    //   setTimeout(() => this.refreshData(), 1000);
  }

  refreshData() {
    this.singleToast = null;
    this.multipleToasts = null;

    if (this.toasts.length > 1) {
      this.multipleToasts = new ToastContent();
      this.multipleToasts.text = this.translationService.tryGetLocalizeByCommon('MORETHANONEPUSRECEIVED').replace('@@COUNT@@', this.toasts.length.toString());
    } else {
      if (this.toasts.length > 0) {
        this.singleToast = this.toasts[0];
      }
    }
  }

  public async setReaded(toastContent: ToastContent): Promise<void> {
    const request = new CommonPushNotificationSetReadedRequest();
    request.uid = toastContent.uid;

    const response = await this.deskCommonService.pushNotificationSetReaded(request);

    if (response != null) {
      this.notificationsCountChanged.next(response.unreadedCount);
    }
  }

  public async open(toastContent: ToastContent): Promise<boolean> {
    return false;
  }



  public async getToastContent(uid: string, pushText: string, updateUnreadedCount: boolean): Promise<ToastContent> {

    const parameters = new Array<PushParameter>();

    let splittedRows: string[];
    splittedRows = pushText.split('|');
    for (const row of splittedRows) {
      let splittedCols: string[];
      splittedCols = row.split('=');
      if (splittedCols.length > 1) {
        parameters.push({ key: splittedCols[0], value: splittedCols[1] });
      }
    }

    const unreadedCountParam = parameters.find((c) => c.key == 'unreadedcount');

    if (updateUnreadedCount && unreadedCountParam != null && unreadedCountParam.value != null) {
      this.notificationsCountChanged.next(parseInt(unreadedCountParam.value));
    }

    return await this.setToastContent(uid, parameters);
  }

  public async setToastContent(uid: string, parameters: PushParameter[]): Promise<ToastContent> {
    return null;
  }
}
