import { Directive, Input, OnInit, Output, EventEmitter, Injector, OnDestroy } from '@angular/core';
import { ContextMenuSelectEvent } from "@progress/kendo-angular-menu";
import { CoreLib_Classes_StringHelper } from 'core';
import { CoreConstants } from 'dto';
import { CommonFilterDescriptor } from 'dto';
import { Subscription } from 'rxjs';
import { FilterData } from '../../classes/FilterData';
import { FilterService } from '../../services/common/filter.service';
import { BaseComponent } from './base.component';

@Directive()
export class BaseFilterComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input()
  public header: string = "";

  @Input()
  public filterGroupKey: string = "";

  @Input()
  public filterName: string = "";

  private _operator: number;

  @Input()
  public get operator(): number {
    return this._operator;
  }
  public set operator(value: number) {
    this._operator = value;

    if (this.isInitSucceeded)
      this.notifyFilterChange();

    this.mapOperatorString();
    this.operatorChange.emit(value);
  }
  @Output() public operatorChange = new EventEmitter<number>();

  @Output()
  public filterChanged: EventEmitter<CommonFilterDescriptor> = new EventEmitter<CommonFilterDescriptor>(true);


  public operators: any = CoreConstants.FilterOperators;

  public operatorString: string = "";

  public isInitSucceeded: boolean = false;

  private filterClearedSubscription: Subscription;

  private filtersDefaultRestoredSubscription: Subscription;


  protected initialOperator: number;

  public hasFilter: boolean;

  //protected filter: CommonFilterDescriptor = null;

  constructor(injector: Injector, protected filterService: FilterService) {
    super(injector);
  }


  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.mapOperatorString();

    this.filterClearedSubscription?.unsubscribe();
    this.filterClearedSubscription = this.filterService.filtersCleared.subscribe((c: string) => {
      if (c == this.filterGroupKey) {
        this.operator = this.initialOperator;
        this.clearFilters();
        this.notifyFilterChange();
      }
    });

    this.filtersDefaultRestoredSubscription?.unsubscribe();
    this.filtersDefaultRestoredSubscription = this.filterService.filtersDefaultRestored.subscribe((c: string) => {
      if (c == this.filterGroupKey) {
        var filterData = this.filterService.getFilter(this.filterGroupKey, this.filterName);

        if (filterData != null)
          this.loadExistingFilters(filterData);
        else
          this.clearFilters();

        this.notifyFilterChange();
      }
    });

    var fData = this.filterService.getFilter(this.filterGroupKey, this.filterName);
    if (fData != null) {
      this.loadExistingFilters(fData);      
    }

    this.notifyFilterChange();
    
    this.isInitSucceeded = true;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.filterClearedSubscription?.unsubscribe();
    this.filtersDefaultRestoredSubscription?.unsubscribe();
  }

  selectOperator(event: ContextMenuSelectEvent) {
    this.operator = event.item.data;
  }

  public loadExistingFilters(filterData: FilterData) {

  }

  public clearFilters() {

  }

  public mapOperatorString() {
    switch (this.operator) {

      case CoreConstants.FilterOperators.EQ:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_EQ'];
        break;
      case CoreConstants.FilterOperators.NOTEQ:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_NOTEQ'];
        break;
      case CoreConstants.FilterOperators.STARTSWITH:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_STARTSWITH'];
        break;
      case CoreConstants.FilterOperators.CONTAINS:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_CONTAINS'];
        break;
      case CoreConstants.FilterOperators.ENDSWITH:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_ENDSWITH'];
        break;
      case CoreConstants.FilterOperators.NOTSTARTSWITH:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_NOTSTARTSWITH'];
        break;
      case CoreConstants.FilterOperators.NOTCONTAINS:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_NOTCONTAINS'];
        break;
      case CoreConstants.FilterOperators.NOTENDSWITH:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_NOTENDSWITH'];
        break;
      case CoreConstants.FilterOperators.GREATHERTHAN:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_GREATHERTHAN'];
        break;
      case CoreConstants.FilterOperators.GREATHERTHANOREQUALSTO:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_GREATHERTHANOREQUALSTO'];
        break;
      case CoreConstants.FilterOperators.LESSTHAN:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_LESSTHAN'];
        break;
      case CoreConstants.FilterOperators.LESSTHANOREQUALSTO:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_LESSTHANOREQUALSTO'];
        break;
      case CoreConstants.FilterOperators.IN:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_IN'];
        break;
      case CoreConstants.FilterOperators.NOTIN:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_NOTIN'];
        break;
      case CoreConstants.FilterOperators.BETWEEN:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_BETWEEN'];
        break;
      case CoreConstants.FilterOperators.NOTBETWEEN:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_NOTBETWEEN'];
        break;
      case CoreConstants.FilterOperators.ISNULL:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_ISNULL'];
        break;
      case CoreConstants.FilterOperators.ISNOTNULL:
        this.operatorString = this.localizeByCommon['FILTEROPERATOR_ISNOTNULL'];
        break;

    }

  }

  public notifyFilterChange() {

    if (this.initialOperator == null) {
      this.initialOperator = this.operator;
    }

    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.filterName)) {
      this.filterService.removeFilter(this.filterGroupKey, this.filterName);
    }
  }

  public addFilter(filter: CommonFilterDescriptor, filterValue: any) {

    if (filter != null)
      this.filterService.addFilter(this.filterGroupKey, filter, this.operator, filterValue);

    this.filterChanged.emit(filter);
  }



}
