import { Component, OnInit, Injector, Input } from '@angular/core';
import { CommonFilterDescriptor, CoreConstants } from 'dto';
import { CoreLib_Classes_StringHelper } from 'core';
import { BaseFilterComponent } from '../_base/base-filter.component';
import { FilterService } from '../../services/common/filter.service';
import { FilterData } from '../../classes/FilterData';

@Component({
  selector: 'app-filter-number',
  templateUrl: './filter-number.component.html'
})
export class FilterNumberComponent extends BaseFilterComponent implements OnInit {

  //#region Input Output



  //#endregion

  //#region Fields

  private _from: number = null;
  @Input()
  public get from(): number {
    return this._from;
  }
  public set from(value: number) {
    this._from = value;

    if (this.isInitSucceeded)
      this.notifyFilterChange();
  }

  private _to: number = null;
  @Input()
  public get to(): number {
    return this._to;
  }
  public set to(value: number) {
    this._to = value;


    if (this.isInitSucceeded)
      this.notifyFilterChange();
  }

  public error: string = "";

  public get computedHeaderFrom() {
    return (this.localizeByCommon['FROM_HEADER'] != null) ? this.localizeByCommon["FROM_HEADER"].replace("@@HEADER@@", this.header) : '';
  }

  public get computedHeaderTo() {
    return (this.localizeByCommon['TO_HEADER'] != null) ? this.localizeByCommon["TO_HEADER"].replace("@@HEADER@@", this.header) : '';
  }
  //#endregion

  //#region Constructor...

  constructor(injector: Injector,
    filterService: FilterService) {
    super(injector, filterService);
    this.formReference = 'FilterNumber';
    this.operator = CoreConstants.FilterOperators.EQ;
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

  }

  public override loadExistingFilters(filterData: FilterData): void {
    super.loadExistingFilters(filterData);
    this.operator = filterData.filterOperator;
    this.from = filterData.filterValue.from;
    this.to = filterData.filterValue.to;
  }

  public override clearFilters(): void {
    super.clearFilters();
    this.from = null;
    this.to = null;    
    this.operator = CoreConstants.FilterOperators.EQ;
  }

  public override notifyFilterChange() {
    super.notifyFilterChange();

    let filter: CommonFilterDescriptor = null;

    this.error = "";

    this.hasFilter = false;

    switch (this.operator) {
      case CoreConstants.FilterOperators.EQ:
      case CoreConstants.FilterOperators.NOTEQ:
      case CoreConstants.FilterOperators.GREATHERTHAN:
      case CoreConstants.FilterOperators.GREATHERTHANOREQUALSTO:
      case CoreConstants.FilterOperators.LESSTHAN:
      case CoreConstants.FilterOperators.LESSTHANOREQUALSTO:
        if (this.from != null) {
          filter = new CommonFilterDescriptor();
          filter.filterName = this.filterName;
          filter.operator = this.operator;
          filter.values = [];
          filter.values.push(this.from);
          this.hasFilter = true;
        }
        break;

      case CoreConstants.FilterOperators.BETWEEN:
      case CoreConstants.FilterOperators.NOTBETWEEN:
        if (this.from != null || this.to != null) {

          if (this.from == null) {
            this.error = this.localizeByCommon["MISSING_FROM"];
          }

          if (this.to == null) {
            this.error = this.localizeByCommon["MISSING_TO"];
          }

          filter = new CommonFilterDescriptor();
          filter.filterName = this.filterName;
          filter.operator = this.operator;
          filter.values = [];
          filter.values.push(this.from);
          filter.values.push(this.to);
          filter.hasErrors = !CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.error);
          this.hasFilter = !filter.hasErrors;
        }
        break;

      case CoreConstants.FilterOperators.ISNULL:
      case CoreConstants.FilterOperators.ISNOTNULL:
        filter = new CommonFilterDescriptor();
        filter.filterName = this.filterName;
        filter.operator = this.operator;
        this.hasFilter = true;
        break;
    }

    this.addFilter(filter, { from: this.from, to: this.to });
  }
  //#endregion
}
