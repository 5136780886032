



export class BaseRequest   {

  public cultureName: string =  '';
}





