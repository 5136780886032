




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonReportEnabledFilterSchemasResponse extends BaseTokenResponse  {
  
  public filterSchemas: string[] = [];
  
}



