import { RolePermissionFlag } from '../../classes/RolePermissionFlag';


export abstract class RolePermissionService {

  //#region Declarations...


  //#endregion

  //#region Properties...

  public rolePermissions: { [index: string]: RolePermissionFlag } = {};

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  public abstract init(): Promise<void>;

  //#endregion

}
