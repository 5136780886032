import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnChanges, OnDestroy, SimpleChange } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-label-expandable',
  templateUrl: './label-expandable.component.html',
  styleUrls: ['./label-expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelExpandableComponent extends BaseComponent implements OnChanges, AfterViewInit, OnDestroy {


  //#region Properties...
  @Input()
  public maxWidth: string;

  private originalMaxWidth: string;

  @Input()
  public maxHeight: string;

  private _text: string;
  @Input()
  get text() {
    return this._text;
  }
  set text(val) {
    this._text = val;
  }

  public expanded: boolean = false;

  @Input()
  public resetMaxWidthAfterLoad: boolean = false;
  private resizeSubscription: Subscription;

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector);

    this.resizeSubscription = fromEvent(window, 'resize').pipe(
      debounceTime(500))
      .subscribe((event) => {
        this.expanded = false;
        this.maxWidth = this.originalMaxWidth;
        this.ref.detectChanges();
        this.postLoadActions();
      });
  }

  //#endregion

  //#region Methods...
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    Promise.resolve(null).then(() => {
      for (const propName in changes) {
        if (!changes[propName].isFirstChange()) {
          if (propName == 'text') {
            this.expanded = false;
            this.ref.detectChanges();
          }
        }
      }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.resizeSubscription?.unsubscribe();        
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    this.postLoadActions();


  }



  private postLoadActions() {
    if (this.resetMaxWidthAfterLoad) {
      setTimeout(() => {
        this.originalMaxWidth = this.maxWidth;
        this.maxWidth = '';
        this.ref.detectChanges();
      }, 1000);
    }
  }
  //#endregion
}
