import { AfterViewInit, ChangeDetectorRef, Directive, Injector, NgZone, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreLib_Classes_Guid, CoreLib_Classes_StringHelper, CoreLib_Services_Common_MessageService, CoreLib_Services_Common_WebViewHostService } from 'core';
import { BaseCrudEntityWrapper, CoreConstants } from 'dto';
import { CrudPanel } from '../../classes/CrudPanel';
import { CrudSaveResult } from '../../enums/CrudSaveResult';
import { CrudViewModes } from '../../enums/CrudViewModes';
import { ValidationService } from '../../services/common/validation.service';
import { BaseViewComponent } from './base-view.component';

/**
 * La classe base dalla quale devono ereditare tutte le view embeddate all'interno di una anagrafica (le sotto entità di una crud)
 * Chi embedda invoca la rotta definita per il componente aggiungendo un querystring con le proprietà Uid e ViewMode
 * All'onInit le proprietà Uid e ViewMode vengono lette dal querystring e settate sulle rispettive proprietà panel.uid e viewMode
 */
@Directive()
export class BaseWidgetCrudViewComponent<T> extends BaseViewComponent implements OnInit, OnDestroy, AfterViewInit {


  //#region Private Properties...

  private webViewHostService: CoreLib_Services_Common_WebViewHostService;

  //#endregion

  //#region Protected Properties...

  protected validationService: ValidationService;
  protected route: ActivatedRoute;
  protected messageService: CoreLib_Services_Common_MessageService;


  //#endregion

  //#region  Public Properties...

  /**
   * Fornisce alla view l'oggetto con lo Uid dell'entità principale e l'oggetto dataWrapper da passare al BaseCrudComponent
   */
  public panel: CrudPanel<BaseCrudEntityWrapper<T>>;

  /**
   * Fornisce alla view il set di valori possibili dell'enumeratore CrudViewModes
   */
  public viewModes: any = CrudViewModes;

  private _viewMode: CrudViewModes = CrudViewModes.List;
  /**
   * Fornisce alla view la modalità corrente di lavoro  (se la crud padre è in modalità lista, view, edit o new)
   */
  public get viewMode(): CrudViewModes {
    return this._viewMode;
  }
  public set viewMode(value: CrudViewModes) {
    this._viewMode = value;
  }

  private forNewId: string = '';

  public getMainEntityUid(){
    if(this.viewMode == CrudViewModes.New){
      return this.forNewId;
    } else {
      return this.panel.uid;
    }
  }
  //#endregion

  //#region Constructor
 /**
 * Inizializza una nuova istanza del componente.
 *
 * @param injector Il servizio che consente di ricavare le istanze di altri servizi tramite dependency injection
 * @param validationService Il servizio ValidationService
 * @param route Il servizio ActivatedRoute
 * @param messageService Il servizio CoreLib_Services_Common_MessageService
 */
  constructor(injector: Injector) {
    super(injector);

    this.validationService = injector.get(ValidationService);
    this.route = injector.get(ActivatedRoute);
    this.messageService = injector.get(CoreLib_Services_Common_MessageService);
    this.webViewHostService = injector.get(CoreLib_Services_Common_WebViewHostService);
    this.panel = new CrudPanel<BaseCrudEntityWrapper<T>>('', 0);
  }
  //#endregion

  //#region Lifecycle Methods...

  override async ngOnInit() {
    await super.ngOnInit();


    window.desk.edit = this.externalEdit.bind(this);
    window.desk.cancelEdit = this.externalCancelEdit.bind(this);
    window.desk.trySave = this.externalTrySave.bind(this);
    window.desk.save = this.externalSave.bind(this);


    const ref = this.injector.get(ChangeDetectorRef);
    if (!(ref as ViewRef).destroyed) {
      ref.detectChanges();
    }

    this.validationService.init();

    const vMode = this.route.snapshot.queryParamMap.get('viewMode');

    if (vMode != null) {
      switch (vMode.toLowerCase()) {
        case 'view':
          this.viewMode = CrudViewModes.View;
          this.panel.uid = this.route.snapshot.queryParamMap.get('uid');
          break;
        case 'edit':
          this.viewMode = CrudViewModes.Edit;
          this.panel.uid = this.route.snapshot.queryParamMap.get('uid');
          break;
        case 'new':
          this.viewMode = CrudViewModes.New;
          this.forNewId = this.route.snapshot.queryParamMap.get('uid');
          this.panel.uid = CoreLib_Classes_Guid.Empty;
          break;
      }
    }
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
    this.webViewHostService.sendMessage(CoreConstants.DeskMessages.LOADED);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    const ref = this.injector.get(ChangeDetectorRef);
    ref.detach();

    window.desk.edit = null;
    window.desk.cancelEdit = null;
    window.desk.trySave = null;
    window.desk.save = null;
  }

  //#endregion

  //#region Private Methods...

  private externalEdit() {
    this.zone.run(() => {
      this.viewMode = CrudViewModes.Edit;
      this.panel.uid = this.route.snapshot.queryParamMap.get('uid');
      this.webViewHostService.sendResult(CoreConstants.DeskResults.EDITSTARTED);
    });
  }

  private externalCancelEdit() {
    this.zone.run(() => {
      this.viewMode = CrudViewModes.View;
      //this.panel.uid = this.panel.uid;
      this.panel.uid = this.route.snapshot.queryParamMap.get('uid');
      this.webViewHostService.sendResult(CoreConstants.DeskResults.EDITCANCELED);
    });
  }

  private externalTrySave(additionalParameters: string) {
    this.zone.run(async () => {
      if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(additionalParameters)) {
        for (const entry of additionalParameters.split('&')) {
          const values = entry.split('=');
          (this as any)[values[0]] = values[1];
        }
      }
      const resultSave = await this.save(true);

      this.processSaveResult(resultSave, true);
    });
  }

  private externalSave() {
    this.zone.run(async () => {

      const resultSave = await this.save(false);

      this.processSaveResult(resultSave, false);
    });
  }

  private processSaveResult(resultSave: CrudSaveResult, isTrySave: boolean) {
    if (resultSave == CrudSaveResult.Success) {
      if (!isTrySave) {
        this.viewMode = CrudViewModes.View;
        this.panel.uid = this.route.snapshot.queryParamMap.get('uid');
      }
      this.webViewHostService.sendResult(CoreConstants.DeskResults.SAVED);
    } else if (resultSave == CrudSaveResult.Invalid) {
      this.webViewHostService.sendResult(CoreConstants.DeskResults.INVALIDFORM);
    } else if (resultSave == CrudSaveResult.Errors) {
      this.webViewHostService.sendResult(CoreConstants.DeskResults.ERROR, this.messageService.lastMessage);
    }
  }
  //#endregion

  //#region Protected Methods...

  //#endregion

  //#region Public Methods...

  /**
   * Estende quanto definito nella classe base. Il component è in modalità di edit se la sua viewMode è Edit o New
   * @returns Un valore booleano che indica se la pagina è in modalità edit.
   */
  public override isInEditMode(): boolean {
    return (this.viewMode == CrudViewModes.Edit || this.viewMode == CrudViewModes.New);
  }

  //#endregion

  //#region Mandatory Overridable Methods...
  public async save(rollbackAfterSave: boolean): Promise<CrudSaveResult> {
    // Questo metodo viene implementato dalle classi derivate...
    return CrudSaveResult.Success;
  }

  public async validate(): Promise<boolean> {
    this.validationService.resetErrors();
    return true;
  }

  //#endregion
}


