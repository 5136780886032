import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ModuleHostDirective } from '../../directives/module-host.directive';

@Component({
  selector: 'app-module-host',
  templateUrl: './module-host.component.html',
})
export class ModuleHostComponent implements AfterViewInit {

  //#region Events...

  //#endregion

  //#region Declarations...

  //#endregion

  //#region Properties...


  //#endregion

  public viewContainerRef: ViewContainerRef;

  @Input()
  public name: string;

  @Input()
  public dataItem: any;

  private moduleHost: ModuleHostDirective;
  public isLoaded: boolean = false;


  private _height: number = null;
  @Input()
  get height() {
    return this._height;
  }
  @Output() heightChange = new EventEmitter();
  set height(val) {
    this._height = val;
    this.heightChange.emit(val);
  }

  @ViewChild(ModuleHostDirective) set _moduleHost(_moduleHost: ModuleHostDirective) {
    this.viewContainerRef = _moduleHost.viewContainerRef;
  }

  _parentElement: ElementRef;

  @ViewChild('parentElement') set parentElement(parentElement: ElementRef) {
    this._parentElement = parentElement;
  }

  raiseHeightChanged() {
    this.heightChange.emit(this._parentElement.nativeElement.clientHeight);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // Trasmetto la height fuori...
      this.height = this._parentElement.nativeElement.clientHeight;
    }, 0);
  }

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  //#endregion
}
