<div *ngIf="isNotificationsMenuEnabled" class="app-notifications-menu" [class.off]="!isOpenedOneTime" [class.opened]="isOpen" [class.closed]="!isOpen && isOpenedOneTime" [class.small-device]="isSmallDevice">

  <h4 *ngIf="toastService.toasts.length > 0">{{localizeByCommon["NOTIFICATIONSMENU_TITLE"]}}</h4>
  <h4 *ngIf="toastService.toasts.length == 0">{{localizeByCommon["NONEWNOTIFICATIONS"]}}</h4>
  <div class="toasts-holder">
    <div class="toast-item" *ngFor="let toast of toastService.toasts" [class.closed]="toast.closeRequested">
      <app-toast [toastContent]="toast" (onClose)="closeToast($event)" (onOpen)="openToast($event)" [title]="toastTitle"></app-toast>
    </div>
  </div>


</div>
