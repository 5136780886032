import { Injector, Directive, Input, Output, EventEmitter } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { CoreLib_Classes_Guid } from 'core';
import { CoreLib_Classes_StringHelper } from 'core';
import { BaseCrudEntityWrapper, IBaseDtoCrudEntityWrapperItems } from 'dto';
import { BaseCrudComponent } from './base-crud.component';


@Directive()
export class BaseCrudListComponent<TDto extends IBaseDtoCrudEntityWrapperItems<TDtoItem>, TDtoItem> extends BaseCrudComponent<TDto>  {

  //#region Declarations...

  //#endregion

  //#region Properties...

  @Input()
  public get items() {
    if (this.dataWrapper != null && this.dataWrapper.dataItem != null) {
      return this.dataWrapper.dataItem.items;
    } else {
      return null;
    }
  }
  public set items(val) {
    this.dataWrapper.dataItem.items = val;
  }

  @Output() hasRecordsChange = new EventEmitter();
  get hasRecords() {
    return this.items != null && this.items.filter((c:any) => c.isDeleted == false).length > 0;
  }

  public searchText: string;

  public filterText: string;

  public pageSize = 10;

  public skip = 0;

  public pagedItems: Array<any> = [];

  public total = 0;

  //#endregion

  //#region Constructors...
  constructor(injector: Injector) {
    super(injector);


  }
  //#endregion

  //#region Methods...
  override ngOnDestroy(): void {
    super.ngOnDestroy();

  }



  override async ngOnInit() {
    await super.ngOnInit();

  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
  }

  public override async afterLoadEditOrViewMode(): Promise<void> {
    // Memorizzo l'hash dell'oggetto originale per ogni item
    this.SetOriginalHashToList<TDtoItem>(this.items);

    this.pageData(0);
  }

  setFilter(goToFirstPage: boolean = true, unselectAll: boolean = true) {
    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.searchText)) {
      this.filterText = this.searchText;
    } else {
      this.filterText = null;
    }

    if (unselectAll) {
      this.unselectAll();
    }

    if (goToFirstPage) {
      this.pageData(0);
    }
  }

  clearFilter(goToFirstPage: boolean = true, unselectAll: boolean = true) {
    this.searchText = "";
    this.setFilter(goToFirstPage, unselectAll);
  }


  public async onPageChange(e: PageChangeEvent): Promise<void> {
    this.pageSize = e.take;
    this.pageData(e.skip);
  }

  public pageData(skip: number, scrollToTarget: boolean = true): void {
    this.skip = skip;
    const itemsFiltered = this.getItemsFiltered().filter((c: any) => c.isDeleted == false);
    this.total = itemsFiltered.length;

    this.pagedItems = itemsFiltered.slice(skip, skip + this.pageSize);
    if (scrollToTarget && this.pagedItems != null && this.pagedItems.length > 0) {      
      this.scrollToTarget(this.pagedItems[0].originalHash, false);
    }
  }

  public override clearFields() {
    super.clearFields();
    this.total = 0;
    this.skip = 0;
    if (this.isUidBinding) {
      this.items = [];
      this.pagedItems = [];
    }
  }

  public override async validate(): Promise<boolean> {
    if (this.dataWrapper != null && this.dataWrapper.dataItem != null && this.dataWrapper.dataItem.items != null) {
      for (const item of this.dataWrapper.dataItem.items) {
        item.errors = {};
      }
    }
    return await super.validate();
  }

  selectAll() {
    for (const item of this.getItemsFiltered().filter((c: any) => c.isDeleted == false)) {
      item.isSelected = true;
    }
  }

  unselectAll() {
    for (const item of this.items) {
      item.isSelected = false;
    }
  }

  public async addItem(item: BaseCrudEntityWrapper<TDtoItem>): Promise<void> {
    item.originalHash = CoreLib_Classes_Guid.newId();
    this.clearFilter(false);
    this.items.push(item);
    this.pageData(Math.floor(this.total / this.pageSize) * this.pageSize, false);
    this.scrollToTarget(item.originalHash);
  }

  public deleted() {
    this.hasRecordsChange.emit();

    if (this.pagedItems.filter((c: any) => c.isDeleted == false).length == 0) {
      if (this.skip > 0) {
        this.pageData(this.skip - this.pageSize);
      }
    } else {
      this.pageData(this.skip);
    }
  }

  //#endregion

  //#region Mandatory Overridable Methods

  public getItemsFiltered(): any {
    return this.items;
  }


  //#endregion

}
