export abstract class ApplicationInitService {

  //#region Declarations...

  //#endregion

  //#region Properties...


  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  public abstract init(): Promise<void>;

  //#endregion

}
