import { Component, Injector, Input, OnInit } from '@angular/core';
import { ReportSelection } from '../../classes/ReportSelection';
import { ReportServiceConfig } from '../../classes/ReportServiceConfig';
import { ReportService } from '../../services/common/report.service';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-report-selection',
  templateUrl: './report-selection.component.html',
  styleUrls: ['./report-selection.component.scss'],
})
export class ReportSelectionComponent extends BaseComponent {

  //#region Properties...

  @Input()
  public reportServiceConfig: ReportServiceConfig;

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private reportService: ReportService) {
    super(injector);
    this.formReference = 'ReportSelection';
  }

  //#endregion

  //#region Methods...

  public printPreview() {
    this.reportService.printReport(this.reportServiceConfig.reports.find((c) => c.isSelected).report, false);
  }

  public printCloud() {
    this.reportService.printReport(this.reportServiceConfig.reports.find((c) => c.isSelected).report, true);
  }

  // public print() {
  //  this.reportService.print();
  // }
  public download() {
    this.reportService.download();
  }
  public closeReportSelection() {
    this.reportServiceConfig.showReportSelection = false;
  }

  public closePrintPreview() {
    this.reportServiceConfig.showPrintPreview = false;
  }

  public select(report: ReportSelection) {
    for (const item of this.reportServiceConfig.reports) {
      item.isSelected = false;
    }

    report.isSelected = true;
  }

  // public iframeLoaded(event) {
  //  if (this.reportService.config.showPrintPreview)
  //    this.reportService.iframeLoaded(event);
  // }

  //#endregion


}
