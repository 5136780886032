




import { CommonMenuItemDataResponse } from './CommonMenuItemDataResponse';    
export class CommonMenuGroupDataResponse   {
  
  public groupDescription: string = '';
  
  public icon: string = '';
  
  public isOpen: boolean = false;
  
  public isActive: boolean = false;
  
  public items: CommonMenuItemDataResponse[] = [];
  
}



