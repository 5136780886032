<div class="app-toolbar">
  <div class="row">
    <div class="col-md-12">

      <div class="toolbar d-table w-100">
        <div class="left d-table-cell" #resizer toolbar-left [formReference]="formReference">

          <ng-content select=".left-container"></ng-content>

          <span #leftAnchor id="leftAnchor{{formReference}}">
            <app-button  (buttonClick)="onToggleLeft()" [icon]="'more-vertical'" [buttonClass]="'k-button-white k-button-more-vertical'"></app-button>
          </span>

        </div>
        <div class="right d-table-cell">

          <ng-content select=".right-container"></ng-content>

          <span #rightAnchor id="rightAnchor{{formReference}}">
            <app-button (buttonClick)="onToggleRight()" [icon]="rightPopupAnchorIcon" [buttonClass]="'k-button-white'"
              [hidden]="!showRightPopupAnchor || !hasItemsInRightPopup">
            </app-button>
          </span>

        </div>
      </div>
    </div>
  </div>
  <kendo-popup [anchor]="{ nativeElement: leftAnchor }" #leftPopup [anchorAlign]="leftAnchorAlign"
    [popupAlign]="leftPopupAlign" (anchorViewportLeave)="leftShow = false" [hidden]="!leftShow">
    <div id="leftPopupContent{{formReference}}" class="popup-content">

    </div>
  </kendo-popup>

  <kendo-popup [anchor]="{ nativeElement: rightAnchor }" #rightPopup [anchorAlign]="rightAnchorAlign"
    [popupAlign]="rightPopupAlign" (anchorViewportLeave)="rightShow = false" [hidden]="!rightShow">
    <div id="rightPopupContent{{formReference}}" #rightPopupContent class="popup-content">

      <ng-content select=".right-container-popup"></ng-content>

    </div>
  </kendo-popup>
</div>
