<ng-container *ngIf="visible">

  <app-grid-filter-text *ngIf="columnDefinition.filterType == 'text'" [filter]="filter"
    [operator]="columnDefinition.filterOperator" [filterField]="getValueFieldName(columnDefinition)">
  </app-grid-filter-text>

  <app-grid-filter-date *ngIf="columnDefinition.filterType == 'date'" [filter]="filter"
    [operator]="columnDefinition.filterOperator" [filterField]="getValueFieldName(columnDefinition)"
    [format]="columnDefinition.format"></app-grid-filter-date>

  <app-grid-filter-time *ngIf="columnDefinition.filterType == 'time'" [filter]="filter"
    [operator]="columnDefinition.filterOperator" [filterField]="getValueFieldName(columnDefinition)"
    [format]="columnDefinition.format"></app-grid-filter-time>

  <app-grid-filter-code *ngIf="columnDefinition.filterType == 'code'" [filter]="filter"
    [operator]="columnDefinition.filterOperator" [filterField]="getValueFieldName(columnDefinition)"
    [firstPartLength]="columnDefinition.codeFirstPartLength" [separator]="columnDefinition.codeSeparator"
    [secondPartLength]="columnDefinition.codeSecondPartLength"></app-grid-filter-code>

  <app-grid-filter-number *ngIf="columnDefinition.filterType == 'numeric'" [filter]="filter"
    [operator]="columnDefinition.filterOperator" [filterField]="getValueFieldName(columnDefinition)">
  </app-grid-filter-number>

  <app-grid-filter-boolean *ngIf="columnDefinition.filterType == 'boolean'" [filter]="filter"
    [filterField]="getValueFieldName(columnDefinition)">
  </app-grid-filter-boolean>

  <ng-container *ngIf="columnDefinition.filterType == 'multiselect'">
    <ng-container *ngIf="columnDefinition.filterTemplate !== null">
      <app-grid-filter-multi-select [filter]="filter" [filterField]="getValueFieldName(columnDefinition)"
        [data]="getThisFieldValue(columnDefinition.filterDataField)" [ondemand]="columnDefinition.ondemand"
        [itemTemplate]="itemTemplate" (filterChange)="getThisFunction(columnDefinition.filterChangeFunctionName,$event)"
        [dropDownFilterOperator]="convertToOperator(columnDefinition.dropDownFilterOperator)"
        [textField]="columnDefinition.filterTextField" [valueField]="columnDefinition.filterValueField">
        <ng-template #itemTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="columnDefinition.filterTemplate"
            [ngTemplateOutletContext]="{dataItem:dataItem}"></ng-container>
        </ng-template>
      </app-grid-filter-multi-select>
    </ng-container>
    <ng-container *ngIf="columnDefinition.filterTemplate === null">
      <app-grid-filter-multi-select [filter]="filter" [filterField]="getValueFieldName(columnDefinition)"
        [data]="getThisFieldValue(columnDefinition.filterDataField)" [ondemand]="columnDefinition.ondemand"
        (filterChange)="getThisFunction(columnDefinition.filterChangeFunctionName,$event)"
        [dropDownFilterOperator]="convertToOperator(columnDefinition.dropDownFilterOperator)"
        [textField]="columnDefinition.filterTextField" [valueField]="columnDefinition.filterValueField">
      </app-grid-filter-multi-select>
    </ng-container>
  </ng-container>
</ng-container>