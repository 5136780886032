import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_PopupService, CoreLib_Services_Common_TranslationService } from 'core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { DeactivationService } from './deactivation.service';
import { CommonNotifyMenuBadgeChangeRequest } from 'dto';

@Injectable({ providedIn: 'root' })
export class NavigationService {

  //#region Declarations

  public cookieExpiredTime: number = 30;

  public themeChanged = new Subject<boolean>();
  
  public badgeChanged = new Subject<CommonNotifyMenuBadgeChangeRequest>();

  //#endregion

  //#region Constructors...

  constructor(private router: Router,
    private deactivationService: DeactivationService,
    private popupService: CoreLib_Services_Common_PopupService,
    private translationService: CoreLib_Services_Common_TranslationService,
    private cookieService: CookieService) {

      this.router.onSameUrlNavigation = 'reload';

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
  
        return false;
  
      };
  }

  //#endregion

  //#region Methods...

  public async navigate(url: string) {
    let continueNavigate: boolean = true;
    if (this.deactivationService.isInEditMode()) {
      if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.translationService.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo) == CoreLib_Enums_PopupResultTypes.No) {
        continueNavigate = false;
      } else {
        this.deactivationService.forceExit = true;
      }
    }

    if (continueNavigate) {
      await this.router.navigate([url]);
    }
  }

  //#region Themes

  public setTheme(theme: string): void {
    if (theme != null) {
      const expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + this.cookieExpiredTime);
      this.cookieService.set('Theme' + location.port, theme, expireDate, '/', null, false, 'Lax');
    }
  }

  public getTheme(): string {
    if (this.cookieService.check('Theme' + location.port)) {
      this.setTheme(this.cookieService.get('Theme' + location.port));
      return this.cookieService.get('Theme' + location.port);
    } else {
      return '';
    }
  }

  public getCurrentTheme() {
    const rootObj = document.getElementById('rootObject');

    if (rootObj.className.indexOf('theme-big') > -1) {
      return 'big';
    } else if (rootObj.className.indexOf('theme-medium') > -1) {
      return 'medium';
    } else if (rootObj.className.indexOf('theme-small') > -1) {
      return 'small';
    } else {
      return 'standard';
    }
  }

  public getThemeScale(): number {
    const currentTheme = this.getTheme();
    if (currentTheme == 'small') {
      return 0.75;
    } else if (currentTheme == 'standard') {
      return 1;
    } else if (currentTheme == 'medium') {
      return 1.25;
    } else if (currentTheme == 'big') {
      return 1.5;
    }

  }

  public getCurrentThemeFactor() {
    const rootObj = document.getElementById('rootObject');

    if (rootObj.className.indexOf('theme-big') > -1) {
      return 1.5;
    } else if (rootObj.className == 'theme-medium') {
      return 1.25;
    } else if (rootObj.className == 'theme-small') {
      return 0.75;
    } else {
      return 1;
    }
  }

  public changeTheme(theme: string, notify: boolean = true) {
    const rootObj = document.getElementById('rootObject');
    rootObj.className = 'theme-' + theme;

    if (notify) {
      this.themeChanged.next(true);
    }
  }

  public switchTheme(themeName: string) {
    this.setTheme(themeName);

    this.changeTheme(this.getTheme());
  }

  //#endregion

  //#region FullScreen

  public setIsDefaultFullScreenLogin(value: boolean): void {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + this.cookieExpiredTime);
    this.cookieService.set('IsDefaultFullScreenLogin' + location.port, value.toString(), expireDate, '/', null, false, 'Lax');
  }

  public getIsDefaultFullScreenLogin(): boolean {
    if (this.cookieService.check('IsDefaultFullScreenLogin' + location.port)) {
      this.setIsDefaultFullScreenLogin(JSON.parse(this.cookieService.get('IsDefaultFullScreenLogin' + location.port)));
      return JSON.parse(this.cookieService.get('IsDefaultFullScreenLogin' + location.port));
    } else {
      return false;
    }
  }

  public goToFullScreen() {
    const doc_elem = document.documentElement as any;

    if (doc_elem.requestFullscreen) {
      doc_elem.requestFullscreen();
    } else if (doc_elem.mozRequestFullScreen) {
      doc_elem.mozRequestFullScreen();
    } else if (doc_elem.webkitRequestFullscreen) {
      doc_elem.webkitRequestFullscreen();
    } else if (doc_elem.msRequestFullscreen) {
      doc_elem.msRequestFullscreen();
    }
  }

  public exitFromFullScreen() {
    const doc = document as any;

    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  }

  //#endregion

  //#endregion
}
