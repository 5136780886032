import { Directive, ElementRef, Input, OnDestroy, QueryList } from '@angular/core';
import { PagerErrorService } from "../services/common/pager-error.service";
import { PagerErrorConfig } from '../classes/PagerErrorConfig';
import { Subscription } from "rxjs";


@Directive({
  selector: '[pagerError]',
})
export class PagerErrorDirective implements OnDestroy {

  private checkPagedErrorsSubscription: Subscription;

  @Input() public pagerError: string;

  private lastPagerErrorConfig: PagerErrorConfig;

  constructor(private element: ElementRef, private pagerErrorService: PagerErrorService) {

    this.checkPagedErrorsSubscription = this.pagerErrorService.checkPagedErrors.subscribe(c => { this.check(c); });
  }

  ngOnDestroy(): void {

    if (this.checkPagedErrorsSubscription != null) {
      this.checkPagedErrorsSubscription.unsubscribe();
    }
  }

  public checkLastPagerErrorConfig() {
    setTimeout(() => this.check(this.lastPagerErrorConfig), 1000);
  }

  public check(pagerErrorConfig: PagerErrorConfig) {
    if (pagerErrorConfig != null && pagerErrorConfig.formReference == this.pagerError) {

      this.lastPagerErrorConfig = pagerErrorConfig;

      // Recupero i selettori prima pagina, pagina precedente, pagina successiva e ultima pagina
      const prevNav = this.element.nativeElement.querySelector("kendo-datapager-prev-buttons")
      const nextNav = this.element.nativeElement.querySelector("kendo-datapager-next-buttons")

      // Ai selettori recuperati aggancio al click il ricontrollo degli errori
      if (prevNav != null) {
        prevNav.removeEventListener("click", this.checkLastPagerErrorConfig);;
        prevNav.addEventListener("click", this.checkLastPagerErrorConfig.bind(this), true);
      }

      if (nextNav != null) {
        nextNav.removeEventListener("click", this.checkLastPagerErrorConfig);;
        nextNav.addEventListener("click", this.checkLastPagerErrorConfig.bind(this), true);
      }

      // Recupero ora il contenitore dei pulsanti delle pagine e relativi elementi al suo interno
      const root = this.element.nativeElement.querySelector('.k-pager-numbers');
      const pagerElements = root.querySelectorAll('li');

      let dotsFirstButton: any;
      let dotsLastButton: any;

      // Pulisco tutti gli elementi dalla classe che imposta l'errore e identifico il primo e ultimo pulsante con i puntini (se presenti)
      let testIndex = 0;
      for (const pagerElement of pagerElements) {
        const button = pagerElement.querySelector('button');
        if (button.innerHTML == "...") {
          if (testIndex == 0) {
            dotsFirstButton = button;
            // Al pulsante recuperato aggancio al click il ricontrollo degli errori
            dotsFirstButton.removeEventListener("click", this.checkLastPagerErrorConfig);;
            dotsFirstButton.addEventListener("click", this.checkLastPagerErrorConfig.bind(this), true);
          } else {
            dotsLastButton = button;
            // Al pulsante recuperato aggancio al click il ricontrollo degli errori
            dotsLastButton.removeEventListener("click", this.checkLastPagerErrorConfig);;
            dotsLastButton.addEventListener("click", this.checkLastPagerErrorConfig.bind(this), true);
          }
        }
        button.classList.remove('error');
        testIndex++;
      }

      //Ora per ogni pagina con errori, cerco il relativo pulsante e gli imposto la classe error...
      for (const page of pagerErrorConfig.pagesInError) {
        let foundButtonPage: boolean = false;
        let firstButtonIndex = -1;
        let itemIndex = -1;
        const pageIndex = page + 1;


        for (const pagerElement of pagerElements) {
          const button = pagerElement.querySelector('button');

          if (button.innerHTML != '...') {
            itemIndex = parseInt(button.innerHTML);


            if (firstButtonIndex == -1 && itemIndex != NaN) {
              firstButtonIndex = itemIndex;
            }

            if (pageIndex == itemIndex) {
              foundButtonPage = true;
              button.classList.add('error');
            }
          }
        }

        // se non ho trovato il pulsante relativo alla pagina, devo capire se impostare la classe errore sul primo o sull'ultimo pulsante con i puntini
        if (!foundButtonPage) {
          if (firstButtonIndex > -1 && pageIndex < firstButtonIndex && dotsFirstButton != null) { // se è una pagina con errori precedente al primo pulsante pagina
            dotsFirstButton.classList.add('error');
          }

          if (itemIndex > -1 && pageIndex > itemIndex && dotsLastButton != null) { // se è una pagina con errori successiva all'ultimo pulsante pagina
            dotsLastButton.classList.add('error');
          }
        }


      }

    }
  }

}
