import { ChangeDetectionStrategy, Component, Injector, Input, TemplateRef } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { CoreLib_Classes_StringHelper } from 'core';
import { BaseComponent } from '../_base/base.component';




@Component({
  selector: 'app-label-box',
  templateUrl: './label-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelBoxComponent extends BaseComponent {

  // BIGGER
  @Input()
  public bigger: boolean = false;

  // COLOR
  @Input()
  public color: string;

  // HEADER
  @Input()
  public header: string;

  // TEXT
  private _text: any;

  @Input()
  public set text(val: any) {
    this._text = val;
  }

  public get text() {
    if (this.type == 'date') {
      if (this.format != null) {
        return this.intlService.formatDate(new Date(this._text), this.format);
      } else {
        return this.intlService.formatDate(new Date(this._text), 'd');
      }
    } else if (this.type == 'number') {
      if (this.format != null) {
        return this.intlService.formatNumber(parseFloat(this._text), this.format);
      } else {
        return this.intlService.formatNumber(parseFloat(this._text), 'n2');
      }
    } else if (this.type == 'boolean') {
      if (JSON.parse(this._text)) {
        return this.localizeByCommon['YES'];
      } else {
        return this.localizeByCommon['NO'];
      }
    } else {
      if (CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this._text)) {
        return '-';
      } else {
        if (this.format != null) {
          return this.intlService.format(this._text, this.format);
        } else {
          return this._text;
        }
      }
    }

  }

  // TYPE
  @Input()
  public type: 'string' | 'number' | 'date' | 'boolean' = 'string';


  // FORMAT
  @Input()
  public format: string;

  @Input()
  template: TemplateRef<any>;

  @Input()
  public context: any = {};
  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private intlService: IntlService) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  //#endregion

}
