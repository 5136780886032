import { Injectable } from '@angular/core';



@Injectable({ providedIn: 'root' })
export class LocalizationService {

  //#region Declarations...

  //#endregion

  //#region Properties...

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  public getLocale(): string {
    let userLang = navigator.language || navigator.languages[0];

    if (userLang.indexOf('-') > -1) {
      userLang = userLang.substring(0, userLang.indexOf('-'));
    }
    return userLang;
  }

  //#endregion

}
