




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonPushNotificationGetUnreadedCountResponse extends BaseTokenResponse  {
  
  public unreadedCount: number = 0;
  
}



