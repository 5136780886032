<div  class="grid-filter-time">
  <kendo-timepicker [placeholder]="(operator == 'between') ? 'Dalle' : null" [disabled]="operator == 'isempty' || operator == 'isnotempty'" [(value)]="fromValue" [format]="format" (valueChange)="onChange($event)">
  </kendo-timepicker>

  <div [hidden]="operator != 'between'">
    <kendo-timepicker [placeholder]="(operator == 'between') ? 'alle' : null" [(value)]="toValue" [format]="format" (valueChange)="onChange($event)">
    </kendo-timepicker>
  </div>
  <span class="warning-span" *ngIf="error != null && error != ''">{{error}}</span>
</div>

<kendo-grid-filter-cell-operators [value]="operator" [operators]="customOperators" [showButton]="hasFilters"
  (clear)="clearFilter(true)" (valueChange)="operatorChange($event)">
</kendo-grid-filter-cell-operators>
