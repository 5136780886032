<div class="app-numeric-up-down" [class.big]="bigger" [class.error]="hasError" [class.hideheader]="hideheader">
  <div [id]="erroranchor"></div>
  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" 
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory" *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>
  
  <div [hidden]="disabled" class="d-flex">
    <div [hidden]="!showSpinners">
      <app-button [icon]="'minus'" [inverted]="true" (buttonClick)="decrement()" [inverted]="true">
      </app-button>
    </div>
    <div class="flex-grow-1">
      <kendo-numerictextbox [decimals]="decimals"
                            [format]="format"
                            [min]="min"
                            [max]="max"
                            [(value)]="value"
                            [autoCorrect]="autoCorrect"
                            (keyup)="onKeyUp($event)"
                            [spinners]="showSpinners"
                            #input_ref></kendo-numerictextbox>
                            <!-- <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span class="mandatory" *ngIf="mandatory"  [innerHtml]="mandatoryText"></span> </span> -->
    </div>
    <div [hidden]="!showSpinners">
      <app-button [icon]="'plus'" [inverted]="true" (buttonClick)="increment()">
      </app-button>
    </div>
  </div>
  <div class="read-only-input-span" [hidden]="!disabled">{{readOnlyValue}}</div>
  <span class="error-span" [innerHtml]="error"></span>
</div>
