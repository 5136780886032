<app-toolbar [formReference]="formReference  || 'default'" [showRightPopupAnchor]="showRightPopupAnchor"
  [rightPopupAnchorIcon]="rightPopupAnchorIcon">
  <ng-container class="left-container">

    <app-button [text]="localizeByCommon['BTN_REFRESH']" [icon]="'refresh'" (buttonClick)="gridView.loadItems()"
      [buttonClass]="'k-button-white'" *ngIf="showGridActions"></app-button>

    <app-button [text]="localizeByCommon['BTN_CLEAR_FILTER']" [icon]="'filter-clear'"
      (buttonClick)="gridView.clearFilters()" [buttonClass]="'k-button-white'"
      *ngIf="showGridActions && gridView.state.filter != null && gridView.state.filter.filters != null && gridView.state.filter.filters.length > 0">
    </app-button>

    <ng-content select=".left-container"></ng-content>

  </ng-container>
  <ng-container class="right-container">

    <ng-content select=".right-container"></ng-content>


  </ng-container>
  <ng-container class="right-container-popup">

    <app-button [text]="localizeByCommon['BTN_EXPORT_EXCEL']" [icon]="'file-excel'" [buttonClass]="'k-button-white'"
      (buttonClick)="gridView.exportExcel()" *ngIf="showGridActions">
    </app-button>
    <app-button [text]="localizeByCommon['BTN_SORTSETTINGS']" [icon]="'arrows-swap'" [buttonClass]="'k-button-white'"
      (buttonClick)="openSortSettings()" *ngIf="showGridActions">
    </app-button>
    <app-button [text]="localizeByCommon['BTN_FILTERSETTINGS']" [icon]="'filter'" [buttonClass]="'k-button-white'"
      (buttonClick)="openFilterSettings()" *ngIf="showGridActions">
    </app-button>
    <app-button [text]="localizeByCommon['BTN_COLUMNORDERSETTINGS']" [icon]="'columns'" [buttonClass]="'k-button-white'"
      (buttonClick)="openColumnOrderSettings()" *ngIf="showGridActions && !isSmallDevice">
    </app-button>

    <ng-content select=".right-container-popup"></ng-content>

  </ng-container>
</app-toolbar>

<kendo-dialog [title]="localizeByCommon['GRID_DIALOG_FILTERSETTINGS_TITLE']" [minWidth]="(isSmallDevice) ? 0 : 800"
  *ngIf="isFilterSettingsOpen" class="k-dialog-fix k-dialog-noscroll" (close)="closeFilterSettings()">
  <div class="container-fluid">

    <div class="row">
      <div class="col-md-6" *ngFor="let col of filterColumns">
        <app-label-box [header]="col.description" [template]="coltemplate">
          <ng-template #coltemplate>
            <app-grid-dynamic-filter-cell [column]="col.column" [filter]="col.filter"
              [columnDefinition]="col.columnDefinition" style="display: block;"
              [style.minWidth]="(col.columnDefinition.filterType == 'date') ? '200px' : null"
              [parentContext]="gridView.parentContext" [view]="gridView"></app-grid-dynamic-filter-cell>
          </ng-template>
        </app-label-box>

      </div>
    </div>

  </div>
  <kendo-dialog-actions>

    <app-button (buttonClick)="closeFilterSettings()" [text]="localizeByCommon['BTN_CANCEL']"
      [buttonClass]="'k-button-white'" class="btn-cancel">
    </app-button>

    <app-button (buttonClick)="clearFilters()" [text]="localizeByCommon['BTN_CLEAR_FILTER']"
      [buttonClass]="'k-button-red'">
    </app-button>

    <app-button (buttonClick)="clearAndSaveFilters()" [text]="localizeByCommon['BTN_CLEARANDSAVE_FILTER']"
      [buttonClass]="'k-button-red'">
    </app-button>

    <app-button (buttonClick)="applyFilterSettings()" [text]="localizeByCommon['BTN_APPLY']">
    </app-button>

    <app-button (buttonClick)="applyAndSaveFilterSettings()" [text]="localizeByCommon['BTN_APPLYANDSAVE']">
    </app-button>

  </kendo-dialog-actions>
  <app-loader [imageSrc]="'/assets/images/logo-bird.png'"></app-loader>
</kendo-dialog>

<kendo-dialog [title]="localizeByCommon['GRID_DIALOG_SORTSETTINGS_TITLE']" [minWidth]="(isSmallDevice) ? 0 : 800"
  *ngIf="isSortSettingsOpen" class="k-dialog-fix k-dialog-noscroll" (close)="closeSortSettings()">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5">
        <h3 style="height:40px">{{localizeByCommon['GRID_DIALOG_SORTSETTINGS_LEFTTITLE']}}</h3>
        <div fixToParentHeight="'left'" class="desk-scrollable-y" [fixToParentHeightEnabled]="!isSmallDevice">
          <div class="card-item standard-bottom-margin"
            *ngFor="let col of selectionColumns | sortBy: 'asc':'selectionOrderIndex'">
            <div class="left-side my-auto font-weight-bold" style="max-width:160px; white-space:normal">
              <!-- {{col.selectionOrderIndex}})&nbsp; -->
              {{col.description}}
            </div>
            <div class="right-side">
              <div class="float-right">
                <app-button [icon]="'plus'" [buttonClass]="'k-button-green'" [inverted]="true" class="ml-2"
                  (buttonClick)="addSort(col)"></app-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <h3 style="height:40px">{{localizeByCommon['GRID_DIALOG_SORTSETTINGS_RIGHTTITLE']}}</h3>
        <div fixToParentHeight="'right'" class="desk-scrollable-y" [fixToParentHeightEnabled]="!isSmallDevice">
          <div class="card-item standard-bottom-margin"
            *ngFor="let col of selectedColumns | sortBy: 'asc':'selectedOrderIndex'">
            <div class="left-side my-auto font-weight-bold" style="max-width:160px; white-space:normal">
              <!-- {{col.selectedOrderIndex}})&nbsp; -->
              {{col.description}}
            </div>
            <div class="right-side">
              <div class="float-right sort-button-area">
                <app-button (buttonClick)="changeSortDir(col)" [buttonClass]="'k-button-black'" [text]="col.dir"
                  [width]="50" class="ml-2"></app-button>
                <app-button [icon]="'arrow-up'" (buttonClick)="moveUpSort(col)" [inverted]="true" class="ml-2"
                  [disabled]="col.selectedOrderIndex == 1"></app-button>
                <app-button [icon]="'arrow-down'" (buttonClick)="moveDownSort(col)" [inverted]="true" class="ml-2"
                  [disabled]="col.selectedOrderIndex == selectedColumns.length"></app-button>
                <app-button [icon]="'trash'" [buttonClass]="'k-button-red'" [inverted]="true"
                  (buttonClick)="  removeSort(col)" [disabled]="selectedColumns.length == 1" class="ml-2"></app-button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <kendo-dialog-actions>

    <app-button (buttonClick)="closeSortSettings()" [text]="localizeByCommon['BTN_CANCEL']"
      [buttonClass]="'k-button-white'">
    </app-button>

    <app-button (buttonClick)="clearSortSettings()" [text]="localizeByCommon['BTN_RESTOREDEFAULT']"
      [buttonClass]="'k-button-red'">
    </app-button>

    <app-button (buttonClick)="applySortSettings()" [text]="localizeByCommon['BTN_APPLY']">
    </app-button>

    <app-button (buttonClick)="applyAndSaveSortSettings()" [text]="localizeByCommon['BTN_APPLYANDSAVE']">
    </app-button>

  </kendo-dialog-actions>
  <app-loader [imageSrc]="'/assets/images/logo-bird.png'"></app-loader>
</kendo-dialog>


<kendo-dialog [title]="localizeByCommon['GRID_DIALOG_COLUMNORDERSETTINGS_TITLE']" [minWidth]="(isSmallDevice) ? 0 : 800"
  *ngIf="isColumnOrderSettingsOpen" class="k-dialog-fix k-dialog-noscroll" (close)="closeColumnOrderSettings()">

  <div class="container-fluid">
    <div class="help-span">
      {{localizeByCommon['GRID_DIALOG_COLUMNORDERSETTINGS_HELP']}}
    </div>
    <div fixToParentHeight="true" class="desk-scrollable-y" #sortableContainer>
      <kendo-sortable [kendoSortableBinding]="orderedColumns"  (dragEnd)="onDragEnd($event)" [animation]="false" itemClass="col-order-drag-item"
        activeItemClass="col-order-drag-item active">
        <ng-template let-item="item">
          <div>
            {{ item.description }}
          </div>
        </ng-template>
      </kendo-sortable>
    </div>
  </div>
  <!-- <div class="card-item standard-bottom-margin"
    *ngFor="let col of orderedColumns | sortBy: 'asc':'selectionOrderIndex'">
    <div class="left-side my-auto font-weight-bold">      
      {{col.description}}
    </div>
    <div class="right-side">
      <div class="float-right">
        <app-button [icon]="'arrow-up'" (buttonClick)="moveUpOrder(col)" [inverted]="true" class="ml-2"
          [disabled]="col.selectionOrderIndex == 1"></app-button>
        <app-button [icon]="'arrow-down'" (buttonClick)="moveDownOrder(col)" [inverted]="true" class="ml-2"
          [disabled]="col.selectionOrderIndex == orderedColumns.length"></app-button>        
      </div>
    </div>
  </div> -->

  <kendo-dialog-actions>

    <app-button (buttonClick)="closeColumnOrderSettings()" [text]="localizeByCommon['BTN_CANCEL']"
      [buttonClass]="'k-button-white'">
    </app-button>

    <app-button (buttonClick)="clearColumnOrderSettings()" [text]="localizeByCommon['BTN_RESTOREDEFAULT']"
      [buttonClass]="'k-button-red'">
    </app-button>


    <app-button (buttonClick)="applyAndSaveColumnOrderSettings()" [text]="localizeByCommon['BTN_APPLYANDSAVE']">
    </app-button>

  </kendo-dialog-actions>
  <app-loader [imageSrc]="'/assets/images/logo-bird.png'"></app-loader>
</kendo-dialog>