import { Guid  as GuidHelper } from 'guid-typescript';

export class Guid {

  public static Empty: string = '00000000-0000-0000-0000-000000000000';
  public static IsValid(guid: string) {
    return guid != null && guid != '' && guid != Guid.Empty && guid.length == 36 && guid != 'cancel';
  }
  public static newId() {
    return GuidHelper.create().toString();
  }
}
