








import { ReportDataSourceDataResponse } from './ReportDataSourceDataResponse';
export class ReportDataResponse  {
      
    public reportUid: string = '00000000-0000-0000-0000-000000000000';    
    public description: string = '';    
    public dataSources: ReportDataSourceDataResponse[] = [];    
}

