




    
export class CommonTranslationGetAllByFormDataResponse   {
  
  public formObject: string = '';
  
  public description: string = '';
  
}



