import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChange, ViewChild } from '@angular/core';
import { CoreLib_Classes_StringHelper } from 'core';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';




@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent extends BaseInputControlComponent {

  @ViewChild('input_ref') inputField: ElementRef;

  //#region Properties...


  @Input()
  public textTransformation: 'uppercase' | 'lowercase' | 'capitalizefirstletter' | 'capitalize';


  private _text: string;
  @Input()
  get text() {
    return this._text;
  }
  @Output() textChange = new EventEmitter();
  set text(val) {
    this._text = val;
    this.setHeaderHidden();
    this.textChange.emit(val);
    this.setReadOnlyValue();

  }

  @Input()
  public autoSize: boolean = true;

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, private ref: ChangeDetectorRef) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  onKeyUp(event: KeyboardEvent) {
    this.text = CoreLib_Classes_StringHelper.transformText(this.text, this.textTransformation);
    this.ref.detectChanges();
  }

  public override setHeaderHidden() {
    this.headerhidden = this.disabled == false && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.text);
    this.ref.detectChanges();
  }

  public insertAtCaret(text: string) {
    const value = this.inputField.nativeElement.value;

    // save selection start and end position
    const start = this.inputField.nativeElement.selectionStart;
    const end = this.inputField.nativeElement.selectionEnd;

    // update the value with our text inserted
    this.text = value.slice(0, start) + text + value.slice(end);

    // update cursor to be at the end of insertion
    this.inputField.nativeElement.selectionStart = this.inputField.nativeElement.selectionEnd = start + text.length;
  }

  public override setReadOnlyValue() {
    if (this.disabled && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.text)) {
      this.readOnlyValue = '-';
    } else {
      this.readOnlyValue = this.text;
    }

    this.ref.detectChanges();
  }

  //#endregion

}
