import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
  selector: '[fixRowsHeight]',
})
export class FixRowsHeightDirective implements AfterViewInit, OnDestroy {

  private resizeSubscription: Subscription;

  private resetSubscription: Subscription;

  private layoutSubscription: Subscription;

  constructor(private element: ElementRef, private ngZone: NgZone) {

    this.resizeSubscription = fromEvent(window, 'resize').pipe(
      debounceTime(200))
      .subscribe((event) => {
        this.reset();
        setTimeout(() => this.layout(), 300);
      });
  }

  @Input() public fixRowsHeight: string;



  ngAfterViewInit(): void {
    this.layout();
  }
  ngOnDestroy(): void {
    this.resizeSubscription?.unsubscribe();    
    this.resetSubscription?.unsubscribe();
    this.layoutSubscription?.unsubscribe();
  }

  private reset() {
    this.resetSubscription?.unsubscribe();
    this.resetSubscription = this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      const element = this.element;
      element.nativeElement.style.position = 'relative';

      for (const e of element.nativeElement.children) {
        e.style.position = '';
        e.style.left = '';
        e.style.right = '';
        e.style.top = '';
        e.style.height = '';
      }
    });
  }

  private layout() {
    this.layoutSubscription = this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      const element = this.element;
      element.nativeElement.style.position = 'relative';
      const rowDefinitions: string[] = this.fixRowsHeight.split(',');
      let availableSpace = element.nativeElement.offsetHeight;
      let countStars = 0;
      let childIndex = 0;
      for (const e of element.nativeElement.children) {
        if (rowDefinitions[childIndex] != null) {
          if (rowDefinitions[childIndex] === '*') {
            countStars++;
          } else if (rowDefinitions[childIndex] === 'auto') {
            availableSpace -= e.offsetHeight;
            e.style.height = e.offsetHeight + 'px';
          }
        }
        childIndex++;
      }
      childIndex = 0;
      for (const e of element.nativeElement.children) {
        if (rowDefinitions[childIndex] != null) {
          if (rowDefinitions[childIndex] === '*') {
            e.style.height = (availableSpace / countStars) + 'px';
            availableSpace -= availableSpace / countStars;
            countStars--;
          }
        }
        childIndex++;
      }
      let calculatedTop = 0;
      for (const e of element.nativeElement.children) {
        e.style.position = 'absolute';
        e.style.left = '0';
        e.style.right = '0';
        if (calculatedTop === 0) {
          e.style.top = '0';
        } else {
          e.style.top = calculatedTop + 'px';
        }
        calculatedTop += e.offsetHeight;
      }
    });
  }
}
