import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';
import { CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_FileService, CoreLib_Services_Common_PopupService, CoreLib_Services_Common_TranslationService, CoreLib_Services_Http_Base_BaseService } from 'core';
import { ReportServiceConfig } from '../../classes/ReportServiceConfig';
import { ReportDataResponse, ReportDataSourceDataRequest, ReportGetListByAreaAndEntityUidRequest, ReportGetListResponse, ReportGetRequest, ReportGetResponse } from 'dto';



@Injectable({ providedIn: 'root' })
export class ReportService {

  //#region Declarations...

  public config: ReportServiceConfig;

  private _objectForGettingParameters: any;

  private _entityUid: string;

  private _fileName: string;

  private iFrame: HTMLIFrameElement;

  private downloadBlob: Blob;

  //#endregion

  //#region Constructors...

  constructor(private baseService: CoreLib_Services_Http_Base_BaseService, private translationService: CoreLib_Services_Common_TranslationService, private popupService: CoreLib_Services_Common_PopupService, private router: Router, private ngProgress: NgProgress) {
    this.config = new ReportServiceConfig();
  }

  //#endregion

  //#region Methods...

  public async reportGetListByAreaAndEntityUid(request: ReportGetListByAreaAndEntityUidRequest): Promise<ReportGetListResponse> {
    return this.baseService.post<ReportGetListResponse>(this.baseService.baseUrl + 'common/reportGetListByAreaAndEntityUid', request).toPromise();
  }

  public async reportGet(request: ReportGetRequest): Promise<ReportGetResponse> {
    return this.baseService.post<ReportGetResponse>(this.baseService.baseUrl + 'common/reportGet', request).toPromise();
  }


  public async loadReports(applicationAreaCode: string, applicationAreaEntityUid: string, objectForGettingParameters: any, entityUid: string, fileName: string) {

    this._objectForGettingParameters = objectForGettingParameters;
    this._entityUid = entityUid;
    this._fileName = fileName;

    const request = new ReportGetListByAreaAndEntityUidRequest();
    request.applicationAreaCode = applicationAreaCode;
    request.applicationAreaEntityUid = applicationAreaEntityUid;

    const response = await this.reportGetListByAreaAndEntityUid(request);

    if (response != null) {
      if (response.reports.length == 0) {
        this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Warning, this.translationService.localizeByCommon['NO_REPORTS_BY_AREA']);
        return;
      }

      this.config.isCloudPrintEnabled = response.isCloudPrintEnabled;

      if (response.isCloudPrintEnabled || response.reports.length > 1) {

        this.config.reports = [];

        for (const item of response.reports) {
          this.config.reports.push({ report: item, isSelected: false });
        }

        this.config.reports[0].isSelected = true;

        this.config.showReportSelection = true;
      } else {
        await this.printReport(response.reports[0], false);
      }
    }
  }

  public async printReport(report: ReportDataResponse, isForQueue: boolean) {

    this.config.showReportSelection = false;
    this.config.showPrintPreview = false;
    this.config.isDirectPrintEnabled = false;
    this.config.isPrintEvaluated = false;

    const progress = this.ngProgress.ref();
    progress.start();

    const request = new ReportGetRequest();
    request.reportUid = report.reportUid;
    request.dataSources = [];
    request.isForQueue = isForQueue;
    request.entityUid = this._entityUid;
    request.reportFileName = CoreLib_Services_Common_FileService.parseFileName(this._fileName);

    for (const dataSource of report.dataSources) {
      const p = new ReportDataSourceDataRequest();
      p.dataSourceUid = dataSource.dataSourceUid;
      p.dataSourceName = dataSource.dataSourceName;
      p.dataSourceParameterUid = dataSource.dataSourceParameterUid;
      p.parameterName = dataSource.parameterName;
      p.parameterValue = this._objectForGettingParameters[dataSource.deskParameterValueIdentifier];
      request.dataSources.push(p);
    }

    const response = await this.reportGet(request);
    if (response != null) {
      if (request.isForQueue) {
        // this.popupService.showMessage(PopupTitlesTypes.Info, this.translationService.localizeByCommon["REPORT_QUEUED_SUCCESS"]);
      } else {
        this.printReportResponse(response, this._fileName);
      }
    }
  }

  public printReportResponse(response: ReportGetResponse, fileName: string) {
    this._fileName = fileName;
    const blob = CoreLib_Services_Common_FileService.getBlobFromByteArray(response.reportData, 'application/pdf');
    this.downloadBlob = CoreLib_Services_Common_FileService.getBlobFromByteArray(response.reportData, 'octet/stream');

    const blob_url = URL.createObjectURL(blob);

    this.config.showPrintPreview = true;

    this.findIframe(blob_url);
  }

  private findIframe(blob_url: string) {
    this.iFrame = document.querySelector('#pdf-hidden-container') as HTMLIFrameElement;
    if (this.iFrame == null) {
      setTimeout(() => this.findIframe(blob_url), 200);
      return;
    }
    this.iFrame.src = blob_url;
    this.config.isPrintEvaluated = true;
  }

  public download() {
    const url = window.URL.createObjectURL(this.downloadBlob);

    CoreLib_Services_Common_FileService.downloadFile(url, this._fileName + '.pdf');

    this.config.showPrintPreview = false;
  }

  //#endregion

}
