







export class CoreConstants   {

  public static readonly DESKGRID_NOPAGING: number  = 1000000;

  
  static DeskGridColumnFilterTypes = class {
    
    public static readonly NONE: string  = 'none';
    
    public static readonly TEXT: string  = 'text';
    
    public static readonly CODE: string  = 'code';
    
    public static readonly NUMERIC: string  = 'numeric';
    
    public static readonly BOOLEAN: string  = 'boolean';
    
    public static readonly DATE: string  = 'date';
    
    public static readonly TIME: string  = 'time';
    
    public static readonly MULTISELECT: string  = 'multiselect';
    
  }
  static DeskGridColumnFilterOperators = class {
    
    public static readonly STARTSWITH: string  = 'startswith';
    
    public static readonly CONTAINS: string  = 'contains';
    
    public static readonly DOESNOTCONTAIN: string  = 'doesnotcontain';
    
    public static readonly ENDSWITH: string  = 'endswith';
    
    public static readonly BETWEEN: string  = 'between';
    
    public static readonly EQUAL: string  = 'eq';
    
    public static readonly NOTEQUAL: string  = 'neq';
    
    public static readonly GREATHEROREQUALSTO: string  = 'gte';
    
    public static readonly GREATHERTO: string  = 'gt';
    
    public static readonly LESSOREQUALSTO: string  = 'lte';
    
    public static readonly LESSTO: string  = 'lt';
    
    public static readonly ISEMPTY: string  = 'isempty';
    
    public static readonly ISNOTEMPTY: string  = 'isnotempty';
    
  }
  static FilterOperators = class {
    
    public static readonly NONE: number  = 0;
    
    public static readonly EQ: number  = 1;
    
    public static readonly NOTEQ: number  = 2;
    
    public static readonly STARTSWITH: number  = 3;
    
    public static readonly CONTAINS: number  = 4;
    
    public static readonly ENDSWITH: number  = 5;
    
    public static readonly NOTSTARTSWITH: number  = 6;
    
    public static readonly NOTCONTAINS: number  = 7;
    
    public static readonly NOTENDSWITH: number  = 8;
    
    public static readonly GREATHERTHAN: number  = 9;
    
    public static readonly GREATHERTHANOREQUALSTO: number  = 10;
    
    public static readonly LESSTHAN: number  = 11;
    
    public static readonly LESSTHANOREQUALSTO: number  = 12;
    
    public static readonly IN: number  = 13;
    
    public static readonly NOTIN: number  = 14;
    
    public static readonly BETWEEN: number  = 15;
    
    public static readonly NOTBETWEEN: number  = 16;
    
    public static readonly ISNULL: number  = 17;
    
    public static readonly ISNOTNULL: number  = 18;
    
  }
  static FilterConvertFunctions = class {
    
    public static readonly NONE: string  = '';
    
    public static readonly CONVERTTODATE: string  = 'ConvertToDate';
    
  }
  static DeskMessages = class {
    
    public static readonly LOGINERROR: string  = 'LOGIN_ERROR';
    
    public static readonly LOGINFAILED: string  = 'LOGIN_FAILED';
    
    public static readonly USERLOGGED: string  = 'USER_LOGGED';
    
    public static readonly LOADED: string  = 'LOADED';
    
    public static readonly ERROR: string  = 'ERROR';
    
    public static readonly OPENMODALCRUD: string  = 'OPEN_MODAL_CRUD';
    
    public static readonly CLOSEMODALCRUD: string  = 'CLOSE_MODAL_CRUD';
    
    public static readonly MODALCRUDRESULT: string  = 'MODAL_CRUD_RESULT';
    
    public static readonly TOKENEXPIRED: string  = 'TOKEN_EXPIRED';
    
    public static readonly FILEREQUESTED: string  = 'FILE_REQUESTED';
    
    public static readonly FILERECEIVED: string  = 'FILE_RECEIVED';
    
    public static readonly DOWNLOADREQUESTED: string  = 'DOWNLOAD_REQUESTED';
    
    public static readonly DOWNLOADRECEIVED: string  = 'DOWNLOAD_RECEIVED';
    
    public static readonly DOWNLOADCOMPLETED: string  = 'DOWNLOAD_COMPLETED';
    
  }
  static DeskResults = class {
    
    public static readonly EDITSTARTED: string  = 'EDIT_STARTED';
    
    public static readonly EDITCANCELED: string  = 'EDIT_CANCELED';
    
    public static readonly SAVED: string  = 'SAVED';
    
    public static readonly INVALIDFORM: string  = 'INVALID_FORM';
    
    public static readonly ERROR: string  = 'ERROR';
    
    public static readonly MODALCRUDRESULTRECEIVED: string  = 'MODAL_CRUD_RESULT_RECEIVED';
    
    public static readonly CANCLOSEMODAL: string  = 'CAN_CLOSE_MODAL';
    
    public static readonly RELOGGED: string  = 'RELOGGED';
    
    public static readonly CANNOTCLOSEMODAL: string  = 'CANNOT_CLOSE_MODAL';
    
    public static readonly INITCRUDSUCCEEDED: string  = 'INIT_CRUD_SUCCEEDED';
    
  }
}

