<div class="card-item">
  <div #target class="filter-operator-selector">
    <div class="badge badge-primary" [class.dark-background-color]="!hasFilter" [class.main-background-color]="hasFilter">
      {{operatorString}}
    </div>
  </div>
  <div class="right-side">
    <app-multi-select *ngIf="operator == operators.IN || operator == operators.NOTIN" [header]="header"
      [itemsSource]="itemsSource" [(selectedItems)]="selectedElements" [textField]="textField" [valueField]="valueField"
      [ondemand]="false">
    </app-multi-select>

  </div>
</div>
<kendo-contextmenu showOn="click" [target]="target" (select)="selectOperator($event)">
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_IN']" [data]="operators.IN"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_NOTIN']" [data]="operators.NOTIN"> </kendo-menu-item>
</kendo-contextmenu>
