<div class="page-login">
  <div class="rounded box">

    <div class="row">
      <div class="col-md-12">
        <h1>{{localizeByCommon['LOGIN_TITLE']}}</h1>

        <p class="text-center" *ngIf="isReloginMode">{{localizeByCommon['RELOGINREQUIRED']}}</p>

        <app-text-box [hidden]="isReloginMode" [header]="localizeByCommon['USERNAME']" [whiteSpaces]="false"
          [mandatory]="true" [(text)]="name" [maxLength]="255" #name_ref (inputKeyUp)="onKeyUp($event)"
          [textTransformation]="'lowercase'">
        </app-text-box>

        <label class="text-info" *ngIf="isReloginMode">{{localizeByCommon['RELOGIN_INSERTPASSWORD']}}&nbsp;<strong
            style="font-size:20px">{{name}}</strong></label>

        <app-text-box [header]="localizeByCommon['PASSWORD']" [(text)]="password" [maxLength]="20" [type]="'password'"
          [mandatory]="true" #password_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

        <div>
          <a routerLink="/lost-password">{{localizeByCommon['LOST_PASSWORD']}}</a>
        </div>

        <!-- <span class="error-span" *ngIf="loginFailedCount >= 3" [innerHtml]="loginFailedText"></span> -->

        <span class="error-span" *ngIf="loginFailed">{{errorText}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center mt-3">
        <app-button [type]="'submit'" (click)="login()" text="{{localizeByCommon['BTN_LOGIN']}}"
          [buttonClass]="'k-primary'"></app-button>
        <app-button (click)="goToHome()" text="{{localizeByCommon['BTN_BACK']}}"
          [buttonClass]="'k-secondary'"></app-button>
      </div>
    </div>

  </div>
</div>