import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactory, ComponentRef, Injector, OnInit, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ControlsLib_Components_Base_BaseComponent, ControlsLib_Directives_PopupHostDirective, ControlsLib_Services_Common_DeactivationService } from 'controls';
import { CoreLib_Classes_ExternalWebViewMessage, CoreLib_Services_Common_PopupService, CoreLib_Services_Common_WebViewHostService } from 'core';
import { CoreConstants } from 'dto';
import { lazyRoute } from 'app/app-routing.module';
import { PopupCrudViewHostConfiguration } from '@modules/_common/shared/common-shared/classes/PopupCrudViewHostConfiguration';


@Component({
  selector: 'app-view-host',
  templateUrl: './view-host.component.html',
})
export class ViewHostComponent extends ControlsLib_Components_Base_BaseComponent implements OnInit, AfterViewInit {

  //#region Declarations...

  private popupHost: ControlsLib_Directives_PopupHostDirective;

  private crudComponentFactory: ComponentFactory<any>;


  private viewContainerRef: ViewContainerRef;

  @ViewChild(ControlsLib_Directives_PopupHostDirective) set content(content: ControlsLib_Directives_PopupHostDirective) {
    this.popupHost = content;
  }


  //#endregion

  //#region Properties...

  //#endregion

  //#region Constructors...
  constructor(injector: Injector, private ref: ChangeDetectorRef, private deactivationService: ControlsLib_Services_Common_DeactivationService, private popupService: CoreLib_Services_Common_PopupService, private webViewHostService: CoreLib_Services_Common_WebViewHostService) {
    super(injector);

    window.desk.initView = this.initFromExternalContainer.bind(this);
    window.desk.canCloseModal = this.externalCanCloseModal.bind(this);
  }
  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
    this.webViewHostService.sendMessage(CoreConstants.DeskMessages.LOADED);
  }

  externalCanCloseModal() {
    this.zone.run(async () => {
      if (this.deactivationService.isInEditMode() || this.popupService.isModalOpen) {
        this.webViewHostService.sendResult(CoreConstants.DeskResults.CANNOTCLOSEMODAL);
      } else {
        this.webViewHostService.sendResult(CoreConstants.DeskResults.CANCLOSEMODAL);
      }
    });
  }

  async initFromExternalContainer(message: any) {

    this.zone.run(async () => {
      const configuration = message as PopupCrudViewHostConfiguration;

      if (configuration.dateCols != null) {
        for (const dateCol of configuration.dateCols) {
          configuration.componentParameters[dateCol] = new Date(configuration.componentParameters[dateCol]);
        }
      }

      this.crudComponentFactory = await this.lazyLoaderService.loadModule(
        (lazyRoute() as any)[configuration.routeName],
        configuration.componentName,
      );

      this.viewContainerRef = this.popupHost.viewContainerRef;

      this.viewContainerRef.clear();

      const factory = await this.crudComponentFactory;

      this.viewContainerRef.createComponent(factory);

      if (!(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
    this.webViewHostService.sendResult(CoreConstants.DeskResults.INITCRUDSUCCEEDED);
  }



  //#endregion
}
