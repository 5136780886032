import { Injectable } from '@angular/core';
import { BaseRequest, BaseTokenResponse, CommonReportEnabledFilterSchemasResponse, CommonAccountDeskGridStateSetColumnOrderSettingsRequest,CommonSettingsResponse, CommonAccountDeskGridStateSetColumnSizeSettingsRequest, CommonEvalRequest, CommonEvalResponse, CommonLoginRequest, CommonLoginResponse, CommonMenuResponse, CommonNotifyLogoutRequest, CommonNotifyReloginRequest, CommonPushNotificationGetUnreadedCountResponse, CommonPushNotificationGetUnreadedResponse, CommonPushNotificationSetReadedRequest, CommonPushNotificationSetReadedResponse, CommonRolePermissionResponse, CommonTranslationGetAllByFormRequest, CommonTranslationGetAllByFormResponse, CommonCrudEntityGetRequest } from 'dto';
import { CommonChangePasswordRequest, CommonChangePasswordResponse } from 'dto';
import 'rxjs/operator/catch';
import { BaseService } from './_base/base.service';

@Injectable({
  providedIn: 'root',
})
export class DeskCommonService {
  constructor(private baseService: BaseService) { }

  private areaName = 'common/';

  public async tokenValid(): Promise<object> {
    return this.baseService.http.get<object>(this.baseService.baseUrl + this.areaName + 'tokenValid').toPromise();
  }

  public async login(request: CommonLoginRequest): Promise<CommonLoginResponse> {
    return this.baseService.http.post<CommonLoginResponse>(this.baseService.baseUrl + this.areaName + 'login', request).toPromise();
  }

  public async changePassword(request: CommonChangePasswordRequest): Promise<CommonChangePasswordResponse> {
    return this.baseService.http.post<CommonChangePasswordResponse>(this.baseService.baseUrl + this.areaName + 'changePassword', request).toPromise();
  }

  public async rolePermission(request: BaseRequest): Promise<CommonRolePermissionResponse> {
    return this.baseService.post<CommonRolePermissionResponse>(this.baseService.baseUrl + this.areaName + 'rolePermission', request).toPromise();
  }

  public async settingGetAll(request: BaseRequest): Promise<CommonSettingsResponse> {
    return this.baseService.post<CommonSettingsResponse>(this.baseService.baseUrl + this.areaName + 'settingGetAll', request).toPromise();
  }

  public async accountSettingGetAll(request: BaseRequest): Promise<CommonSettingsResponse> {
    return this.baseService.post<CommonSettingsResponse>(this.baseService.baseUrl + this.areaName + 'accountSettingGetAll', request).toPromise();
  }

  public async notifyRelogin(request: CommonNotifyReloginRequest): Promise<object> {
    return this.baseService.post<object>(this.baseService.baseUrl + this.areaName + 'notifyRelogin', request).toPromise();
  }

  public async notifyLogout(request: CommonNotifyLogoutRequest): Promise<object> {
    return this.baseService.post<object>(this.baseService.baseUrl + this.areaName + 'notifyLogout', request).toPromise();
  }

  public async menu(request: BaseRequest): Promise<CommonMenuResponse> {
    return this.baseService.post<CommonMenuResponse>(this.baseService.baseUrl + this.areaName + 'menu', request).toPromise();
  }

  public async pushNotificationGetUnreaded(request: BaseRequest): Promise<CommonPushNotificationGetUnreadedResponse> {
    return this.baseService.post<CommonPushNotificationGetUnreadedResponse>(this.baseService.baseUrl + this.areaName + 'pushNotificationGetUnreaded', request).toPromise();
  }

  public async pushNotificationGetUnreadedCount(request: BaseRequest): Promise<CommonPushNotificationGetUnreadedCountResponse> {
    return this.baseService.post<CommonPushNotificationGetUnreadedCountResponse>(this.baseService.baseUrl + this.areaName + 'pushNotificationGetUnreadedCount', request).toPromise();
  }

  public async pushNotificationSetReaded(request: CommonPushNotificationSetReadedRequest): Promise<CommonPushNotificationSetReadedResponse> {
    return this.baseService.post<CommonPushNotificationSetReadedResponse>(this.baseService.baseUrl + this.areaName + 'pushNotificationSetReaded', request).toPromise();
  }

  public async translationGetAllByFormRequest(cultureName: string, formReference: string): Promise<CommonTranslationGetAllByFormResponse> {
    const message: CommonTranslationGetAllByFormRequest = new CommonTranslationGetAllByFormRequest();
    message.cultureName = cultureName;
    message.formReference = formReference;
    return this.baseService.post<CommonTranslationGetAllByFormResponse>(this.baseService.baseUrl + this.areaName + 'translationGetAllByForm', message).toPromise();
  }

  public async eval(request: CommonEvalRequest): Promise<CommonEvalResponse> {
    return this.baseService.post<CommonEvalResponse>(this.baseService.baseUrl + this.areaName + 'eval', request).toPromise();
  }
  public async accountDeskGridStateSetColumnOrderSettings(request: CommonAccountDeskGridStateSetColumnOrderSettingsRequest): Promise<BaseTokenResponse> {
    return this.baseService.post<BaseTokenResponse>(this.baseService.baseUrl + this.areaName + 'accountDeskGridStateSetColumnOrderSettings', request).toPromise();
  }
  public async accountDeskGridStateSetColumnSizeSettings(request: CommonAccountDeskGridStateSetColumnSizeSettingsRequest): Promise<BaseTokenResponse> {
    return this.baseService.post<BaseTokenResponse>(this.baseService.baseUrl + this.areaName + 'accountDeskGridStateSetColumnSizeSettings', request).toPromise();
  }
  public async reportEnabledFilterSchemas(request: CommonCrudEntityGetRequest): Promise<CommonReportEnabledFilterSchemasResponse> {
    return this.baseService.post<CommonReportEnabledFilterSchemasResponse>(this.baseService.baseUrl + this.areaName + 'reportEnabledFilterSchemas', request).toPromise();
  }
}
