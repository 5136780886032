


import { IBaseUidDataResponse } from './IBaseUidDataResponse';
export class BaseUidDataResponse   implements IBaseUidDataResponse {

  public uid: string =  '00000000-0000-0000-0000-000000000000';
}





