import { AfterViewInit, ChangeDetectorRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, Directive } from '@angular/core';
import { NgProgress } from '@ngx-progressbar/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { CoreLib_Classes_Guid } from 'core';
import { BaseCrudEntityWrapper } from 'dto';
import { Subscription } from 'rxjs';

import { CrudViewModes } from '../../enums/CrudViewModes';
import { IBaseCrudContract } from '../../interfaces/IBaseCrudContract';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { ICrudEntity } from '../../interfaces/ICrudEntity';
import { ICrudLoaded } from '../../interfaces/ICrudLoaded';
import { BaseHashedCrudComponent } from './base-hashed-crud.component';
import { CrudActivatedModes } from '../../enums/CrudActivatedModes';


@Directive()
export class BaseCrudComponent<TDto> extends BaseHashedCrudComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy, IBaseCrudContract {

  //#region Declarations...

  protected scrollToService: ScrollToService;
  private onSelectTabSubscription: Subscription;

  protected isInEditMode = false;
  public lastViewModeProcessed: string;

  //private oldRaisedUid: string = 'fake';
  //#endregion

  //#region Properties...
  private _id: string;
  @Input()
  public get id() {
    return this._id;
  }
  public set id(val) {
    this._id = val;
  }

  @Input()
  public get uid() {
    return this._uid;
  }
  public set uid(val) {
    this._uid = val;
  }

  // DATAWRAPPER
  private _inputDataWrapper: ICrudEntity;
  @Input()
  public get inputDataWrapper() {
    return this._inputDataWrapper;
  }
  public set inputDataWrapper(val) {
    if (val != null) {
      this._inputDataWrapper = val;
      this.inputDataWrapperChange.emit(this.inputDataWrapper);
    }
  }
  @Output() inputDataWrapperChange = new EventEmitter();


  public get dataWrapper(): BaseCrudEntityWrapper<TDto> {
    return this.inputDataWrapper as BaseCrudEntityWrapper<TDto>;
  }

  public set dataWrapper(val) {
    this.inputDataWrapper = val;
  }


  private ngProgress: NgProgress;

  private _crudView: IBaseCrudViewContract;
  @Input()
  public get crudView() {
    return this._crudView;
  }
  public set crudView(val) {
    this._crudView = val;

    if (val != null) {
      if (this.onSelectTabSubscription != null) {
        this.onSelectTabSubscription.unsubscribe();
      }

      this.onSelectTabSubscription = val.onSelectTab.subscribe(async (panelName) => {
        if (panelName == this.id && this.dataWrapper.isLoaded) {
          await this.reActivated();
          await this.activated(CrudActivatedModes.Selection);
          this.injector.get(ChangeDetectorRef).detectChanges();
        }
      });
    }
  }

  public isInitSucceded: boolean = false;
  public isInitialized: boolean = false;


  // UID
  private _uid: string = 'fake';

  public instance: TDto;

  public isUidBinding: boolean = true;

  //#endregion

  //#region Constructors...
  constructor(injector: Injector) {
    super(injector);

    this.scrollToService = this.injector.get(ScrollToService);
    this.ngProgress = this.injector.get(NgProgress);

    const dtw = new BaseCrudEntityWrapper<TDto>();
    this.setEmptyInstance();
    dtw.dataItem = this.instance;
    dtw.errors = {};

    this.inputDataWrapper = dtw;
  }
  //#endregion

  //#region Methods...
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.onSelectTabSubscription != null) {
      this.onSelectTabSubscription.unsubscribe();
    }
  }



  override async ngOnInit() {
    await super.ngOnInit();
    this.isInitSucceded = true;
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
  }

  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.isUidBinding) {
      Promise.resolve(null).then(async () => {
        for (const propName in changes) {
          if (propName != null) {
            await this.raisePropertyChanged(propName);
          }
        }
      });
    }
  }

  public scrollToTarget(target: string, highlight: boolean = true) {
    setTimeout(() => {
      const config: ScrollToConfigOptions = {
        duration: 50,
        target,
      };

      this.scrollToService.scrollTo(config);
      if (highlight) {
        const targetObj = document.getElementById(target);
        if (targetObj != null) {
          targetObj.style.borderLeft = '4px solid #111111';

          setTimeout(() => {
            targetObj.style.borderLeft = '';
          }, 3000);
        }
      }
    }, 200);

  }

  public isEdited() {
    return this.isLoaded() && this.checkEdited(this.dataWrapper);
  }

  public override async raisePropertyChanged(propName: string): Promise<void> {

    if (propName == 'uid' || propName == 'viewMode') {

      if (this.viewMode + this.uid != this.lastViewModeProcessed && this.uid != '') {

        await this.initDataWrapper();

        await this.loadActions();
      }
    }
  }

  trackByUid(index: number, item: any) {
    //console.log(item.dataItem.uid);
    return item.originalHash + item.dataItem.uid;
  }

  private async initDataWrapper() {

    this.applicationStateService.isInLoadingCrud += 1;

    const dtw = new BaseCrudEntityWrapper<TDto>();
    this.setEmptyInstance();
    dtw.dataItem = this.instance;
    dtw.errors = {};

    this.inputDataWrapper = dtw;

    if (!this.isInitialized) {
      await this.initialize();
      this.isInitialized = true;
    }

    this.dataWrapper.isLoaded = false;

    this.applicationStateService.isInLoadingCrud -= 1;

  }

  public async refresh(): Promise<void> {
    if (this.dataWrapper.isLoaded) {

      this.dataWrapper.isLoaded = false;

      await this.loadActions();

    }
  }

  public async unload(): Promise<void> {
    if (this.dataWrapper.isLoaded) {
      this.dataWrapper.isLoaded = false;
      this.lastViewModeProcessed = '';
    }
  }

  public isLoaded(): boolean {
    return this.dataWrapper != null && this.dataWrapper.isLoaded;
  }

  public async loadIfNeverLoaded(uid: string): Promise<BaseCrudEntityWrapper<TDto>> {
    if (this.dataWrapper.isLoaded == false) {

      await this.initDataWrapper();

      this.uid = uid;

      await this.loadActions();

      return this.dataWrapper;
    }
    return null;
  }


  public async loadActions(): Promise<void> {

    this.applicationStateService.isInLoadingCrud += 1;

    this.lastViewModeProcessed = this.viewMode + this.uid;

    if ((this._uid != '' && this._uid != 'cancel' && CoreLib_Classes_Guid.IsValid(this._uid))) {
      if (this.viewMode == CrudViewModes.Edit || this.viewMode == CrudViewModes.View) {
        this.clearFields();
        this.isInEditMode = true;
        await this.loadEditOrViewMode();
        await this.afterLoadEditOrViewMode();
        this.dataWrapper.isLoaded = true;
        if (this.crudView != null) {
          await this.crudView.afterCrudComponentLoadEditOrViewMode(this.id);
        }
        if (this.viewMode == CrudViewModes.Edit)
          await this.activated(CrudActivatedModes.Edit);
        else
          await this.activated(CrudActivatedModes.View);
      }
    } else if (this._uid == CoreLib_Classes_Guid.Empty) {
      if (this.viewMode == CrudViewModes.New) {
        this.clearFields();
        this.isInEditMode = false;
        await this.loadNewMode();
        await this.afterLoadNewMode();
        this.dataWrapper.isLoaded = true;
        if (this.crudView != null) {
          await this.crudView.afterCrudComponentLoadNewMode(this.id);
        }
        await this.activated(CrudActivatedModes.New);
      }

    } else {
    }

    // Memorizzo l'hash dell'oggetto originale
    this.SetOriginalHashToItem<TDto>(this.dataWrapper);

    this.applicationStateService.isInLoadingCrud -= 1;
  }

  public isWrapperLoaded(item: ICrudLoaded): boolean {
    return item != null && item.isLoaded;
  }

  public clearSingleError(key: string) {
    this.dataWrapper.errors[key] = '';
  }


  //#endregion

  //#region Mandatory Overridable Methods

  public setEmptyInstance() {
    this.instance = null;
  }

  public async initialize(): Promise<void> {

  }

  public async loadEditOrViewMode(): Promise<void> {

  }

  public async afterLoadEditOrViewMode(): Promise<void> {

  }

  public async loadNewMode(): Promise<void> {

  }

  public async afterLoadNewMode(): Promise<void> {

  }

  public async reActivated(): Promise<void> {

  }

  public async activated(mode: CrudActivatedModes): Promise<void> {

  }


  public clearFields() {

  }

  public async validate(): Promise<boolean> {
    this.dataWrapper.errors = {};
    return true;
  }

  public async validateAlerts(): Promise<boolean> {
    return true;
  }

  //#endregion

}
