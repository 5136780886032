import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { CrudViewModes } from '../../enums/CrudViewModes';
import { IPluginData } from '../../interfaces/IPluginData';
import { BaseComponent } from '../_base/base.component';
import { ModuleHostComponent } from '../module-host/module-host.component';

@Component({
  selector: 'app-crud-module-host',
  templateUrl: './crud-module-host.component.html',
})
export class CrudModuleHostComponent extends BaseComponent implements AfterViewInit {


  //#region Events...

  //#endregion

  //#region Declarations...

  private instance: any;

  //#endregion

  //#region Properties...

  // UID
  private _uid: string = '';
  @Input()
  public get uid() {
    return this._uid;
  }
  public set uid(val) {
    this._uid = val;

    if (this.instance != null) {
      this.instance.setField('uid', val);
    }

  }

  public viewModes: any = CrudViewModes;

  private _viewMode: CrudViewModes = CrudViewModes.List;
  @Input()
  public get viewMode(): CrudViewModes {
    return this._viewMode;
  }
  public set viewMode(value: CrudViewModes) {
    this._viewMode = value;

    if (this.instance != null) {
      this.instance.setField('viewMode', this.viewMode);
    }

  }

  @Output()
  public instanceLoaded: EventEmitter<any> = new EventEmitter<any>();

  //#endregion

  @Input()
  public module: IPluginData;

  private moduleHost: ModuleHostComponent;

  @ViewChild(ModuleHostComponent) set content(content: ModuleHostComponent) {
    this.moduleHost = content;
  }

  //#region Constructors...

  constructor(injector: Injector) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  override async ngAfterViewInit(): Promise<void> {
    this.instance = await this.pluginLoader.getModuleInstance(this.module, this.moduleHost.viewContainerRef);

    this.instanceLoaded.emit(this.instance);

    if (this.instance != null && this.instance.uid != undefined) {
      this.instance.setField('uid', this.uid);
    }


    if (this.instance != null && this.instance.viewMode != undefined) {
      this.instance.setField('viewMode', this.viewMode);
    }

  }
  //#endregion
}
