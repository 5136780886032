import { AfterViewInit, Directive, ElementRef, EventEmitter, Injector, NgZone, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent as KendoGridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';
import { CoreLib_Classes_ObjectHelper, CoreLib_Enums_PopupTitlesTypes } from 'core';
import { CoreLib_Classes_SearchRequest } from 'core';
import { CommonGridColumnDataDefinition, CommonGridResponse, CommonGridResponseWithGroupBy } from 'dto';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CrudPopupResult } from '../../classes/CrudPopupResult';
import { GridHelperMethods } from '../../classes/GridHelperMethods';
import { GridSetting } from '../../classes/GridSetting';
import { CrudBulkSteps } from '../../enums/CrudBulkSteps';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseGridResponseContract } from '../../interfaces/IBaseGridResponseContract';
import { IBaseGridViewContract } from '../../interfaces/IBaseGridViewContract';
import { ICrudEntity } from '../../interfaces/ICrudEntity';
import { GridService } from '../../services/common/grid.service';
import { FixToParentHeightService } from '../../services/common/fix-to-parent-height.service';
import { CrudGridComponent } from '../crud-grid/crud-grid.component';
import { GridComponent } from '../grid/grid.component';
import { BaseViewComponent } from './base-view.component';
import { BaseComponent } from './base.component';
import { Md5 } from 'ts-md5';

/**
 * E' la classe base dalla quale ereditano le view che al loro interno gestiscono una grid con i dati di una specifica entità
 * NECESSITA DELLE SEGUENTI TRANSLATION:
 * - EXPORTTITLE
 * - PAGETITLE
 * - PAGETITLENEW
 * - PAGETITLEEDIT
 * - PAGETITLEVIEW
 */
@Directive()
export class BaseGridViewComponent<TGridDto extends ICrudEntity, TAggregationDto> extends BaseViewComponent implements IBaseGridViewContract, IBaseGridResponseContract<TGridDto, TAggregationDto>, OnInit, AfterViewInit, OnDestroy {

  //#region Private Properties...

  private parentHeightChangedSubscription: Subscription;
  private gridHeightChangedSubscription: Subscription;
  private onResizeSubscription: Subscription;
  private grid_dataStateChangeSubscription: Subscription;
  private grid_columnResizeSubscription: Subscription;
  private grid_columnReorderSubscription: Subscription;
  private popupService_closeRequestedSubscription: Subscription;

  @ViewChild(KendoGridComponent)
  private set _grid(content: KendoGridComponent) {
    this.grid = content;
  }

  @ViewChild(CrudGridComponent)
  private set _crudGridContent(content: CrudGridComponent) {
    if (content != null) {
      this.grid = content.grid;
    }
  }

  @ViewChild(GridComponent)
  private set _gridContent(content: GridComponent) {
    if (content != null) {
      this.grid = content.grid;
    }
  }

  private gridHeightChanged = new Subject<boolean>();

  //#endregion

  //#region Public Properties...

  /**
   * Mappa il template con i pulsanti custom della riga della griglia, visibili in linea
   */
  @ViewChild('templatePrimaryExtraButtons')
  public set templatePrimaryExtraButtons(val: TemplateRef<any>) {
    this.gridSetting.templatePrimaryExtraButtons = val;
  }

  /**
   * Mappa il template con i pulsanti custom della riga della griglia, visibili nel popup
   */
  @ViewChild('templateSecondaryExtraButtons')
  public set templateSecondaryExtraButtons(val: TemplateRef<any>) {
    this.gridSetting.templateSecondaryExtraButtons = val;
  }

  /**
   * Mappa il template della riga in modalità mobile
   */
  @ViewChild('templateComputed')
  public set templateComputed(val: TemplateRef<any>) {
    this.gridSetting.templateComputed = val;
  }
  
  /**
* Mappa il template della toolbar della griglia
*/
  @ViewChild('templateToolbar')
  public set templateToolbar(val: TemplateRef<any>) {
    this.gridSetting.templateToolbar = val;
  }

  /**
     * Ospita il componente opzionale che definisce i vari template custom della griglia, i crud services e le proprietà.
     * Utile per centralizzare in un componente separato, gli oggetti (template, services ecc..) che possono essere utilizzati in più griglie
     */
  public searchSchemaTemplate: BaseComponent;
  @ViewChild('searchSchemaTemplate') set _searchSchemaTemplate(content: BaseComponent) {
    this.searchSchemaTemplate = content;
  }

  /**
   * Il riferimento al componente KendoGrid
   */
  public grid: KendoGridComponent;

  /**
  * Mappa l'oggetto html dove è posizionata la direttiva fixToParentHeightElement
  */

  public fixToParentHeightElement: ElementRef;
  @ViewChild('fixToParentHeightElement') set _fixToParentHeightElement(content: ElementRef) {
    this.fixToParentHeightElement = content;
  }

  private _gridResponse: CommonGridResponse<GridDataResult, TAggregationDto> = null;
  /**
     * Ospita la sorgente dati della griglia:
     * - Il nome del search schema
     * - Il set di colonne
     * - I record della pagina corrente
     * - Il campo utilizzato per l'ordinamento iniziale
     */
  public get gridResponse() {
    return this._gridResponse;
  }
  public set gridResponse(val: CommonGridResponse<GridDataResult, TAggregationDto>) {
    this._gridResponse = val;
    GridHelperMethods.afterGridResponseSet(this, val);
  }

  /**
   * Restituisce o imposta l'oggetto GridDataResult che contiene il numero totale di elementi e l'array degli elementi della pagina corrente
   */
  public items: GridDataResult;

  /**
   * Restituisce o imposta l'oggetto con lo stato corrente della griglia (pagina, filtri, ordinamento)
   */
  public state: DataSourceRequestState = {
    skip: 0,
    take: 30,
  };

  /**
  * Raccoglie in un unico oggetto, tutte le impostazioni per la griglia
  * - Flag isLoaded
  * - Proprietà gridHeight
  * - Tutti i template
  * - La definizione delle colonne
  * @returns un oggetto di tipo GridSetting
  */

  private _gridSetting = new GridSetting();
  public get gridSetting() {
    return this._gridSetting;
  }
  public set gridSetting(value) {
    this._gridSetting = value;
  }

  public selectedOrUnselectedUids: { [index: string]: TGridDto } = {};

  public initialFilters: CompositeFilterDescriptor;


  private _aboveGridHeight: number = 0;

  public get aboveGridHeight() {
    return this._aboveGridHeight;
  }
  public set aboveGridHeight(val: number) {
    this._aboveGridHeight = val;
    this.gridHeightChanged.next(true);
  }
  public popupCloseRequested = new EventEmitter<CrudPopupResult>();


  private _belowGridHeight: number = 0;

  public get belowGridHeight() {
    return this._belowGridHeight;
  }

  public set belowGridHeight(val: number) {
    this._belowGridHeight = val;
    this.gridHeightChanged.next(true);
  }

  private _selectionMode: GridSelectionModes = GridSelectionModes.None;

  public get selectionMode() {
    return this._selectionMode;
  }
  public set selectionMode(val: GridSelectionModes) {
    this._selectionMode = val;
    this.selectionModeChange.emit(val);
  }
  @Output() selectionModeChange = new EventEmitter<GridSelectionModes>();

  public searchRequest: CoreLib_Classes_SearchRequest;

  public isInPopup: boolean = false;

  public excelFileName: string = "";

  public showSelectUnselectAll: boolean = true;

  public thisView: any = this;

  public isFirstRequest: boolean = true;

  public saveSortState: boolean;

  public saveFilterState: boolean;
  //#endregion

  //#region Constructor...
  constructor(injector: Injector) {
    super(injector);

    this.parentHeightChangedSubscription = this.injector.get(FixToParentHeightService).parentHeightChanged.pipe(debounceTime(100)).subscribe(() => setTimeout(() => { this.gridHeightChanged.next(true); }, 100));

    this.gridHeightChangedSubscription = this.gridHeightChanged.pipe(debounceTime(100)).subscribe(() => { this.updateGridHeight(); });

    this.onResizeSubscription = fromEvent(window, 'resize').pipe(
      debounceTime(500))
      .subscribe(() => {
        this.gridHeightChanged.next(true);
      });

    this.popupService_closeRequestedSubscription = this.popupService.closeRequested.subscribe(() => { this.popupCloseRequested.emit(new CrudPopupResult(GridSelectionModes.None)); });

  }

  //#endregion

  //#region Methods...


  override  async ngOnInit() {
    await super.ngOnInit();

    if (this.initialFilters != null) {
      this.state.filter = this.initialFilters;
    }

    if (this.localizeByModule['EXPORTTITLE'] != null) {
      this.excelFileName = this.localizeByModule['EXPORTTITLE'] + '.xlsx';
    } else {
      this.excelFileName = 'GridExport.xlsx'
    }
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    if (this.grid != null) {
      this.grid_dataStateChangeSubscription = this.grid.dataStateChange.subscribe((e) => this.dataStateChange(e));

      this.grid_columnResizeSubscription = this.grid.columnResize.pipe(debounceTime(500))
        .subscribe(() => this.columnResize());

      this.grid_columnReorderSubscription = this.grid.columnReorder.pipe(debounceTime(500))
        .subscribe(() => this.columnReorder());
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.parentHeightChangedSubscription?.unsubscribe();
    this.gridHeightChangedSubscription?.unsubscribe();
    this.onResizeSubscription?.unsubscribe();
    this.popupService_closeRequestedSubscription?.unsubscribe();
    this.grid_dataStateChangeSubscription?.unsubscribe();
    this.grid_columnResizeSubscription?.unsubscribe();
    this.grid_columnReorderSubscription?.unsubscribe();
  }

  private updateGridHeight(): void {

    const ngZone = this.injector.get(NgZone) as NgZone;

    ngZone.runOutsideAngular(() => {
      if (this.grid != null && this.fixToParentHeightElement != null) {
        this.grid.wrapper.nativeElement.style.height = (this.fixToParentHeightElement.nativeElement.offsetHeight - 2 - this.aboveGridHeight - this.belowGridHeight) + 'px';
      }
    });
    if (this.fixToParentHeightElement != null) {
      this.gridSetting.gridHeight = this.fixToParentHeightElement.nativeElement.offsetHeight - 2 - this.aboveGridHeight - this.belowGridHeight;
    }

  }

  public refreshItems() {
    this.items = CoreLib_Classes_ObjectHelper.deepCopy(this.items);
  }

  private filterCheckSum: string;

  public async loadItems() {

    let checSumNew: string = '';

    if (this.state?.filter != null) {
      checSumNew = Md5.hashStr(JSON.stringify(this.state.filter));
    }

    let selectionCleared: boolean = false;
    if (checSumNew != this.filterCheckSum) {
      this.selectedOrUnselectedUids = {};
      selectionCleared = true;
    }

    this.gridResponse = await this.getItems(this.state, null);

    if (this.state?.filter != null) {
      this.filterCheckSum = Md5.hashStr(JSON.stringify(this.state.filter));
    } else {
      this.filterCheckSum = '';
    }

    if (selectionCleared) {
      await this.initAggregates();
    }

    await this.itemsLoaded();
  }

  public async initAggregates(): Promise<any> {

  }

  public async getAllItems(): Promise<any> {
    return GridHelperMethods.getAllItems(this, this);
  }


  public async itemsLoaded() {
    this.isFirstRequest = false;
    GridHelperMethods.handleItemsLoaded(this);
  }

  public onExcelExport(e: any): void {
    GridHelperMethods.baseOnExcelExport(e);
  }


  public async dataStateChange(state: DataStateChangeEvent): Promise<void> {
    GridHelperMethods.handleDataStateChange(this, state);
  }

  public async columnResize() {
    await GridHelperMethods.saveColumnResizeState(this);
  }

  public async columnReorder() {
    await GridHelperMethods.saveColumnReorderState(this);
  }



  public getValueFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getValueFieldName(columnDefinition);
  }

  public getTextFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getTextFieldName(columnDefinition);
  }

  public getComposedTextFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getComposedTextFieldName(columnDefinition);
  }

  public getFieldTitle(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getFieldTitle(this, columnDefinition);
  }

  public get parentContext(): any { return GridHelperMethods.getParentContext(this); }


  public async clearFilters() {
    this.state.filter = null;
    this.injector.get(GridService).gridFilterCleared.next(true);
    await this.loadItems();
  }

  public selectAll() {
    this.selectedOrUnselectedUids = {};
    this.selectionMode = GridSelectionModes.MultipleAllSelected;
  }


  public unselectAll() {
    this.selectedOrUnselectedUids = {};
    this.selectionMode = GridSelectionModes.MultipleAllUnselected;
  }

  public itemUidSelectionChange(value: boolean, itemUid: string) {
    this.itemSelectionChange(value, this.items.data.find(c => c.uid == itemUid));
  }

  public itemSelectionChange(value: boolean, item: TGridDto) {
    if (this.selectionMode == GridSelectionModes.MultipleAllSelected) { // se di base è tutto selezionato
      if (value == true) {
        // se ha prima deselezionato e poi riselezionato, rimuovo l'elemento precedentemente aggiunto con la deselezione
        this.selectedOrUnselectedUids[item.uid] = null;
      } else {
        // se ha deselezionato lo aggiungo ai deselezionati
        this.selectedOrUnselectedUids[item.uid] = item;
      }
    } else if (this.selectionMode == GridSelectionModes.MultipleAllUnselected) { // se di base è tutto deselezionato
      if (value == true) {
        // se ha selezionato lo aggiungo ai selezionati
        this.selectedOrUnselectedUids[item.uid] = item;
      } else {
        // se ha prima selezionato e poi deselezionato, rimuovo l'elemento precedentemente aggiunto con la selezione
        this.selectedOrUnselectedUids[item.uid] = null;
      }
    }
  }

  public async haveSelection() {

    let returnValue: boolean = false;

    if (this.selectionMode == GridSelectionModes.None || this.selectionMode == GridSelectionModes.Single)
      returnValue = false;

    let count: number = 0;
    Object.keys(this.selectedOrUnselectedUids).map((prop) => this.selectedOrUnselectedUids[prop]).forEach((item) => {
      if (item != null) {
        count++;
      }
    });

    if (this.selectionMode == GridSelectionModes.MultipleAllUnselected && count == 0) {
      returnValue = false;
    } else if (this.selectionMode == GridSelectionModes.MultipleAllSelected && count == this.gridResponse.dataSource.total) {
      returnValue = false;
    } else {
      returnValue = true;
    }

    if (returnValue == false) {
      await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Warning, this.localizeByCommon["NO_SELECTED_ITEMS"]);
    }

    return returnValue;

  }

  public async confirmSelection() {
    if (await this.haveSelection()) {

      const res = new CrudPopupResult(this.selectionMode);

      if (this.selectionMode == GridSelectionModes.MultipleAllSelected) {
        // se la modalità è tutto selezionato devo passare il filtro...
        res.filter = this.state.filter;
      }

      res.selectedOrUnselectedUids = [];
      Object.keys(this.selectedOrUnselectedUids).map((prop) => this.selectedOrUnselectedUids[prop]).forEach((item) => {
        if (item != null) {
          res.selectedOrUnselectedUids.push(item);
        }
      });

      res.sort = this.state.sort;

      this.popupCloseRequested.emit(res);
    }
  }

  public async selectItem(selectedItem: ICrudEntity) {
    const res = new CrudPopupResult(this.selectionMode);
    res.selectedOrUnselectedUids.push(selectedItem);
    this.popupCloseRequested.emit(res);
  }

  public hasUidSelected(uid: string): boolean {
    return this.selectedOrUnselectedUids[uid] != null;
  }

  public exportExcel() {

    this.getAllItems = this.getAllItems.bind(this);

    this.grid.saveAsExcel();
  }

  //#endregion

  //#region BULK CHANGES MANAGEMENT

  public bulkSearchSchemaName: string;
  public bulkStep: CrudBulkSteps = CrudBulkSteps.None;
  public crudBulkSteps: any = CrudBulkSteps;

  public bulkActionStepTitle: string = '';


  private _bulkGridResponse: CommonGridResponseWithGroupBy<GridDataResult, any, TAggregationDto> = null;

  public get bulkGridResponse() {
    return this._bulkGridResponse;
  }

  public set bulkGridResponse(val: CommonGridResponseWithGroupBy<GridDataResult, any, TAggregationDto>) {
    this._bulkGridResponse = val;
    if (val != null) {

      if (this.bulkState.skip > 0 && this.bulkitems != null && this.bulkitems.total > 0) {// Ottimizzazione: leggo la count solo sulla prima pagina
        val.dataSource.total = this.bulkitems.total;
      }


      if (val.columnsDefinitions != null) {
        for (const col of val.columnsDefinitions) {
          if (col.templateOutlet != null && col.templateOutlet != '') {

            // se trovo il template nella view prendo quello...
            col.template = (this as any)[col.templateOutlet];

            // se non lo trovo nella view, se ho un searchSchemaTemplate lo cerco li...
            if (col.template == null && this.searchSchemaTemplate != null) {
              col.template = (this.searchSchemaTemplate as any)[col.templateOutlet];
            }

            if (col.template == null) {
              throw new Error('Unable to find templateOutlet ' + col.templateOutlet);
            }
          }

          if (col.filterTemplateOutlet != null && col.filterTemplateOutlet != '') {

            col.filterTemplate = (this as any)[col.filterTemplateOutlet];

            if (col.filterTemplate == null && this.searchSchemaTemplate != null) {
              col.filterTemplate = (this.searchSchemaTemplate as any)[col.filterTemplateOutlet];
            }

            if (col.filterTemplate == null) {
              throw new Error('Unable to find filterTemplateOutlet ' + col.filterTemplateOutlet);
            }

          }
        }
      }


      this.bulkitems = val.dataSource;
      this.bulkSearchSchemaName = val.searchSchemaName;
      this.bulkGridSettings = new GridSetting();


      this.bulkGridSettings.searchSchemaName = val.searchSchemaName;      
      this.bulkGridSettings.templateComputed = this.gridSetting.templateComputed;
      this.bulkGridSettings.templateToolbar = this.gridSetting.templateToolbar;
      this.bulkGridSettings.columnsDefinitions = val.columnsDefinitions;
      this.bulkGridSettings.gridHeight = 0;
      this.bulkGridSettings.isLoaded = true;

      GridHelperMethods.setSortSetting(val.sortSetting, this.bulkState);

    }
  }

  public bulkPreviewInfo: string = '';
  public bulkitems: GridDataResult;
  public bulkGridSettings: GridSetting;

  public bulkProcessableItemsCount: number = 0;
  public bulkUnprocessableItemsCount: number = 0;
  public bulkState: DataSourceRequestState = {
    skip: 0,
    take: 30,
  };

  public async openBulkActions() {
    if (await this.haveSelection()) {

      //AVVIO IL PRIMO STEP DI SELEZIONE DELL'OPERAZIONE BULK
      this.bulkStep = CrudBulkSteps.SelectAction;
      this.bulkActionStepTitle = this.localizeByCommon['SELECTACTION'];
      this.bulkState.skip = 0;
      this.bulkState.take = 30;
      this.bulkState.filter = CoreLib_Classes_ObjectHelper.deepCopy(this.state.filter);

      await this.initBulkAction();

    }
  }

  public async initBulkAction() {
    //PULISCO PREVENTIVAMENTE I DATI
    this.bulkitems = null;
  }


  public async nextBulkActionStep() {
    switch (this.bulkStep) {
      case CrudBulkSteps.SelectAction:
        if (await this.processActionBulkStepSelected())
          this.bulkStep = CrudBulkSteps.ProcessAction;
        break;
      case CrudBulkSteps.ProcessAction:
        if (await this.previewBulkStepSelected()) {
          this.bulkActionStepTitle = this.localizeByCommon['BULK_CHANGE_PREVIEW'];
          this.bulkStep = CrudBulkSteps.Preview;
        }
        break;
      case CrudBulkSteps.Preview:
        if (await this.summaryBulkStepSelected()) {
          this.bulkActionStepTitle = this.localizeByCommon['BULK_CHANGE_RESULT'];
          this.bulkStep = CrudBulkSteps.Summary;
        }
        break;
      case CrudBulkSteps.Summary:
        this.bulkStep = CrudBulkSteps.None;
        break;
    }
  }

  public async loadBulkPreview() {
    //CARICAMENTO RIEPILOGO
    const searchRequest = this.getBulkChangeSearchRequest(false);

    this.bulkGridResponse = await this.getBulkGridResponse(this.bulkState, searchRequest);

    //Carico le info
    if (this.bulkGridResponse.groupByCounter != null) {
      this.bulkPreviewInfo = '';

      this.bulkProcessableItemsCount = 0;
      const processableItem = this.bulkGridResponse.groupByCounter.find((c: any) => c.value == true);

      if (processableItem != null)
        this.bulkProcessableItemsCount = processableItem.counter;

      this.bulkUnprocessableItemsCount = 0;
      const unprocessableItem = this.bulkGridResponse.groupByCounter.find((c: any) => c.value == false);

      if (unprocessableItem != null)
        this.bulkUnprocessableItemsCount = unprocessableItem.counter;

      this.bulkPreviewInfo = this.localizeByCommon['BULKPROCESSABLEITEMSINFO'].replace("@@PROCESSABLECOUNT@@", this.bulkProcessableItemsCount.toString()).replace("@@UNPROCESSABLECOUNT@@", this.bulkUnprocessableItemsCount.toString());

      await this.afterLoadBulkPreview();
    }
  }

  public async afterLoadBulkPreview() {

  }

  public async getBulkGridResponse(bulkState: DataSourceRequestState, searchRequest: CoreLib_Classes_SearchRequest): Promise<CommonGridResponseWithGroupBy<GridDataResult, any, TAggregationDto>> {
    return null;
  }

  public getBulkChangeSearchRequest(commitBulkChanges: boolean): CoreLib_Classes_SearchRequest {
    return null;
  }

  public async processActionBulkStepSelected(): Promise<boolean> {
    return true;
  }

  public async previewBulkStepSelected(): Promise<boolean> {
    this.bulkState.skip = 0;
    this.bulkState.take = 30;
    this.bulkState.filter = this.state.filter;
    return true;
  }

  public async summaryBulkStepSelected(): Promise<boolean> {
    return true;
  }


  public closeBulkActions() {
    this.bulkStep = CrudBulkSteps.None;
  }

  public previousBulkActionStep() {
    switch (this.bulkStep) {
      case CrudBulkSteps.SelectAction:

        break;
      case CrudBulkSteps.ProcessAction:
        this.bulkStep = CrudBulkSteps.SelectAction;
        break;
      case CrudBulkSteps.Preview:
        this.bulkStep = CrudBulkSteps.ProcessAction;
        break;
      case CrudBulkSteps.Summary:
        break;
    }
  }

  public async bulkDataStateChange(state: DataStateChangeEvent) {
    this.bulkState = state;
    await this.loadBulkPreview();
  }



  //#endregion

  //#region Mandatory Overridable Methods...

  /**
   * Metodo che è necessario sovrascrivere nella classe derivata.
   * In questo metodo la classe derivata si deve preoccupare di restituire l'oggetto CommonGridResponse<GridDataResult>, invocando il servizio http dedicato
   */
  public async getItems(state: DataSourceRequestState, uid: string): Promise<CommonGridResponse<GridDataResult, TAggregationDto>> {
    return null;
  }


  //#endregion
}
