import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeItExtra from '@angular/common/locales/extra/it';
import localeIt from '@angular/common/locales/it';
import * as AngularCore from '@angular/core';
import { APP_INITIALIZER, Compiler, COMPILER_OPTIONS, CompilerFactory, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/it/all';
import { ControlsLib_ControlsModule, ControlsLib_Services_Common_RolePermissionService, ControlsLib_Services_Common_RouterService, ControlsLib_Services_PluginLoader_PluginLoaderService, ControlsLib_Services_PluginLoader_TransferStateService, ControlsLib_Services_Common_ColorService,ControlsLib_Services_Common_ControlsTranslationsService } from 'controls';
import { CoreLib_CoreModule, CoreLib_Services_Common_ApplicationInitService, CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_ErrorsHandlerService, CoreLib_Services_Common_LicenseService, CoreLib_Services_Common_LocalizationService, CoreLib_Services_Common_MessageService, CoreLib_Services_Common_TokenService, CoreLib_Services_Common_TranslationService, CoreLib_Services_Http_Base_BaseHttpInterceptorService } from 'core';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CrudHostComponent } from './components/crud-host/crud-host.component';
import { EmptyPluginHostComponent } from './components/plugin-hosts/empty-plugin-host/empty-plugin-host.component';
import { RolePermissionService } from './services/controls-extension/role-permission.service';
import { ApplicationInitService } from './services/core-extension/application-init.service';
import { LicenseService } from './services/core-extension/license.service';
import { MessageService } from './services/core-extension/message.service';
import { ClientPluginLoaderService } from './services/plugin-loader/client-plugin-loader.service';
import { CrudHostViewComponent } from './views/crud-host-view/crud-host-view.component';
import { HomeComponent } from './views/home/home.component';
import { ViewHostComponent } from './components/view-host/view-host.component';
import { ViewHostViewComponent } from './views/view-host-view/view-host-view.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ColorService } from './services/controls-extension/color.service';
import { LoginPersComponent } from './views/login/login.component';
import { ChangePasswordPersComponent } from './views/change-password/change-password.component';
import { LostPasswordComponent } from './views/lost-password/lost-password.component';
import { RecoverPasswordComponent } from './views/recover-password/recover-password.component';

registerLocaleData(localeIt, localeItExtra);

export function initApp(translationService: CoreLib_Services_Common_TranslationService, controlsTranslationsService: ControlsLib_Services_Common_ControlsTranslationsService, licenseService: CoreLib_Services_Common_LicenseService, tokenService: CoreLib_Services_Common_TokenService, applicationInitService: CoreLib_Services_Common_ApplicationInitService, routerService: ControlsLib_Services_Common_RouterService, pluginLoaderService: ControlsLib_Services_PluginLoader_PluginLoaderService) {
  return async () => {
    await licenseService.init();
    await tokenService.init();
    await applicationInitService.init();
    await translationService.initCommon(controlsTranslationsService.localizeByCultureName);
    await pluginLoaderService.loadModules();

    for (const module of pluginLoaderService.allDataModules) {
      if (module.destinationRoutePath.length > 0) {

        let component: AngularCore.Type<any>;

        switch (module.destinationHostType) {
          case 'empty-plugin-host':
            component = EmptyPluginHostComponent;
            break;
          default:
            component = EmptyPluginHostComponent;
            break;
        }

        routerService.createAndRegisterRoute(module, component);
      }
    }


  };
}

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginPersComponent,
        LostPasswordComponent,
        RecoverPasswordComponent,
        ChangePasswordPersComponent,
        CrudHostComponent,
        CrudHostViewComponent,
        EmptyPluginHostComponent,
        ViewHostComponent,
        ViewHostViewComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        AppRoutingModule,
        IntlModule,
        InputsModule,
        CoreLib_CoreModule,
        ControlsLib_ControlsModule,
        BrowserTransferStateModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [CoreLib_Services_Common_LocalizationService],
            useFactory: (localizationService:any) => localizationService.getLocale(),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CoreLib_Services_Http_Base_BaseHttpInterceptorService,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: CoreLib_Services_Common_ErrorsHandlerService,
        },
        Title,
        CookieService,
        { provide: CoreLib_Services_Common_LicenseService, useClass: LicenseService },
        { provide: CoreLib_Services_Common_ApplicationInitService, useClass: ApplicationInitService },
        { provide: CoreLib_Services_Common_MessageService, useClass: MessageService },
        { provide: ControlsLib_Services_Common_RolePermissionService, useClass: RolePermissionService },
        { provide: ControlsLib_Services_Common_ColorService, useClass: ColorService },
        { provide: ControlsLib_Services_PluginLoader_PluginLoaderService, useClass: ClientPluginLoaderService },
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [CoreLib_Services_Common_TranslationService, ControlsLib_Services_Common_ControlsTranslationsService, CoreLib_Services_Common_LicenseService, CoreLib_Services_Common_TokenService, CoreLib_Services_Common_ApplicationInitService, ControlsLib_Services_Common_RouterService, ControlsLib_Services_PluginLoader_PluginLoaderService], multi: true },
        { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
        { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
        { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(transferStateService: ControlsLib_Services_PluginLoader_TransferStateService) { }
}
