








import { ReportDataSourceParameterDataRequest } from './ReportDataSourceParameterDataRequest';
export class ReportDataSourceDataRequest  {
      
    public dataSourceUid: string = '00000000-0000-0000-0000-000000000000';    
    public dataSourceName: string = '';    
    public dataSourceParameterUid: string = '00000000-0000-0000-0000-000000000000';    
    public parameterName: string = '';    
    public parameterValue: any = null;    
    public parameters: ReportDataSourceParameterDataRequest[] = [];    
    public where: string = '';    
}

