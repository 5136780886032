import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { CoreLib_Services_Common_TranslationService } from 'core';
import { Subscription } from 'rxjs';
import { GridService } from '../../services/common/grid.service';


@Component({
  selector: 'app-grid-filter-boolean',
  templateUrl: './grid-filter-boolean.component.html',
  styleUrls: ['./grid-filter-boolean.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridFilterBooleanComponent extends BaseFilterCellComponent implements OnInit, OnDestroy {

  //#region Declarations...

  @HostBinding('class.highlighted')
  get promoted() { return this.hasFilters; }

  //#endregion

  //#region Input...

  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public data: any[];
  @Input() public filterField: string;
  @Input() public noFilterText: string;

  //#endregion

  //#region Properties...

  public selectedValue: any;

  public hasFilters: boolean;

  //#endregion

  //#region  Private variables...

  private gridFilterClearedSubscription: Subscription;

  //#endregion
  
  //#region Constructor...

  constructor(filterService: FilterService, private translationService: CoreLib_Services_Common_TranslationService, private ref: ChangeDetectorRef, gridService: GridService) {
    super(filterService);

    this.gridFilterClearedSubscription = gridService.gridFilterCleared.subscribe(c => {
      this.selectedValue = this.data[0];
      this.hasFilters = false;      
    });
  }

  //#endregion

  //#region Methods...

  ngOnInit(): void {
    this.data = [];
    this.data.push(this.translationService.localizeByCommon["kendo.grid.filterBooleanAll"]);
    this.data.push(this.translationService.localizeByCommon["YES"]);
    this.data.push(this.translationService.localizeByCommon["NO"]);
    this.selectedValue = this.data[0];

    const thisFieldFilters: FilterDescriptor[] = [];

    if (this.filter != null) {

      for (const f of this.filter.filters) {

        if ((f as CompositeFilterDescriptor).filters) {
          for (const cf of (f as CompositeFilterDescriptor).filters) {
            const cfCasted = cf as FilterDescriptor;
            if (cfCasted.field == this.filterField) {
              thisFieldFilters.push(cfCasted)
            }
          }
        } else {
          const fCasted = f as FilterDescriptor;
          if (fCasted.field == this.filterField) {
            thisFieldFilters.push(fCasted)
          }
        }
      }

      if (thisFieldFilters.length > 0) {
        if(thisFieldFilters[0].value == true){
          this.selectedValue = this.data[1];
        } else {
          this.selectedValue = this.data[2];
        }
        
        this.hasFilters = true;
      }

    }

  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.gridFilterClearedSubscription != null) {
      this.gridFilterClearedSubscription.unsubscribe();
    }
  }

  public onChange(value: any): void {

    this.hasFilters = value != this.translationService.localizeByCommon["kendo.grid.filterBooleanAll"];

    this.applyFilter(
      (value === null || value === this.translationService.localizeByCommon["kendo.grid.filterBooleanAll"]) ? // value of the default item
        this.removeFilter(this.filterField) : // remove the filter
        this.updateFilter({ // add a filter for the field with the value
          field: this.filterField,
          operator: 'eq',
          value: (value == this.translationService.localizeByCommon["YES"]) ? "1" : "0",
        }),
    ); // update the root filter

    this.ref.detectChanges();
  }

  clearFilter() {
    this.selectedValue = this.data[0];
    this.onChange(this.data[0]);
  }

  //#endregion

}
