<div class="app-toast" [class.clickable]="toastContent.clickable" (click)="open()">
  <div class="padding-wrapper">
    <div class="left">

    </div>
    <div class="center">
      <div class="title">{{title}}</div>
      

      <div class="text" [innerHTML]="toastContent.text | keepHtml"></div>
      <div *ngFor="let line of toastContent.lines" class="text" [innerHTML]="line | keepHtml"></div>
    </div>
    <div class="right">
      <span class="k-icon k-i-close close" (click)="close($event)"></span>
      <span class="k-icon k-i-hyperlink-open-sm open" *ngIf="toastContent.clickable"></span>
    </div>
  </div>
</div>
