import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { CoreLib_Classes_ToastContent, CoreLib_Services_Common_ToastService } from 'core';
import { HostService } from '../../services/common/host.service';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent extends BaseComponent {

  @Output()
  public onClose = new EventEmitter<CoreLib_Classes_ToastContent>();

  @Output()
  public onOpen = new EventEmitter<CoreLib_Classes_ToastContent>();

  //#region Properties...

  @Input()
  public toastContent: CoreLib_Classes_ToastContent;

  @Input()
  public title: string;

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, public toastService: CoreLib_Services_Common_ToastService, private hostService: HostService) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  close(event: any): void {
    event.stopPropagation();

    //Notifico che un click sul documento è avvenuto così da chiudere eventuali popup inline aperti
    this.hostService.documentClick.next(event);

    this.onClose.emit(this.toastContent);
  }

  open(): void {
    this.onOpen.emit(this.toastContent);
  }
  //#endregion
}
