import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonGridColumnDataDefinition } from 'dto';



@Component({
  selector: 'app-grid-dynamic-group-cell',
  templateUrl: './grid-dynamic-group-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridDynamicGroupCellComponent {

  //#region Properties...

  @Input()
  public title: string = '';

  @Input()
  public group: any;

  @Input()
  public aggregates: any;

  @Input()
  public columnDefinition: CommonGridColumnDataDefinition;

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  str(item: any) {
    return JSON.stringify(item);
  }

  public getGroupName(): string {

    let dataItem: any =  this.group.items[0];

    while(dataItem.items != null){
      dataItem = dataItem.items[0];
    }


    if (this.columnDefinition.textField != null && this.columnDefinition.textField.length > 0) {
      if (this.columnDefinition.isPersField && dataItem['persData'] != null) {
        return dataItem['persData'][this.columnDefinition.textField];
      } else {
        return dataItem[this.columnDefinition.textField];
      }
    } else {
      if (this.columnDefinition.isPersField && dataItem['persData'] != null) {
        return dataItem['persData'][this.columnDefinition.valueField];
      } else {
        return dataItem[this.columnDefinition.valueField];
      }
    }
  }
  //#endregion
}
