









export class ReportDataSourceDataResponse  {
      
    public dataSourceUid: string = '00000000-0000-0000-0000-000000000000';    
    public dataSourceName: string = '';    
    public dataSourceParameterUid: string = '00000000-0000-0000-0000-000000000000';    
    public parameterName: string = '';    
    public appParameterValueIdentifier: string = '';    
    public portalParameterValueIdentifier: string = '';    
    public deskParameterValueIdentifier: string = '';    
}

