import { Pipe, PipeTransform } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Pipe({
  name: 'formatDate',
  pure: true,
})
export class FormatDatePipe implements PipeTransform {


  constructor(private intlService: IntlService) {

  }
  transform(date: Date, format: string = 'd'): string {
    date = new Date(date);
    return this.intlService.formatDate(date, format);
  }
}
