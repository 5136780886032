



  import { BaseRequest } from 'dto';
  export class CommonLostPasswordRequest extends BaseRequest   {        
          
      public name: string = '';
            
}







