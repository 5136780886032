import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ControlsLib_Components_Base_BaseViewComponent, ControlsLib_Components_TextBox_TextBoxComponent } from 'controls';
import { CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_TranslationService, CoreLib_Services_Http_DeskCommonService } from 'core';
import { CommonChangePasswordRequest, CommonChangePasswordResponse } from 'dto';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordPersComponent extends ControlsLib_Components_Base_BaseViewComponent implements OnInit, AfterViewInit {

  @ViewChild('passwordold_ref', { static: true }) passwordOldField: ControlsLib_Components_TextBox_TextBoxComponent;
  @ViewChild('passwordnew_ref', { static: true }) passwordNewField: ControlsLib_Components_TextBox_TextBoxComponent;
  @ViewChild('passwordnewconfirm_ref', { static: true }) passwordNewConfirmField: ControlsLib_Components_TextBox_TextBoxComponent;

  public name: string = '';

  public passwordOld: string = '';
  public passwordNew: string = '';
  public passwordNewConfirm: string = '';

  public errorText: string;
  public changePasswordFailed: boolean = false;

  public get imageHeaderUrl(): string {
    return "assets/images/header_" + this.translateService.cultureName + ".png";
  }

  constructor(injector: Injector,
              private deskApiCommonService: CoreLib_Services_Http_DeskCommonService,
              private translateService: CoreLib_Services_Common_TranslationService,
              public authenticationService: CoreLib_Services_Common_AuthenticationService) {
    super(injector);
    this.formReference = 'CommonChangePassword';
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.passwordOld = "";

    //Se ho un nome utente procedo se no rimando alla login
    this.name = this.authenticationService.getAccountName();
    if (this.name == null) {
      this.router.navigate(['/login']);
    }

    this.setChangePasswordTheme();
  }

  private setChangePasswordTheme() {
    // La pagina change password ha sempre il tema standard
    this.navigationService.changeTheme('standard', false);
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
  }

  async onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    if (event.keyCode == 13) {
      await this.change();
    }
  }

  private setFocusOnPasswordNew() {
    if (this.passwordNewField != null) {
      setTimeout(() => this.passwordNewField.focus(), 500);
    }
  }

  private setFocusOnPasswordOld() {
    if (this.passwordOldField != null) {
      setTimeout(() => this.passwordOldField.focus(), 500);
    }
  }

  private setFocusOnPasswordNewConfirm() {
    if (this.passwordNewConfirmField != null) {
      setTimeout(() => this.passwordNewConfirmField.focus(), 500);
    }
  }

  // Torna alla login
  async return() {
    this.router.navigate(['/login']);
  }

  // Effettuo la chiamata alla Web Api per il ChangePassword manuale
  async change() {

    this.changePasswordFailed = false;
    this.errorText = '';


    if (this.passwordOld == null || this.passwordOld.length == 0) {
      this.changePasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDOLDREQUIRED'];
      this.setFocusOnPasswordOld();
      return;
    }

    if (this.passwordNew == null || this.passwordNew.length == 0) {
      this.changePasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDNEWREQUIRED'];
      this.setFocusOnPasswordNew();
      return;
    }
    
    if (this.passwordNewConfirm == null || this.passwordNewConfirm.length == 0) {
      this.changePasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDNEWCONFIRMREQUIRED'];
      this.setFocusOnPasswordNewConfirm();
      return;
    }

    if (this.passwordNewConfirm != this.passwordNew) {
      this.changePasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDNEWMISMATCH'];
      this.passwordNewConfirm = '';
      this.setFocusOnPasswordNewConfirm();
      return;
    }

    if (this.passwordNew == this.passwordOld) {
      this.changePasswordFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDNEWALREADYUSED'];
      this.passwordNew = '';
      this.passwordNewConfirm = '';
      this.setFocusOnPasswordNew();
      return;
    }

    // costruisco l'oggetto request
    const changePasswordRequest = new CommonChangePasswordRequest();
    changePasswordRequest.name = this.name;
    changePasswordRequest.newPassword = this.passwordNew;
    changePasswordRequest.oldPassword = this.passwordOld;

    // invoco la Web Api
    const result = await this.deskApiCommonService.changePassword(changePasswordRequest);
    await this.processChangePasswordResult(result);
  }

  // Processo la risposta della chiamata alla Web Api per il cambio password
  async processChangePasswordResult(data: CommonChangePasswordResponse): Promise<void> {
    if (data != null && data.isValid) {
      // Il cambio password è avvenuto con successo: popup di conferma e torno alla login
      this.changePasswordFailed = false;

      if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Info, this.localizeByCommon['CHANGE_PASSWORD_OK'], CoreLib_Enums_PopupButtonsTypes.Ok) == CoreLib_Enums_PopupResultTypes.Ok) {
        this.router.navigate(['/login']);
      }
      
    } else {
      // se fallisce il cambio password mostro un avviso di errore
      this.changePasswordFailed = true;

      // se è un errore di conformità della password estraggo la traduzione degli errori multipli e sostituisco l'eventuale placeholder se prensente
      // altrimenti estraggo la traduzione del motivo
      if (data.reasonIsNotValid == "NEW_PASSWORD_NOT_VALID") {
          for (const item of data.passwordValidationErrors) {
              var indexOfUnderscore = item.indexOf('_');

              var itemToTranslate = item;
              var placeholderValue = "";
              if (indexOfUnderscore != -1) {
                  itemToTranslate = item.substring(0, indexOfUnderscore);
                  placeholderValue = item.substring(indexOfUnderscore + 1, item.length);
              }

              if (this.localizeByCommon[itemToTranslate] != null) {
                  this.errorText = this.errorText + this.localizeByCommon[itemToTranslate].replace("@@VALUE@@", placeholderValue) + "\n";
              } else {
                  this.errorText = this.errorText + itemToTranslate;
              }
          }

          this.passwordNew = '';
          this.passwordNewConfirm = '';
          this.setFocusOnPasswordNew();
      } else {
        if(this.localizeByCommon[data.reasonIsNotValid] != null) {
          this.errorText = this.errorText + this.localizeByCommon[data.reasonIsNotValid];
        } else {
          this.errorText = this.errorText + data.reasonIsNotValid;
        }

        this.passwordOld = '';
        this.setFocusOnPasswordOld();
      }
    }
  }

  public goBack() {
    this.router.navigate(['registration/view']);
  }

}
