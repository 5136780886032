import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgProgress, NgProgressState } from '@ngx-progressbar/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CoreLib_Classes_ObjectHelper, CoreLib_Classes_StringHelper, CoreLib_Services_Common_PopupService } from 'core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CrudPopupResult } from '../../classes/CrudPopupResult';
import { ElementHelperMethods } from '../../classes/ElementHelperMethods';
import { PopupHostDirective } from '../../directives/popup-host.directive';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseGridViewContract } from '../../interfaces/IBaseGridViewContract';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';
import { MultiSelectComponent as KendoMultiSelectComponent } from '@progress/kendo-angular-dropdowns';


@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent extends BaseInputControlComponent implements OnInit, AfterViewInit, OnDestroy {

  //#region Events...

  @Output() filterChange = new EventEmitter<string>();

  @Output() selectedCrudItemChanged = new EventEmitter<any[]>();

  //#endregion

  //#region Declarations...

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  @ViewChild('multiselect')
  public multiselect: KendoMultiSelectComponent;


  private popuphelperService_closeRequested_subscribe: Subscription;
  private crudComponent_closeRequested_subscribe: Subscription;
  private crudComponent_selectedItemChanged_subscribe: Subscription;
  private componentRef: ComponentRef<any>;
  private viewContainerRef: ViewContainerRef;
  private ngProgressSubscription: Subscription;

  private popupHost: PopupHostDirective;

  @ViewChild(PopupHostDirective) set content(content: PopupHostDirective) {
    this.popupHost = content;
  }

  //#endregion

  //#region Properties...

  @Input() itemTemplate: TemplateRef<any>;

  @Input() tagTemplate: TemplateRef<any>;

  @Input() crudComponentFactory: ComponentFactory<any>;


  @Input() crudModuleName: string;
  @Input() crudComponentName: string;

  // ONDEMAND
  @Input()
  public ondemand: boolean = false;

  // ITEMSSOURCE
  private _itemsSource: any;
  @Input()
  get itemsSource() {
    return this._itemsSource;
  }
  set itemsSource(val) {
    this._itemsSource = val;

    this.setNoDataText();
  }

  // SELECTEDITEM
  private _selectedItems: any[] = [];
  @Input()
  get selectedItems() {
    return this._selectedItems;
  }
  @Output() selectedItemsChange = new EventEmitter();
  set selectedItems(val) {
    this._selectedItems = val;
    this.setHeaderHidden();
    this.selectedItemsChange.emit(val);
    this.setReadOnlyValue();


    if (this.ondemand && this.itemsSource != null && this.itemsSource.length > 0)
      this.itemsSource.splice(0, this.itemsSource.length);
  }

  // TEXTFIELD
  private _textField: string;
  @Input()
  get textField() {
    return this._textField;
  }
  set textField(val) {
    this._textField = val;
    this.setReadOnlyValue();
  }

  // VALUEFIELD
  private _valueField: string;
  @Input()
  get valueField() {
    return this._valueField;
  }

  set valueField(val) {
    this._valueField = val;
  }

  // POPUPOPENED
  public popupOpened: boolean = false;

  // SHOWEXTENDEDSEARCH
  @Input()
  public showExtendedSearch: boolean = false;

  // ALLOWCUSTOM
  @Input()
  public allowCustom: boolean = false;

  @Input()
  public normalizer: any = (text$: Observable<string>): any => text$.pipe(
    map((userInput: string) => {

      return userInput;
    }),
  );

  // ENTITYOWNER
  @Input()
  public entityOwner: string = '';

  @Input()
  public extendedSearchInitialFilters: CompositeFilterDescriptor;

  @Input()
  public extendedSearchRequest: any;

  public noDataText = '';

  public get dialogTitle() {

    if (this.entityOwner != null && this.entityOwner.length > 0) {
      return this.localizeByCommon['COMBO_DIALOG_TITLE_FOR'].replace('@@HEADER@@', this.header).replace('@@ENTITYOWNER@@', this.entityOwner);
    } else {
      return this.localizeByCommon['COMBO_DIALOG_TITLE'].replace('@@HEADER@@', this.header);
    }
  }

  private internalFilterChange = new EventEmitter();

  private internalFilterChangeSubscription: Subscription;

  private tempFilterValue: string = '';

  private minCharToStartSearch: number = 2;

  

  public isInLoadingOnDemand: boolean = false;


  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, ngProgress: NgProgress, private popupService: CoreLib_Services_Common_PopupService, private componentFactoryResolver: ComponentFactoryResolver, private ref: ChangeDetectorRef, private element: ElementRef) {
    super(injector, validationService);

    this.internalFilterChangeSubscription = this.internalFilterChange.pipe(debounceTime(300)).subscribe(c => {
      if (this.tempFilterValue.length >= this.minCharToStartSearch)
        this.filterChange.emit(this.tempFilterValue);
    });

    this.ngProgressSubscription = ngProgress.ref().state.subscribe((e: NgProgressState) => {
      this.isInLoadingOnDemand = this.hasFocus && e.active;
    })
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.setNoDataText();
    // if (this.normalizer == null)
    //   this.normalizer = (text$: Observable<string>): any => text$.pipe(
    //     map((userInput: string) => {

    //       return userInput;
    //     }),
    //   );

  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    const input = this.element.nativeElement.querySelector('kendo-searchbar');
    if (input != null) {
      input.removeEventListener("keydown", this.handleFilterSearchKeyDown);;
      input.addEventListener("keydown", this.handleFilterSearchKeyDown.bind(this), true);
    }

  }

  private searchEnterHandled: boolean = false;
  private searchStopAutoArrowDown: boolean = false;
  private timeoutSendArrowDownAndEnter: any = null;
  private lasttarget: any = null;

  private async handleFilterSearchKeyDown(e: any) {
    if ((e.keyCode == 13 || e.which == 13) && this.searchEnterHandled == false && this.searchStopAutoArrowDown == false) { //&& elementList
      e.cancelBubble = true;
      e.preventDefault();
      e.stopPropagation();
      this.lasttarget = e.target;
      this.sendArrowDownAndEnter();
    } else if (e.keyCode == 40 || e.which == 40 || e.keyCode == 38 || e.which == 38) {
      this.searchStopAutoArrowDown = true;
    } else {
      this.searchStopAutoArrowDown = false;
    }
  }

  private sendArrowDownAndEnter() {

    clearTimeout(this.timeoutSendArrowDownAndEnter);
    this.timeoutSendArrowDownAndEnter = null;
    if (this.lasttarget != null) {
      this.timeoutSendArrowDownAndEnter = setTimeout(async () => {
        if (this.multiselect.isOpen) {
          await ElementHelperMethods.elementReady(document.documentElement, 'kendo-popup kendo-list ul li', 300);

          this.searchEnterHandled = true;
          if (!this.allowCustom) {
            this.lasttarget.dispatchEvent(new KeyboardEvent('keydown', <KeyboardEventInit>{ keyCode: 40 }));
          }
          this.lasttarget.dispatchEvent(new KeyboardEvent('keydown', <KeyboardEventInit>{ keyCode: 13 }));

          setTimeout(async () => {
            this.searchEnterHandled = false;
            this.lasttarget = null;
          }, 500);

        }
      }, 300);
    }
  }


  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe();
    this.ngProgressSubscription?.unsubscribe();
    this.internalFilterChangeSubscription?.unsubscribe();
  }

  private setNoDataText() {
    if (this.ondemand == false || this.tempFilterValue.length >= this.minCharToStartSearch) {
      this.noDataText = this.translationService.tryGetLocalizeByCommon("NO_ITEMS_FOUND");
    } else {
      this.noDataText = this.translationService.tryGetLocalizeByCommon("X_CHARS_TOCONTINUE").replace("@@X@@", this.minCharToStartSearch.toString());
    }
  }

  public handleFilter(value: any): void {
    this.tempFilterValue = value;
    this.setNoDataText();
    if (this.ondemand && value.length >= this.minCharToStartSearch) {
      this.internalFilterChange.emit();
    } else {
      if (this.ondemand && this.itemsSource != null && this.itemsSource.length > 0)
        this.itemsSource.splice(0, this.itemsSource.length);
    }

    this.setHeaderHidden();
  }
  focus() {
    this.applicationStateService.isInLoadingEnabled = false;
    this.hasFocus = true;
  }

  blur() {
    this.applicationStateService.isInLoadingEnabled = true;
    this.hasFocus = false;
    this.setHeaderHidden();
  }

  public close(): void {
    this.multiselect.clearFilter();
    this.ref.detectChanges();
  }

  public open(): void {
    if (this.ondemand) {
      this.itemsSource = CoreLib_Classes_ObjectHelper.deepCopy(this.selectedItems);
      this.ref.detectChanges();
    }
  }

  public override setHeaderHidden(): void {
    this.headerhidden = this.disabled == false && (this.selectedItems == null || this.selectedItems.length == 0);

    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.tempFilterValue))
      this.headerhidden = false;

    this.ref.detectChanges();
  }

  public override setReadOnlyValue(): void {
    if (this.disabled && (this.selectedItems == null || this.selectedItems.length == 0)) {
      this.readOnlyValue = '-';
    } else {
      if (this.selectedItems == null || this.selectedItems.length > 0) {
        this.readOnlyValue = '';
        for (const item of this.selectedItems) {
          this.readOnlyValue += item[this.textField] + ';';
        }
        if (CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.readOnlyValue)) {
          this.readOnlyValue = '-';
        }
      } else {
        this.readOnlyValue = '-';
      }
    }

    this.ref.detectChanges();
  }

  public popupClose(): void {
    this.popupOpened = false;
    this.ref.detectChanges();
  }

  public popupOpen(): void {
    //if (this.crudComponentFactory != null) {
    this.popupOpened = true;
    this.ref.detectChanges();
    //}
  }

  public async loadComponent() {
    this.unsubscribe();
    this.viewContainerRef = this.popupHost.viewContainerRef;
    this.viewContainerRef.clear();

    let factory: any = null;

    if (this.crudComponentFactory != null) {
      factory = this.crudComponentFactory;
    } else {
      factory = await this.lazyLoaderService.loadModule3(this.crudModuleName, this.crudComponentName);
    }

    this.componentRef = this.viewContainerRef.createComponent(factory);

    const componentInstance = (this.componentRef.instance as IBaseGridViewContract);
    componentInstance.isInPopup = true;
    componentInstance.selectionMode = GridSelectionModes.MultipleAllUnselected;
    componentInstance.initialFilters = this.extendedSearchInitialFilters;
    componentInstance.searchRequest = this.extendedSearchRequest;
    componentInstance.showSelectUnselectAll = false;

    this.crudComponent_closeRequested_subscribe?.unsubscribe();

    this.crudComponent_closeRequested_subscribe = componentInstance.popupCloseRequested.subscribe((result: CrudPopupResult) => {
      this.popupOpened = false;
      this.setHeaderHidden();
      if (result.selectionMode == GridSelectionModes.MultipleAllUnselected) {
        this.selectedCrudItemChanged.emit(result.selectedOrUnselectedUids);
      }
    });

    this.ref.detectChanges();
  }

  private unsubscribe(): void {

    if (this.viewContainerRef != null) {
      this.viewContainerRef.detach();
    }

    if (this.componentRef != null) {
      this.componentRef.destroy();
    }

    if (this.popuphelperService_closeRequested_subscribe != null) {
      this.popuphelperService_closeRequested_subscribe.unsubscribe();
    }

    if (this.crudComponent_closeRequested_subscribe != null) {
      this.crudComponent_closeRequested_subscribe.unsubscribe();
    }

    if (this.crudComponent_selectedItemChanged_subscribe != null) {
      this.crudComponent_selectedItemChanged_subscribe.unsubscribe();
    }


  }

  //#endregion
}
