




import { CommonGroupByCounterDataResponse } from './CommonGroupByCounterDataResponse';
import { CommonGridResponse } from './CommonGridResponse';    
export class CommonGridResponseWithGroupBy<TDto, TGroupByValue, TAggregationDto> extends CommonGridResponse<TDto,TAggregationDto>  {
  
  public groupByCounter: CommonGroupByCounterDataResponse<TGroupByValue>[] = [];
  
}



