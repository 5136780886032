import { AfterViewInit, Directive, EventEmitter, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent as KendoGridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { CommonGridColumnDataDefinition, CommonGridResponse } from 'dto';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GridHelperMethods } from '../../classes/GridHelperMethods';
import { GridSetting } from '../../classes/GridSetting';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseGridContract } from '../../interfaces/IBaseGridContract';
import { IBaseGridResponseContract } from '../../interfaces/IBaseGridResponseContract';
import { ICrudEntity } from '../../interfaces/ICrudEntity';
import { FixToParentHeightService } from '../../services/common/fix-to-parent-height.service';
import { GridComponent } from '../grid/grid.component';
import { BaseComponent } from './base.component';
import { CoreLib_Classes_Guid } from 'core';
import { GridService } from '../../services/common/grid.service';

/**
 * E' la classe base dalla quale ereditano i componenti che al loro interno gestiscono una grid con i dati di una specifica entità
 * Tali dati verranno caricati in funzione del valore della proprietà uid ricevuta in input
 */
@Directive()
export class BaseGridComponent<TGridDto extends ICrudEntity, TAggregationDto> extends BaseComponent implements IBaseGridContract, IBaseGridResponseContract<TGridDto, TAggregationDto>, OnInit, AfterViewInit, OnDestroy, OnChanges {

  //#region Private Properties...

  private parentHeightChangedSubscription: Subscription;

  private gridHeightChangedSubscription: Subscription;

  private onResizeSubscription: Subscription;

  private grid_dataStateChangeSubscription: Subscription;

  private grid_columnResizeSubscription: Subscription;

  private grid_columnReorderSubscription: Subscription;

  private oldRaisedUid: string = 'fake';

  @ViewChild(KendoGridComponent)
  private set _grid(content: KendoGridComponent) {
    this.grid = content;
  }

  @ViewChild(GridComponent)
  private set _gridContent(content: GridComponent) {
    if (content != null) {
      this.grid = content.grid;
    }
  }

  private gridHeightChanged = new Subject<boolean>();
  //#endregion

  //#region Public Input Properties...

  /**
   * Imposta il valore dello uid dell'entità principale.
   */
  @Input()
  public uid: string;

  public isFirstRequest: boolean = true;

  public saveSortState: boolean;

  public saveFilterState: boolean;

  @Input()
  public excelFileName: string = "";
  //#endregion

  //#region Public Properties...

   /**
   * Mappa il template con i pulsanti custom della riga della griglia, visibili in linea
   */
    @ViewChild('templatePrimaryExtraButtons')
    public set templatePrimaryExtraButtons(val: TemplateRef<any>){
      this.gridSetting.templatePrimaryExtraButtons = val;
    }
  
    /**
     * Mappa il template con i pulsanti custom della riga della griglia, visibili nel popup
     */
    @ViewChild('templateSecondaryExtraButtons')
    public set templateSecondaryExtraButtons(val: TemplateRef<any>){
      this.gridSetting.templateSecondaryExtraButtons = val;
    }
  
    /**
     * Mappa il template della riga in modalità mobile
     */
    @ViewChild('templateComputed')
    public set templateComputed(val: TemplateRef<any>){
      this.gridSetting.templateComputed = val;
    }
      
    /**
  * Mappa il template della toolbar della griglia
  */
    @ViewChild('templateToolbar')
    public set templateToolbar(val: TemplateRef<any>){
      this.gridSetting.templateToolbar = val;
    }
    
  /**
   * Ospita il componente opzionale che definisce i vari template custom della griglia, i crud services e le proprietà.
   * Utile per centralizzare in un componente separato, gli oggetti (template, services ecc..) che possono essere utilizzati in più griglie
   */
  public searchSchemaTemplate: BaseComponent;
  @ViewChild('searchSchemaTemplate') set _searchSchemaTemplate(content: BaseComponent) {
    this.searchSchemaTemplate = content;
  }


  /**
   * Il riferimento al componente KendoGrid
   */
  public grid: KendoGridComponent;

  private _gridResponse: CommonGridResponse<GridDataResult, TAggregationDto> = null;
  /**
   * Ospita la sorgente dati della griglia:
   * - Il nome del search schema
   * - Il set di colonne
   * - I record della pagina corrente
   * - Il campo utilizzato per l'ordinamento iniziale
   */
  public get gridResponse() {
    return this._gridResponse;
  }
  public set gridResponse(val: CommonGridResponse<GridDataResult, TAggregationDto>) {
    this._gridResponse = val;
    GridHelperMethods.afterGridResponseSet(this, val);    
  }

  /**
   * Restituisce o imposta l'oggetto GridDataResult che contiene il numero totale di elementi e l'array degli elementi della pagina corrente
   */
  public items: GridDataResult;

  /**
   * Restituisce o imposta l'oggetto con lo stato corrente della griglia (pagina, filtri, ordinamento)
   */
  public state: DataSourceRequestState = {
    skip: 0,
    take: 30,
  };

  /**
   * Raccoglie in un unico oggetto, tutte le impostazioni per la griglia
   * - Flag isLoaded
   * - Proprietà gridHeight
   * - Tutti i template
   * - La definizione delle colonne
   * @returns un oggetto di tipo GridSetting
   */

   private _gridSetting = new GridSetting();
   public get gridSetting() {
     return this._gridSetting;
   }
   public set gridSetting(value) {
     this._gridSetting = value;
   }

  /**
   * Espone al template la view corrente
   */
  public thisView: any = this;


  private _selectionMode: GridSelectionModes = GridSelectionModes.None;

  public get selectionMode() {
    return this._selectionMode;
  }
  public set selectionMode(val: GridSelectionModes) {
    this._selectionMode = val;
    this.selectionModeChange.emit(val);
  }
  @Output() selectionModeChange = new EventEmitter<GridSelectionModes>();


  public selectedOrUnselectedUids: { [index: string]: TGridDto } = {};

  //#endregion

  //#region Constructor...
  /**
* Inizializza una nuova istanza del componente.
*
* @param injector Il servizio che consente di ricavare le istanze di altri servizi tramite dependency injection
*/
  constructor(injector: Injector) {
    super(injector);

    this.parentHeightChangedSubscription = this.injector.get(FixToParentHeightService).parentHeightChanged.pipe(debounceTime(100)).subscribe(() => setTimeout(() => { this.gridHeightChanged.next(true); }, 100));

    this.gridHeightChangedSubscription = this.gridHeightChanged.pipe(debounceTime(100)).subscribe(() => { this.updateGridHeight(); });

    this.onResizeSubscription = fromEvent(window, 'resize').pipe(
      debounceTime(500))
      .subscribe(() => {
        this.gridHeightChanged.next(true);
      });

  }

  //#endregion

  //#region Lifecycle Methods...

  override async ngOnInit() {
    await super.ngOnInit();
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    if (this.grid != null) {
      this.grid_dataStateChangeSubscription = this.grid.dataStateChange.subscribe((e) => this.dataStateChange(e));

      this.grid_columnResizeSubscription = this.grid.columnResize.pipe(debounceTime(500))
        .subscribe((e) => this.columnResize());

      this.grid_columnReorderSubscription = this.grid.columnReorder.pipe(debounceTime(500))
        .subscribe((e) => this.columnReorder());
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.parentHeightChangedSubscription?.unsubscribe();
    this.gridHeightChangedSubscription?.unsubscribe();
    this.onResizeSubscription?.unsubscribe();
    this.grid_dataStateChangeSubscription?.unsubscribe();
    this.grid_columnResizeSubscription?.unsubscribe();
    this.grid_columnReorderSubscription?.unsubscribe();
  }

  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    Promise.resolve(null).then(async () => {
      for (const propName in changes) {
        if (propName != null) {
          await this.raisePropertyChanged(propName);
        }
      }
    });
  }

  //#endregion

  //#region Private Methods...

  private updateGridHeight(): void {

    const ngZone = this.injector.get(NgZone) as NgZone;

    // if (this.isSmallDevice) {
    //   this.gridSetting.gridHeight = null;
    //   if (this.grid != null) {
    //     this.grid.wrapper.nativeElement.style.height = '';
    //   }
    //   return;
    // }


    ngZone.runOutsideAngular(() => {

      let childPos = this.grid.wrapper.nativeElement.getBoundingClientRect();

      let parent = this.grid.wrapper.nativeElement.parentElement;

      let gridRect = this.grid.wrapper.nativeElement.getBoundingClientRect();
      
      let parentTops = gridRect.top;
      
      let parentPos: any = null;

      let foundCorrectWrapper: boolean = false;
      while (foundCorrectWrapper == false && parent != null) {

        parentPos = parent.getBoundingClientRect();

        if (parent.getAttribute("fixToParentHeight") != null || parent.className.indexOf('desk-scrollable-y') > -1 || parent.className.indexOf('container-fluid') > -1) {
          foundCorrectWrapper = true;
        } else {
          
          if (childPos.top - parentPos.top > 0) {
            parentTops += childPos.top - parentPos.top;
          }

          parent = parent.parentElement;
        }

        childPos = parentPos;

      }

      if (this.grid != null) {
        this.grid.wrapper.nativeElement.style.height = (parent.clientHeight - (gridRect.top -  parentPos.top) - 13) + 'px';
        ngZone.run(() => this.gridSetting.gridHeight = parent.clientHeight - (gridRect.top -  parentPos.top) -13 );
      }

    });

    //GridHelperMethods.preventDebounceOnTextualFilters(this);
    
  }

  public exportExcel() {

    this.getAllItems = this.getAllItems.bind(this);

    this.grid.saveAsExcel();
  }

  public async clearFilters() {
    this.state.filter = null;
    this.injector.get(GridService).gridFilterCleared.next(true);
    await this.loadItems();
  }
  //#endregion

  //#region Protected Methods...

  /**
* Viene invocato dal metodo ngOnChanges. Sovrascrive quanto definito nella classe base BaseComponent, per notificare quando cambia lo Uid. In questo modo è possibile ricaricare i dati nella griglia, dato il nuovo uid
* @param propName il nome della proprietà che ha modificato il valore
*/
  protected override async raisePropertyChanged(propName: string): Promise<void> {
    if (propName == 'uid' && this.uid != '' && this.uid != this.oldRaisedUid) {
      this.oldRaisedUid = this.uid;
      if (CoreLib_Classes_Guid.IsValid(this.uid)) {
        await this.loadItems();
      }
    }
  }


  //#endregion

  //#region Public Methods...

  /**
 * Invoca il metodo getItems per ottenere i dati per la griglia. La classe che eredita, deve invocare questo metodo nell'OnInit e in tutti i punti in cui ha bisogno di ricaricare la griglia
 */
  public async loadItems() {
    this.gridResponse = await this.getItems(this.state, null);
    await this.itemsLoaded();
  }

  /**
   * Viene utilizzato dalla procedura di export excel.
   * @returns Restituisce un array di elementi con tutte le righe della griglia
   */
  public async getAllItems(): Promise<any> {
    return GridHelperMethods.getAllItems(this, this);
  }

  /**
   * Viene invocato come ultima cosa dal metodo loadItems. Questo metodo esegue alcune operazioni sulla griglia dopo che è stata caricata (Sistema l'altezza, gestisce la pressione del tasto enter sui filtri ecc...).
   */
  public async itemsLoaded() {
    this.isFirstRequest = false;
    GridHelperMethods.handleItemsLoaded(this);
    this.gridHeightChanged.next(true);
  }

  public onExcelExport(e: any): void{
    GridHelperMethods.baseOnExcelExport(e);
  }

  /**
   * Gesisce gli eventi di change della griglia (cambio pagina, filtraggio, ordinamento)
   * @param state L'oggetto state della griglia
   */
  public async dataStateChange(state: DataStateChangeEvent): Promise<void> {
    GridHelperMethods.handleDataStateChange(this, state);
  }

  /**
   * Viene invocato quando l'utente ridimensiona una colonna della griglia e si preoccupa di salvare lo stato
   */
  public async columnResize() {
    await GridHelperMethods.saveColumnResizeState(this);
  }

  /**
   * Viene invocato quando l'utente sposta una colonna della griglia e si preoccupa di salvare lo stato
   */
  public async columnReorder() {
    await GridHelperMethods.saveColumnReorderState(this);
  }


  /**
   * Restituisce il value field name corretto di una colonna alla griglia, gestendo le colonne di tipo pers
   * @param columnDefinition la column definition dalla quale estrarre il value field name
   * @returns Il value field name della colonna
   */
  public getValueFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getValueFieldName(columnDefinition);
  }

  /**
   * Restituisce il text field name corretto di una colonna alla griglia, gestendo le colonne di tipo pers
   * @param columnDefinition la column definition dalla quale estrarre il text field name
   * @returns Il text field name della colonna
   */
  public getTextFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getTextFieldName(columnDefinition);
  }

  
  /**
   * Restituisce il text field name corretto di una colonna alla griglia, gestendo le colonne di tipo pers
   * @param columnDefinition la column definition dalla quale estrarre il text field name
   * @returns Il text field name della colonna
   */
  public getComposedTextFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getComposedTextFieldName(columnDefinition);
  }

  /**
   * Restituisce alla griglia il titolo di una colonna tradotto se necessario
    * @param columnDefinition la column definition dalla quale estrarre il text field name
    * @returns Il titolo della colonna
   */
  public getFieldTitle(columnDefinition: CommonGridColumnDataDefinition): string {
    return GridHelperMethods.getFieldTitle(this, columnDefinition);
  }

  /**
   * Restituisce alla griglia il contesto dal quale ricavare i template
   */
  public get parentContext(): any { return GridHelperMethods.getParentContext(this); }

  public itemUidSelectionChange(value: boolean, itemUid: string) {
    this.itemSelectionChange(value, this.items.data.find(c => c.uid == itemUid));
  }

  public itemSelectionChange(value: any, item: TGridDto) {
    if (this.selectionMode == GridSelectionModes.MultipleAllSelected) { // se di base è tutto selezionato
      if (value == true) {
        // se ha prima deselezionato e poi riselezionato, rimuovo l'elemento precedentemente aggiunto con la deselezione
        this.selectedOrUnselectedUids[item.uid] = null;
      } else {
        // se ha deselezionato lo aggiungo ai deselezionati
        this.selectedOrUnselectedUids[item.uid] = item;
      }
    } else if (this.selectionMode == GridSelectionModes.MultipleAllUnselected) { // se di base è tutto deselezionato
      if (value == true) {
        // se ha selezionato lo aggiungo ai selezionati
        this.selectedOrUnselectedUids[item.uid] = item;
      } else {
        // se ha prima selezionato e poi deselezionato, rimuovo l'elemento precedentemente aggiunto con la selezione
        this.selectedOrUnselectedUids[item.uid] = null;
      }
    }
  }

  public hasUidSelected(uid: string): boolean {
    return this.selectedOrUnselectedUids[uid] != null;
  }

  //#endregion

  //#region Mandatory Overridable Methods

  /**
    * Metodo che è necessario sovrascrivere nella classe derivata.
    * In questo metodo la classe derivata si deve preoccupare di restituire l'oggetto CommonGridResponse<GridDataResult>, invocando il servizio http dedicato
    */
  public async getItems(state: DataSourceRequestState, uid: string): Promise<CommonGridResponse<GridDataResult, TAggregationDto>> {
    return null;
  }


  //#endregion




}
