<div class="page-title" *ngIf="crudView == null || crudView.titleVisible">
  <h2 *ngIf="crudView == null || crudView.viewMode == viewModes.List">
    <ng-container *ngIf="crudView">{{crudView.localizeByModule['PAGETITLE']}}</ng-container>
    <ng-container *ngIf="crudView == null">{{itemDescription}}</ng-container>
  </h2>

  <h2 *ngIf="crudView != null && crudView.viewMode == viewModes.New">
    {{crudView.localizeByModule['PAGETITLENEW']}}
  </h2>

  <h2 *ngIf="crudView != null && crudView.viewMode == viewModes.Edit">
    {{crudView.localizeByModule['PAGETITLEEDIT']}}&nbsp;{{itemDescription}}
  </h2>

  <h2 *ngIf="crudView != null && crudView.viewMode == viewModes.View">
    {{crudView.localizeByModule['PAGETITLEVIEW']}}&nbsp;{{itemDescription}}
  </h2>
</div>