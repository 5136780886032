<div class="app-multi-select" [class.big]="bigger" [class.error]="hasError" [class.ondemand]="ondemand"
  [class.hideheader]="hideheader" [class.focus]="hasFocus" [class.loading]="isInLoadingOnDemand">
  <div [id]="erroranchor"></div>

  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
  title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory"
    *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>


  <div class="read-only-input-span" *ngIf="selectedItems && selectedItems.length > 0 && disabled && itemTemplate">
    <ng-container *ngFor="let item of selectedItems" [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{$implicit: item}">
    </ng-container>
  </div>

  <div class="read-only-input-span" [hidden]="!disabled"
    *ngIf="itemTemplate == null || selectedItems == null || selectedItems.length == 0">{{readOnlyValue}}</div>
  <div *ngIf="!disabled" class="d-flex">
    <div class="align-self-start" style="width:100%">

      <kendo-multiselect #multiselect *ngIf="!ondemand" [data]="itemsSource" (focus)="focus()" (blur)="blur()" (close)="close()" (open)="open()"
        [(ngModel)]="selectedItems" [filterable]="ondemand" [textField]="textField" [valueField]="valueField"
        [allowCustom]="allowCustom" [valueNormalizer]="normalizer" (filterChange)="handleFilter($event)" [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="false" [placeholder]="''"
        [popupSettings]="{ popupClass: (bigger) ? 'app-multi-select-popup big' : 'app-multi-select-popup'}">
        <ng-template kendoMultiSelectItemTemplate let-dataItem *ngIf="itemTemplate">
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
          </ng-container>
        </ng-template>
        <ng-template kendoMultiSelectTagTemplate let-dataItem *ngIf="tagTemplate">
          <ng-container [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
          </ng-container>
        </ng-template>
        <ng-template kendoMultiSelectNoDataTemplate>
          <span class="help-span ml-2 mr-2">
            {{noDataText}}
          </span>
        </ng-template>
      </kendo-multiselect>


      <kendo-multiselect #multiselect *ngIf="ondemand" [data]="itemsSource" (focus)="focus()" (blur)="blur()" (close)="close()" (open)="open()"
        [(ngModel)]="selectedItems" [filterable]="ondemand" [textField]="textField" [valueField]="valueField"
        [allowCustom]="allowCustom" (filterChange)="handleFilter($event)" [valuePrimitive]="false"
        [placeholder]="''"
        [popupSettings]="{ popupClass: (bigger) ? 'app-multi-select-popup big' : 'app-multi-select-popup'}">
        <ng-template kendoMultiSelectItemTemplate let-dataItem *ngIf="itemTemplate">
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
          </ng-container>
        </ng-template>
        <ng-template kendoMultiSelectTagTemplate let-dataItem *ngIf="tagTemplate">
          <ng-container [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
          </ng-container>
        </ng-template>
        <ng-template kendoMultiSelectNoDataTemplate>
          <span class="help-span ml-2 mr-2">
            {{noDataText}}
          </span>
        </ng-template>
      </kendo-multiselect>

      <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span
      class="mandatory" *ngIf="mandatory" [innerHtml]="mandatoryText"></span> </span>
    </div>
    <div class="d-inline-flex right-buttons" *ngIf="showExtendedSearch">
      <ng-content select=".right-buttons-container-left"></ng-content>
      <app-button [icon]="'search'" [inverted]="true" (buttonClick)="popupOpen()">
      </app-button>
      <ng-content select=".right-buttons-container-right"></ng-content>

    </div>
  </div>
  <span class="error-span" [innerHtml]="error"></span>
</div>
<app-async-load-panel *ngIf="popupOpened" (init)="loadComponent()">
  <kendo-dialog [title]="dialogTitle" (close)="popupClose()" class="k-dialog-full k-dialog-notscroll">
    <div class="container-fluid">
      <ng-template popup-host></ng-template>
    </div>
    <app-loader></app-loader>
  </kendo-dialog>
</app-async-load-panel>