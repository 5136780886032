import { Location } from '@angular/common';
import { AfterViewInit, Directive, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_PopupService } from 'core';
import { RolePermissionFlag } from '../../classes/RolePermissionFlag';
import { DeactivationService } from '../../services/common/deactivation.service';
import { RolePermissionService } from '../../services/common/role-permission.service';
import { BaseComponent } from './base.component';

/**
 * E' la classe base di tutti componenti di tipo View.
 */
@Directive()
export class BaseViewComponent extends BaseComponent implements OnInit, AfterViewInit {

  //#region Private Properties...


  //#endregion

  //#region Protected Properties...
  /**
 * Espone alle classi derivate il servizio DeactivationService
 */
  protected deactivationService: DeactivationService;

  /**
 * Espone alle classi derivate il servizio PopupService
 */
  protected popupService: CoreLib_Services_Common_PopupService;

  /**
 * Espone alle classi derivate il servizio Router
 */
  protected router: Router;

  /**
 * Espone alle classi derivate il servizio che contiene il set di record della tabella RolePermissionDesk, per il componente corrente (data la sua formReference)
 */
  protected rolePermissionService: RolePermissionService;

  /**
 * Espone alle classi derivate il servizio Title
 */
  protected titleService: Title;

  //#endregion

  //#region Public Properties...

  /**
* Espone alle classi derivate le impostazioni definite nella tabella RolePermissionDesk per il componente corrente (data la sua formReference), relativamente a:
* Flag IsViewEnabled
* Flag IsEditEnabled
* Flag IsNewEnabled
* Flag IsDeleteEnabled
*/
  public get rolePermission(): RolePermissionFlag {
    if (this.rolePermissionService.rolePermissions[this.formReference] == null) {
      return new RolePermissionFlag(true, true, true, true);
    } else {
      return this.rolePermissionService.rolePermissions[this.formReference];
    }
  }

  //#endregion

  //#region Constructor...
  /**
  * Inizializza una nuova istanza del componente.
  *
  * @param injector Il servizio che consente di ricavare le istanze di altri servizi tramite dependency injection
  */
  constructor(injector: Injector) {
    super(injector);

    this.rolePermissionService = injector.get(RolePermissionService);
    this.deactivationService = injector.get(DeactivationService);
    this.popupService = injector.get(CoreLib_Services_Common_PopupService);
    this.router = injector.get(Router);
    this.titleService = injector.get(Title);

 
    this.setCurrentView();
  }

  //#endregion

  //#region Lifecycle Methods...

  public setCurrentView(){
    this.deactivationService.currentView = this;

  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }
  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    const homeRoute = this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home');

    if (this.rolePermission.isViewEnabled == false) {
      this.router.navigate([homeRoute]);
    }
  }

  //#endregion

  //#region Private Methods...

  //#endregion

  //#region Protected Methods...



  //#endregion

  //#region Public Methods...

  /**
   * E' possibile sovrascrivere questo metodo nella classe derivata. Restituendo true, si informa il DeactivationService che l'utente deve essere avvisato di eventuale perdita modifiche prima di abbandonare la pagina corrente.
   * @returns Un valore booleano che indica se la pagina è in modalità edit.
   */
  public isInEditMode(): boolean {
    return false;
  }

  /**
  * Gestisce il fatto di avvisare l'utente prima di abbabandonare la pagina, se questa è in modalità edit (se il flag isInEditMode sta restituendo true)
  * Viene invocato dalla guardia definita nel DeactivationService
  */
  public canDeactivateCalled(currentRoute: ActivatedRouteSnapshot): boolean {

    const location = this.injector.get(Location) as Location;
    const router = this.injector.get(Router) as Router;

    if (this.deactivationService.isInEditMode()) {
      this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo).then((result) => {
        if (result == CoreLib_Enums_PopupResultTypes.Yes) {
          this.deactivationService.forceExit = true;
          location.back();
        }
      });
      const currentUrlTree = router.createUrlTree([], currentRoute);

      location.go(currentUrlTree.toString());
      return false;
    } else {
      return true;
    }
  }
  //#endregion


}
