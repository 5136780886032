




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonEvalResponse extends BaseTokenResponse  {
  
  public result: string = '';
  
  public evaluated: boolean = false;
  
  public hasEvaluationErrors: boolean = false;
  
}



