import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CommonNotifyLogoutRequest, CoreConstants } from 'dto';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApplicationStateService } from './application-state.service';
import { WebViewHostService } from './web-view-host.service';



@Injectable({ providedIn: 'root' })
export class AuthenticationService implements CanActivate {

  //#region Declarations...

  accountDescription = new BehaviorSubject<string>('');

  reloginRequested = new Subject<boolean>();

  loginRequested = new Subject<boolean>();

  logoutRequested = new Subject<CommonNotifyLogoutRequest>();

  private _redirectUrl: string = '';

  public unsecuredUrls: string[] = [];

  get redirectUrl(): string {
    if (this._redirectUrl == '' || this._redirectUrl == '/home') {
      return this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home');
    } else {
      return this._redirectUrl;
    }
  }
  set redirectUrl(val: string) {
    this._redirectUrl = val;
  }

  isInLogin: boolean = false;

  isWindowsAuth: boolean = false;

  attemptRelogin: boolean = false;

  //#endregion

  //#region Constructors...

  constructor(private router: Router, private cookieService: CookieService, private applicationStateService: ApplicationStateService, private webViewHostService: WebViewHostService) {
    this.accountDescription.next(this.getAccountDescription());

  }

  //#endregion

  //#region Methods...

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isLoggedIn() || this.attemptRelogin || this.isWindowsAuth) {
      return true;
    }

    if (state.url == '/' || state.url == '/login') {
      this.redirectUrl = this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home');
    } else {
      this.redirectUrl = state.url;
    }

    // const request = new CommonNotifyLogoutRequest();
    // request.loginUid = this.getLoginUid();
    // request.accountName = this.getAccountName();
    // request.isFromTokenExpired = false;
    // this.logoutRequested.next(request);

    // go login page
    this.router.navigate(['/login']);

    return false;
  }

  isLoggedIn(): boolean {
    return this.getToken().length > 0;
  }

  logout(): void {

    const request = new CommonNotifyLogoutRequest();
    request.loginUid = this.getLoginUid();
    request.accountName = this.getAccountName();
    request.isFromTokenExpired = false;
    this.setToken('');
    this.setAccountDescription('');
    this.setAccountName('');
    this.setLoginUid('');

    this.logoutRequested.next(request);

  }

  relogin(): void {
    if (this.isLoggedIn()) {

      this.setToken('');

      if (this.applicationStateService.isEmbedded) {
        this.webViewHostService.sendMessage(CoreConstants.DeskMessages.TOKENEXPIRED, '');
      } else {
        // const request = new CommonNotifyLogoutRequest();
        // request.loginUid = this.getLoginUid();
        // request.accountName = this.getAccountName();
        // request.isFromTokenExpired = true;

        // this.setAccountDescription('');

        //this.logoutRequested.next(request);
        this.reloginRequested.next(true);
      }

    } else if (!this.applicationStateService.isEmbedded && this.getAccountName() == '') {
      this.attemptRelogin = false;
      this.router.navigate(['/login']);
    }
  }

  setAccountDescription(accountDescription: string): void {
    this.accountDescription.next(accountDescription);
    this.cookieService.set('AccountDescription' + location.port, accountDescription, null, '/', null, false, 'Lax');
  }

  getAccountDescription(): string {
    if (this.cookieService.check('AccountDescription' + location.port)) {
      return this.cookieService.get('AccountDescription' + location.port);
    } else {
      return '';
    }
  }

  setToken(token: string): void {
    if (token.length == 0) {
      this.cookieService.set('Token' + location.port, token, null, '/', null, false, 'Lax');
      this.cookieService.delete('Token' + location.port);
    } else {
      this.cookieService.set('Token' + location.port, token, null, '/', null, false, 'Lax');
    }
  }

  getToken(): string {
    if (this.cookieService.check('Token' + location.port)) {
      return this.cookieService.get('Token' + location.port);
    } else {
      return '';
    }
  }

  setLoginUid(loginUid: string): void {
    this.cookieService.set('LoginUid' + location.port, loginUid, null, '/', null, false, 'Lax');
  }

  getLoginUid(): string {
    if (this.cookieService.check('LoginUid' + location.port)) {
      return this.cookieService.get('LoginUid' + location.port);
    } else {
      return '';
    }
  }

  setAccountName(accountName: string): void {
    this.cookieService.set('AccountName' + location.port, accountName, null, '/', null, false, 'Lax');
  }
  getAccountName(): string {
    if (this.cookieService.check('AccountName' + location.port)) {
      return this.cookieService.get('AccountName' + location.port);
    } else {
      return '';
    }
  }

  //#endregion

}
