import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PagerErrorConfig } from '../../classes/PagerErrorConfig';


@Injectable({ providedIn: 'root' })
export class PagerErrorService {

  //#region Declarations...

  public checkPagedErrors = new Subject<PagerErrorConfig>();

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  //#endregion

}
