<div class="card-item">
  <div #target class="filter-operator-selector">
    <div class="badge badge-primary" [class.dark-background-color]="!hasFilter" [class.main-background-color]="hasFilter">
      {{operatorString}}
    </div>
  </div>
  <div class="right-side">
    <div class="row">
      <div [class.col-md-12]="operator != operators.BETWEEN && operator != operators.NOTBETWEEN"
        [class.col-md-6]="operator == operators.BETWEEN || operator == operators.NOTBETWEEN">

        <app-date-picker *ngIf="operator != operators.ISNULL && operator != operators.ISNOTNULL" [header]="(operator == operators.BETWEEN || operator == operators.NOTBETWEEN) ? computedHeaderFrom : header" [(value)]="from" [error]="error"></app-date-picker>

        <app-label-box *ngIf="operator == operators.ISNULL" [header]="header" [text]="localizeByCommon['IS_EMPTY']">
        </app-label-box>

        <app-label-box *ngIf="operator == operators.ISNOTNULL" [header]="header"
          [text]="localizeByCommon['IS_NOTEMPTY']">
        </app-label-box>
      </div>
      <div class="col-md-6" *ngIf="operator == operators.BETWEEN || operator == operators.NOTBETWEEN">
        <app-date-picker [header]="computedHeaderTo" [(value)]="to"></app-date-picker>
      </div>
    </div>


  </div>
</div>
<kendo-contextmenu showOn="click" [target]="target" (select)="selectOperator($event)">
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_EQ']" [data]="operators.EQ"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_NOTEQ']" [data]="operators.NOTEQ"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_GREATHERTHAN']" [data]="operators.GREATHERTHAN">
  </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_GREATHERTHANOREQUALSTO']"
    [data]="operators.GREATHERTHANOREQUALSTO"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_LESSTHAN']" [data]="operators.LESSTHAN"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_LESSTHANOREQUALSTO']" [data]="operators.LESSTHANOREQUALSTO">
  </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_BETWEEN']" [data]="operators.BETWEEN"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_NOTBETWEEN']" [data]="operators.NOTBETWEEN">
  </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_ISNULL']" [data]="operators.ISNULL"> </kendo-menu-item>
  <kendo-menu-item [text]="localizeByCommon['FILTEROPERATOR_ISNOTNULL']" [data]="operators.ISNOTNULL">
  </kendo-menu-item>
</kendo-contextmenu>
