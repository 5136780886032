import { Component, EventEmitter, Injector, Input, ViewChild, Output, AfterViewInit, OnInit, OnDestroy, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { ColumnBase, ColumnReorderEvent, ColumnResizeArgs, DataStateChangeEvent, GridComponent as KendoGridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CoreLib_Classes_Poco_EntityIntString, CoreLib_Services_Http_DeskCommonService } from 'core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators';

import { GridSetting } from '../../classes/GridSetting';
import { IBaseGridContract } from '../../interfaces/IBaseGridContract';
import { FixToParentHeightService } from '../../services/common/fix-to-parent-height.service';
import { BaseComponent } from '../_base/base.component';
import { GridHelperMethods } from '../../classes/GridHelperMethods';
import { CommonAccountDeskGridStateSetColumnSizeSettingsRequest, CommonAccountDeskGridStateColumnSizeSettingDataRequest, CommonAccountDeskGridStateSetColumnOrderSettingsRequest, CommonAccountDeskGridStateColumnOrderSettingDataRequest, CommonGridColumnDataDefinition } from 'dto';
import { CellOptions } from '@progress/kendo-angular-excel-export';




@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  //#region Declarations...

  private grid_dataStateChangeSubscription: Subscription;
  private grid_columnResizeSubscription: Subscription;
  private grid_columnReorderSubscription: Subscription;

  private parentHeightValueChangedSubscription: Subscription;

  public grid: KendoGridComponent;

  @ViewChild(KendoGridComponent) set content(content: KendoGridComponent) {
    this.grid = content;
  }

  //#endregion

  //#region Properties...

  @Input()
  public state: DataSourceRequestState;

  @Input()
  public items: GridDataResult;

  @Input()
  public view: IBaseGridContract;

  private _gridSetting: GridSetting;
  @Input()
  public get gridSetting(): GridSetting {
    return this._gridSetting;
  }
  public set gridSetting(value: GridSetting) {
    this._gridSetting = value;
  }

  public get gridColumns() {
    return this.gridSetting?.columnsDefinitions?.filter(c => c.isVisible);
  }

  public get excelColumns() {
    return this.gridSetting?.columnsDefinitions?.filter(c => c.isExcelVisible);
  }

  @Input()
  public showActionsColumn: boolean = true;

  @Input()
  public actionsColumnWidth: number = 90;

  @Input()
  public filterable: boolean = true;

  @Input()
  public groupable: boolean = false;


  @Input()
  public rowCallback: any = this.defaultRowCallback;

  @Input()
  public fixToParentHeightName: string;

  @Input()
  public searchSchemaName: string;

  @Output()
  public dataStateChange = new EventEmitter<DataStateChangeEvent>()

  @Input()
  public showRecordInfoColumn: boolean = true;

  @Input()
  public toolbarPosition: "top" | "bottom" | "both" = "top";

  //#endregion

  //#region Constructors...

  constructor(injector: Injector) {
    super(injector);

    this.parentHeightValueChangedSubscription = this.injector.get(FixToParentHeightService).parentHeightValueChanged.pipe(debounceTime(100)).subscribe((e: CoreLib_Classes_Poco_EntityIntString) => this.updateGridHeight(e));
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();


    if (this.grid != null) {
      this.grid_dataStateChangeSubscription = this.grid.dataStateChange.pipe(debounceTime(50))
        .subscribe((e) => this.internalDataStateChange(e));

      this.grid_columnResizeSubscription = this.grid.columnResize.pipe(debounceTime(500))
        .subscribe((e) => this.internalColumnResize(e));

      this.grid_columnReorderSubscription = this.grid.columnReorder.pipe(debounceTime(500))
        .subscribe((e) => this.internalColumnReorder(e));
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.grid_dataStateChangeSubscription != null) {
      this.grid_dataStateChangeSubscription.unsubscribe();
    }

    if (this.grid_columnResizeSubscription != null) {
      this.grid_columnResizeSubscription.unsubscribe();
    }

    if (this.grid_columnReorderSubscription != null) {
      this.grid_columnReorderSubscription.unsubscribe();
    }

    if (this.parentHeightValueChangedSubscription != null) {
      this.parentHeightValueChangedSubscription.unsubscribe();
    }

  }

  public async internalDataStateChange(state: DataStateChangeEvent): Promise<void> {
    this.dataStateChange.emit(state);
  }

  public async internalColumnResize(args: Array<ColumnResizeArgs>) {
    GridHelperMethods.adjustLastColumn(this.view, this.grid);
    await this.saveColumnResizeState();
  }

  public async internalColumnReorder(args: ColumnReorderEvent) {
    await this.saveColumnReorderState();
  }

  public allData = (): Promise<any> => {
    return this.view.getAllItems();
  }


  public async saveColumnResizeState() {
    if (this.searchSchemaName != null) {
      const api: CoreLib_Services_Http_DeskCommonService = this.injector.get(CoreLib_Services_Http_DeskCommonService);
      const request = new CommonAccountDeskGridStateSetColumnSizeSettingsRequest();
      request.searchSchemaName = this.searchSchemaName;
      request.gridColumnSizeSettings = this.grid.columns.filter(item => (item as any).field != null).map(item => <CommonAccountDeskGridStateColumnSizeSettingDataRequest>{ fieldName: GridHelperMethods.parseFieldNameForPers((item as any).field), width: item.width });

      await api.accountDeskGridStateSetColumnSizeSettings(request);
    }
  }

  public async saveColumnReorderState() {
    if (this.searchSchemaName != null) {
      const api: CoreLib_Services_Http_DeskCommonService = this.injector.get(CoreLib_Services_Http_DeskCommonService);
      const request = new CommonAccountDeskGridStateSetColumnOrderSettingsRequest();
      request.searchSchemaName = this.searchSchemaName;
      request.gridColumnOrderSettings = this.grid.columns.filter(item => (item as any).field != null).map(item => <CommonAccountDeskGridStateColumnOrderSettingDataRequest>{ fieldName: GridHelperMethods.parseFieldNameForPers((item as any).field), orderIndex: item.orderIndex });

      await api.accountDeskGridStateSetColumnOrderSettings(request);
    }
  }

  private updateGridHeight(e: CoreLib_Classes_Poco_EntityIntString): void {

    if (e.description == this.fixToParentHeightName) {
      const ngZone = this.injector.get(NgZone) as NgZone;

      ngZone.runOutsideAngular(() => {
        if (this.grid != null) {
          this.grid.wrapper.nativeElement.style.height = (e.value) + 'px';
        }
      });
      if (this.grid != null) {
        this.grid.height = (e.value);
      }
    }
  }

  public defaultRowCallback(context: RowClassArgs) {
    //const isEven = context.index % 2 == 0;
    return {

    };
  }

  public getCellOptions(columnDefinition: CommonGridColumnDataDefinition) {
    if (columnDefinition.excelCellOptions != null && columnDefinition.excelCellOptions != '') {
      return JSON.parse(columnDefinition.excelCellOptions) as CellOptions;
    } else {
      return null;
    }
  }

  public onExcelExport(e: any): void {
    this.view.onExcelExport(e);
  }

  public getMobileExtraFiltersDescription(): string {
    return GridHelperMethods.getMobileExtraFiltersDescription(this.view);
  }

  //#endregion
}



