
import { AngularResizedEventModule } from 'angular-resize-event';
import { AsyncLoadPanelComponent } from './components/async-load-panel/async-load-panel.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { ComboBoxModule, DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { CommonModule } from '@angular/common';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { CoreLib_CoreModule } from 'core';
import { CrudGridActionsComponent } from './components/crud-grid-actions/crud-grid-actions.component';
import { CrudGridComponent } from './components/crud-grid/crud-grid.component';
import { CrudModuleHostComponent } from './components/crud-module-host/crud-module-host.component';
import { CrudToolbarComponent } from './components/crud-toolbar/crud-toolbar.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DefaultRouteComponent } from './views/default-route/default-route.component';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownListComponent } from './components/drop-down-list/drop-down-list.component';
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';
import { ExcelModule, FilterService, GridModule } from '@progress/kendo-angular-grid';
import { FixRowsHeightDirective } from './directives/fix-rows-height.directive';
import { FixToParentHeightDirective } from './directives/fix-to-parent-height.directive';
import { FixToParentHeightService } from './services/common/fix-to-parent-height.service';
import { FixToParentHeightTopSensorDirective } from './directives/fix-to-parent-height-top-sensor.directive';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridComponent } from './components/grid/grid.component';
import { GridDynamicCellComponent } from './components/grid-dynamic-cell/grid-dynamic-cell.component';
import { GridDynamicFilterCellComponent } from './components/grid-dynamic-filter-cell/grid-dynamic-filter-cell.component';
import { GridDynamicGroupCellComponent } from './components/grid-dynamic-group-cell/grid-dynamic-group-cell.component';
import { GridEmptyActionsComponent } from './components/grid-empty-actions/grid-empty-actions.component';
import { GridFilterDateComponent } from './components/grid-filter-date/grid-filter-date.component';
import { GridFilterMultiSelectComponent } from './components/grid-filter-multi-select/grid-filter-multi-select.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { KendoPanelBarItemHideIfCollapsedDirective } from './directives/kendo-panel-bar-item-hide-if-collapsed.directive';
import { LabelBoolComponent } from './components/label-bool/label-bool.component';
import { LabelBoxComponent } from './components/label-box/label-box.component';
import { LabelColorComponent } from './components/label-color/label-color.component';
import { LabelDateComponent } from './components/label-date/label-date.component';
import { LabelErrorComponent } from './components/label-error/label-error.component';
import { LabelExpandableComponent } from './components/label-expandable/label-expandable.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './views/login/login.component';
import { LoginOtherComponent } from './views/login-other/login-other.component';
import { MessageService as KendoMessageService } from '@progress/kendo-angular-l10n';
import { MessagesComponent } from './components/messages/messages.component';
import { MinutesAsHoursPipe } from './pipes/minutes-as-hours.pipe';
import { ModuleHostComponent } from './components/module-host/module-host.component';
import { ModuleHostDirective } from './directives/module-host.directive';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { MyKendoMessageService } from './services/common/my-kendo.message.service';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgModule } from '@angular/core';
import { NgProgressConfig, NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { NotificationsMenuComponent } from './components/notifications-menu/notifications-menu.component';
import { NumericUpDownComponent } from './components/numeric-up-down/numeric-up-down.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PagerComponent } from './components/pager/pager.component';
import { PagerErrorDirective } from './directives/pager-error.directive';
import { PagerModule } from '@progress/kendo-angular-pager';
import { PanelBarButtonsComponent } from './components/panel-bar-buttons/panel-bar-buttons.component';
import { PasswordBoxComponent } from './components/password-box/password-box.component';
import { PopupHostDirective } from './directives/popup-host.directive';
import { PopupModule } from '@progress/kendo-angular-popup';
import { RecordInfoComponent } from './components/record-info/record-info.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { SwitchComponent } from './components/switch/switch.component';
import { TemporaryStreamUploadComponent } from './components/temporary-stream-upload/temporary-stream-upload.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToastHolderComponent } from './components/toasts/toast-holder.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ToolbarLeftDirective } from './directives/toolbar-left.directive';
import { ToolbarLeftService } from './services/common/toolbar-left.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadModule } from '@progress/kendo-angular-upload';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ValidationService } from './services/common/validation.service';
import { GridFilterTimeComponent } from './components/grid-filter-time/grid-filter-time.component';
import { GridFilterCodeComponent } from './components/grid-filter-code/grid-filter-code.component';
import { GridToolbarComponent } from './components/grid-toolbar/grid-toolbar.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { GridFilterTextComponent } from './components/grid-filter-text/grid-filter-text.component';
import { GridFilterNumberComponent } from './components/grid-filter-number/grid-filter-number.component';
import { GridFilterBooleanComponent } from './components/grid-filter-boolean/grid-filter-boolean.component';
import { ReportSelectionComponent } from './components/report-selection/report-selection.component';
import { FilterStringComponent } from './components/filter-string/filter-string.component';
import { FilterLookupStaticComponent } from './components/filter-lookup-static/filter-lookup-static.component';
import { FilterLookupComponent } from './components/filter-lookup/filter-lookup.component';
import { FilterDateComponent } from './components/filter-date/filter-date.component';
import { FilterNumberComponent } from './components/filter-number/filter-number.component';
import { ImageThumbZoomableComponent } from './components/image-thumb-zoomable/image-thumb-zoomable.component';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';



@NgModule({
  imports: [
    AngularResizedEventModule,
    ButtonsModule,
    ComboBoxModule,
    CommonModule,
    ContextMenuModule,
    CoreLib_CoreModule,
    DateInputsModule,
    DialogsModule,
    DropDownsModule,
    ExcelModule,
    FormsModule,
    GridModule,
    InputsModule,
    LayoutModule,
    MultiSelectModule,
    NgCircleProgressModule.forRoot(),
    NgProgressHttpModule,
    NgProgressModule.withConfig({ debounceTime: 0 } as NgProgressConfig),
    NgProgressRouterModule,
    PagerModule,
    PopupModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot(),
    SortableModule,
    TooltipModule,
    UploadModule,
    WindowModule,        
    
  ],
  declarations: [
    AsyncLoadPanelComponent,
    ButtonComponent,
    CodeEditorComponent,
    ColorPickerComponent,
    ComboBoxComponent,
    CrudGridActionsComponent,
    CrudGridComponent,
    CrudModuleHostComponent,
    AutocompleteComponent,
    CrudToolbarComponent,
    DatePickerComponent,
    DefaultRouteComponent,
    DropDownListComponent,
    EscapeHtmlPipe,
    FixRowsHeightDirective,
    FixToParentHeightDirective,
    FixToParentHeightTopSensorDirective,
    FormatDatePipe,
    GridComponent,
    GridDynamicCellComponent,
    GridDynamicFilterCellComponent,
    GridDynamicGroupCellComponent,
    GridEmptyActionsComponent,
    GridFilterDateComponent,
    GridFilterTimeComponent,
    GridFilterMultiSelectComponent,
    GridFilterCodeComponent,    
    GridFilterTextComponent,
    GridFilterNumberComponent,
    GridFilterBooleanComponent,
    GridToolbarComponent,
    ReportSelectionComponent,
    KendoPanelBarItemHideIfCollapsedDirective,
    LabelBoolComponent,
    LabelBoxComponent,
    LabelColorComponent,
    LabelDateComponent,
    LabelErrorComponent,
    LabelExpandableComponent,
    LoaderComponent,
    LoginComponent,
    LoginOtherComponent,
    ChangePasswordComponent,
    MessagesComponent,
    MinutesAsHoursPipe,
    ModuleHostComponent,
    ModuleHostDirective,
    MultiSelectComponent,
    NavigatorComponent,
    NotificationsMenuComponent,
    NumericUpDownComponent,
    PageTitleComponent,
    PagerComponent,
    PagerErrorDirective,
    PanelBarButtonsComponent,
    PasswordBoxComponent,
    PopupHostDirective,
    RecordInfoComponent,
    SwitchComponent,
    TemporaryStreamUploadComponent,
    TextAreaComponent,
    TextBoxComponent,
    TimePickerComponent,
    ToastComponent,
    ToastHolderComponent,
    ToolbarComponent,
    ImageThumbZoomableComponent,

    ToolbarLeftDirective,
    UserMenuComponent,
    SortByPipe,
    CapitalizeFirstLetterPipe,

    FilterDateComponent,
    FilterLookupComponent,
    FilterLookupStaticComponent,
    FilterStringComponent,
    FilterNumberComponent,
  ],
  exports: [

    AngularResizedEventModule,
    AsyncLoadPanelComponent,
    ButtonComponent,
    ButtonsModule,
    CodeEditorComponent,
    ColorPickerComponent,
    ComboBoxComponent,
    ContextMenuModule,
    CrudGridActionsComponent,
    CrudGridComponent,
    CrudModuleHostComponent,
    CrudToolbarComponent,
    DateInputsModule,
    DatePickerComponent,
    AutocompleteComponent,
    DefaultRouteComponent,
    DialogsModule,
    DropDownListComponent,
    EscapeHtmlPipe,
    FixRowsHeightDirective,
    FixToParentHeightDirective,
    FixToParentHeightTopSensorDirective,
    FormatDatePipe,
    FormsModule,
    GridComponent,
    GridDynamicCellComponent,
    GridDynamicFilterCellComponent,
    GridDynamicGroupCellComponent,
    GridEmptyActionsComponent,
    GridFilterDateComponent,
    GridFilterTimeComponent,
    GridFilterMultiSelectComponent,
    GridFilterCodeComponent,
    GridFilterTextComponent,
    GridFilterNumberComponent,
    GridFilterBooleanComponent,
    GridToolbarComponent,
    ReportSelectionComponent,
    GridModule,    
    InputsModule,
    KendoPanelBarItemHideIfCollapsedDirective,
    LabelBoolComponent,
    LabelBoxComponent,
    LabelColorComponent,
    LabelDateComponent,
    LabelErrorComponent,
    LabelExpandableComponent,
    LayoutModule,
    LoaderComponent,
    LoginComponent,
    LoginOtherComponent,
    ChangePasswordComponent,
    MessagesComponent,
    MinutesAsHoursPipe,
    ModuleHostComponent,
    ModuleHostDirective,
    MultiSelectComponent,
    NavigatorComponent,
    NotificationsMenuComponent,
    NumericUpDownComponent,
    PageTitleComponent,
    PagerComponent,
    PagerErrorDirective,
    PagerModule,
    PanelBarButtonsComponent,
    PasswordBoxComponent,
    PopupHostDirective,
    PopupModule,
    ReactiveFormsModule,
    RecordInfoComponent,
    SortableModule,
    SwitchComponent,
    TemporaryStreamUploadComponent,
    TextAreaComponent,
    TextBoxComponent,
    TimePickerComponent,
    ToastComponent,
    ToastHolderComponent,
    ToolbarComponent,
    ToolbarLeftDirective,
    ImageThumbZoomableComponent,
    TooltipModule,
    UploadModule,
    UserMenuComponent,
    WindowModule,
    SortByPipe,    
    CapitalizeFirstLetterPipe,
    FilterDateComponent,
    FilterLookupComponent,
    FilterLookupStaticComponent,
    FilterStringComponent,
    FilterNumberComponent,
  ],
  providers: [
    FixToParentHeightService,    
    ToolbarLeftService,
    ValidationService,
    FilterService,
    { provide: KendoMessageService, useClass: MyKendoMessageService },
  ],
})
export class ControlsModule {

}
