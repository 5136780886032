








export class BaseConstants   {

  public static readonly ATTACHMENTIMAGEFILEEXTENSIONS: string  = '.jpg,.jpeg,.png,';

  public static readonly ATTACHMENTDOCUMENTFILEEXTENSIONS: string  = '.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,';

  
}
