export class ExternalWebViewMessage {

  public isResult: boolean = false;

  public message: string = '';

  public extra: string = '';

  constructor(_isResult: boolean, _message: string, _extra: string) {
    this.isResult = _isResult;
    this.message = _message;
    this.extra = _extra;
  }
}
