







  import { BaseRequest } from 'dto';
  export class CommonValidatePasswordRequest extends BaseRequest   {        
          
      public username: string = '';
          
      public password: string = '';
            
}



