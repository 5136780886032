import { Type } from '@angular/compiler/src/core';
import { DataResult, DataSourceRequestState } from '@progress/kendo-data-query';
import { CoreLib_Classes_SearchRequest, CoreLib_Services_Http_Base_BaseService } from 'core';
import { CommonGridResponse, CommonGridResponseWithGroupBy } from 'dto';
import { BaseService } from './BaseService';


export class BaseProcService extends BaseService {

  constructor(baseService: CoreLib_Services_Http_Base_BaseService, areaName: string) {
    super(baseService, areaName);

  }

  public async grid<TAggregationDto>(state: DataSourceRequestState, searchRequest: CoreLib_Classes_SearchRequest, gridType: Type, isFirstRequest: boolean, saveSortState: boolean, saveFilterState: boolean, uid: string): Promise<CommonGridResponse<DataResult, TAggregationDto>> {
    return this.baseService.baseGridPost(state, this.areaName, searchRequest, gridType, isFirstRequest, saveSortState, saveFilterState, uid);
  }
  public async gridWithGroupBy<TGroupByValue, TAggregationDto>(state: DataSourceRequestState, searchRequest: CoreLib_Classes_SearchRequest, gridType: Type, isFirstRequest: boolean, saveSortState: boolean, saveFilterState: boolean): Promise<CommonGridResponseWithGroupBy<DataResult,TGroupByValue, TAggregationDto>> {
    return this.baseService.baseGridPostWithGroupBy<TGroupByValue, TAggregationDto>(state, this.areaName, searchRequest, gridType, isFirstRequest, saveSortState, saveFilterState);
  }

}
