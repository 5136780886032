



import { BaseRequest } from './BaseRequest';
export class CommonEvalRequest extends BaseRequest {        
  
  public evalString: string = '';
  
}




