import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactory, ComponentRef, Injector, OnInit, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ControlsLib_Classes_CrudPopupResult, ControlsLib_Components_Base_BaseComponent, ControlsLib_Directives_PopupHostDirective, ControlsLib_Enums_GridSelectionModes, ControlsLib_Interfaces_IBaseCrudViewContract, ControlsLib_Services_Common_DeactivationService } from 'controls';
import { CoreLib_Services_Common_PopupService, CoreLib_Services_Common_WebViewHostService } from 'core';
import { CoreConstants } from 'dto';
import { lazyRoute } from 'app/app-routing.module';
import { PopupCrudViewHostConfiguration } from '@modules/_common/shared/common-shared/classes/PopupCrudViewHostConfiguration';


@Component({
  selector: 'app-crud-host',
  templateUrl: './crud-host.component.html',
})
export class CrudHostComponent extends ControlsLib_Components_Base_BaseComponent implements OnInit, AfterViewInit {

  //#region Declarations...

  private popupHost: ControlsLib_Directives_PopupHostDirective;

  private crudComponentFactory: ComponentFactory<any>;

  private componentRef: ComponentRef<any>;

  private viewContainerRef: ViewContainerRef;

  @ViewChild(ControlsLib_Directives_PopupHostDirective) set content(content: ControlsLib_Directives_PopupHostDirective) {
    this.popupHost = content;
  }


  //#endregion

  //#region Properties...

  //#endregion

  //#region Constructors...
  constructor(injector: Injector, private ref: ChangeDetectorRef, private deactivationService: ControlsLib_Services_Common_DeactivationService, private popupService: CoreLib_Services_Common_PopupService, private webViewHostService: CoreLib_Services_Common_WebViewHostService) {
    super(injector);

    window.desk.initCrud = this.initFromExternalContainer.bind(this);
    window.desk.canCloseModal = this.externalCanCloseModal.bind(this);
  }
  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
    console.log(CoreConstants.DeskMessages.LOADED);
    this.webViewHostService.sendMessage(CoreConstants.DeskMessages.LOADED);
  }

  externalCanCloseModal() {
    this.zone.run(async () => {

      if (this.deactivationService.isInEditMode() || this.popupService.isModalOpen) {
        this.webViewHostService.sendResult(CoreConstants.DeskResults.CANNOTCLOSEMODAL);
      } else {
        this.webViewHostService.sendResult(CoreConstants.DeskResults.CANCLOSEMODAL);
      }
    });
  }

  async initFromExternalContainer(message: any) {

    this.zone.run(async () => {
      const configuration = message as PopupCrudViewHostConfiguration;

      if (configuration.dateCols != null) {
        for (const dateCol of configuration.dateCols) {
          configuration.componentParameters[dateCol] = new Date(configuration.componentParameters[dateCol]);
        }
      }

      this.crudComponentFactory = await this.lazyLoaderService.loadModule(
        (lazyRoute() as any)[configuration.routeName],
        configuration.componentName,
      );

      this.viewContainerRef = this.popupHost.viewContainerRef;

      this.viewContainerRef.clear();

      const factory = await this.crudComponentFactory;

      this.componentRef = this.viewContainerRef.createComponent(factory);

      const componentInstance = this.componentRef.instance as ControlsLib_Interfaces_IBaseCrudViewContract;

      componentInstance.searchRequest = configuration.searchRequest;
      componentInstance.componentParameters = configuration.componentParameters;

      componentInstance.popupCloseRequested.subscribe((result: ControlsLib_Classes_CrudPopupResult) => {
        if (result.selectionMode == ControlsLib_Enums_GridSelectionModes.None) {
          if (this.applicationStateService.isEmbedded) {
            this.webViewHostService.sendMessage(CoreConstants.DeskMessages.CLOSEMODALCRUD);
          }
        } else if (result.selectionMode == ControlsLib_Enums_GridSelectionModes.Single) {
          this.webViewHostService.sendMessage(CoreConstants.DeskMessages.MODALCRUDRESULT, JSON.stringify(result.selectedOrUnselectedUids[0]));
        }

      });

      if (configuration.selectedItemUid != null) {
        componentInstance.initialFilters = ({
          filters: [{ field: 'uid', operator: 'eq', value: configuration.selectedItemUid } as FilterDescriptor],
          logic: 'and',
        } as CompositeFilterDescriptor);
        componentInstance.selectedPanelItemName = configuration.selectedPanelItemName;
        componentInstance.startInViewMode = !configuration.startInEditMode;
        componentInstance.startInEditMode = configuration.startInEditMode;
      } else {
        componentInstance.initialFilters = configuration.initialFilters;
        componentInstance.startInNewMode = configuration.startInNewMode;
        componentInstance.selectionMode = configuration.selectionMode;
      }

      if (!(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
    this.webViewHostService.sendResult(CoreConstants.DeskResults.INITCRUDSUCCEEDED);
  }

  //#endregion
}
