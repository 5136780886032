import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_ApplicationStateService, CoreLib_Services_Common_PopupService } from 'core';
import { CommonMenuGroupDataResponse, CommonMenuItemDataResponse } from 'dto';
import { DeactivationService } from '../../services/common/deactivation.service';
import { HostService } from '../../services/common/host.service';
import { BaseComponent } from '../_base/base.component';


@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],

})
export class NavigatorComponent extends BaseComponent implements OnInit {

  //#region Events...

  @Output() onOpen: EventEmitter<any> = new EventEmitter();

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();


  //#endregion

  //#region Properties...

  @Input() public groups: any[];


  private _isOpen: boolean;
  @Input()
  get isOpen() {
    return this._isOpen;
  }

  set isOpen(val) {
    this._isOpen = val;
    if (val) {
      this.isOpenedOneTime = true;
    }
  }


  isNavigationMenuEnabled: boolean = false;

  isOpenedOneTime: boolean = false;

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private router: Router, private deactivationService: DeactivationService, private popupService: CoreLib_Services_Common_PopupService, private hostService: HostService) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.isNavigationMenuEnabled = !this.applicationStateService.isEmbedded;
  }

  public toggleGroup(group: CommonMenuGroupDataResponse): void {
    group.isOpen = !group.isOpen;
  }

  public async navigate(group: CommonMenuGroupDataResponse, item: CommonMenuItemDataResponse): Promise<void> {

    if ((window.event as any).ctrlKey) {
      window.open(this.router.createUrlTree([item.itemRoute]).toString(), '_blank');
    } else {
      this.onClose.emit(true);

      let continueNavigate: boolean = true;
      if ('/' + item.itemRoute != this.router.url) {
        if (this.deactivationService.isInEditMode()) {
          if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo) == CoreLib_Enums_PopupResultTypes.No) {
            continueNavigate = false;
          } else {
            this.deactivationService.forceExit = true;
          }
        }

        if (continueNavigate) {
          this.router.navigate([item.itemRoute]);
        }
      }
    }
  }


  public open(event: any, group: CommonMenuGroupDataResponse): void {
    event.stopPropagation();

    //Notifico che un click sul documento è avvenuto così da chiudere eventuali popup inline aperti
    this.hostService.documentClick.next(event);

    if (group.isOpen && this.isOpen) {
      group.isOpen = false;
      this.onClose.emit(false);
      return;
    } else {
      if (!this.isOpen) {
        this.onOpen.emit();
      } else {
        group.isOpen = !group.isOpen;
        return;
      }
    }

    group.isOpen = true;
  }

  //#endregion
}
