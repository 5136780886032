




    
export class CommonUidDataResponse   {
  
  public uid: string = '00000000-0000-0000-0000-000000000000';
  
}



