




    
export class CommonAggregationDataResponse   {
  
  public totalItems: number = 0;
  
}



