import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_ApplicationStateService, CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_PopupService } from 'core';
import { DeactivationService } from '../../services/common/deactivation.service';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],

})
export class UserMenuComponent extends BaseComponent implements OnInit {

  //#region Events...

  @Output()
  onClose: EventEmitter<any> = new EventEmitter();

  //#endregion

  //#region Properties...

  private _isOpen: boolean;
  @Input()
  get isOpen() {
    return this._isOpen;
  }

  set isOpen(val) {
    this._isOpen = val;
    if (val) {
      this.isOpenedOneTime = true;
    }
  }

  private _isAccountSettingEnabled: boolean;
  @Input()
  get isAccountSettingEnabled() {
    return this._isAccountSettingEnabled;
  }

  set isAccountSettingEnabled(val) {
    this._isAccountSettingEnabled = val;
  }

  isUserMenuEnabled: boolean = false;

  isOpenedOneTime: boolean = false;

  private _isDefaultFullScreenLogin: boolean;
  public get isDefaultFullScreenLogin() {
    this._isDefaultFullScreenLogin = this.navigationService.getIsDefaultFullScreenLogin();
    return this._isDefaultFullScreenLogin;
  }
  public set isDefaultFullScreenLogin(val) {
    this._isDefaultFullScreenLogin = val;
    this.navigationService.setIsDefaultFullScreenLogin(val);
  }

  private _isFullScreen: boolean;
  public get isFullScreen() {
    const doc = document as any;
    this._isFullScreen = doc.fullscreenElement;
    return this._isFullScreen;
  }

  public get currentTheme() {
    return this.navigationService.getCurrentTheme();
  }

  //#endregion

  //#region Constructors...

  constructor(injector: Injector,
              public authenticationService: CoreLib_Services_Common_AuthenticationService,
              public deactivationService: DeactivationService,
              private popupService: CoreLib_Services_Common_PopupService,
              private router: Router) {
    super(injector);
  }

  //#endregion

  //#region Methods...


  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.isUserMenuEnabled = !this.applicationStateService.isEmbedded;
  }

  public changeTheme(themeName: string) {
    this.onClose.emit();
    this.navigationService.switchTheme(themeName);
  }

  public async logout(): Promise<void> {
    this.onClose.emit();

    let continueNavigate: boolean = true;

    if (this.deactivationService.isInEditMode()) {
      if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo) == CoreLib_Enums_PopupResultTypes.No) {
        continueNavigate = false;
      } else {
        this.deactivationService.forceExit = true;
      }
    }

    if (continueNavigate) {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    }
  }


  /* Se si vuole intercettare l'evento Change della FullScreen Mode vedere il link seguente :
   * https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API */
  public showHideFullScreen(): void {
    const doc = document as any;

    const fullscreen = (doc.fullscreenElement == null ? (doc.fullscreen == null ? false : doc.fullscreen) : doc.fullscreenElement);

    if (!fullscreen) {
      this.navigationService.goToFullScreen();
    } else {
      this.navigationService.exitFromFullScreen();
    }
  }

  public async navigateAccountSetting(): Promise<void> {
    if ((window.event as any).ctrlKey) {
      window.open('account-setting-desk', '_blank');
    } else {
      this.onClose.emit(true);

      let continueNavigate: boolean = true;
      if ('/account-setting-desk' != this.router.url) {
        if (this.deactivationService.isInEditMode()) {
          if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo) == CoreLib_Enums_PopupResultTypes.No) {
            continueNavigate = false;
          } else {
            this.deactivationService.forceExit = true;
          }
        }

        if (continueNavigate) {
          this.router.navigate(['account-setting-desk']);
        }
      }
    }
  }

  public async navigateChangePassword(): Promise<void> {
    this.onClose.emit();

    let continueNavigate: boolean = true;

    if (this.deactivationService.isInEditMode()) {
      if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Confirm, this.localizeByCommon['NAVIGATION_CONFIRM_EXIT_EDIT'], CoreLib_Enums_PopupButtonsTypes.YesNo) == CoreLib_Enums_PopupResultTypes.No) {
        continueNavigate = false;
      } else {
        this.deactivationService.forceExit = true;
      }
    }

    if (continueNavigate) {
      this.router.navigate(['change-password']);
    }
  }
  //#endregion
}
