import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { BaseViewComponent } from '../../components/_base/base-view.component';

@Injectable({ providedIn: 'root' })
export class DeactivationService implements CanDeactivate<BaseViewComponent> {

  //#region Declarations...

  public isModalShow: boolean = false;
  public forceExit: boolean = false;
  public currentView: BaseViewComponent;

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  canDeactivate(component: BaseViewComponent, currentRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.forceExit) {
      this.forceExit = false;
      return true;
    } else {
      return component.canDeactivateCalled(currentRoute);
    }

  }

  public isInEditMode(): boolean {
    return this.currentView.isInEditMode();
  }

  //#endregion

}
