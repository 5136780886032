<div class="app-drop-down-list" [class.big]="bigger" [class.error]="hasError" [class.hideheader]="hideheader" [class.value]="selectedItem" [class.focus]="hasFocus">
  
  <div [id]="erroranchor"></div>
  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory"
      *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>

  <div class="read-only-input-span" *ngIf="selectedItem && disabled && itemTemplate">
    <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit: selectedItem}">
    </ng-container>
  </div>

  <div class="read-only-input-span" [hidden]="!disabled" *ngIf="itemTemplate == null">{{readOnlyValue}}</div>

  <div *ngIf="!disabled" class="d-flex">
    <div class="align-self-start" style="width:100%;position:relative">
      <kendo-dropdownlist [valuePrimitive]="false" [textField]="textField" [valueField]="valueField" (blur)="onBlur()" (focus)="onFocus()"
        [filterable]="filterable" title="{{(selectedItem != null) ? selectedItem[textField] : header}}"
        [data]="filteredSource" [(ngModel)]="selectedItem" (filterChange)="handleFilter($event)"
        [popupSettings]="{ popupClass: (bigger) ? 'app-drop-down-list-popup big' : 'app-drop-down-list-popup'}">
        <ng-template *ngIf="itemTemplate" kendoComboBoxItemTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
          </ng-container>
        </ng-template>
      </kendo-dropdownlist>
      <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span
          class="mandatory" *ngIf="mandatory" [innerHtml]="mandatoryText"></span> </span>
      <!-- <div class="placeholder" *ngIf="headerhidden"  title="{{header}}">{{header}}</div> -->
    </div>
    <div class="d-inline-flex" *ngIf="showExtendedSearch">
      <app-button [icon]="'search'" [inverted]="true" (buttonClick)="popupOpen()"></app-button>
    </div>
  </div>
  <span class="error-span" [innerHtml]="error"></span>
</div>
<app-async-load-panel *ngIf="popupOpened" (init)="loadComponent()">
  <kendo-dialog [title]="dialogTitle" (close)="popupClose()" class="k-dialog-full k-dialog-notscroll">
    <div class="container-fluid">
      <ng-template popup-host></ng-template>
    </div>
    <app-loader></app-loader>
  </kendo-dialog>
</app-async-load-panel>