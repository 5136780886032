import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChange, ViewChild } from '@angular/core';
import { DatePipe, IntlService } from '@progress/kendo-angular-intl';

import { TimePickerComponent as KendoTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';


@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent extends BaseInputControlComponent implements AfterViewInit {


  //#region Properties...

  @ViewChild(KendoTimePickerComponent, { static: true })
  public timeobject: KendoTimePickerComponent;

  private focused: boolean = false;

  // VALUE
  private _value: Date = null;
  @Input()
  get value() {
    if (this._value != null) {
      return new Date(this._value);
    } else {
      return null;
    }
  }
  @Output() valueChange = new EventEmitter();
  set value(val) {
    this._value = val;
    this.valueChange.emit(val);
    this.setHeaderHidden();
    this.setReadOnlyValue();
  }

  @Input()
  public format: string = 'HH:mm';

  //#endregion

  //#region Constructor...

  constructor(injector: Injector, validationService: ValidationService, private ref: ChangeDetectorRef) {
    super(injector, validationService);
  }

  //#endregion

  //#region Methods...

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
    this.timeobject.onFocus.subscribe(() => { this.focused = true; this.setHeaderHidden(); });
    this.timeobject.onBlur.subscribe(() => { this.focused = false; this.setHeaderHidden(); });

    try {
      const clearButton: HTMLElement = document.createElement('span');
      clearButton.setAttribute('role', 'button');
      clearButton.className = 'k-icon k-clear-value k-i-close';
      clearButton.addEventListener('click', this.clearHandler);

      this.timeobject.wrapper.nativeElement.insertBefore(clearButton, this.timeobject.wrapper.nativeElement.childNodes[1]);
    } catch {

    }

  }

  private clearHandler = () => {
    this.value = null;
  }

  public override setReadOnlyValue() {
    if (this.disabled && this.value == null) {
      this.readOnlyValue = '-';
    } else {
      if (this.value != null) {
        const pipe = new DatePipe(this.injector.get(IntlService));
        this.readOnlyValue = pipe.transform(this.value, this.format);
      }
    }

    this.ref.detectChanges();
  }

  public override setHeaderHidden(): void {
    if (this.focused) {
      this.headerhidden = false;
    } else {
      this.headerhidden = this.disabled == false && (this.value == null);
    }

    this.ref.detectChanges();
  }

  //#endregion
}
