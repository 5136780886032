import { Injectable } from '@angular/core';
import { ExternalWebViewMessage } from '../../classes/ExternalWebViewMessage';

@Injectable({ providedIn: 'root' })
export class WebViewHostService {

  public isInsideWebViewHost(): boolean{
    return window.chrome != null && window.chrome.webview != null;
  }

  public sendMessage(message: string, extra: string = '') {
    console.log("sendMessage" + message);
    this.send(new ExternalWebViewMessage(false, message, extra))
  }

  public sendResult(result: string, extra: string = '') {
    console.log("sendResult" + result);
    this.send(new ExternalWebViewMessage(true, result, extra))
  }

  private send(message: ExternalWebViewMessage) {
    if (window.chrome != null) {
      if (window.chrome.webview != null) {
        console.log("posting message");
        console.log(message);
        window.chrome.webview.postMessage(JSON.stringify(message));
        console.log("message posted");
      } else {
        console.log("window.chrome.webview is null");
      }
    } else {
      console.log("window.chrome is null");
    }
  }
}
