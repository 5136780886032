import { ViewContainerRef } from '@angular/core';
import { ModuleHolder } from '../../classes/ModuleHolder';
import { IPluginData } from '../../interfaces/IPluginData';


export abstract class PluginLoaderService {
  protected constructor() {
    this.provideExternals();
  }

  public allDataModules: IPluginData[] = new Array<IPluginData>();
  public allModules: ModuleHolder[] = new Array<ModuleHolder>();

  abstract provideExternals(): void;

  abstract loadModules(): Promise<void>;

  abstract load(moduleInfo: IPluginData): Promise<ModuleHolder>;

  abstract getModuleInstance(module: IPluginData, viewContainerRef: ViewContainerRef): Promise<any>;
}
