import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChange, ViewChild } from '@angular/core';

import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { formatNumber } from '@telerik/kendo-intl';
import { ValidationService } from '../../services/common/validation.service';
import { BaseInputControlComponent } from '../_base/base-input-control.component';


@Component({
  selector: 'app-numeric-up-down',
  templateUrl: './numeric-up-down.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericUpDownComponent extends BaseInputControlComponent {

  @ViewChild('input_ref') inputField: NumericTextBoxComponent;

  //#region Properties...

  // DECIMALS
  @Input()
  public decimals: number = 0;

  // MIN
  @Input()
  public min: number;

  // MAX
  @Input()
  public max: number;

  // AUTOCORRECT
  @Input()
  public autoCorrect: boolean = true;


  @Input()
  public showSpinners: boolean = true;


  private _format: string;
  @Input()
  get format() {
    return this._format;
  }
  set format(val) {
    this._format = val;
  }


  // VALUE
  private _value: number;
  @Input()
  get value() {
    return this._value;
  }
  @Output() valueChange = new EventEmitter();
  set value(val) {
    this._value = val;
    this.valueChange.emit(val);
    this.setReadOnlyValue();
  }

  @Output() inputKeyUp = new EventEmitter<KeyboardEvent>();

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, private ref: ChangeDetectorRef) {
    super(injector, validationService);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    if (this.format == undefined) {
      if (this.decimals == null) {
        this.format = 'n0';
      } else {
        this.format = 'n' + this.decimals.toString();
      }
    }
    this.setReadOnlyValue();
  }
  //#endregion

  //#region Methods...


  onKeyUp(event: KeyboardEvent) {
    this.inputKeyUp.emit(event);
  }

  public decrement() {
    if (this.min == undefined || this.value - 1 >= this.min) {
      this.value--;
    } else {
      this.value = this.min;
    }
  }
  public increment() {
    if (this.max == undefined || this.value + 1 <= this.max) {
      this.value++;
    } else {
      this.value = this.max;
    }
  }

  public focus() {
    this.inputField.focus();
  }

  public select() {
    this.inputField.focus();
  }


  public override setReadOnlyValue() {

    let _val: number = 0;

    if (this.value != null) {
      _val = this.value;
    }

    this.readOnlyValue = formatNumber(_val, this.format);
    this.ref.detectChanges();
  }
  //#endregion

}
