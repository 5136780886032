import { Component, Injector, Input } from '@angular/core';

import { KeyValue } from '@angular/common';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { ICrudComponentPanel } from '../../interfaces/ICrudComponentPanel';
import { BaseComponent } from '../_base/base.component';
import { PanelItemKey } from '../../classes/PanelItemKey';

@Component({
  selector: 'app-panel-bar-buttons',
  templateUrl: './panel-bar-buttons.component.html',
})
export class PanelBarButtonsComponent extends BaseComponent {

  //#region Properties...

  @Input()
  public crudView: IBaseCrudViewContract;

  private isClickEnabled: boolean = true;

  
  //#endregion

  //#region Constructors...

  constructor(injector: Injector) {
    super(injector);
  }

  //#endregion

  valueAscOrder = (a: KeyValue<string, ICrudComponentPanel>, b: KeyValue<string, ICrudComponentPanel>): number => {
    return a.value.order.toString().localeCompare(b.value.order.toString());
  }

  selectTab(panelItemName: string) {
    if (this.isClickEnabled) {
      this.isClickEnabled = false;
      this.crudView.selectTab(panelItemName);
      setTimeout(() => this.isClickEnabled = true, 500);
    }


  }
}
