



import { BaseRequest } from './BaseRequest';
export class CommonTranslationGetAllByFormRequest extends BaseRequest {        
  
  public formReference: string = '';
  
}




