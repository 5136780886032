import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Directive, Injector, NgZone, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { CoreLib_Services_Common_ApplicationStateService, CoreLib_Services_Common_TranslationService } from 'core';
import { Subscription } from 'rxjs';
import { IBaseComponentContract } from '../../interfaces/IBaseComponentContract';

import { LazyLoaderService } from '../../services/common/lazy-loader.service';
import { NavigationService } from '../../services/common/navigation.service';
import { RouterService } from '../../services/common/router.service';
import { PluginLoaderService } from '../../services/plugin-loader/plugin-loader.service';
import { ModuleHostComponent } from '../module-host/module-host.component';

/**
 * E' la classe base di tutti i componenti Desk. Fornisce i metodi in comune a tutti i componenti
 */
@Directive()
export abstract class BaseComponent implements IBaseComponentContract, OnInit, AfterViewInit, OnDestroy {

  //#region Private properties...

  private moduleHostsSubscription: Subscription;
  private breakpointObserverSubscription: Subscription;
  private breakpointObserver: BreakpointObserver;
  @ViewChildren(ModuleHostComponent)
  private allModuleHostDirectives!: QueryList<ModuleHostComponent>;

  //#endregion

  //#region Protected Properties...

  
  /**
 * @ignore
 */
  protected lazyLoaderService: LazyLoaderService;

  /**
 * @ignore
 */
  protected pluginLoader: PluginLoaderService;

  /**
  * @ignore
  */
  protected persComponents: any[] = [];

  /**
 * Espone il servizio RouterService
 */
  protected routerService: RouterService;

  /**
 * Espone il servizio NgZone
 */
  protected zone: NgZone;

  /**
* Espone il servizio per la gestione della navigazione tra pagine. Questo servizio fornisce inoltre i metodi per la gestione del tema corrente e per il passaggio a modalità fill screen
* Nota: Il metodo navigate, verifica se è in corso una modifica sulla pagina corrente e nel caso chiede conferma all'utente prima di procedere
*/
  protected navigationService: NavigationService;

  //#endregion

  //#region Public Properties...
  
  public thisComponent: any = this;

  /**
* Restituisce o imposta l'identificativo assegnato al componente per ricavare le etichette. Se non viene specificato un valore, non viene caricata nessuna etichetta specifica per il componente
*/
  public formReference: string;

  /**
  * Espone il servizio per l'estrazione delle etichette
  */
  public translationService: CoreLib_Services_Common_TranslationService;

  /**
  * Espone il servizio ApplicationStateService. Questo servizio fornisce:
  * - i settings e i relativi metodi per l'estrazione
  * - l'elenco delle voci di menu
  * - Un flag che indica se Desk è embeddato
  */
  public applicationStateService: CoreLib_Services_Common_ApplicationStateService;


  /**
* Attiva o meno la gestione delle pers per il component corrente.
*/
  protected isDynamicModulesEnabled: boolean = true;


  /**
* Fornisce l'indicazione se si sta navigando in modalità mobile (con un browser su un dispositivo con larghezza schermo inferiore a 768px)
*/
  public isSmallDevice: boolean = false;


  private _localizeByModule: { [index: string]: string } = {};
  /**
* Espone il set di etichette COMMON
*/
  get localizeByCommon() {
    return this.translationService.localizeByCommon;
  }

  /**
* Espone il set di etichette che hanno come formReference, il valore di formReference definito nel costruttore del componente. Se la proprietà formReference è vuota, non viene caricata nessuna etichetta.
*/
  get localizeByModule() {
    return this._localizeByModule;
  }

  /**
* Wrapper della proprietà isInLoading disponibile nel servizio ApplicationStateService
*/
  private _isInLoading: boolean = false;

  public get isInLoading(): boolean {
    return this._isInLoading;
  }

  public set isInLoading(val: boolean) {
    this._isInLoading = val;
  }

  //#endregion

  //#region Constructor...

  /**
 * Inizializza una nuova istanza del componente.
 *
 * Nel costruttore della classe derivata, valorizzare la proprietà formReference con il nome del componente, per caricare le etichette relative.
 * @param injector Il servizio che consente di ricavare le istanze di altri servizi tramite dependency injection
 */
  constructor(public injector: Injector) {
    this.translationService = injector.get(CoreLib_Services_Common_TranslationService);
    this.breakpointObserver = injector.get(BreakpointObserver);
    this.routerService = this.injector.get(RouterService);
    this.zone = this.injector.get(NgZone);

    this.applicationStateService = injector.get(CoreLib_Services_Common_ApplicationStateService);

    if (!this.applicationStateService.isEmbedded) {
      this.observeSmallDevice(this.breakpointObserver);
    }

    this.pluginLoader = this.injector.get(PluginLoaderService);
    this.persComponents = [];
    this.lazyLoaderService = this.injector.get(LazyLoaderService);
    this.navigationService = this.injector.get(NavigationService);
  }


  //#endregion

  //#region Lifecycke Methods...

  async ngOnInit(): Promise<void> {
    if (this.formReference != null && this.formReference.length > 0) {
      this._localizeByModule = await this.translationService.initForm(this.formReference);
      this.injector.get(ChangeDetectorRef).detectChanges();
    }
  }

  async ngAfterViewInit(): Promise<void> {

    if (this.moduleHostsSubscription != null) {
      this.moduleHostsSubscription.unsubscribe();
    }

    // this.moduleHosts = allModuleHostDirectives.map(c => <ModuleHost>{ name: c.name, moduleHost: c, viewContainerRef: c.viewContainerRef });
    if (this.allModuleHostDirectives != null) {
      await this.loadPers(this.allModuleHostDirectives.toArray());
      this.moduleHostsSubscription = this.allModuleHostDirectives.changes.subscribe(async (elements) => { await this.loadPers(elements); });
    }
  }

  ngOnDestroy(): void {
    if (this.moduleHostsSubscription != null) {
      this.moduleHostsSubscription.unsubscribe();
    }

    if (this.breakpointObserverSubscription != null) {
      this.breakpointObserverSubscription.unsubscribe();
    }
  }

  //#endregion

  //#region Private Methods...

  private observeSmallDevice(breakpointObserver: BreakpointObserver) {
    this.breakpointObserverSubscription = breakpointObserver.observe([
      '(max-width: 768px)',
    ]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
      } else {
        this.isSmallDevice = false;
      }
    });
  }

  private async loadPers(elements: ModuleHostComponent[]) {
    if (!this.isDynamicModulesEnabled) {
      return;
    }

    // Per entry nel file di configurazione dei moduli pers...
    for (const module of this.pluginLoader.allDataModules) {
      // Se la destinazione è questo componente...
      if (module.destinationComponentName.length > 0 && module.destinationComponentName == this.formReference + 'Component') {
        // cerco in quale host va inserito il componente...
        for (const host of elements) {
          if (host.isLoaded == false && host.name == module.destinationHostName) {
            var instance = await this.pluginLoader.getModuleInstance(module, host.viewContainerRef);

            instance.dataItem = host.dataItem;
            instance.context = this;
            
            this.persComponents.push(instance);
            host.isLoaded = true;
            host.raiseHeightChanged();
          }
        }
      }

    }
  }
  //#endregion

  //#region Protected Methods...
  /**
 * Viene invocato dal metodo setField. Viene implementato dalle classi derivate per gestire il change delle proprietà modificate
 * @param propName il nome della proprietà che ha modificato il valore
 */
  protected async raisePropertyChanged(propName: string): Promise<void> {

  }
  //#endregion

  //#region Public Methods...
  /**
* Restituisce un nuovo valore numerico moltiplicato per il fattore del tema corrente
* @param value il valore da moltiplicare per il fattore del tema
*/
  public scaleByTheme(value: number) {
    return value * this.navigationService.getCurrentThemeFactor();
  }

  /**
* Questo metodo consente di modificare il valore di una proprietà del component dato il suo nome all'interno del contesto angular. Viene utilizzata per esempio dall'oggetto che carica una pers per settare Uid e ViewMode sul componente personalizzato.
* @param field il nome della proprietà alla quale modificare il valore
* @param value il valore
*/
  public setField(field: string, value: any): any {    
    let t: any = this;    
    if (t[field] != undefined) {
      this.zone.run(async () => {
        t[field] = value;
        await this.raisePropertyChanged(field);
      });
    }

  }

  /**
* Questo metodo consente di invocare e attendere il termine del metodo ricevuto in ingresso.
*
* Si utilizza come gestore dell'evento click di un pulsante se si vuole impedire che si possa cliccare nuovamente sul pulsante prima che l'operazione avviata al primo click sia terminata.
* @param fn il nome della funzione da invocare
*/
  public async callWithIsInLoading(fn: () => Promise<void>): Promise<void> {
    if (!this.isInLoading) {

      var bindedFn = fn.bind(this);

      this.isInLoading = true;

      await bindedFn();
      this.isInLoading = false;
    }
  }


  //#endregion

}
