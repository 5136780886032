




    
export class CommonPushNotificationGetUnreadedDataResponse   {
  
  public uid: string = '00000000-0000-0000-0000-000000000000';
  
  public pushText: string = '';
  
}



