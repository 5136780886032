import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { BaseComponent } from '../_base/base.component';

@Component({
  selector: 'app-record-info',
  templateUrl: './record-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordInfoComponent extends BaseComponent {

  //#region Properties...

  private _dataItem: any;
  @Input()
  get dataItem() {
    return this._dataItem;
  }

  set dataItem(val) {
    this._dataItem = val;
  }

  //#endregion

  //#region Constructors...

  constructor(injector: Injector) {
    super(injector);
  }

  //#endregion

}
