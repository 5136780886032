import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-label-bool',
  templateUrl: './label-bool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelBoolComponent extends BaseComponent implements OnChanges, OnInit {

  //#region Properties...

  // COLOR
  @Input()
  public color: string;

  // TEXT
  private _text: string;
  @Input()
  get text() {
    return this._text;
  }
  set text(val) {
    this._text = val;
  }

  // CHECKED
  private _checked: any;
  @Input()
  get checked() {
    return this._checked;
  }
  set checked(val) {
    this._checked = val;
  }

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.setText();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    Promise.resolve(null).then(() => {
      for (const propName in changes) {
        if (!changes[propName].isFirstChange()) {
          if (propName == 'checked') {
            this.setText();
          }
        }
      }
    });
  }

  private setText() {
    if (this.checked) {
      this.text = this.localizeByCommon['YES'];
    } else {
      this.text = this.localizeByCommon['NO'];
    }
    this.ref.detectChanges();
  }
  //#endregion
}
