import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnChanges, ViewChild } from '@angular/core';
import { CoreLib_Classes_StringHelper } from 'core';
import { ValidationService } from '../../services/common/validation.service';
import { TextBoxComponent } from '../text-box/text-box.component';


@Component({
  selector: 'app-password-box',
  templateUrl: './password-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordBoxComponent extends TextBoxComponent {

  @ViewChild('input_ref') override inputField: ElementRef;

  //#region Properties...

  
  public showPasswordHolder: boolean = false;

  @Input()
  public isShowPasswordButtonVisible: boolean = false;

  // TYPE
  @Input()
  public override type: string = 'password';

  //#endregion

  //#region Constructors...

  constructor(injector: Injector, validationService: ValidationService, ref: ChangeDetectorRef) {
    super(injector, validationService, ref);
  }

  //#endregion

  //#region Methods...
  public override setReadOnlyValue() {
    if (this.disabled && CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.text)) {
      this.readOnlyValue = '-';
    } else {
        this.readOnlyValue = '**********';
    }

    this.ref.detectChanges();
  }
  //#endregion

}
