




    
export class CommonSettingDataResponse   {
  
  public settingKey: string = '';
  
  public settingValue: string = '';
  
}



