import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreLib_Services_Common_AuthenticationService } from 'core';
import { BaseComponent } from '../../components/_base/base.component';
@Component({
  selector: 'app-login-other',
  templateUrl: './login-other.component.html',
})
export class LoginOtherComponent extends BaseComponent implements OnInit {

  constructor(injector: Injector, public authenticationService: CoreLib_Services_Common_AuthenticationService, private router: Router) {
    super(injector);
    this.formReference = 'CommonLoginOther';
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.authenticationService.logout();
  }

  retry() {
    this.router.navigate(['/login']);
  }
}
