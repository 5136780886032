


import { BaseTokenResponse } from './BaseTokenResponse';
export class BaseCloneResponse extends BaseTokenResponse  {

  public cloneUid: string =  '00000000-0000-0000-0000-000000000000';
}





