import { Component, ComponentFactory, Input, OnInit, Injector, EventEmitter, Output, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { IBaseCrudSharedDataResponse, CommonFilterDescriptor, CoreConstants } from 'dto';
import { CoreLib_Classes_StringHelper, CoreLib_Classes_SearchRequest } from 'core';
import { BaseFilterComponent } from '../_base/base-filter.component';
import { BaseCrudService } from '../../services/http/BaseCrudService';
import { FilterService } from '../../services/common/filter.service';
import { FilterData } from '../../classes/FilterData';

@Component({
  selector: 'app-filter-lookup',
  templateUrl: './filter-lookup.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterLookupComponent extends BaseFilterComponent implements OnInit, AfterViewInit {

  //#region Input Output

  @Input()
  public crudService: BaseCrudService<any, any, any, any, any>;

  @Input()
  public crudRouteName: string = "";

  @Input()
  public crudViewName: string = "";

  @Input()
  public searchRequest: CoreLib_Classes_SearchRequest = null;

  //#endregion

  //#region Fields

  public elements: IBaseCrudSharedDataResponse[] = [];

  private _selectedElements: IBaseCrudSharedDataResponse[] = [];

  @Input()
  public get selectedElements(): IBaseCrudSharedDataResponse[] {
    return this._selectedElements;
  }
  public set selectedElements(value: IBaseCrudSharedDataResponse[]) {
    this._selectedElements = value;
    if (this.isInitSucceeded)
      this.notifyFilterChange();
  }


  private _text: string = "";
  public get text(): string {
    return this._text;
  }
  public set text(value: string) {
    this._text = value;
    this.notifyFilterChange();
  }


  //#endregion

  //#region Constructor...

  constructor(injector: Injector,
    filterService: FilterService) {
    super(injector, filterService);
    this.operator = CoreConstants.FilterOperators.IN;
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

  }


  public async crudItemChanged(items: IBaseCrudSharedDataResponse[]) {
    for (var item of items) {
      if (this.selectedElements.find(c => c.uid == item.uid) == null) {
        this.selectedElements.push(item);
      }
    }

    this.notifyFilterChange();
  }

  public override loadExistingFilters(filterData: FilterData): void {
    super.loadExistingFilters(filterData);
    this.operator = filterData.filterOperator;
    this.selectedElements = filterData.filterValue;
  }

  public override clearFilters(): void {
    super.clearFilters();
    this.operator = CoreConstants.FilterOperators.IN;
    this.selectedElements = [];    
  }

  public override notifyFilterChange() {
    super.notifyFilterChange();

    this.hasFilter = false;
    let filter: CommonFilterDescriptor = null;

    switch (this.operator) {
      case CoreConstants.FilterOperators.IN:
      case CoreConstants.FilterOperators.NOTIN:
        if (this.selectedElements != null && this.selectedElements.length > 0) {
          filter = new CommonFilterDescriptor();
          filter.filterName = this.filterName;
          filter.operator = this.operator;
          filter.values = [];
          for (const el of this.selectedElements) {
            filter.values.push(el.uid);
            this.hasFilter = true;
          }
        }
        break;

      case CoreConstants.FilterOperators.STARTSWITH:
      case CoreConstants.FilterOperators.CONTAINS:
      case CoreConstants.FilterOperators.ENDSWITH:
      case CoreConstants.FilterOperators.NOTSTARTSWITH:
      case CoreConstants.FilterOperators.NOTCONTAINS:
      case CoreConstants.FilterOperators.NOTENDSWITH:
        if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(this.text)) {
          filter = new CommonFilterDescriptor();
          filter.filterName = this.filterName;
          filter.operator = this.operator;
          filter.values = [];
          filter.values.push(this.text);
          this.hasFilter = true;
        }
        break;

      case CoreConstants.FilterOperators.ISNULL:
      case CoreConstants.FilterOperators.ISNOTNULL:
        filter = new CommonFilterDescriptor();
        filter.filterName = this.filterName;
        filter.operator = this.operator;
        this.hasFilter = true;
        break;
    }


    this.addFilter(filter, this.selectedElements);
  }
  //#endregion
}

