



import { BaseRequest } from './BaseRequest';
export class CommonChangePasswordRequest extends BaseRequest {        
  
  public name: string = '';
  
  public newPassword: string = '';
  
  public oldPassword: string = '';
  
}




