import { Injectable } from '@angular/core';
import { ControlsLib_Services_Common_RolePermissionService,ControlsLib_Classes_RolePermissionFlag } from 'controls';
import { CoreLib_Services_Http_DeskCommonService } from 'core';
import { BaseRequest } from 'dto';


@Injectable({ providedIn: 'root' })
export class RolePermissionService extends ControlsLib_Services_Common_RolePermissionService {


  constructor(private deskApiCommonService: CoreLib_Services_Http_DeskCommonService) {
    super();
  }

  public async init() {
    const response = await this.deskApiCommonService.rolePermission(new BaseRequest());

    this.rolePermissions = {};

    if (response != null) {
      for (const item of response.items) {
        const flag = new ControlsLib_Classes_RolePermissionFlag(item.isViewEnabled, item.isEditEnabled, item.isNewEnabled, item.isDeleteEnabled);
        this.rolePermissions[item.formReference] = flag;
      }
    }
  }


}
