
export class ToastContent {
  constructor() {

  }

  public uid: string;
  
  public text: string;
  
  public lines:string[] = [];

  private _launchKey: string;

  public get launchKey(): string {
    return this._launchKey;
  }
  public set launchKey(value: string) {
    this._launchKey = value;  
  }

  public launchParameter: string;

  public closeRequested: boolean;

  public clickable: boolean;

}
