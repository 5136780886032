



  import { BaseRequest } from 'dto';
  export class CommonRecoverPasswordRequest extends BaseRequest   {        
          
      public code: string = '';
          
      public newPassword: string = '';
            
}







