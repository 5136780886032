import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { ControlsLib_Components_Base_BaseComponent } from 'controls';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-crud-host-view',
  templateUrl: './crud-host-view.component.html',
})
export class CrudHostViewComponent extends ControlsLib_Components_Base_BaseComponent {

  //#region Declarations...

  //#endregion

  //#region Properties...

  //#endregion

  //#region Constructors...
  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector);
  }
  //#endregion

  //#region Methods...

  //#endregion
}
