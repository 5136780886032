



import { BaseRequest } from './BaseRequest';
export class CommonLoginRequest extends BaseRequest {        
  
  public name: string = '';
  
  public password: string = '';
  
  public isCrypted: boolean = false;
  
}




