<div class="page-login">
  <div class="rounded box">

    <div class="row">
      <div class="col-md-12">
        <h1>{{localizeByCommon['RECOVER_PASSWORD']}}</h1>

        <app-text-box [header]="localizeByCommon['USERNAME']" [whiteSpaces]="false"
          [mandatory]="true" [(text)]="name" [maxLength]="255" #name_ref (inputKeyUp)="onKeyUp($event)"
          [textTransformation]="'lowercase'">
        </app-text-box>

        <span class="error-span" *ngIf="errorText">{{errorText}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center mt-3">
        <app-button [type]="'submit'" (click)="recover()" text="{{localizeByCommon['BTN_RECOVER']}}"
          [buttonClass]="'k-primary'"></app-button>
        <app-button (click)="goToHome()" text="{{localizeByCommon['BTN_BACK']}}"
          [buttonClass]="'k-secondary'"></app-button>
      </div>
    </div>

  </div>
</div>