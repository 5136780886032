




import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonLoginResponse extends BaseTokenResponse  {
  
  public isValid: boolean = false;
  
  public reasonIsNotValid: string = '';
  
  public accountName: string = '';
  
  public accountDescription: string = '';
  
  public deskTheme: string = '';
  
  public loginUid: string = '00000000-0000-0000-0000-000000000000';
  
}



