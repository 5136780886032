import { Injectable, OnInit } from '@angular/core';
import { MessageService as KendoMessageService } from '@progress/kendo-angular-l10n';
import { CoreLib_Services_Common_TranslationService } from 'core';


@Injectable({ providedIn: 'root' })
export class MyKendoMessageService extends KendoMessageService {
  
  constructor(private translationService: CoreLib_Services_Common_TranslationService) {
    super();
  }

  public override get(key: string): string {
    return this.translationService.tryGetLocalizeByCommon(key);
  }
}
