<div class="page-login">
  <div class="rounded box">

    <div class="row">
      <div class="col-md-12">
        <h1>{{localizeByCommon['RENEW_PASSWORD_TITLE']}}</h1>

        <app-text-box [header]="localizeByCommon['USERNAME']" [(text)]="name" [maxLength]="25"
          [disabled]="true"></app-text-box>

        <app-text-box [header]="localizeByCommon['PASSWORDNEW']" [(text)]="passwordNew" [maxLength]="20"
          [type]="'password'" #passwordnew_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

        <app-text-box [header]="localizeByCommon['PASSWORDNEWCONFIRM']" [(text)]="passwordNewConfirm" [maxLength]="20"
          [type]="'password'" #passwordnewconfirm_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

        <p><b>{{translationService.localizeByShared[sharedAccount]['PASSWORD_RULES_TITLE']}}</b>
        </p>
        <p class="password-rules" [innerHtml]="passwordRules"></p>

        <span class="error-span" *ngIf="recoverPasswordFailed" [innerText]="errorText"></span>

        <div class="row">
          <div class="col-md-12 text-center mt-2">
            <app-button [type]="'submit'" (click)="save()" text="{{localizeByCommon['BTN_SAVE']}}"
              [buttonClass]="'k-primary'"></app-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>