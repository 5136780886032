import { Injectable } from '@angular/core';



@Injectable({ providedIn: 'root' })
export class FileService {

  //#region Declarations...

  //#endregion

  //#region Properties...

  public static validExtensions: string[] = [
    'doc',
    'docx',
    'jpeg',
    'jpg',
    'pdf',
    'png',
    'ppt',
    'pptx',
    'rar',
    'xls',
    'xlsx',
    'zip'];

  public static imageExtensions: string[] = [
    'jpeg',
    'jpg',
    'png'];

  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...

  public static getFileSizeString(size: number): string {
    let sizeKB: number;
    let sizeMB: number;
    let sizeGB: number;
    let returnValue: string = '';

    const sizeBytes: number = size;
    const conversion: number = 1024;

    if (sizeBytes > conversion) {
      sizeKB = sizeBytes / conversion;
      if (sizeKB > conversion) {
        sizeMB = sizeKB / conversion;

        if (sizeMB > conversion) {
          sizeGB = sizeMB / conversion;
          returnValue = Math.round(sizeGB) + ' Gb';

        } else {
          returnValue = Math.round(sizeMB) + ' Mb';
        }

      } else {
        returnValue = Math.round(sizeKB) + ' Kb';
      }
    } else {
      returnValue = size + ' Bytes';

    }

    return returnValue;
  }

  public static getBlobFromByteArray(encodedString: string, type: string) {
    const byteString = window.atob(encodedString);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });
    return blob;
  }
  public static getBlobUrlFromBase64(data: any, cType: string): string {
    const binaryImg = atob(data);
    const length = binaryImg.length;
    const arrayBuffer = new ArrayBuffer(length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < length; i++) {
      uintArray[i] = binaryImg.charCodeAt(i);
    }
       
    const currentBlob = new Blob([uintArray], { type: cType });

    return URL.createObjectURL(currentBlob);
  }
  public static async getBase64DataUrlFromBlob(file: Blob) {

    const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    return await toBase64(file);
  }

  public static downloadFile(url: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);

    a.style.display = 'none';
    a.href = url;

    const cleanedFileName = this.parseFileName(fileName);

    a.download = cleanedFileName;

    a.click();
    window.URL.revokeObjectURL(url);
  }

  public static parseFileName(filename: string) {
    const regex = new RegExp('[/\\?%*:|"<>]', 'g');

    return filename.replace(regex, '-');
  }

  public static getExtension(fileName: string) {

    return fileName.substring(fileName.lastIndexOf('.'));
  }

  public static getNameWithoutExtension(fileName: string) {

    return fileName.substring(0, fileName.lastIndexOf('.') - 1);
  }

  //#endregion

}
