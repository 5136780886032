import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Injector, Input, ViewChild, OnDestroy, ChangeDetectorRef, DoCheck } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import { ToolbarLeftService } from '../../services/common/toolbar-left.service';
import { BaseComponent } from '../_base/base.component';
import { HostService } from '../../services/common/host.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';



@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent extends BaseComponent implements AfterViewInit, OnDestroy, DoCheck {


  @Input()
  public override formReference: string;

  @Input()
  public showRightPopupAnchor: boolean = false;

  @Input()
  public rightPopupAnchorIcon: string = 'gear';


  public leftAnchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  public rightAnchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };

  public leftPopupAlign: Align = { horizontal: 'right', vertical: 'top' };
  public rightPopupAlign: Align = { horizontal: 'right', vertical: 'top' };

  @ViewChild('leftAnchor', { static: true }) public leftAnchor: ElementRef;
  @ViewChild('rightAnchor', { static: true }) public rightAnchor: ElementRef;

  @ViewChild('leftPopup', { read: ElementRef, static: true }) public leftPopup: ElementRef;
  @ViewChild('rightPopup', { read: ElementRef, static: true }) public rightPopup: ElementRef;

  @ViewChild('rightPopupContent', { read: ElementRef, static: true }) public rightPopupContent: ElementRef;

  @ViewChild('resizer', { read: ElementRef, static: true }) public resizer: ElementRef;

  private leftContentObserver: MutationObserver;

  private rightPopupObserver: MutationObserver;

  public leftShow: boolean = false;

  public rightShow: boolean = false;

  public hasItemsInRightPopup: boolean = false;

  private hostService_documentClick_Subscription: Subscription;

  private oldWidth: number = 0;

  private isInitialized: boolean = false;


  public docheckChanged = new Subject<boolean>();
  private docheckChanged_Subscription: Subscription;

  constructor(injector: Injector, private toolbarLeftService: ToolbarLeftService, private hostService: HostService, private ref: ChangeDetectorRef) {
    super(injector);
    this.hostService_documentClick_Subscription = this.hostService.documentClick.subscribe(c => {
      if (!this.leftContains(c.target)) {
        this.leftToggle(false);
      }

      if (!this.rightContains(c.target)) {
        this.rightToggle(false);
      }
    });

    this.docheckChanged_Subscription?.unsubscribe();

    this.docheckChanged_Subscription = this.docheckChanged.subscribe(val => {
        if (this.isInitialized && this.resizer?.nativeElement?.children != null && this.resizer.nativeElement.children[this.resizer.nativeElement.children.length - 1].offsetLeft != this.oldWidth) {
          this.oldWidth = this.resizer.nativeElement.children[this.resizer.nativeElement.children.length - 1].offsetLeft as number;
          this.toolbarLeftService.docheckChanged.next(true);
        }
      });
  }



  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    const config = { attributes: false, childList: true, subtree: false };

    this.rightPopupObserver?.disconnect();
    this.rightPopupObserver = new MutationObserver((mutation) => {

      if (this.hasItemsInRightPopup && this.rightPopupContent.nativeElement.children.length == 0) {
        this.hasItemsInRightPopup = false;
        this.ref.detectChanges();
      } else {
        if (!this.hasItemsInRightPopup)
          this.hasItemsInRightPopup = true;
        this.ref.detectChanges();
      }

    })
    this.rightPopupObserver.observe(this.rightPopupContent.nativeElement, config);
    this.hasItemsInRightPopup = this.rightPopupContent.nativeElement.children.length > 0;


    this.leftContentObserver?.disconnect();
    this.leftContentObserver = new MutationObserver((mutation) => {

      if (this.resizer.nativeElement.children[this.resizer.nativeElement.children.length - 1].offsetLeft != this.oldWidth) {
        this.oldWidth = this.resizer.nativeElement.children[this.resizer.nativeElement.children.length - 1].offsetLeft as number;
        this.toolbarLeftService.docheckChanged.next(true);
      }

    })
    this.leftContentObserver.observe(this.resizer.nativeElement, config);
    this.toolbarLeftService.docheckChanged.next(true);

    this.ref.detectChanges();

    this.isInitialized = true;
  }


  ngDoCheck(): void {
    if (this.docheckChanged_Subscription && !this.docheckChanged_Subscription.closed)
      this.docheckChanged.next(true);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.hostService_documentClick_Subscription?.unsubscribe();
    this.docheckChanged_Subscription?.unsubscribe();

    this.rightPopupObserver?.disconnect();
    this.leftContentObserver?.disconnect();
  }


  public onToggleLeft(): void {
    this.leftToggle(!this.leftShow);
  }

  public onToggleRight(): void {
    this.rightToggle(!this.rightShow);
  }

  public leftToggle(show?: boolean): void {
    this.leftShow = show !== undefined ? show : !this.leftShow;
    this.ref.detectChanges();
  }

  public rightToggle(show?: boolean): void {
    this.rightShow = show !== undefined ? show : !this.rightShow;
    this.ref.detectChanges();
  }

  private leftContains(target: any): boolean {
    return this.leftAnchor.nativeElement.contains(target) ||
      (this.leftPopup ? this.leftPopup.nativeElement.contains(target) : false);
  }

  private rightContains(target: any): boolean {
    return this.rightAnchor.nativeElement.contains(target) ||
      (this.rightPopup ? this.rightPopup.nativeElement.contains(target) : false);
  }


  //#endregion
}
