import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { BaseComponent } from '../_base/base.component';


@Component({
  selector: 'app-image-thumb-zoomable',
  templateUrl: './image-thumb-zoomable.component.html',
  styleUrls: ['./image-thumb-zoomable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageThumbZoomableComponent extends BaseComponent {

  //#region Properties...

  @Input()
  public thumbImageContainerClass: string = "default-container";
  
  @Input()
  public thumbImageContainerStyle: string = "";

  @Input()
  public thumbImageUrl: string = "";
  
  @Input()
  public thumbImageStyle: string = "";

  @Input()
  public imageUrl: string = "";

  @Input()
  public description: string = "";
  
  public isPhotoPopupOpen: boolean = false;

  public mapCanvas: ElementRef;
  @ViewChild('imgcanvas') set imgcanvas(imgcanvas: ElementRef) {
    this.mapCanvas = imgcanvas;

    this.loadFullImage();

  }

  private map: L.Map;
  private availableHeight: number;
  private scaleFactor: number;

  private bounds: L.LatLngBounds;
  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector,);
  }

  //#endregion

  //#region Methods...


  public loadFullImage() {
    if (this.mapCanvas != null) {
      setTimeout(() => {
        this.zone.runOutsideAngular(() => {

          if (this.map != null) {
            this.map.remove();
          }

          this.map = L.map(this.mapCanvas.nativeElement, {
            crs: L.CRS.Simple,
            minZoom: -5,
            dragging: true,
            touchZoom: true,
            tap: true,
            zoomControl: false,
          });

          // Cerco il parent che ha la classe container-fluid...
          let parent = this.mapCanvas.nativeElement.parentElement;
          let foundCorrectWrapper: boolean = false;
          while (foundCorrectWrapper == false && parent != null) {
            if (parent.className.indexOf('container-fluid') > -1) {
              foundCorrectWrapper = true;
            } else {
              parent = parent.parentElement;
            }
          }

          // Calcolo lo spazio disponibile...
          this.availableHeight = parent.offsetHeight - 20 - this.mapCanvas.nativeElement.offsetTop;

          // Ora devo misurare la grandezza dell'immagine così che posso ricalcolare la larghezza in funzione del fattore di zoom...
          const img = new Image();
          const context = this;

          img.onload = function () {
            // calcolo il fattore di scala tra l'immagine e il contenitore
            context.scaleFactor = context.availableHeight / img.height;

            // calcolo la width in funzione della altezza ottenuta, mantenendo il fattore di scala...
            const factorizedWidth = img.width * context.scaleFactor;

            // imposto i confini del canvas...
            context.bounds = new L.LatLngBounds([
              [0, 0],
              [context.availableHeight, factorizedWidth],
            ]);
            
            // carico l'immagine della mappa nel camvas...
            const image = L.imageOverlay(context.imageUrl, context.bounds);
            image.addTo(context.map);
            context.map.fitBounds(context.bounds);
            image.bringToFront();
          };

          img.src = this.imageUrl;
        });
      }, 100);
    }
  }

  public showFullScreenImage() {
    this.isPhotoPopupOpen = true;
  }
  public closeFullScreenImage() {
    this.isPhotoPopupOpen = false;
  }
  //#endregion
}
