import { IPluginData } from '../interfaces/IPluginData';

export class PersCrudPanel  {

  //#region Declarations...

  private _alreadyExpanded = false;

  //#endregion

  //#region Constructors...
  constructor(_module: IPluginData, _preloadUid: string) {
    this.module = _module;
    this.preloadUid = _preloadUid;
  }
  //#endregion


  //#region Properties...

  public error: boolean = false;

  // PRELOADUID
  public _preloadUid: string = '';
  get preloadUid() {
    return this._preloadUid;
  }
  set preloadUid(val) {
    this._preloadUid = val;

    // if (this._preloadUid == 'cancel')
    //  this.dataWrapper = null;

    this._alreadyExpanded = false;

    if (this.expanded) {
      this.uid = val;
      this._alreadyExpanded = true;
    } else {
      this.uid = '';
    }
  }

  // UID
  public uid: string = '';

  // EXPANDED
  private _expanded: boolean = false;
  get expanded() {
    return this._expanded;
  }
  set expanded(val) {
    this._expanded = val;

    if (!this._alreadyExpanded) {
      if (val && this.preloadUid != '') {
        this.uid = this.preloadUid;
        this._alreadyExpanded = true;
      }
    }
  }
  public module: IPluginData;

  public isVisible(): boolean{
    return true;
  }

  //#endregion

}
