import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PopupTitlesTypes } from '../../../enums/PopupTitlesTypes';
import { AuthenticationService } from '../../common/authentication.service';
import { MessageService } from '../../common/message.service';
import { PopupService } from '../../common/popup.service';
import { TranslationService } from '../../common/translation.service';
import { BaseService } from './base.service';



@Injectable()
export class BaseHttpInterceptorService implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, private translationService: TranslationService, private baseService: BaseService, private popupService: PopupService, private messageService: MessageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.baseService.loader = this.baseService.loader + 1;

    // Clone the request to add the new header.
    let token: string = '';

    let authReq = req;

    if (this.authenticationService != null && this.authenticationService.getToken().length > 0) {

      token = this.authenticationService.getToken();
      authReq = req.clone({ headers: req.headers.set('X-RIBO-AUTH', token) });

      if (authReq.body != null) {
        authReq.body.cultureName = this.translationService.cultureName;
      }
    }


    if (!authReq.url.endsWith('.json') && !authReq.url.endsWith('.js')) {
      // send the newly created request
      return next.handle(authReq)
        .pipe(
          map<any, any>((resp) => {
            if (resp instanceof HttpResponse) {
              this.baseService.loader = this.baseService.loader - 1;

              if (resp.body.payload != null && resp.body.payload.token) {
                this.authenticationService.setToken(resp.body.payload.token);
              }

              if (resp.status != 200 && resp.status != 401) {
                // Cado qui quando ottengo un errore prima ancora di arrivare nel codice delle webapi
                this.messageService.setError(`Http ERROR: ${resp.status.toString()}`, resp.status);
                resp.body.payload = null;
                return null;
              }

              if (resp.body != null && resp.body.statusCode != 200 && resp.body.statusCode != 401) {
                // Cado qui quando ho un errore interno alle webapi
                this.messageService.setError(`Errors inside Smart.1 Desk Services: ${resp.body.errorDesc}`, resp.body.statusCode);
                resp.body.payload = null;
                return null;
              }

              if (resp.body != null && resp.body.payload != null && resp.body.payload.success != null) {
                if (resp.body.payload.success == false) {
                  // In questo caso ho ottenuto un alert dalle webapi (es.: violazione chiave su db)
                  this.popupService.showMessage(PopupTitlesTypes.Warning, resp.body.payload.friendlyMessage);
                  resp.body.payload = null;
                }
              }

              // let payload = resp.body?.payload;

              // if (payload != null) {
              //   this.convert(payload);
              // }

              // Restituisco una response piazzando nel body l'oggetto presente nella proprietà payload...
              return resp.clone({ body: resp.body.payload });
            } else {
              return resp;
            }
          })
          , catchError((error, caught) => {
            if (error instanceof HttpErrorResponse) {
              // ottengo una HttpErrorResponse con 401 tutte le volte che fallisce la verifica del token sul server : return new Microsoft.AspNetCore.Mvc.UnauthorizedResult
              if (error.status !== 401) {
                // Caso mai intercettato ma gestisco l'errore in caso sia diverso da un 401..

                this.messageService.setError(`Errors reacing Smart.1 Desk Services: ${error.message}`, error.status);

                return of(error);
              }
              // Se è un 401: invalid token....
              if (error.url.endsWith('tokenValid')) {
                // Se l'applicativo stava verificando il token in avvio, devo restituire l'errore come observable così che la procedura di verifica rediriga sulla pagina di login...
                return of(error);
              } else {
                // Se l'applicativo stava effettuando una chiamata API qualsiasi e la verifica del token non è andata a buon fine (scaduto o non più valido),
                // devo scatenare l'errore sulla pipeline in modo che venga intercettato dalla funzione handleRetryWhen nel base.service
                return throwError(error);
              }
            } else {
              // Gestisco l'errore (verrà intercettato nell'errors-handler.service) se non ho ottenuto un HttpErrorResponse
              return throwError(error);
            }
          }) as any,
        );
    } else {
      return next.handle(authReq);
    }
  }


  // private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

  // private iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;


  // isIsoDateString(value: any): boolean {
  //   if (value === null || value === undefined) {
  //     return false;
  //   }
  //   if (typeof value === 'string') {
  //     let test = this._isoDateFormat.test(value);

  //     if (!test)
  //       test = this.iso8601.test(value);

  //     return test;
  //   } return false;
  // }

  // convert(body: any) {
  //   if (body === null || body === undefined) {
  //     return body;
  //   }
  //   if (typeof body !== 'object') {
  //     return body;
  //   }
  //   for (const key of Object.keys(body)) {
  //     const value = body[key];
  //     if (this.isIsoDateString(value)) {
  //       body[key] = new Date(value);
  //     } else if (typeof value === 'object') {
  //       this.convert(value);
  //     }
  //   }
  // }
}


