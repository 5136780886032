import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { BaseComponent } from '../_base/base.component';


@Component({
  selector: 'app-label-date',
  templateUrl: './label-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelDateComponent extends BaseComponent implements OnInit {

  //#region Properties...

  private intlService: IntlService;

  // COLOR
  @Input()
  public color: string;

  // TEXT
  private _text: string;
  @Input()
  get text() {
    return this._text;
  }
  set text(val) {
    this._text = val;
  }

  @Input()
  public format: string = 'd';

  private _value: any;
  @Input()
  get value() {
    return this._value;
  }
  set value(val) {
    if (val != null) {
      if (val instanceof Date) {
        this._text = this.intlService.formatDate(val, this.format);
      } else {
        this._text = this.intlService.formatDate(new Date(val), this.format);
      }
    } else {
      this._text = '';
    }

    this._value = val;
    this.injector.get(ChangeDetectorRef).detectChanges();  
  }

  //#endregion

  //#region Constructors...

  constructor(injector: Injector) {
    super(injector);
    this.intlService = injector.get(IntlService);
  }


  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    if (this.value != null) {
      if (this.value instanceof Date) {
        this.text = this.intlService.formatDate(this.value, this.format);
      } else {
        this.text = this.intlService.formatDate(new Date(this.value), this.format);
      }
    } else {
      this.text = '';
    }

    this.injector.get(ChangeDetectorRef).detectChanges();  
  }

  //#endregion
}
