


import { BaseFilterRequest } from './BaseFilterRequest';
export class BaseRepoRequest extends BaseFilterRequest  {

  public reportUid: string =  '00000000-0000-0000-0000-000000000000';
}





