import { SortDescriptor, DataSourceRequestState, CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IBaseGridContract } from '../interfaces/IBaseGridContract';
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { from } from 'rxjs';
import { CoreLib_Classes_ObjectHelper, CoreLib_Classes_StringHelper, CoreLib_Services_Http_DeskCommonService } from 'core';
import { map } from 'rxjs/operators';
import { CommonAccountDeskGridStateColumnOrderSettingDataRequest, CommonAccountDeskGridStateColumnSizeSettingDataRequest, CommonAccountDeskGridStateSetColumnOrderSettingsRequest, CommonAccountDeskGridStateSetColumnSizeSettingsRequest, CommonGridResponse, CoreConstants } from 'dto';
import { GridComponent as KendoGridComponent, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CommonGridColumnDataDefinition } from 'dto';
import { IBaseGridResponseContract } from '../interfaces/IBaseGridResponseContract';
import { CoreLib_Classes_JsonHelper } from 'core';
import { IBaseGridViewContract } from '../interfaces/IBaseGridViewContract';


export class GridHelperMethods {

  static parseFieldNameForPers(fieldName: string) {
    return fieldName?.replace('persData.', '');
  }
  static afterGridResponseSet<TAggregationDto>(view: IBaseGridContract, val: CommonGridResponse<GridDataResult, TAggregationDto>) {
    if (val != null) {
      view.items = val.dataSource;
      view.gridSetting.sortSetting = val.sortSetting;
      view.gridSetting.searchSchemaName = val.searchSchemaName;
      view.gridSetting.columnsDefinitions = val.columnsDefinitions;

      if (view.gridSetting.columnsDefinitions != null) {
        for (const col of view.gridSetting.columnsDefinitions.filter(c => c.isVisible)) {

          //Aggancio oggetti template celle
          if (col.templateOutlet != null && col.templateOutlet != '') {

            // se trovo il template nella view prendo quello...
            col.template = (view as any)[col.templateOutlet];

            // se non lo trovo nella view, se ho un searchSchemaTemplate lo cerco li...
            if (col.template == null && view.searchSchemaTemplate != null) {
              col.template = (view.searchSchemaTemplate as any)[col.templateOutlet];
            }

            if (col.template == null) {
              throw new Error('Unable to find templateOutlet ' + col.templateOutlet);
            }
          }

          //Aggancio oggetti template filtri
          if (col.filterTemplateOutlet != null && col.filterTemplateOutlet != '') {

            col.filterTemplate = (view as any)[col.filterTemplateOutlet];

            if (col.filterTemplate == null && view.searchSchemaTemplate != null) {
              col.filterTemplate = (view.searchSchemaTemplate as any)[col.filterTemplateOutlet];
            }

            if (col.filterTemplate == null) {
              throw new Error('Unable to find filterTemplateOutlet ' + col.filterTemplateOutlet);
            }

          }

          //Processamento converter        
          GridHelperMethods.processConverters(view, col, view.items.data);

        }
      }
    }

    GridHelperMethods.setSortSetting(val?.sortSetting, view.state);
    GridHelperMethods.setFilterSetting(val?.filterSetting, view.state);
  }


  public static handleItemsLoaded(view: IBaseGridContract) {
    if (!view.gridSetting.isLoaded) {
      view.gridSetting.isLoaded = true;
      view.injector.get(ChangeDetectorRef).detectChanges();
    }
  }

  public static setSortSetting(sortSetting: string, state: DataSourceRequestState): void {
    state.sort = [];
    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(sortSetting)) {
      let sortToParse = JSON.parse(sortSetting);
      for (const s of sortToParse) {
        let dir: string = 'asc';
        if (s.SortDirection == 1)
          dir = 'desc';
        state.sort.push(<SortDescriptor>{ field: s.Member, dir: dir });
      }
    }
  }

  public static setFilterSetting(filterSetting: string, state: DataSourceRequestState): void {
    //state.filter = null;
    if (!CoreLib_Classes_StringHelper.isNullOrWhiteSpace(filterSetting)) {
      state.filter = <CompositeFilterDescriptor>{
        filters: JSON.parse(filterSetting, GridHelperMethods.dateParser),
        logic: 'and'
      };
    }
  }

  static dateParser(key: any, value: any) {
    if (key == 'value') {
      return CoreLib_Classes_JsonHelper.dateParser(key, value);
    } else {
      return value;
    }
  };

  public static async getAllItems<TGridDto, TAggregationDto>(contractView: IBaseGridContract, view: IBaseGridResponseContract<TGridDto, TAggregationDto>): Promise<any> {
    const state = CoreLib_Classes_ObjectHelper.deepCopy(view.state);
    state.take = CoreConstants.DESKGRID_NOPAGING;
    const observable = from(view.getItems(state, null)).pipe(map(c => c.dataSource));
    const result = await observable.toPromise();

    //Processamento converters
    if (contractView.gridSetting.columnsDefinitions != null) {
      for (const col of contractView.gridSetting.columnsDefinitions.filter(c => c.isExcelVisible)) {
        GridHelperMethods.processConverters(contractView, col, result.data);
      }
    }

    return result;
  }

  public static baseOnExcelExport(e: any): void {

    const rows = e.workbook.sheets[0].rows;

    // set alternating row color
    let altIdx = 0;
    rows.forEach((row: any) => {
      let maxRowsInCell: number = 0;
      row.cells.forEach((cell: any) => {
        if (cell.value) {
          let rowsInThisCell = cell.value.toString().split('\r\n').length;
          if (rowsInThisCell > 1) {
            cell.wrap = true;
          }
          maxRowsInCell = Math.max(rowsInThisCell, maxRowsInCell);
        }
      });
      row.height = 20 * maxRowsInCell;
    });
  }

  public static processConverters(contractView: IBaseGridContract, col: CommonGridColumnDataDefinition, data: any) {

    let schema: any = contractView.searchSchemaTemplate;

    if (schema == null)
      schema = contractView;

    let fConverter = (schema as any)[col.valueField + 'Converter'];

    if (fConverter != null) {
      for (let row of data) {

        if (col.textField != null && col.textField != '') {
          row[col.textField] = fConverter.bind(schema)(row);
        } else {
          row[col.valueField] = fConverter.bind(schema)(row);
        }

      }
    }
  }

  public static async saveColumnResizeState<TGridDto, TAggregationDto>(view: IBaseGridResponseContract<TGridDto, TAggregationDto>): Promise<void> {
    if (view.gridResponse != null) {
      const api: CoreLib_Services_Http_DeskCommonService = view.injector.get(CoreLib_Services_Http_DeskCommonService);
      const request = new CommonAccountDeskGridStateSetColumnSizeSettingsRequest();
      request.searchSchemaName = view.gridResponse.searchSchemaName;
      request.gridColumnSizeSettings = view.grid.columns.filter(item => (item as any).field != null).map(item => <CommonAccountDeskGridStateColumnSizeSettingDataRequest>{ fieldName: GridHelperMethods.parseFieldNameForPers((item as any).field), width: item.width });

      await api.accountDeskGridStateSetColumnSizeSettings(request);
    }
  }

  public static async saveColumnReorderState<TGridDto, TAggregationDto>(view: IBaseGridResponseContract<TGridDto, TAggregationDto>): Promise<void> {
    if (view.gridResponse != null) {
      const api: CoreLib_Services_Http_DeskCommonService = view.injector.get(CoreLib_Services_Http_DeskCommonService);
      const request = new CommonAccountDeskGridStateSetColumnOrderSettingsRequest();
      request.searchSchemaName = view.gridResponse.searchSchemaName;
      request.gridColumnOrderSettings = view.grid.columns.filter(item => (item as any).field != null).map(item => <CommonAccountDeskGridStateColumnOrderSettingDataRequest>{ fieldName: GridHelperMethods.parseFieldNameForPers((item as any).field), orderIndex: item.orderIndex });

      await api.accountDeskGridStateSetColumnOrderSettings(request);
    }
  }


  public static async handleDataStateChange(view: IBaseGridContract, state: DataStateChangeEvent) {
    view.state = state;
    await view.loadItems();
    const dt = view.injector.get(ChangeDetectorRef) as ChangeDetectorRef;
    dt.markForCheck();
  }

  // public static getComposedValueFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
  //   return (columnDefinition.isPersField) ? 'persData.' + columnDefinition.valueField + '' : CoreLib_Classes_StringHelper.replaceAll(columnDefinition.valueField, "_", ".");
  // }

  public static getValueFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    return (columnDefinition.isPersField) ? 'persData.' + columnDefinition.valueField + '' : columnDefinition.valueField;
  }

  public static getTextFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    if (CoreLib_Classes_StringHelper.isNullOrWhiteSpace(columnDefinition.textField)) {
      return (columnDefinition.isPersField) ? 'persData.' + columnDefinition.valueField + '' : columnDefinition.valueField;
    } else {
      return (columnDefinition.isPersField) ? 'persData.' + columnDefinition.textField + '' : columnDefinition.textField;
    }
  }

  public static getComposedTextFieldName(columnDefinition: CommonGridColumnDataDefinition): string {
    if (CoreLib_Classes_StringHelper.isNullOrWhiteSpace(columnDefinition.textField)) {
      return (columnDefinition.isPersField) ? 'persData.' + columnDefinition.valueField + '' : CoreLib_Classes_StringHelper.replaceAll(columnDefinition.valueField, "_", ".");
    } else {
      return (columnDefinition.isPersField) ? 'persData.' + columnDefinition.textField + '' : CoreLib_Classes_StringHelper.replaceAll(columnDefinition.textField, "_", ".");
    }
  }

  public static getFieldTitle(view: IBaseGridContract, columnDefinition: CommonGridColumnDataDefinition): string {
    const thisObject = (view.searchSchemaTemplate != null) ? view.searchSchemaTemplate : view;

    let result: string = "";

    if (columnDefinition.description != null && columnDefinition.description != '') {
      result = columnDefinition.description
    } else {
      if (columnDefinition.translationIsCommon == true) {

        //se lo trovo sulla view prendo quello
        result = view.localizeByCommon[columnDefinition.translationKey];

        //se non l'ho trovato sulla view lo cerco nel searchSchemaTemplate
        if (result == null && view.searchSchemaTemplate != null) {
          result = view.searchSchemaTemplate.localizeByCommon[columnDefinition.translationKey];
        }

      } else {

        //se lo trovo sulla view prendo quello
        result = view.localizeByModule[columnDefinition.translationKey];

        //se non l'ho trovato sulla view lo cerco nel searchSchemaTemplate
        if (result == null && view.searchSchemaTemplate != null) {
          result = view.searchSchemaTemplate.localizeByModule[columnDefinition.translationKey];
        }
      }

      if (result == null) {
        result = columnDefinition.translationKey;
      }
    }

    return result;
  }
  public static getParentContext(view: IBaseGridContract): any {
    return (view.searchSchemaTemplate != null) ? view.searchSchemaTemplate : view;
  }


  public static getMasterColumnsWidth(tbl: any) {
    var result = 0;
    var lastResult = 0;
    tbl.querySelector("colgroup").querySelectorAll("col").forEach(function (element: any) {
      lastResult = parseInt(element.style.width || 0, 10);
      result += lastResult
    });

    result -= lastResult
    return result;
  }

  public static adjustLastColumn(view: IBaseGridContract, grid: KendoGridComponent) {
    const ngZone = view.injector.get(NgZone) as NgZone;
    //console.log("handleFilterTxtKeyDown");
    ngZone.runOutsideAngular(() => {
      var contentDiv = grid.wrapper.nativeElement.querySelector(".k-grid-content");
      var masterHeaderTable = grid.wrapper.nativeElement.querySelector('.k-grid-header-wrap').querySelector('table');
      var masterBodyTable = contentDiv.querySelector("table");
      var gridDivWidth = contentDiv.offsetWidth - 17;

      masterHeaderTable.style.width = "";
      masterBodyTable.style.width = "";

      var headerWidth = this.getMasterColumnsWidth(masterHeaderTable);

      var masterHeaderTableNodes = masterHeaderTable.querySelectorAll('col');
      var lastHeaderColElement = masterHeaderTableNodes[masterHeaderTableNodes.length - 1];
      var dataColElements = masterBodyTable.querySelector("colgroup").querySelectorAll("col");

      var lastDataColElement = dataColElements[dataColElements.length - 1];
      //   lastDataColElement = grid.tbody.parent().children("colgroup").find("col").last(),
      var delta = parseInt(gridDivWidth.toString(), 10) - parseInt(headerWidth.toString(), 10);

      if (delta > 0) {
        delta = Math.abs(delta);
        lastHeaderColElement.style.width = delta + "px";
        lastDataColElement.style.width = delta + "px";
      } else {
        lastHeaderColElement.style.width = "0";
        lastDataColElement.style.width = "0";
      }

      contentDiv.scrollLeft = contentDiv.scrollLeft - 1;
      contentDiv.scrollLeft = contentDiv.scrollLeft + 1;
    });
  }


  public static getMobileExtraFiltersDescription(view: IBaseGridContract) {
    let s: string = '';

    let filterColumns: string[] = [];


    if (view?.state?.filter != null) {
      //Costruisco gli elementi per l'interfaccia
      filterColumns = this.getMobileExtraFiltersArray(view, view?.state?.filter, filterColumns);
    }

    if (filterColumns.length > 0) {
      s = view.localizeByCommon["MOBILEEXTRAFILTERS_SETTED"] ;

      for (let item of filterColumns) {
        s += item + ', ';
      }
      s = s.substring(0, s.length - 2);
    }

    return s;
  }

  public static getMobileExtraFiltersArray(view: IBaseGridContract, filter: CompositeFilterDescriptor | FilterDescriptor, filterColumns: string[]): string[] {

    let s: string = '';
    if (isCompositeFilterDescriptor(filter)) {
      for (var f of (filter as CompositeFilterDescriptor).filters) {
        filterColumns = GridHelperMethods.getMobileExtraFiltersArray(view, f, filterColumns);
      }

    } else if(view?.gridSetting?.columnsDefinitions) {

      var colDef = view.gridSetting.columnsDefinitions.find(c => c.valueField?.replace('persData.', '') == (filter as FilterDescriptor).field.toString()?.replace('persData.', ''));
      if (colDef != null) {
        let colName = GridHelperMethods.getFieldTitle(view, colDef);

        if (filterColumns.indexOf(colName) == -1)
          filterColumns.push(colName);
      }
    }

    return filterColumns;
  }


}
