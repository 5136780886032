import { CoreLib_Classes_SearchRequest, CoreLib_Services_Http_Base_BaseService } from 'core';
import { BaseSearchRequest, CommonCrudEntityGetListResponse } from 'dto';


export class BaseService {

    public ondemandItems: any[];

    constructor(protected baseService: CoreLib_Services_Http_Base_BaseService, protected areaName: string) {

    }

    public async handleOnDemandFilter(filterValue: string, filterOperator: 'startsWith' | 'contains' = 'startsWith', searchRequest: CoreLib_Classes_SearchRequest = null, targetHost: any = null, targetName: string = null) {
        this.ondemandItems = [];
        
        let request: BaseSearchRequest;

        if (searchRequest != null) {
            request = searchRequest.request;
        }

        if (request == null) {
            request = new BaseSearchRequest();
        }

        if (filterOperator == null) {
            filterOperator = 'startsWith';
        }

        request.filterValue = filterValue;
        request.filterOperator = filterOperator;
        request.isComboOnDemand = true;



        let response: CommonCrudEntityGetListResponse<any>;

        if (searchRequest != null) {
            response = await this.baseService.post<CommonCrudEntityGetListResponse<any>>(this.baseService.baseUrl + this.areaName + 'combo' + searchRequest.mode, request).toPromise();
        } else {
            response = await this.baseService.post<CommonCrudEntityGetListResponse<any>>(this.baseService.baseUrl + this.areaName + 'combo', request).toPromise();
        }


        if (response != null) {

            if (!targetHost || !targetHost[targetName]) {
                //this.ondemandItems = [];
                for (const item of response.items) {
                    this.ondemandItems.push(item);
                }
            } else {
                targetHost[targetName] = [];

                for (const item of response.items) {
                    targetHost[targetName].push(item);
                }
            }
        }

    }

}
