




    
export class CommonGroupByCounterDataResponse<T>   {
  
  public counter: number = 0;
  
  public value: T = null;
  
}



