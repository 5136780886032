import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { BaseComponent } from '../_base/base.component';



@Component({
  selector: 'app-async-load-panel',
  templateUrl: './async-load-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsyncLoadPanelComponent extends BaseComponent implements OnInit {

  //#region Properties...

  @Output()
  public init = new EventEmitter();
  //#endregion

  //#region Constructors...

  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector);
  }

  //#endregion

  //#region Methods...

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.init.emit();
  }

  //#endregion
}
