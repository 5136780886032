import { Component, Injector, OnInit } from '@angular/core';
import { ControlsLib_Components_Base_BaseViewComponent } from 'controls';
import { CoreLib_Services_Common_AuthenticationService } from 'core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends ControlsLib_Components_Base_BaseViewComponent implements OnInit {

  //#region Constructors...

  public emailSupport: string = "";

  constructor(injector: Injector
    , public authenticationService: CoreLib_Services_Common_AuthenticationService) {
    super(injector);
    this.formReference = "Home";
  }

  //#endregion

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    
    var emailSupport = this.applicationStateService.getStringSetting('EmailSupport', 'accrediti@atalanta.it');
    this.emailSupport = emailSupport;
  }

  public async goHome(): Promise<void> {
    this.router.navigate(['']);
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  goToEventRequest() {
    this.router.navigate(['event-request']);
  }
  
  goToRegistration() {
    this.router.navigate(['registration']);
  }

}
