import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Injector, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { CoreLib_Classes_Guid } from 'core';
import { Subscription } from 'rxjs';
import { GridSelectionModes } from '../../enums/GridSelectionModes';
import { IBaseCrudViewContract } from '../../interfaces/IBaseCrudViewContract';
import { BaseComponent } from '../_base/base.component';
import { HostService } from '../../services/common/host.service';

@Component({
  selector: 'app-crud-grid-actions',
  templateUrl: './crud-grid-actions.component.html',
  styleUrls: ['./crud-grid-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrudGridActionsComponent extends BaseComponent implements AfterViewInit, OnDestroy {

  private hostService_documentClick_Subscription: Subscription;


  @ViewChild('popup', { read: ElementRef, static: true }) public popup: ElementRef;
  @ViewChild('anchor', { static: true }) public anchor: ElementRef;



  private selectionModeChange_Subscription: Subscription;

  private _show: boolean;

  private uid: string;


  public get show() {
    return this._show;
  }

  public set show(val: boolean) {
    this._show = val;
    this.ref.detectChanges();
  }

  public showExpander: boolean = true;

  public gridSelectionModes: any = GridSelectionModes;


  @Input()
  public crudView: IBaseCrudViewContract;

  @Input()
  public dataItem: any;

  public popupLeft: string;

  constructor(injector: Injector, private hostService: HostService, private scrollToService: ScrollToService, private ref: ChangeDetectorRef) {
    super(injector);
    this.uid = CoreLib_Classes_Guid.newId();

    this.hostService_documentClick_Subscription = this.hostService.documentClick.subscribe(c => {
      if (!this.contains(c.target)) {
        this.toggle(false);
      }
    });

  }
  popupMouseUp() {
    this.hostService.documentClick.next(true);
  }


  private contains(target: any): boolean {
    return this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false);
  }


  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.selectionModeChange_Subscription != null) {
      this.selectionModeChange_Subscription.unsubscribe();
    }
    this.hostService_documentClick_Subscription?.unsubscribe();
  }

  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();
    setTimeout(() => {
      if (this.popup != null && this.popup.nativeElement.innerHTML.indexOf('<button') == -1) {
        this.showExpander = false;
      } else {
        this.showExpander = true;
      }

      this.popupLeft = this.anchor.nativeElement.offsetLeft + 20 + "px";

      this.ref.detectChanges();
    }, 400);


    this.selectionModeChange_Subscription = this.crudView.selectionModeChange.subscribe((c) => {
      this.ref.detectChanges();

    });
  }

  public onToggle(event: any): void {
    event.stopPropagation();
    this.toggle(!this.show);

    //Notifico che un click sul documento è avvenuto così da chiudere eventuali popup inline aperti
    this.hostService.documentClick.next(event);

    if (this.show) {

      const el = document.getElementById('popup' + this.dataItem.uid);
      const t: number = el.offsetTop;

      let parent = el.parentElement;

      while (parent.className.indexOf('k-grid-content') == -1) {
        parent = parent.parentElement;
      }

      setTimeout(() => {
        const config: ScrollToConfigOptions = {
          target: 'popupButton' + this.dataItem.uid,
          offset: -(parent.offsetHeight - el.offsetHeight - 50),
          duration: 50,
        };

        if (!this.elementInViewport(document.getElementById('popup' + this.dataItem.uid))) {
          this.scrollToService.scrollTo(config);
        }
      }, 500);
    }

    this.ref.detectChanges();
  }

  public elementInViewport(el: any): boolean {
    const top = el.offsetTop;
    const height = el.offsetHeight;

    let parent = el.parentElement;

    while (parent.className.indexOf('k-grid-content') == -1) {
      parent = parent.parentElement;
    }

    const val = (top + height - 60 - parent.scrollTop) < parent.offsetHeight;

    return val;
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;

    this.ref.detectChanges();
  }

}
