import { EventEmitter, Injectable } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CoreLib_Services_Common_TranslationService } from 'core';




@Injectable({ providedIn: 'root' })
export class ValidationService {

  //#region Declarations...

  private errorid: number = 0;

  private noerrorid: number = 0;

  private _beforeScrollToErrorAction: () => void = null;

  //public resetErrorsCalled = new EventEmitter();

  //#endregion

  //#region Constructors...

  constructor(private dialogService: DialogService, private scrollToService: ScrollToService, private translationService: CoreLib_Services_Common_TranslationService) {

  }

  //#endregion

  //#region Methods...

  beforeScrollToErrorAction(arg0: () => void): any {
    if (this._beforeScrollToErrorAction == null) {
      this._beforeScrollToErrorAction = arg0;
    }
  }

  public init(): void {
    this.noerrorid = 0;
    this.errorid = 0;
  }

  public resetErrors(): void {
    this._beforeScrollToErrorAction = null;
    this.errorid = 0;
    //this.resetErrorsCalled.emit();
  }

  public getId(hasError: boolean): string {
    if (hasError) {
      this.errorid++;
      return 'errorid' + this.errorid;
    } else {
      this.noerrorid++;
      return 'noerrorid' + this.noerrorid;
    }
  }

  public showAlert() {

    if (this._beforeScrollToErrorAction != null) {
      this._beforeScrollToErrorAction();
    }

    const dialog: DialogRef = this.dialogService.open({
      title: this.translationService.localizeByCommon['ALERT_TITLE_WARNING'],
      content: this.translationService.localizeByCommon['CRUD_VALIDATION_ERROR'],
      actions: [
        { text: this.translationService.localizeByCommon['OK'], primary: true },
      ],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {

      const config: ScrollToConfigOptions = {
        duration: 50,
        target: 'errorid1',
      };

      this.scrollToService.scrollTo(config);

    });

  }

  //#endregion

}
