


import { BaseCrudNestedEntityWrapper } from './BaseCrudNestedEntityWrapper';
export class BaseCrudEntityWrapper<TDto> extends BaseCrudNestedEntityWrapper<TDto>  {

  public isLoaded: boolean =  false;
  public isSelected: boolean =  false;
}





