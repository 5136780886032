<div class="app-user-menu" [class.off]="!isOpenedOneTime" [class.opened]="isOpen" [class.closed]="!isOpen && isOpenedOneTime" [class.small-device]="isSmallDevice">
  <div class="primary-items">
    <div class="user-name"><i>{{authenticationService.accountDescription | async}}</i></div>

    <div class="changeTheme d-none d-sm-block">
      <div class="inner">
        <div class="small" [class.active]="currentTheme == 'small'">
          <span class="k-icon k-i-font-size" (click)="changeTheme('small')"></span>
        </div>
        <div class="standard" [class.active]="currentTheme == 'standard'">
          <span class="k-icon k-i-font-size" (click)="changeTheme('standard')"></span>
        </div>
        <div class="medium" [class.active]="currentTheme == 'medium'">
          <span class="k-icon k-i-font-size" (click)="changeTheme('medium')"></span>
        </div>
        <div class="big" [class.active]="currentTheme == 'big'">
          <span class="k-icon k-i-font-size " (click)="changeTheme('big')"></span>
        </div>
      </div>
    </div>

    <app-switch *ngIf="!authenticationService.isWindowsAuth" [header]="localizeByCommon['IS_DEFAULT_FULL_SCREEN_LOGIN']" [(checked)]="isDefaultFullScreenLogin" class="d-none d-sm-block"></app-switch>

    <div class="menu-button" (click)="showHideFullScreen()"><span>{{localizeByCommon["FULL_SCREEN"]}}</span></div>
    <br *ngIf="isAccountSettingEnabled" />
    <div class="menu-button" *ngIf="isAccountSettingEnabled" (click)="navigateAccountSetting()"><span>{{localizeByCommon["ACCOUNT_SETTING"]}}</span></div>
    <br/>
    <div class="menu-button" *ngIf="!authenticationService.isWindowsAuth" (click)="navigateChangePassword()"><span>{{localizeByCommon["CHANGE_PASSWORD"]}}</span></div>
    <br/>
    <div class="menu-button" *ngIf="!authenticationService.isWindowsAuth" (click)="logout()"><span>{{localizeByCommon["LOGOUT"]}}</span></div>

  </div>
</div>
