<div class="app-text-box" [class.big]="bigger" [class.error]="hasError" [class.hideheader]="hideheader" [class.value]="text" [class.focus]="hasFocus">
  <div [id]="erroranchor"></div>
  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory" *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>
  <input kendoTextBox [type]="type" name="{{name}}" (blur)="onBlur()" (focus)="onFocus()" (keyup)="onKeyUp($event)" style="width:100%"
    [(ngModel)]="text" [hidden]="disabled" [autocomplete]="autocomplete" [maxlength]="maxLength" 
    #input_ref />
  <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span class="mandatory" *ngIf="mandatory"  [innerHtml]="mandatoryText"></span> </span>
  <div class="read-only-input-span" [hidden]="!disabled">{{readOnlyValue}}</div>
  <span class="error-span" [innerHtml]="error"></span>
</div>