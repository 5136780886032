



  import { BaseRequest } from 'dto';
  export class CommonRecoverPasswordAccessRequest extends BaseRequest   {        
          
      public code: string = '';
            
}







