



import { BaseRequest } from './BaseRequest';
export class CommonCrudEntityDeleteRequest extends BaseRequest {        
  
  public uid: string = '00000000-0000-0000-0000-000000000000';
  
}




