




export class CommonAccountDeskGridStateColumnSizeSettingDataRequest  {        
  
  public fieldName: string = '';
  
  public width: number = 0;
  
}




