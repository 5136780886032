
<kendo-grid #grid [hidden]="!gridSetting.isLoaded" [data]="items" [pageSize]="state.take" [skip]="state.skip"
  [sort]="state.sort" [sortable]="true" [pageable]="true" [height]="gridSetting.gridHeight" scrollable="scrollable"
  [resizable]="true" [navigable]="true" [groupable]="groupable" [group]="state.group" [filterable]="filterable"
  [filter]="state.filter" [reorderable]="true" [rowClass]="rowCallback" (excelExport)="onExcelExport($event)">
  <kendo-grid-column *ngIf="isSmallDevice" field="computed" operator="startswith" [class]="'desk-grid-col-computed'">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
      <div *ngIf="isSmallDevice" class="warning-span small">
        {{getMobileExtraFiltersDescription()}}
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="desk-grid-col-computed-container">
        <div class="desk-grid-col-computed-actions">
          <app-grid-empty-actions [dataItem]="dataItem">
            <ng-container class="primary-extra-buttons" [ngTemplateOutlet]="gridSetting.templatePrimaryExtraButtons"
              [ngTemplateOutletContext]="{dataItem:dataItem}"></ng-container>
            <ng-container class="secondary-extra-buttons" [ngTemplateOutlet]="gridSetting.templateSecondaryExtraButtons"
              [ngTemplateOutletContext]="{dataItem:dataItem}"></ng-container>
          </app-grid-empty-actions>
        </div>
        <div class="desk-grid-col-computed-text">
          <ng-container [ngTemplateOutlet]="gridSetting.templateComputed"
            [ngTemplateOutletContext]="{dataItem:dataItem}"></ng-container>
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!isSmallDevice && showActionsColumn" [title]="localizeByCommon['GRIDHEADER_ACTIONS']"
    [columnMenu]="false" [width]="actionsColumnWidth" [sortable]="false" [filterable]="false" [reorderable]="false"
    [class]="'desk-grid-col-actions'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <app-grid-empty-actions [dataItem]="dataItem">
        <ng-container class="primary-extra-buttons" [ngTemplateOutlet]="gridSetting.templatePrimaryExtraButtons"
          [ngTemplateOutletContext]="{dataItem:dataItem}"></ng-container>
        <ng-container class="secondary-extra-buttons" [ngTemplateOutlet]="gridSetting.templateSecondaryExtraButtons"
          [ngTemplateOutletContext]="{dataItem:dataItem}"></ng-container>
      </app-grid-empty-actions>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column *ngFor="let columnDefinition of gridColumns" [sortable]="columnDefinition.sortable"
    [filterable]="columnDefinition.filterType != 'none'" [field]="view.getValueFieldName(columnDefinition)"
    [width]="(columnDefinition.width > 0) ? columnDefinition.width : null"
    [title]="view.getFieldTitle(columnDefinition)" [hidden]="isSmallDevice"
    [minResizableWidth]="(columnDefinition.filterType == 'date') ? 200 : 120">
    <ng-template kendoGridGroupHeaderTemplate let-group="group" let-aggregates="aggregates">
      <app-grid-dynamic-group-cell [title]="view.getFieldTitle(columnDefinition)" [group]="group"
        [aggregates]="aggregates" [columnDefinition]="columnDefinition">
      </app-grid-dynamic-group-cell>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <app-grid-dynamic-filter-cell [column]="column" [filter]="filter" [columnDefinition]="columnDefinition"
        style="display: block;" [style.minWidth]="(columnDefinition.filterType == 'date') ? '200px' : null"
        [parentContext]="view.parentContext" [view]="view"></app-grid-dynamic-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <app-grid-dynamic-cell [dataItem]="dataItem" [columnDefinition]="columnDefinition"></app-grid-dynamic-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="!isSmallDevice && showRecordInfoColumn" [title]="localizeByCommon['GRIDHEADER_RECORDINFO']"
    [columnMenu]="false" [width]="35" [sortable]="false" [filterable]="false" [reorderable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
      <app-record-info [dataItem]="dataItem"></app-record-info>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!isSmallDevice" [title]=" " [columnMenu]="false" [sortable]="false" [filterable]="false"
    [reorderable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>

    </ng-template>
  </kendo-grid-column>
  <ng-template kendoGridToolbarTemplate [position]="toolbarPosition" *ngIf="gridSetting.templateToolbar">
    <ng-container class="toolbar" [ngTemplateOutlet]="gridSetting.templateToolbar"></ng-container>
  </ng-template>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons *ngIf="!isSmallDevice" [buttonCount]="10"></kendo-pager-numeric-buttons>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
  </ng-template>
  <kendo-grid-excel fileName="{{view.excelFileName}}" [fetchData]="allData">
    <kendo-excelexport-column *ngFor="let columnDefinition of excelColumns"
      [field]="view.getComposedTextFieldName(columnDefinition)" [title]="view.getFieldTitle(columnDefinition)"
      [cellOptions]="getCellOptions(columnDefinition)">
    </kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>