




import { BaseUidDataResponse } from './BaseUidDataResponse';    
export class CommonNestedDataResponse extends BaseUidDataResponse  {
  
  public description: string = '';
  
}



