import { Compiler, Injectable, Injector, Type } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IPluginData } from '../../interfaces/IPluginData';


@Injectable({ providedIn: 'root' })
export class RouterService {

  private router: Router;

  constructor(private injector: Injector) {

  }

  createAndRegisterRoute(moduleToRegister: IPluginData, component: Type<any>) {
    const route: Route = {
      path: moduleToRegister.destinationRoutePath,
      component,
      data: moduleToRegister,
    };

    this.registerRoute(route);
  }

  private routeIsRegistered(path: string) {
    return this.router.config.filter((r) => r.path === path).length > 0;
  }

  registerRoute(route: Route) {

    this.router = this.injector.get(Router);


    if (this.routeIsRegistered(route.path)) { return; }


    this.router.config.push(route);
    this.updateRouteConfig(this.router.config);
  }

  unRegisterRoute(path: string) {
    this.router = this.injector.get(Router);
    this.updateRouteConfig(this.router.config.filter((route) => route.path !== path));
  }

  private updateRouteConfig(config: Routes) {
    this.router.resetConfig(config);
  }


}
