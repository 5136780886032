import { Directive, EventEmitter, Injector, Input, OnDestroy, Output } from '@angular/core';
import { ValidationService } from '../../services/common/validation.service';
import { BaseComponent } from './base.component';


/**
 * E' la classe base di tutti componenti di tipo Input (Textbox Combobox, ecc).
 */
@Directive()
export class BaseInputControlComponent extends BaseComponent implements OnDestroy {

  //#region Public Input Properties...

  /**
   * Un valore boolean che indica se l'oggetto deve essere mostrato nella sua modalità "grande" o meno (di fatto al nodo padre del componente viene appesa anche la classe css "bigger")
   */
  @Input()
  public bigger: boolean = false;

  /**
   * Un valore boolean che indica se l'etichetta descrittiva deve essere nascosta o meno. Agisce con *ngIf
   */
  @Input()
  public hideheader: boolean = false;

  private _header: string;
  /**
   * Il testo dell'etichetta descrittiva
   */
  @Input()
  get header() {
    if (this._header == undefined) {
      return '';
    } else {
      return this._header;
    }
  }
  set header(val) {
    this._header = val;
  }

  /**
   * Il codice colore da assegnare alla proprietà style.color dell'etichetta descrittiva
   */
  @Input()
  public headercolor: string = null;


  private _disabled: boolean = false;
  /**
   * Se impostato a true, inibisce la possibilità di modificare il valore dell'oggetto
   */
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(val) {
    this._disabled = val;
    this.setHeaderHidden();
    this.setReadOnlyValue();
  }

  
  /**
   * Un valore boolean che indica se il campo è obbligatorio
   */
  @Input()
  public mandatory: boolean = false;

  
  @Input()
  public mandatoryText: string = '*';

  
  /**
   * Il testo con l'errore di validazione
   */
  private _error: string;
  @Input()
  get error() {
    return this._error;
  }
  set error(val) {
    this._error = val;

    this.hasError = (val != null && val.length > 0);

    if (this.validationService != null) {
      this.erroranchor = this.validationService.getId(this.hasError);
    }

    this.errorChange.emit(val);
  }
  @Output() errorChange = new EventEmitter();

  
  //#endregion

  //#region Public Properties...

  /**
   * Definisce internamente se l'etichetta descrittiva è visibile o meno. Vince su hideHeader nel senso che applica lo stile Visibility:hidden o visible
   */
  public headerhidden: boolean = true;

  /**
   * Ospita il valore da mostrare quando l'oggetto è in modalità "read only"
   */
  public readOnlyValue: string;

  /**
   * Restituisce un valore booleano che informa se sono presenti errori di validazione per il valore dell'oggetto
   * Viene settato internamente: a true se la proprietà error ha un valore, a false se la proprietà error non ha valore
   */
  public hasError: boolean;

  /**
   * Ospita l'id assegnato all'ancora dell'oggetto. Utile per rendere visibile all'utente il primo oggetto nella pagina che presenta errori di validazione.
   */
  public erroranchor: string;

  public hasFocus: boolean;
  //#endregion

  //#region Constructor...

  /**
 * Inizializza una nuova istanza del componente.
 *
 * @param injector Il servizio che consente di ricavare le istanze di altri servizi tramite dependency injection
 * @param validationService Il servizio ValidationService
 */
  constructor(injector: Injector, private validationService: ValidationService) {
    super(injector);
  }

  //#endregion

  //#region Lifecycle Methods...

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

    //#endregion

  //#region Private Methods...


    //#endregion

  //#region Protected Methods...

    //#endregion

  //#region Public Methods...

  /**
   * Viene sovrascritto dalla classe derivata per stabilire il valore della proprietà headerHidden
   */
  public setHeaderHidden() {

  }

  /**
   * Viene sovrascritto dalla classe derivata per stabilire il valore della proprietà readOnlyValue
   */
   public setReadOnlyValue() {

  }
  //#endregion


}
