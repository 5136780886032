



import { BaseRequest } from './BaseRequest';
export class CommonNotifyMenuBadgeChangeRequest extends BaseRequest {        
  
  public itemRoute: string = '';
  
  public badgeText: string = '';
  
}




