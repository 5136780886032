import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({ providedIn: 'root' })
export class GridService {

  //#region Declarations...

  public gridFilterCleared = new Subject<boolean>();

  public gridFilterRefresh = new Subject<boolean>();
  //#endregion

  //#region Constructors...

  constructor() {

  }

  //#endregion

  //#region Methods...


  //#endregion

}
