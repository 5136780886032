<div class="app-autocomplete" [class.big]="bigger" [class.error]="hasError" [class.ondemand]="ondemand"
  [class.hideheader]="hideheader">
  <div [id]="erroranchor"></div>

  <label class="header" [style.color]="headercolor" *ngIf="!hideheader" [style.visibility]="headerhidden ? 'hidden' : 'visible'"
    title="{{header}}"><span class="text" [innerHtml]="header"></span><span class="mandatory"
      *ngIf="mandatory && !disabled" [innerHtml]="mandatoryText"></span></label>

  <div class="read-only-input-span" [hidden]="!disabled">{{readOnlyValue}}</div>
  <div *ngIf="!disabled" class="d-flex">
    <div class="align-self-start" style="width:100%" (click)="loadItemsIfEmpty($event)">
      <kendo-autocomplete [data]="itemsSource" [(ngModel)]="selectedItem" [filterable]="ondemand"
        [valueField]="valueField" [suggest]="true"  (filterChange)="handleFilter($event)"
        (valueChange)="handleValueChange($event)" [placeholder]="''"
        [popupSettings]="{ popupClass: (bigger) ? 'app-autocomplete-popup big' : 'app-autocomplete-popup'}">
        <ng-template kendoAutocompleteItemTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}">
          </ng-container>
        </ng-template>
      </kendo-autocomplete>

      <span class="placeholder" *ngIf="headerhidden && !disabled"><span class="text" [innerHtml]="header"></span> <span
      class="mandatory" *ngIf="mandatory" [innerHtml]="mandatoryText"></span> </span>

    </div>
  </div>
  <span class="error-span" [innerHtml]="error"></span>
</div>
