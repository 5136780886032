



export class BaseCrudNestedEntityWrapper<TDto>   {

  public uid: string =  '00000000-0000-0000-0000-000000000000';
  public originalHash: string =  '';
  public dataItem: TDto =  null;
  public isDeleted: boolean =  false;
  public errors: { [key: string]: string; } =  {};
}





