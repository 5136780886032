




import { CommonTranslationGetAllByFormDataResponse } from './CommonTranslationGetAllByFormDataResponse';
import { BaseResponse } from './BaseResponse';    
export class CommonTranslationGetAllByFormResponse extends BaseResponse  {
  
  public translations: CommonTranslationGetAllByFormDataResponse[] = [];
  
}



